import { Component, Input, OnInit } from '@angular/core';
import * as languageLibrary from '../../../../services/language'
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MasterService } from 'src/app/services/master.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'service-notes',
  templateUrl: './service-notes.component.html',
  styleUrls: ['./service-notes.component.scss']
})
export class ServiceNotesComponent implements OnInit {

  // Unique internal identity of the associated contact
  @Input() ticketId: number = 0
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  // save the note
  public notes = []
  public noteSelected: number = 0

  // A handle to navbar event messages so we can unsubscribe later.
  private navbarWatcher: any = null
  
  // Group of inputs
  public information = new FormGroup({
    text: new FormControl(null, [Validators.required]),
    id: new FormControl(null),
    alertFlag: new FormControl(null),
    serviceTicketHeaderId: new FormControl(null, [Validators.required])
  })

  constructor(private master: MasterService, private ms: MessageService) {
    this.navbarWatcher = this.ms.channelComponents$.subscribe(res => {
      if (res.message == 'changeLanguage') {
        this.language = res.value
      }
    })
  }

  ngOnInit() {
    this.resetInformation()

    if (!this.ticketId) {
      return
    }

    this.getNotes()
  }

  ngOnDestroy() {
    if (!this.navbarWatcher) {
      return
    }

    this.navbarWatcher.unsubscribe()
  }

  resetInformation() {
    this.noteSelected = 0
    this.information.reset();
    this.information.get('serviceTicketHeaderId').setValue(this.ticketId);
  }

  // get notes
  public getNotes() {
    this.master.get(`service/${this.ticketId}/notes`, (res) => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.notes = res.data.notes
      if (res.data.alertExists) {
        (document.getElementById('btn-note-alert') as HTMLButtonElement).click();
      }
    })
  }

  // add new note
  public save = (): void => {
    const identity = parseInt(this.information.get('id').value)
    this.information.get('serviceTicketHeaderId').setValue(this.ticketId)
    if (isNaN(identity)) {
      this.createNote()
      return
    }

    this.modifyNote(identity)
  }

  public createNote() {
    if (this.information.invalid) {
      return
    }

    this.master.post(`service/${this.ticketId}/notes`, { information: this.information.value }, (res) => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.resetInformation()
      this.notes = res.data.notes
    })
  }

  public modifyNote(identity: number) {
    if (this.information.invalid) {
      return
    }

    this.master.put(`service/${this.ticketId}/notes/${identity}`, { information: this.information.value }, (res) => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.resetInformation()
      this.notes = res.data.notes
    })
  }


  public deleteNote(identity: number) {
    this.master.discard(`service/${this.ticketId}/notes/${identity}`, (res) => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.resetInformation()
      this.notes = res.data.notes
    })
  }

  // set note form for update
  public setInformation = (note: Object): void => {
    (document.getElementById('save-btn-note').innerHTML = "Update")
    this.noteSelected = note['id']
    this.information.setValue({
      text: note['text'],
      id: note['id'],
      alertFlag: note['alertFlag'],
      serviceTicketHeaderId: this.ticketId
    })
  }

}
