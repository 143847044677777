import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './views/login/login.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ProfileDashboardComponent } from './views/profile-dashboard/profile-dashboard.component'
// activation views
import { EmailComponent } from './views/activation-views/email/email.component'
import { ActivateAccountComponent } from './views/activation-views/activate-account/activate-account.component';
import { ResetPasswordComponent } from './views/activation-views/reset-password/reset-password.component';
import { ResetChangePasswordComponent } from './views/activation-views/reset-change-password/reset-change-password.component';
// import { InvitationsActivationComponent } from './views/activation-views/invitations-activation/invitations-activation.component'
// contacts views
import { UsersComponent } from './views/users/users.component'
import { ContactsComponent } from './views/contacts-views/contacts/contacts.component'
import { ContactsDetailComponent } from './views/contacts-views/contacts/contacts-detail/contacts-detail.component'
import { HistoryComponent } from './views/contacts-views/contacts/history/history.component'
// corporations
import { CorporationsComponent } from './views/corporateSetup-views/views/corporations/corporations.component'
import { CorporationsDetailComponent } from './views/corporateSetup-views/views/corporations-detail/corporations-detail.component'
// security
import { SecurityComponent } from './views/security/security.component'
// inventory
import { InventoryComponent } from './views/inventory/inventory/inventory.component'
import { InventoryDetailComponent } from './views/inventory/inventory-detail/inventory-detail.component'
// reports
import { BuyersReportComponent } from './views/reports/buyers-report/buyers-report.component'
// sales
import { SalesComponent } from './views/sales/sales/sales.component'
import { SalesDetailComponent } from './views/sales/sales-detail/sales-detail.component'
import { ServiceComponent } from './views/service/service.component';
import { ServiceDetailComponent } from './views/service/components/service-detail/service-detail.component';
import { IntegrationComponent } from './views/integration-views/integration.component'
import { AutoInsuranceComponent } from './views/sales/components/auto-insurance/auto-insurance.component'
import { DealsComponent } from './views/deals/deals.component';
// payments
import { CollectionsComponent } from './views/payments/collections.component'
import { CollectComponent } from './views/payments/collect/collect.component'
import { PaymentReceiptComponent } from './views/reports/payment-receipt/payment-receipt.component'
// communication center
import { CommunicationCenterComponent } from './views/communication-center/communication-center.component'
// information views
import { LandingComponent } from './information-views/landing/landing.component'
import { ContactComponent } from './information-views/contact/contact.component'
import { PrivacyComponent } from './information-views/privacy/privacy.component'
import { PricingComponent } from './information-views/pricing/pricing.component'
import { PartnersComponent } from './information-views/partners/partners.component'
import { FeaturesComponent } from './information-views/features/features.component'
import { FaqComponent } from './information-views/faq/faq.component'
import { HelpComponent } from './information-views/help/help.component'
// services
import { MasterService } from './services/master.service'

import { CanDeactivateGuard } from './services/can-deactivate-guard.service';

import { GpsComponent } from './views/gps/gps.component';

//const routes: Routes = [];

const APP_ROUTES: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'dashboard', component: DashboardComponent, canActivate: [MasterService], },
    { path: 'profile', component: ProfileDashboardComponent, canActivate: [MasterService] },
    { path: 'email/:email', component: EmailComponent },
    { path: 'activate-account/:type/:token', component: ActivateAccountComponent },
    { path: 'activate-account/:token', component: ActivateAccountComponent },
    { path: 'reset-password/:type/:token', component: ResetChangePasswordComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'invitations', component: ActivateAccountComponent },
    { path: 'users', component: UsersComponent },
    { path: 'contacts/:id', component: ContactsDetailComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'contacts', component: ContactsComponent },
    { path: 'employees/:id', component: ContactsDetailComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'employees', component: ContactsComponent },
    { path: 'auditlog/:type/:id', component: HistoryComponent },
    { path: 'corporations', component: CorporationsComponent },
    { path: 'corporations/:type/:id', component: CorporationsDetailComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'inventory', component: InventoryComponent },
    { path: 'inventory/:id', component: InventoryDetailComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'permissions', component: SecurityComponent },
    { path: 'reports/:type/:entity/:entityId', component: BuyersReportComponent },
    { path: 'reports/payment-receipt', component: PaymentReceiptComponent },
    { path: 'communication-center', component: CommunicationCenterComponent },
    { path: 'sales', component: SalesComponent },
    { path: 'deals', component: DealsComponent },
    { path: 'quotes', component: SalesComponent },
    { path: 'service', component: ServiceComponent },
    { path: 'service/:id', component: ServiceDetailComponent },
    { path: 'sales/:saleId/insurance', component: AutoInsuranceComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'integration', component: IntegrationComponent },
    { path: 'sales/:id', component: SalesDetailComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'quotes/:id', component: SalesDetailComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'collections/:id', component: CollectComponent },
    { path: 'collections', component: CollectionsComponent },
    { path: 'landing', component: LandingComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'privacy', component: PrivacyComponent },
    { path: 'pricing', component: PricingComponent },
    { path: 'partners', component: PartnersComponent },
    { path: 'features', component: FeaturesComponent },
    { path: 'faqs', component: FaqComponent },
    { path: 'help', component: HelpComponent },
    { path: 'gps', component: GpsComponent },
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(APP_ROUTES, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
