import { Component, Input, OnInit, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MasterService } from '../../../../../../../../services/master.service'
import { StoreService } from '../../../../../../../../services/store.service'
import { MessageService } from '../../../../../../../../services/message.service'
import * as languageLibrary from '../../../../../../../../services/language'
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as moment from 'moment'
import { PAYMENT_CYCLE_OPTIONS } from 'src/app/constants/sale';
import { SAVE_DEBOUNCE_MILLIS } from 'src/app/constants/sale';

@Component({
  selector: 'card-calculate',
  templateUrl: './card-calculate.component.html',
  styleUrls: ['./card-calculate.component.scss']
})
export class CardCalculateComponent implements OnInit, OnChanges {
  // **********************
  // variables
  
  // *******************
  @Input() inventoryTaxRate: number = 0
  
  //
  @Input() paymentsList: any = []
  
  //
  @Input() catalogCycles: any = []
  
  //
  @Input() loanTerms: any = []
  
  //
  @Input() saleId = ''
  
  //
  @Input() saleType = ''
  
  //
  @Input() totalSalePrice = ''

  //
  @Input() driveOutPrice = ''
  
  //
  @Input() buyer = ''
  
  //
  @Input() inventory = ''
  
  //
  @Input() fees: any = []

  //
  @Input() tradeAllowance: number = 0

  //
  @Input() tradePayoff: number = 0
  
  //
  @Input() initialDown: any = []
  
  //
  @Input() deferredDownPayments: any = []

  //
  @Output() loanTerms$: EventEmitter<object> = new EventEmitter()


  //
  public quotes:any = []

  //
  public computing: boolean = false
  
  //
  public minDate:string=moment().format('yyyy-MM-DD')
  
  //
  public paymentCyclesOptions = PAYMENT_CYCLE_OPTIONS
  
  //
  public edit: boolean = false
  
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  
  // set all words
  public words = languageLibrary.language
  
  //
  public information = new FormGroup({
    apr: new FormControl(0, []),
    months: new FormControl(0, []),
    scheduledPaymentAmt: new FormControl(0, []),
    paymentCycle: new FormControl('Monthly', [Validators.required]),
    firstPaymentDate: new FormControl(moment().format('yyyy-MM-DD'), [Validators.required]),
    numberOfPayments: new FormControl(0, []),
    lastPayment: new FormControl(null, []),
    totalPaymentAmount: new FormControl(null, [])
  })

  // A timeout identity for the compute operation.
  private watcher: ReturnType<typeof setTimeout> = null

  // A handle to navbar event messages so we can unsubscribe later.
  private navbarWatcher: any = null

  // **********************
  // life cycles
  // *******************
  constructor(private master: MasterService, private ms: MessageService, private store: StoreService) {}

  ngOnInit() {
    this.navbarWatcher = this.ms.channelComponents$.subscribe(res => {
      switch (res.message) {
        case 'setPermissions':
          // this.lots = this.paymentTerm['lotsInCorp']
          break
        case 'changeLanguage':
          this.language = res.value
          break
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.loanTerms && changes.loanTerms.isFirstChange) {
      this.information.setValue({
        apr: this.loanTerms.APR,
        months: this.loanTerms.months,
        scheduledPaymentAmt: this.loanTerms.schedulePmtAmt,
        paymentCycle: this.loanTerms.paymentCycleId,
        firstPaymentDate: this.loanTerms.firstScheduledPmtDue,
        numberOfPayments: this.loanTerms.numberOfPmt,
        lastPayment: this.loanTerms.lastPmtAmt,
        totalPaymentAmount: this.loanTerms.totalPaymentAmount
      })
    } 
  }

  ngOnDestroy() {
    if (this.watcher !== null) {
      clearTimeout(this.watcher)
      this.watcher = null
    }

    if (!this.navbarWatcher) {
      return
    }

    this.navbarWatcher.unsubscribe()
  }

  // =============================
  // ? create taxes
  // =============================
  public calculate = async () => {
    if (!this.buyer || !this.inventory || !this.store || !this.store.userAccount) {
      return
    }

    if (this.store.userAccount['corporateAddresses'].length == 0) {
      this.ms.sendMessage("alert", { type: "danger", text: 'Corporate address not exist' });
      return
    }
    if (!this.inventory['stockNumber']) {
      this.ms.sendMessage("alert", { type: "danger", text: 'Vehicle not selected' });
      return
    }

    if (this.buyer['county'] == "") {
      this.ms.sendMessage("alert", { type: "danger", text: 'Buyer not selected or not address' });
      return
    }

    if (this.inventory['datePurchased'] == '' || this.inventory['datePurchased'] == null) {
      this.ms.sendMessage("alert", { type: "danger", text: 'Data purchased not defined' });
      return
    }

    if (parseFloat(this.totalSalePrice) <= 0) {
      this.ms.sendMessage("alert", { type: "danger", text: 'Base sale price most be greater than 0' });
      return
    }

    if (this.watcher !== null) {
      clearTimeout(this.watcher)
      this.watcher = null
    }

    // When screen initially loads a lot of data is coming in 
    // and compute can be called multiple times and ignored so 
    // let's wait a bit until all items are available.
    this.watcher = setTimeout(() => {
      this.compute()
    }, SAVE_DEBOUNCE_MILLIS)
  }

  private compute = async () => {
    if (this.computing) {
      return
    }

    let paramFees = this.initialDown.concat(this.fees)
    paramFees.push({Amount: this.inventoryTaxRate, TitleId: '1069', Base:'Cash'})

    let desiredPayment = parseFloat(this.information.value['scheduledPaymentAmount'])
    if (!desiredPayment || isNaN(desiredPayment) || (desiredPayment <= 0)) {
      desiredPayment = 0
    }

    const initialDown = this.initialDown.reduce((total, down) => { return total + parseFloat(down.amountDue) }, 0)
    const deferredDown = this.deferredDownPayments.reduce((total, down) => { return total + parseFloat(down.Payment) }, 0)
    const financed = parseFloat(this.driveOutPrice) + this.tradePayoff - (Math.abs(initialDown) + Math.abs(deferredDown))
    
    const apr = parseFloat(this.information.get('apr').value)
    if (!apr || isNaN(apr) || (apr <= 0)) {
      return
    }

    this.computing = true
    let res = await this.master.getAsync(`vendors/terms/compute?saleId=${this.saleId}&financed=${financed}&apr=${apr}&frequency=${this.information.value['paymentCycle']}&periods=${parseInt(this.information.value['numberOfPayments'])}&first=${this.information.value['firstPaymentDate']}`)
    this.computing = false

    if (!res || !res.data) {
      this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
      return
    }

    this.quotes = res['data']

    this.information.setValue({
      apr: res.data.apr,
      months: this.getDiffDate(res.data.first, res.data.last, this.information.value['paymentCycle']),
      scheduledPaymentAmt: res.data.payment,
      paymentCycle: res.data.frequency,
      firstPaymentDate: moment(res.data.first).format('yyyy-MM-DD'),
      numberOfPayments: res.data.count,
      lastPayment: res.data.final,
      totalPaymentAmount: res.data.payments
    })

    this.edit = false

    let cycle = this.catalogCycles.find(el => this.information.get('paymentCycle').value.toLowerCase() == el['carletonApiName'].toLowerCase())

    let params = {
      saleId: this.saleId,
      optionIndex: 1,
      paymentCycleId: cycle['id'],
      contractRate: this.information.get('apr').value,
      scheduledPaymentAmount: this.information.get('scheduledPaymentAmt').value,
      firstScheduledPaymentDate: moment(this.information.value['firstPaymentDate']).format('yyyy-MM-DD'),
      lastPaymentAmount: this.information.get('lastPayment').value || 0,
      percentLateFeeCharged: null,
      numberOfRegularPayments: this.information.get('numberOfPayments').value,
      months: this.information.get('months').value,
      totalPaymentAmount: this.information.get('totalPaymentAmount').value
    }

    this.loanTerms$.emit({ value: params, quotes: res.data, request: {}, response: res.data })
  }

  //
  public getDiffDate = (dateStart, dateEnd, cycle) => {
    switch (cycle) {
      case 'Weekly':
        return moment(dateEnd).diff(dateStart, 'weeks')
      case 'Semimonthly':
        return moment(dateEnd).diff(dateStart, 'weeks')
      case 'Monthly':
        return moment(dateEnd).diff(dateStart, 'months')
      case 'Biweekly':
        return moment(dateEnd).diff(dateStart, 'months')
    }
  }

}
