import { Component, OnInit, Input, Injector } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import * as languageLibrary from '../../../../../../../services/language'
// services
import { MasterService } from '../../../../../../../services/master.service'
import { MessageService } from '../../../../../../../services/message.service'

import { ISSUERS } from 'src/app/constants/contact';
import { token } from '../../roles.component';
import { DynamicService } from 'src/app/services/dynamic.service';

// external libreries
import * as moment from 'moment'

@Component({
    selector: 'identifications',
    templateUrl: './identifications.component.html',
    styleUrls: ['./identifications.component.scss']
})
export class IdentificationsComponent implements OnInit {
    @Input() contactId: number
    public words = languageLibrary.language
    public language: string = 'EN'
    public identificationList: Object[] = []
    @Input() isFlat: boolean = false
    public loading: boolean = false
    public identificationSelected: Object = { object: {}, index: 0 }
    public selected: number = 0
    public identificationUpdate: boolean;
    public types: string[] = []
    public viewNumber: boolean = false
    public savePending: boolean = false
    public issuers = ISSUERS
    public dataIn: string;

    // Group of inputs
    public form = this.fb.group({
        ids: this.fb.array([])
    })

    public modForm = new FormGroup(
        {
            contactId: new FormControl(0, [Validators.required]),
            identificationTypeId: new FormControl(null, [Validators.required]),
            number: new FormControl(null, [Validators.required]),
            issuer: new FormControl(null, [Validators.required]),
            expirationDate: new FormControl(null)
        },
        {
            validators: Validators.compose([this.dateLessThan('expirationDate')])
        }
    )

    // get information of inputs
    get number() { return this.modForm.get('number') }
    get issuer() { return this.modForm.get('issuer') }
    get expirationDate() { return this.modForm.get('expirationDate') }

    /*
    * Life Cycles
    */
    constructor(private master: MasterService, private ms: MessageService, private fb: FormBuilder, private inject: Injector, private serv: DynamicService) {
    }

    /**
     * Helper method to consistently get the list of form identities as an Array.
     */
    get ids() {
        return this.form.controls['ids'] as FormArray;
    }

    ngOnInit() {
        this.getTypes()
        this.reset()
        this.dataIn = this.inject.get(token);
        this.contactId = this.dataIn['contactId']

        if (!this.contactId) {
            return
        }

        this.getIds()
    }

    public dateLessThan(expirationDate: string) {
        return (group: FormGroup): { [key: string]: any } => {
            let expiry = group.controls[expirationDate]
            if (expiry.value <= moment().format()) {
                return {
                    dates: "Date from should be less than Date to"
                };
            }
            return
        }
    }

    public getIds() {
        this.master.get(`contacts/${this.contactId}/id`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.identificationList = res.data.idList
        })
    }
    /*
    * Reset the identifications input array
    */
    public reset = (): void => {
        this.form.reset()
        this.ids.clear()
        this.add(null)

        this.loading = false
        this.savePending = false
    }

    public save = (): void => {
        if (this.form.invalid) {
            return
        }

        const payload = []

        for (let group of this.ids.controls) {
            const controls = (group as FormGroup).controls
            payload.push({
                contactId: this.contactId,
                identificationTypeId: controls.identificationTypeId.value,
                number: controls.number.value,
                issuer: controls.issuer.value,
                expirationDate: controls.expirationDate.value
            })
        }

        if (payload.length <= 0) {
            this.loading = false
            return
        }

        this.loading = true

        this.master.post(`contacts/${this.contactId}/identifications`, { identifications: payload }, res => {
            this.loading = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.savePending = false

            this.identificationList = res.data.idList

            // recharge user data
            this.reset()
            if (!this.isFlat) {
                const btn = (document.getElementById('close-modal-identifications') as HTMLButtonElement)
                if (btn) {
                    btn.click()
                }
            }
        })


    }

    /*
    * Reset/empty the contact identity modification fields
    */
    public resetMod = (): void => {
        this.identificationSelected = { object: {}, index: 0 }
        this.identificationUpdate = false
        this.modForm.reset()
    }

    /*
      ? update
      */
    /*
    todo: update some row
    */
    public update = (): void => {
        if (this.modForm.invalid) {
            return
        }
        this.loading = true
        this.master.put(`contacts/${this.contactId}/identification/${this.identificationSelected['object']['id']}`, this.modForm.value, res => {
            this.loading = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.resetMod()
            this.identificationList = res.data.idList

            if (!this.isFlat) {
                // close modal
                const btn = (document.getElementById('close-modal-update-identifications') as HTMLButtonElement)
                if (btn) {
                    btn.click()
                }
            }
        })
    }

    /*
    todo: select identification update
    */
    public selectIdentificationUpdate = (identification: Object): void => {
        this.identificationSelected['object'] = identification
        this.identificationUpdate = true
        this.modForm.setValue({
            contactId: this.contactId,
            identificationTypeId: identification['identificationTypeId'],
            number: identification['number'],
            issuer: identification['issuer'],
            expirationDate: identification['expirationDate'],
        })
    }

    /*
    /*
    todo: select identification
    */
    public selectIdentification = (identification: Object): void => {
        this.identificationSelected['object'] = identification
    }

    /*
    todo: delete identification
    */
    public delete = (): void => {
        if (!this.identificationSelected['object']['id']) {
            return
        }
        this.loading = true
        this.master.discard(`contacts/${this.contactId}/identification/${this.identificationSelected['object']['id']}`, res => {
            this.loading = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            if (!this.isFlat) {
                const btn = (document.getElementById('close-delete-modal-identification') as HTMLButtonElement)
                if (btn) {
                    btn.click()
                }
            }

            this.resetMod()
            this.identificationList = res.data.idList
        })
    }

    /*
   todo: Quest before of delete
   */
    public okToDelete = (identification: Object) => {
        if (confirm(this.words[this.language]['deleteIdentifications'])) {
            this.selectIdentification(identification)
            this.delete()
        }
    }

    /*
    todo: change identification
    */
    public changeIdentification = (value): void => {
        this.selected = value
    }

    /*
   todo: define the identification default
   */
    public selectDefault = (identificationSelected): void => {
        this.master.patch(`contacts/${this.contactId}/identifications/${identificationSelected['id']}/primary`, null, res => {
            this.loading = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
            }

            this.identificationList = res.data.idList
            
        })
    }

    /*
    todo: get types of identifications
    */
    public getTypes = (): void => {
        this.master.get('collections/contacts/identificationTypes', res => {
            if (res) {
                if (res.status == 200) {
                    this.types = res.data.IdentificationTypes
                } else {
                    this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                }
            } else {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
            }
        })
    }

    /**
     * Add a new contact identity row to the form array
     * @param e Event that triggerd this method, if any
     */
    public add = (e): void => {
        const identity = new FormGroup({
            identificationTypeId: new FormControl(null, [Validators.required]),
            number: new FormControl(null, [Validators.required]),
            issuer: new FormControl(null, [Validators.required]),
            expirationDate: new FormControl(null),
            contactId: new FormControl(this.contactId, [Validators.required])
        },
            {
                validators: Validators.compose([this.dateLessThan('expirationDate')])
            }
        )

        this.ids.push(identity)
    }

    /**
     * Remove a contact identity from the form array
     * @param index The index of the invite to remove
     */
    public discard = (index: number): void => {
        if ((index < 0) || (index > this.ids.length - 1)) {
            return
        }

        this.ids.removeAt(index)
    }
}
