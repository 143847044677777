import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { FormControl, FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
// services
import { MasterService } from '../../../../../../services/master.service'
import { MessageService } from '../../../../../../services/message.service'
import * as languageLibrary from '../../../../../../services/language'
import { formatCurrency } from 'src/app/utils/format';
import { clean } from 'src/app/utils/numeric';

@Component({
    selector: 'accessories',
    templateUrl: './accessories.component.html',
    styleUrls: ['./accessories.component.scss']
})
export class AccesoriesComponent implements OnInit, OnChanges {
    // =============================
    // todo: Variables
    // =============================

    // Indicates whether or not the sale is unlocked and can accept changes.
    @Input() isUnLock: boolean = true

    // sale id
    @Input() saleId: number = null

    //
    @Output() accessoriesChanged: EventEmitter<Object> = new EventEmitter();

    // save the language
    public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'

    // set all words
    public words = languageLibrary.language

    // A list of currently available corporate accessories.
    public corpAccessories = []

    // A list of accessories that are still available to add based on the corp and currently selected sale accessories.
    public availableAccessories = []

    // A list of available vendors who may sell accessories.
    public vendors = []

    // The main form group of accessories.
    public form: FormGroup = null

    // Indicates data is loading or being saved.
    public loading: boolean = false

    // Indicates one or more accessotries are currently being saved.
    public saving: boolean = false

    // =============================
    // todo: life cycles
    // =============================

    constructor(private master: MasterService, private ms: MessageService, private fb: FormBuilder) {
        this.form = this.fb.group({
            accessories: this.fb.array([])
        })
    }

    ngOnInit() {
        this.readSaleAccesories()
        this.readAccesories()
        this.fetchVendors()
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes) {
            return
        }

        const first = Object.values(changes).some(c => c.isFirstChange())
        if (first) {
            return
        }

        if (changes.isUnLock) {
            this.form.disable({emitEvent: false})
            if (this.isUnLock) {
                this.form.enable({emitEvent: false})
            }

            return
        }

        if (changes.saleId) {
            if (!this.saleId || (this.saleId <= 0)) {
                return
            }

            this.readSaleAccesories()
        }
    }

    /**
     * Helper method to consistently get the list of form accessories as an Array.
     */
    get accessories() {
        return this.form.controls['accessories'] as FormArray;
    }

    // =============================
    // functions
    // =============================

    /*
        reload and clean the inputs
    */
    public reset = (): void => {
        this.form.reset()
        this.accessories.clear()
    }

    /**
     * After a sale accessory change or newly 
     * available corp accessories make sure 
     * the available accessories list is correct.
     */
    private rebuildAvailable = (): void => {
        if (!this.corpAccessories || (this.corpAccessories.length <= 0)) {
            this.availableAccessories = []
            return
        }

        const selected = []
        if (this.accessories && (this.accessories.length > 0)) {
            for (let accessory of this.accessories.controls) {
                const id = parseInt(accessory.get('accessoryId').value)
                if (!id || (id < 0)) {
                    continue
                }

                selected.push(id)
            }
        }

        this.availableAccessories = this.corpAccessories.filter((acc) => !selected.includes(parseInt(acc.id)))
    }

    /**
     * Let any listeners know that the accessories changed.
     */
    private emit = () => {
        if (!this.accessories) {
            return
        }

        let total = 0
        for (let accessory of this.accessories.controls) {
            const id = parseInt(accessory.get('id').value)
            if (!id || (id < 0)) { // only include saved/commited accessories
                continue
            }

            total += clean(accessory.get('price').value)
        }

        this.accessoriesChanged.emit({ value: total, save: true })
    }

    /**
     * 
     */
    public readAccesories = (): void => {
        this.loading = true
        this.master.get(`accessories`, res => {
            this.loading = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.corpAccessories = res.data.accessories
            this.rebuildAvailable()
        })
    }

    /**
     * 
     */
    public readSaleAccesories = (): void => {
        if (!this.saleId || (this.saleId <= 0)) {
            return
        }

        this.reset()

        this.loading = true
        this.master.get(`sales/${this.saleId}/accessories`, res => {
            this.loading = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            if (!res.data.accessories || (res.data.accessories.length <= 0)) {
                return
            }

            for (let accessory of res.data.accessories) {
                this.accessories.push(this.fb.group({
                    id: [parseInt(accessory['id']), [Validators.required]],
                    saleId: [this.saleId, [Validators.required] ],
                    accessoryId: [parseInt(accessory['accessoryId']), []],
                    accessory: [accessory['accessory'] ? accessory['accessory']['name'] : '', [Validators.required, Validators.minLength(1)]],
                    contactId: [parseInt(accessory['contactId']), []], // vendor contactId
                    vendor: [accessory['contact'] ? accessory['contact']['name'] : '', [Validators.required, Validators.minLength(1)]],
                    price: [formatCurrency(accessory['price']), []],
                    cost: [formatCurrency(accessory['cost']), []]        
                }))
            }

            this.rebuildAvailable()
            this.emit()
        })
    }

    /**
     * Get a list of vendors who may have sale accessories.
     */
    public fetchVendors = (): void => {
        this.loading = true
        this.master.get("contacts?type=vendor", (res) => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", {type: "danger", text: res.data.error})
                return
            }
            
            this.vendors = res.data.contacts
        })
    }

    /**
     * Handle vendor selections and other changes
     * @param index The index of the accessory whose vendor is changing.
     */
    public vendorChanged = (index: number): void => {
        if ((index === undefined) || (index === null) || (index < 0) || (index > this.accessories.length - 1)) {
            return
        }

        const accessory = this.accessories.controls[index] as FormGroup
        const vendor = accessory.get('vendor').value

        if (!vendor || (vendor.length <= 0) || !this.vendors || (this.vendors.length <= 0)) {
            accessory.get('contactId').setValue(0)
            return
        }

        for (let item of this.vendors) {
            if (item.name !== vendor) {
                continue
            }

            accessory.get('contactId').setValue(item.id)
            return
        }

        accessory.get('contactId').setValue(0) // no match found
    }

    /**
     * Save all acccessories that are pending a save.
     */
    public saveAll = (): Promise<void> => {
        if (this.saving) {
            return
        }

        for (const accessory of this.accessories.controls) {
            if (accessory.invalid || accessory.pristine) {
                continue
            }

            this.upsert(accessory as FormGroup)
        }

        this.emit()
    }

    /**
     * Save (upsert) an accessory from the form array
     * @param index The index of the accessory to save
     */
    public save = (index: number): Promise<void> => {
        if ((index === undefined) || (index === null) || (index < 0) || (index > this.accessories.length - 1)) {
            return
        }

        if (this.saving) {
            return
        }

        const accessory = this.accessories.controls[index] as FormGroup
        this.upsert(accessory)        
    }

    /**
     * Determine if the API call should be an insert or 
     * modify and call the proper method.
     * @param accessory 
     * @returns 
     */
    private upsert = (accessory: FormGroup) => {
        const id = parseInt(accessory.get('id').value)
        if ((isNaN(id)) || (id < 0)) {
            return
        }

        if (id === 0) {
            this.insert(accessory)            
            return
        }

        this.modify(id, accessory)
    }

    /**
     * Add a new accessory to the current sale
     * @param accessory Accessory meta data to add to the sale.
     */
    private insert = (accessory: FormGroup): Promise<void> => {
        if (!this.saleId || !accessory) {
            return
        }

        const payload = accessory.value
        delete(payload.id)

        payload.price = clean(payload.price)
        payload.cost = clean(payload.cost)

        this.saving = true
        this.master.post(`sales/${this.saleId}/accessories`, payload, res => {
            this.saving = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            accessory.markAsPristine()

            if (!res.data || !res.data.accessories || (res.data.accessories.length <= 0)) {
                return
            }

            accessory.get('id').setValue(parseInt(res.data.accessories[0].id))
            accessory.get('accessoryId').setValue(parseInt(res.data.accessories[0].accessoryId))
            accessory.get('contactId').setValue(parseInt(res.data.accessories[0].contactId))
            this.emit()
        })
    }

    /**
     * Modify an existing sale accessory.
     * @param id Unique internal identity of the existsing accessory
     * @param accessory Accessory meta data to modify the existing data with.
     */
    private modify = (id: number, accessory: FormGroup): Promise<void> => {
        if (!id || !this.saleId || !accessory || (id <= 0)) {
            return
        }

        const payload = accessory.value
        delete(payload.id)

        payload.price = clean(payload.price)
        payload.cost = clean(payload.cost)

        this.saving = true
        this.master.put(`sales/${this.saleId}/accessories/${id}`, payload, res => {
            this.saving = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            accessory.markAsPristine()
            this.emit()
        })
    }

    /**
     * Remove a sale accessory from the form array
     * @param index The index of the accessory to remove
     */
    public discard = (index: number): void => {
        if ((index < 0) || (index > this.accessories.length - 1)) {
            return
        }

        const id = parseInt(this.accessories.at(index).get('id').value)
        if (!id || isNaN(id) || id <= 0) {
            this.accessories.removeAt(index)
            return
        }

        this.master.discard(`sales/${this.saleId}/accessories/${id}`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.accessories.removeAt(index)
            this.emit()
        })
    }

    /**
     * Add a new sale accessory row to the form array
     * @param e Event that triggerd this method, if any
     * @param {Object} [accessory = null] Optional known corporate accessory to add to the sale.
     */
    public add = (e, accessory = null): void => {
        if (e) {
            e.preventDefault()
        }

        this.accessories.push(this.fb.group({
            id: [0, [Validators.required]],
            saleId: [this.saleId, [Validators.required] ],
            accessoryId: [accessory ? parseInt(accessory.id) : 0, []],
            accessory: [accessory ? accessory.name : null, [Validators.required, Validators.minLength(1)]], // name of the selected accessory
            contactId: [accessory ? parseInt(accessory.contactId) : 0, []], // vendor contactId
            vendor: [(accessory && (accessory.contact)) ? accessory.contact.name : null, [Validators.required, Validators.minLength(1)]], // name of the selected vendor
            price: [formatCurrency(accessory ? accessory.price : 0), [Validators.min(1)]],
            cost: [formatCurrency(accessory ? accessory.cost : 0), [Validators.min(0)]]
        }))

        if (accessory && accessory.id) {
            this.save(this.accessories.length - 1)
            this.rebuildAvailable()
        }
    }

}
