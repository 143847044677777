import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MasterService } from '../../../services/master.service';
import { MessageService } from 'src/app/services/message.service';
import * as languageLibrary from '../../../services/language'
@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit {
  // save the language
  @Input() language: string = 'EN'
  // set all words
  public words = languageLibrary.language
  private token: string;
  private type: string;
  public textMessage: string;
  public textTitle: string;

  constructor(private master: MasterService, private route: ActivatedRoute, private ms: MessageService, private router: Router) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.token = params.get('token')

      this.type = "email"
      if (params.has('type')) {
        this.type = params.get('type')
      }

      this.verify()
    })
  }

  verify() {
    if (!this.token) {
      return
    }

    if (!this.type) {
      return
    }

    this.master.patch(`users/activate`, { type: this.type, token: this.token }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        this.textTitle = 'Account was not activated successfully.';
        this.textMessage = 'Contact the AutoAction Support Team.';
        return
      }

      if (res.status !== 200) {
        // this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        this.textTitle = 'Account was not activated successfully.';
        this.textMessage = res.data.error;
        if (this.textMessage == 'setupPassword') {
          this.master.token = res.data.token;
          localStorage.setItem('token', this.master.token)
          this.router.navigate([`reset-password/authenticate/${res.data.token}`])
        }
        return
      }

      this.textTitle = 'Account activated successfully';
      this.textMessage = 'Your account has been activated.';
      this.master.token = res.data.token;
      localStorage.setItem('token', this.master.token)
      this.router.navigate([`dashboard`])
    })
  }
}
