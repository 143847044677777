
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MasterService } from '../../../../services/master.service'
import { MessageService } from '../../../../services/message.service'
import { StoreService } from '../../../../services/store.service'
import { Router, ActivatedRoute } from '@angular/router';
import * as languageLibrary from '../../../../services/language'
import { SEARCH_DEBOUNCE_MILLIS, MIN_SEARCH_CHARS } from 'src/app/constants/list';

@Component({
  selector: 'addresses-corporate',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss']
})
export class AddressesCorporateComponent implements OnChanges {
  /*
  * variables
  */
  
  // save the language
  @Input() language: string = 'EN'
  
  //  implement corporate object
  @Input() corporate: Object = {}

  // permissions
  @Input() permissions: Object[] = []
  
  // Unique internal identity of the associated contact
  @Input() contactId: number = 0

  // Let the parent know one or more addresses were added/modified.
  @Output() addressChanged$: EventEmitter<Object> = new EventEmitter()

  // set all words
  public words = languageLibrary.language

  // define the selected address
  public addressSelected: number = 0
  
  // define the address selected
  public selected: number = 0
  
  // save all addresses
  public addressesList: Object[] = []
  
  // save the types
  public types: Object[] = [{ id: 1, name: 'billing' }, { id: 2, name: 'home' }, { id: 3, name: 'mailing' }, { id: 4, name: 'work' }]
  
  // save the rent or own
  public rentOwn: string[] = ['Rent', 'Own', 'Other']
  
  // define the contact type
  public type: string = ''
  
  //  define if some changes not saved
  public savePending: boolean = false
  
  // define the contact type
  public id: number = 0
  
  // define if loading state
  public loading: boolean = false
  
  // zip code list
  public zipCodeList: Object[] = []
  
  // latest search inquiry
  public zipInquiry = null
  
  // set time out
  private timeOutID: any;
  
  // address payload
  public addressPayload: Object[] = [];

  // addresslokup
  public addressLookup: Object = {
    city: "",
    county: "",
    state: "",
    zipcode: "",
    country: ""
  }

  public expanded: boolean = false
  
  // Group of inputs
  public information = new FormGroup({
    contactId: new FormControl(0, [Validators.required]),
    id: new FormControl(0),
    addressTypeId: new FormControl(parseInt(this.types.find((type) => type['name'] === 'mailing')['id']), [Validators.required]),
    status: new FormControl('Other', [Validators.required]),
    address: new FormControl(null, [Validators.required]),
    address2: new FormControl(null),
    zipCode: new FormControl(null, [Validators.required, Validators.pattern('[0-9]{3,10}')]),
    city: new FormControl(null, [Validators.required]),
    state: new FormControl(null, [Validators.required]),
    county: new FormControl(null, [Validators.required]),
    country: new FormControl(null, [Validators.required]),
    primaryAddress: new FormControl(false, [])
  })
  
  // get information of inputs
  get address() { return this.information.get('address') }
  get address2() { return this.information.get('address2') }
  get zipCode() { return this.information.get('zipCode') }


  /*
  * Life cicles
  */
  constructor(private master: MasterService, private ms: MessageService, private route: ActivatedRoute, private store: StoreService) {}

  ngOnInit() {
    this.type = this.route.snapshot.params['type']
    this.id = parseInt(this.route.snapshot.params['id'])
    
    if (isNaN(this.contactId) || (this.contactId <= 0)) {
      return
    }

    this.information.get('contactId').setValue(this.contactId)
    this.getAddresses()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) {
      return
    }

    if (changes.contactId) {
      this.information.get('contactId').setValue(this.contactId)
      this.getAddresses()
      return
    }
  }

  /*
  * functions
  */
  // clean Fields
  public cleanFields = (clearPayload: boolean): void => {    
    if (clearPayload) { 
      this.addressPayload = [] 
    }

    this.zipCodeList = []
    this.information.reset()

    this.information.patchValue({
      contactId: this.contactId,
      addressTypeId: parseInt(this.types.find((type) => type['name'] === 'mailing')['id']),
      status: 'Other',
    })
  }
  /*
  ?: create
  */
  // save
  public save = (closeAfter: boolean): void => {
    if (this.addressPayload.length == 0 && this.information.invalid) {
      return
    }

    if (this.information.invalid) {
      return
    }

    if (!this.addressesList || (this.addressesList.length <= 0)) {
      this.information.get('primaryAddress').setValue(true)
    }

    this.addressPayload.push(this.information.value)
    this.loading = true

    //  save address
    this.master.post(`contacts/${this.contactId}/addresses`, { addresses: this.addressPayload }, res => {
      this.loading = false
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.savePending = false
      // close modal
      const btn = (document.getElementById('btn-cancel-addresses') as HTMLButtonElement)
      if (closeAfter && btn) {
        btn.click()
      }

      // clean the fields
      this.cleanFields(true)
      this.getAddresses()

      this.addressChanged$.emit(JSON.parse(JSON.stringify(this.information.value)))
    })
  }

  /*
  todo: update
  */
  // save
  public modifyAddress = (address: Object): void => {
    if (this.address.invalid) {
      return
    }

    this.loading = true

    this.master.put(`contacts/${this.contactId}/addresses/${address['id']}`, this.information.value, res => {
      this.loading = false

      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }


      // close modal
      const btn = (document.getElementById('btn-close-addresses-update') as HTMLButtonElement)
      if (btn) {
        btn.click()
      }

      // clean the fields
      this.cleanFields(true)
      this.getAddresses()

    })

  }

  public getAddress = (addressId: string): void => {
    this.master.get(`contacts/${this.contactId}/addresses/${addressId}`, res => {
      this.loading = false
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.setFormInfo(res.data.address)

    })
  }

  // select address update
  public setFormInfo = (address: Object): void => {
    this.information.setValue({
      addressTypeId: address['addressTypeId'],
      address: address['address'],
      address2: address['address2'],
      zipCode: address['zipCode'],
      city: address['city'],
      state: address['state'],
      county: address['county'],
      country: address['country'],
      id: address['id'],
      contactId: this.contactId,
      status: 'Other',
      primaryAddress: address['primaryAddress'] || false
    })
  }
  // select address
  public selectAddress = (addressId: number): void => {
    this.addressSelected = addressId
  }

  /*
  ! delete
  */
  public delete = (): void => {
    this.loading = true

    this.master.discard(`contacts/${this.contactId}/addresses/${this.addressSelected}`, res => {
      this.loading = false
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      const btn = (document.getElementById('close-delete-modal') as HTMLButtonElement)
      if (btn) {
        btn.click()
      }

      // clean the fields
      this.cleanFields(true)
      this.getAddresses()
    })
  }
  /*
 * select defaul
 */
  // define the address default
  public selectDefault = (id: number): void => {
    if (!id) {
      return
    }

    const identity = id
    if (isNaN(identity) || (identity <= 0)) {
      this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
      return
    }

    this.master.patch(`contacts/${this.contactId}/addresses/${id}/primary`, null, res => {
      this.loading = false

      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.getAddresses()
    })
  }

  // ? address lookup
  // verify if the corporate is already exist
  public searchZipCode(): void {
    const code = this.information.get('zipCode').value
    if (!code || code.length < MIN_SEARCH_CHARS) {
      return
    }

    if (this.zipInquiry && (code === this.zipInquiry)) { // hasn't changed?
      return
    }

    clearTimeout(this.timeOutID)

    this.timeOutID = setTimeout(() => {
      this.zipCodeList = []
      this.zipInquiry = code

      this.master.get(`readLocationByZipcode?zipcode=${code}`, res => {
        if (!res) {
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
          return
        }

        if (res.status !== 200) {
          return
        }

        this.zipCodeList = res.data.locations

        // autocomplete
        if ((code >= 5) && (this.zipCodeList.length === 1)) {
          this.selectZipCode(this.zipCodeList[0])
        }
      })
    }, SEARCH_DEBOUNCE_MILLIS);

    /**/
  }

  /**
   * Handle zip code changes
   * @param address Zip code meta data containing address information.
   */
  public selectZipCode = (address: Object): void => {
    if (!address || !this.information) {
      return
    }

    this.information.get('city').setValue(address['city'])
    this.information.get('state').setValue(address['state'])
    this.information.get('county').setValue(address['county'])
    this.information.get('zipCode').setValue(address['zipcode'])
    this.information.get('country').setValue('United States')

    this.zipCodeList = []
  }

  // open the location
  public openLocation = (address: string, county: string, state: string): void => {
    address = address.replace(' ', '+')
    county = county.replace(' ', '+')
    state = state.replace(' ', '+')
    console.info(`https://www.google.com/maps/search/?api=1&query=${address}+${county}+${state}`);

    window.open(`https://www.google.com/maps/search/?api=1&query=${address}+${county}+${state}`, '_blank')
  }

  // find primary address
  public findPrimaryAddress = (): void => {
    if (this.corporate['addresses']) {
      let primary = this.corporate['addresses'].filter(el => el.primaryAddress == 1)
      if (primary.length > 0) {
        this.selected = primary[0].id
      }
    }
  }

  getAddresses() {
    if (isNaN(this.contactId) || (this.contactId <= 0)) {
      return
    }

    this.master.get(`contacts/${this.contactId}/addresses`, res => {
      this.loading = false
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.addressesList = res.data.addresses
    })
  }

  public add = (addressToSave: Object): void => {
    this.addressPayload.push(addressToSave)
    this.cleanFields(false)
  }

}
