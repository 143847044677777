import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import { MasterService } from 'src/app/services/master.service';
import * as languageLibrary from '../../../../../../services/language'
import { MessageService } from 'src/app/services/message.service';
import { Product } from 'src/app/views/contacts-views/contacts/components/roles/role-warranty/role-warranty.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatCurrency } from 'src/app/utils/format';
import { clean } from 'src/app/utils/numeric';

@Component({
  selector: 'products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class SalesProductsComponent implements OnInit, OnChanges {
  
  // Indicates whether or not the sale is unlocked and can accept changes.
  @Input() isUnLock: boolean = true

  @Input() saleId: number = 0

  @Output() productEmitter$: EventEmitter<Object> = new EventEmitter()
  
  // The current search text to filter the list by.
  public inquiry: string = null
  
  public words = languageLibrary.language
  
  public language: string = 'EN'
  
  public products: Product[] = [];
  
  public saleProducts: [];
  
  public form = null
  
  public selectableProducts: Product[];
  
  public total: number = 0;

  constructor(private master: MasterService, private ms: MessageService, private fb: FormBuilder) {
    this.form = this.fb.group({
      saleProductsList: this.fb.array([])
    })
  }

  ngOnInit() {
    this.getCorporateProducts()
    this.getSaleProducts()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return
    }

    const first = Object.values(changes).some(c => c.isFirstChange())
    if (first) {
      return
    }

    if (changes.isUnLock) {
      this.form.disable({ emitEvent: false })
      if (this.isUnLock) {
        this.form.enable({ emitEvent: false })
      }
    }

    if (changes.saleId) {
      if (!this.saleId || (this.saleId <= 0)) {
        return
      }

      this.getSaleProducts()
    }
  }

  /**
   * Helper saleProduct to consistently get the list of form saleProducts as an Array.
   */
  get saleProductsList() {
    return this.form.controls['saleProductsList'] as FormArray;
  }

  // CORPORATE PRODUCTS
  getCorporateProducts() {
    let inquiryParam = ''
    if (this.inquiry && (this.inquiry)) {
      inquiryParam = `&name=${encodeURIComponent(this.inquiry)}`
    }

    this.master.get(`corporation/products?${inquiryParam}`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      if (!res.data.products && res.data.products.length == 0) {
        return
      }

      this.products = res.data.products
      this.selectableProducts = this.products

    })
  }

  /**
     * Add a new sale products row to the form array
     * @param e Event that triggered this method, if any
     */
  public add = (product): void => {
    let saleProduct = this.fb.group({
      id: [null],
      contactId: [null],
      productId: [product ? product['id'] : null],
      saleId: [this.saleId, [Validators.required]],
      dealerCost: [null],
      sellingPrice: [null],
      contactRoleType: [product ? product.category.key : 'warranty'],      //Add New Warranty calls this function with an empty parameter, defaults to warranty
      productName: [null],
      contactName: [null]
    },)

    if (product) {
      this.createSaleProduct(saleProduct)
    } else {
      this.saleProductsList.push(saleProduct)
    }
  }

  /**
     * Add a new sale products row to the form array
     * @param e Event that triggered this method, if any
     */
  public build = (saleProductInfo, index?): void => {
    if (index !== null && index !== undefined) {
      this.saleProductsList.controls[index].patchValue({
        id: saleProductInfo.id,
        contactId: saleProductInfo.contactId,
        productId: saleProductInfo.productId,
        saleId: this.saleId,
        dealerCost: formatCurrency(saleProductInfo.dealerCost),
        sellingPrice: formatCurrency(saleProductInfo.sellingPrice),
        contactRoleType: "warranty",
        productName: saleProductInfo.product ? saleProductInfo.product.name : null,
        contactName: saleProductInfo.contact ? saleProductInfo.contact.name : null
      })
    } else {
      const saleProduct = this.fb.group({
        id: [saleProductInfo.id],
        contactId: [saleProductInfo.contactId],
        productId: [saleProductInfo.productId],
        saleId: [this.saleId, [Validators.required]],
        dealerCost: [formatCurrency(saleProductInfo.dealerCost)],
        sellingPrice: [formatCurrency(saleProductInfo.sellingPrice)],
        contactRoleType: [saleProductInfo.product ? saleProductInfo.product.category.key : null],
        productName: [saleProductInfo.product ? saleProductInfo.product.name : null],
        contactName: [saleProductInfo.contact ? saleProductInfo.contact.name : null]
      },)
      this.saleProductsList.push(saleProduct)
    }
  }

  public getSaleProducts() {
    if (isNaN(this.saleId) || this.saleId == null){
      return
    }
    
    this.master.get(`sales/${this.saleId}/products`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      if (!res.data.saleProducts && res.data.saleProducts.length == 0) {
        return
      }


      if (!res.data.saleProducts && res.data.saleProducts.length == 0) {
        return
      }

      this.setProductInformation(res.data.saleProducts)
    })
  }

  public setProductInformation(saleProductInfo) {
    this.saleProductsList.clear()
    this.saleProducts = saleProductInfo
    this.saleProducts.forEach(saleProduct => {
      this.build(saleProduct)
    })

    this.selectableProducts = this.products.filter((product) => !this.saleProducts.find(item => item['product']['name'] === product['name']))
    let gapSelectProductExists = this.selectableProducts.findIndex((product) => product.name == 'GAP')
    let dcaSelectProductExists = this.selectableProducts.findIndex((product) => product.name == 'Debt Cancelation')
    if (gapSelectProductExists == -1) {
      this.selectableProducts = this.selectableProducts.filter((product) => product.name !== 'Debt Cancelation')
    }
    if (dcaSelectProductExists == -1) {
      this.selectableProducts = this.selectableProducts.filter((product) => product.name !== 'GAP')
    }
    this.calculateProductTotal(false)
  }

  public createSaleProduct(saleProduct, index?) {
    const payload = JSON.parse(JSON.stringify(saleProduct.value))
    payload.dealerCost = clean(payload.dealerCost)
    payload.sellingPrice = clean(payload.sellingPrice)

    if (payload.productId == null && payload.contactId == null) {
      return
    }

    this.master.post(`sales/${this.saleId}/products`, { information: payload }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      if (!res.data.newSaleProduct && res.data.newSaleProduct.length == 0) {
        return
      }

      this.build(res.data.newSaleProduct, index)
      this.selectableProducts = this.selectableProducts.filter((product) => res.data.newSaleProduct.product.name !== product['name']);
      let gapSelectProductExists = this.selectableProducts.findIndex((product) => product.name == 'GAP')
      let dcaSelectProductExists = this.selectableProducts.findIndex((product) => product.name == 'Debt Cancelation')
      if (gapSelectProductExists == -1) {
        this.selectableProducts = this.selectableProducts.filter((product) => product.name !== 'Debt Cancelation')
      }
      if (dcaSelectProductExists == -1) {
        this.selectableProducts = this.selectableProducts.filter((product) => product.name !== 'GAP')
      }
      this.calculateProductTotal(false)

    })
  }

  public modifySaleProduct(saleProduct) {
    const payload = JSON.parse(JSON.stringify(saleProduct.value))
    payload.dealerCost = clean(payload.dealerCost)
    payload.sellingPrice = clean(payload.sellingPrice)

    this.master.put(`sales/${this.saleId}/products/${saleProduct.value['id']}`, { information: payload }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      if (!res.data.saleProducts && res.data.saleProducts.length == 0) {
        return
      }
      this.saleProducts = res.data.saleProducts
      this.selectableProducts = this.products.filter((product) => !this.saleProducts.find(item => item['product']['name'] === product['name']));
      let gapSelectProductExists = this.selectableProducts.findIndex((product) => product.name == 'GAP')
      let dcaSelectProductExists = this.selectableProducts.findIndex((product) => product.name == 'Debt Cancelation')
      if (gapSelectProductExists == -1) {
        this.selectableProducts = this.selectableProducts.filter((product) => product.name !== 'Debt Cancelation')
      }
      if (dcaSelectProductExists == -1) {
        this.selectableProducts = this.selectableProducts.filter((product) => product.name !== 'GAP')
      }
      this.calculateProductTotal(true)

    })
  }

  public discardProduct(index) {
    if (this.saleProductsList.controls[index].get("id").value == null) {
      this.saleProductsList.removeAt(index)
      return
    }
    this.master.discard(`sales/${this.saleId}/products/${this.saleProductsList.controls[index].get("id").value}`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      if (!res.data.saleProducts && res.data.saleProducts.length == 0) {
        return
      }

      this.saleProductsList.removeAt(index)
      // this.setProductInformation(res.data.saleProducts)
      this.selectableProducts = this.products.filter((product) => !this.saleProductsList.controls.find(item => item['value']['productName'] === product['name']));
      let gapSelectProductExists = this.selectableProducts.findIndex((product) => product.name == 'GAP')
      let dcaSelectProductExists = this.selectableProducts.findIndex((product) => product.name == 'Debt Cancelation')
      if (gapSelectProductExists == -1) {
        this.selectableProducts = this.selectableProducts.filter((product) => product.name !== 'Debt Cancelation')
      }
      if (dcaSelectProductExists == -1) {
        this.selectableProducts = this.selectableProducts.filter((product) => product.name !== 'GAP')
      }
      this.calculateProductTotal(true)
    })
  }

  public calculateProductTotal(save: boolean) {
    this.total = 0
    this.saleProductsList.controls.forEach(el => {
      this.total += clean(el.value.sellingPrice) > 0 ? clean(el.value.sellingPrice) : 0
    })
    this.productEmitter$.emit({ value: this.total, save: save, productCount: this.saleProductsList.controls.length })
  }

  public setProductIdEvent(e, index) {
    if (!e) {
      return
    }
    if (this.saleProductsList.controls[index].get("productId").value == e) {
      return
    }
    this.saleProductsList.controls[index].get("productId").setValue(e)
    this.saleProductsList.controls[index].markAsDirty();

    this.changed(e, index)
    // if (this.saleProductsList.controls[index].get('id').value > 0) {
    //   return
    // }

    // this.createSaleProduct(this.saleProductsList.controls[index])
  }

  public setContactIdEvent(e, index) {
    if (!e) {
      return
    }
    if (this.saleProductsList.controls[index].get("contactId").value == e) {
      return
    }

    this.saleProductsList.controls[index].get("contactId").setValue(e)


    this.saleProductsList.controls[index].markAsDirty();

    this.changed(e, index)

    // if (this.saleProductsList.controls[index].get('id').value > 0) {
    //   return
    // }

    // this.createSaleProduct(this.saleProductsList.controls[index])
  }

  /**
   * Handle input blur or change events 
   * and recalculate when needed.
   * @param e 
   * @returns 
   */
  public changed(e, index): void {
    if (this.saleProductsList.controls[index].pristine) {
      return
    }

    if (this.saleProductsList.controls[index].get('id').value > 0) {
      this.modifySaleProduct(this.saleProductsList.controls[index])
      this.saleProductsList.markAsPristine()
      return
    }

    this.createSaleProduct(this.saleProductsList.controls[index], index)
    this.saleProductsList.markAsPristine()
  }

}
