import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// services
import { MasterService } from '../../../../../services/master.service'
import { MessageService } from '../../../../../services/message.service'
import { SecurityService } from '../../../../../services/security.service'
import * as languageLibrary from '../../../../../services/language'

@Component({
  selector: 'notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  /*
  * variables
  */

  // Unique internal identity of the associated contact
  @Input() contactId: number = 0

  // permissions
  @Input() permissions: string[] = []

  // 
  @Input() isEdit: boolean = true

  @Input() isFlat: boolean = false

  @Output() notes$: EventEmitter<Object> = new EventEmitter()

  public notesList: Object[] = []

  public viewAlert: Boolean = false

  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'

  // set all words
  public words = languageLibrary.language

  // define note selected
  public noteSelected: number = 0

  // define the index of notes
  public indexNote: number = 0

  // define if form window is open
  public windowOpen: boolean = false

  // loading state
  public loading: boolean = false

  // A handle to navbar event messages so we can unsubscribe later.
  private navbarWatcher: any = null

  // save the note
  // public notes = []

  // Group of inputs
  public information = new FormGroup({
    text: new FormControl(null, [Validators.required]),
    alertFlag: new FormControl(false)
  })

  // Group of inputs
  public informationUpdate = new FormGroup({
    id: new FormControl(0, [Validators.required]),
    contactId: new FormControl(0, [Validators.required]),
    text: new FormControl(null, [Validators.required]),    
    alertFlag: new FormControl(false),
  })

  /*
  * functions
  */

  // get notes
  public list = (): void => {
    if (!this.contactId || (this.contactId <= 0)) {
      this.notesList = []
      return
    }

    if (this.loading) {
      return
    }
    
    this.loading = true

    this.master.get(`contacts/${this.contactId}/notes`, res => {
      this.loading = false
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.notesList = res.data
      this.alert()
    })
  }

  // add new note
  public save = (e): void => {
    e.preventDefault()
    
    if (this.information.invalid || !this.information.value) {
      return
    }

    this.loading = true
    this.master.post(`contacts/${this.contactId}/notes`, { ...this.information.value, contactId: this.contactId, type: 'contact' }, res => {
      this.loading = false
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
      if (this.information.get('alertFlag').value == true){
        this.windowOpen = true
      }
      this.reset()
      this.list()
    })
  }

  public reset = (): void => {
    this.information.reset()
    this.noteSelected = 0
    this.indexNote = -1
  }

  /**
   * Check to see if the any existing notes are alertable 
   * and display the notes automatically if so.
   */
  public alert = (): void => {
    if (!this.notesList || (!Array.isArray(this.notesList)) || (this.notesList.length <= 0) || this.windowOpen) {
      return
    }

    for (const note of this.notesList) {
      if (!note['alertFlag']) {
        continue
      }

      this.viewAlert = true
      this.windowOpen = true
      
      const btn = document.getElementById('btn-note-alert-contact')
      if (btn) {
        btn.click()
      }

      break
    }
  }

  /*
  todo: update functions
  */
  // set note form for update
  public setNote = (note: Object, index: number): void => {
    this.noteSelected = note['id']
    this.indexNote = index
    this.informationUpdate.setValue({
      contactId: this.contactId,
      text: note['text'],
      id: note['id'],
      alertFlag: note['alertFlag'],
      createdAt: note['createdAt'],
      createdBy: note['createdBy']
    })
  }

  // send form for update
  public update = (e): void => {
    e.preventDefault()

    if (this.informationUpdate.invalid) {
      return
    }

    this.loading = true
    this.master.put(`contacts/${this.contactId}/notes/${this.noteSelected}`, { ...this.informationUpdate.value }, res => {
      this.loading = false
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.reset()
      this.list()      
    })
  }

  /*
  !: delete functions
  */
  public delete = (e, id, index: number): void => {
    e.preventDefault()
    
    if (!id || (index < 0) || (index > this.notesList.length - 1)) {
      return
    }

    this.loading = true
    this.master.discard(`contacts/${this.contactId}/notes/${id}`, res => {
      this.loading = false

      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.reset()
      this.list()
    })
  }

  /*
  * lifre cycles
  */
  constructor(private master: MasterService, private ms: MessageService, private security: SecurityService) { }

  ngOnInit() {
    this.reset()
    this.list()

    this.navbarWatcher = this.ms.channelComponents$.subscribe(res => {
      if (res.message == "changeLanguage") {
        this.language = res.value
      }
    })
  }

  ngOnDestroy() {
    if (!this.navbarWatcher) {
      return
    }

    this.navbarWatcher.unsubscribe()
  }
}
