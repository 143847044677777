import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { parseString } from "xml2js";
// services
import { MessageService } from '../../../../services/message.service'
import { MasterService } from '../../../../services/master.service'
import { StoreService } from '../../../../services/store.service'
import * as languageLibrary from '../../../../services/language'

import * as moment from 'moment/moment';
import { isDate } from 'util';
import { SUPPORTED_IMAGE_TYPES } from 'src/app/constants/file';


@Component({
    selector: 'inventory-scan',
    templateUrl: './inventory-scan.component.html',
    styleUrls: ['./inventory-scan.component.scss']
})
export class InventoryScanComponent implements OnInit {
    /*
    * Variables
    */    
    @Input() lotId: number;
    
    @Input() inventoryId: number = 0
    
    // save the language
    @Input() language: string = 'EN'
    
    @Input() calledFrom: string = ""
    
    @Input() inventorySetupData: Object
    
    @Output() emitterInformation$: EventEmitter<Object> = new EventEmitter()

    public vinImages: any[] = []

    public vehicleImages: any[] = []

    // set all words
    public words = languageLibrary.language

    public vendors: Object
    
    public duplicateVINFound: boolean = false
    
    public inventorySourceId: number

    /*
   * life cycles
   */
    constructor(private router: Router, private route: ActivatedRoute, private ms: MessageService, private master: MasterService, private store: StoreService) {}

    ngOnInit() {
        this.lotId = parseInt(this.route.snapshot.queryParams["lotId"])
        
        this.getVendors()
    }
    
    // * format bytes
    public formatBytes = (bytes: number): string => {
        return (bytes / 1024).toFixed(2) + " KB"
    }

    // * upload vin number vinImages
    public organizeVINImage = (e): void => {
        let files = e.target.files
        let reader = []
        let vm = this
        for (let index = 0; index < files.length; index++) {
            // check if some file is invalid
            if (SUPPORTED_IMAGE_TYPES.includes(files[index].type)) {
                // * format valid
                reader[index] = new FileReader();
                reader[index].readAsDataURL(files[index]);
                // listener for reader
                reader[index].onload = function (event) {
                    vm.vinImages.push({
                        image: `${reader[index].result}`,
                        name: files[index].name,
                        size: vm.formatBytes(files[index].size),
                        type: files[index].type,
                        status: 'Uploading Image',
                        imageIndex: index
                    })
                    vm.scanVIN(files[index], index)
                };

            } else {
                // ! invalid format
                vm.ms.sendMessage("alert", { type: "danger", text: vm.words[vm.language]['Invalid image format'] });
            }
        }
    }

    // * send post to get vin Number
    public async scanVIN(files, index) {
        // send post
        let vm = this
        let vinImage = new FormData();
        vinImage.append("inputimage", files);
        let request = new XMLHttpRequest();
        request.open("POST", "https://www.recognition.ws/vinbarcode/v1?accesscode=f3297bcb-6a50-41d2-8b5e-f4859d5062f4&saveImage=FALSE&vindecode=TRUE");
        request.send(vinImage)
        request.onload = await function async(oEvent) {
            parseString(request.response, async function (err, results) {
                // parsing to json
                let data = results
                if (vm.calledFrom === 'inventoryDetail') {
                    if (data.VINbarcode.$.Status == 'SUCCESS') {
                        vm.emitterInformation$.emit({ message: 'saveScannedVIN', vinNumber: data.VINbarcode.VIN_Captured[0] })
                    } else {
                        vm.emitterInformation$.emit({ message: 'failedScannedVIN', error: data.VINbarcode.Message[0].$.Value })
                    }
                    (document.getElementById('btn-close-scan-list') as HTMLButtonElement).click();
                    return
                }
                if (data.VINbarcode.$.Status == 'SUCCESS') {
                    vm.vinImages[index].modelYear = data.VINbarcode.VINdecode[0].Year["0"]
                    vm.vinImages[index].make = data.VINbarcode.VINdecode[0].Make["0"]
                    vm.vinImages[index].model = data.VINbarcode.VINdecode[0].Model["0"]
                    vm.vinImages[index].vinNumber = data.VINbarcode.VIN_Captured[0]
                    vm.vinImages[index].status = 'SUCCESS'
                    vm.vinImages[index].inventoryId = 0
                    vm.vinImages[index].vehicleImages = []
                    await vm.checkDuplicateVIN(vm.vinImages[index])
                    await vm.decode(vm.vinImages[index])
                    await vm.setStockNumber(vm.vinImages[index])
                } else
                    vm.vinImages[index].status = data.VINbarcode.Message[0].$.Value
            });
        }
    }

    // * upload vin number vehicleImages
    public organizeVehicleImages = (e, i): void => {
        let files = e.target.files
        let reader = []
        let vm = this
        for (let index = 0; index < files.length; index++) {
            // check if some file is invalid
            if (['image/jpg', 'image/jpeg', 'image/heic', 'image/heif', , 'image/webp'].includes(files[index].type)) {
                // * format valid
                reader[index] = new FileReader();
                reader[index].readAsDataURL(files[index]);
                // listener for reader
                reader[index].onload = function (event) {
                    vm.vinImages[i].vehicleImages.push({
                        image: `${reader[index].result}`,
                        name: files[index].name,
                        size: vm.formatBytes(files[index].size),
                        type: files[index].type,
                        status: 'Scanning VIN Image',
                        imageIndex: i
                    })
                };

            } else {
                // ! invalid format
                vm.ms.sendMessage("alert", { type: "danger", text: vm.words[vm.language]['Invalid image format'] });
            }
        }
    }


    // * upload  car images
    public uploadImage = (image): void => {
        this.master.post('uploadImages', { lastSort: 1, moduleType: 'inventory', imagesArr: image, inventoryId: this.inventoryId }, res => {

            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }
        })
    }

    public async checkDuplicateVIN(responseVINData) {
        if (!responseVINData || !responseVINData.vinNumber || (responseVINData.vinNumber.length <= 0)) {
            return
        }

        responseVINData.status = ""
        this.duplicateVINFound = false        
        this.master.get(`inventory/vin/exists?vinNumber=${responseVINData.vinNumber}`, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                responseVINData.status = "Contact support team."
            }
            
            if (res.status === 200) {
                return
            }

            this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
            this.duplicateVINFound = (res.status === 409)
        })
    }

    public async setStockNumber(responseVINData) {
        responseVINData.status = ""
        this.master.get(`inventory/stock/next?lotId=${this.lotId}&vinNumber=${responseVINData.vinNumber}`, res => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            const info = res.data.inventoryStockData
            if (!info) {
                return
            }

            const stockNumber = info.preStockNumber || ''
            const statusId = parseInt(res.data.inventoryStatusId)

            responseVINData.stockNumber = stockNumber
            responseVINData.lotId = this.lotId
            responseVINData.cleanStockNumber = stockNumber
            responseVINData.statusId = statusId
            responseVINData.dateOnLot = moment(moment().format('YYYY-MM-DD'))
        })
    }

    public async saveAll() {
        const vinImagesToCreate = this.vinImages.filter(image => image.vinNumber !== null && image.vinNumber !== "")
        if (vinImagesToCreate.length == 0) {
            return
        }
        let i = vinImagesToCreate.length
        while (i--) {
            await this.createInventory(vinImagesToCreate[i], i)
        }
    }

    public async createInventory(responseVINData, index) {
        responseVINData["inventorySourceId"] = (document.getElementById('inventorySourceId') as HTMLInputElement).value !== "" ? (document.getElementById('inventorySourceId') as HTMLInputElement).value : null
        responseVINData.status = "Creating Inventory"
        const payload = responseVINData
        this.master.post(`inventory/`, { inventoryData: payload }, res => {
            if (!res) {
                responseVINData.status = "Error: " + this.words[this.language]['apiNoResponse']
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.data.error == 'Error: Stock Number is taken.') {
                responseVINData.status = res.data.error
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                responseVINData.status = res.data.error
                return
            }

            responseVINData.status = "Inventory Created Successfully"
            this.vinImages.splice(index, 1)
            if (index = 0) {
                this.vinImages = []
            }

        })
    }
    public changeStockNumber(responseVINData: any[]) {
        responseVINData['stockNumber'] = (document.getElementById('stockNumber_' + responseVINData['imageIndex']) as HTMLInputElement).value
        responseVINData['status'] = null
    }
    public changeMiles(responseVINData: any[]) {
        responseVINData['miles'] = (document.getElementById('miles_' + responseVINData['imageIndex']) as HTMLInputElement).value
    }

    public changeCost(responseVINData: any[]) {
        responseVINData['purchaseCost'] = (document.getElementById('cost_' + responseVINData['imageIndex']) as HTMLInputElement).value
    }

    public changeInventorySource(responseVINData: any[]) {
        responseVINData['inventoryId'] = (document.getElementById('invSrcId_' + responseVINData['imageIndex']) as HTMLInputElement).value
    }

    public async decode(responseVINData: any[]) {
        this.master.get(`vendors/vin/decode?vinNumber=${responseVINData['vinNumber']}&lotId=${this.lotId}`, res => {
            if (!res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            const decoded = res.data
            responseVINData["vinNumber"] = decoded.vinNumber
            responseVINData["modelYear"] = decoded.modelYear
            responseVINData["make"] = decoded.make
            responseVINData["model"] = decoded.model
            responseVINData["cylinders"] = decoded.cylinders
            responseVINData["transmission"] = decoded.transmission
            responseVINData["driveline"] = decoded.driveline
            responseVINData["trimLevel"] = decoded.trimLevel
            responseVINData["bodyStyle"] = decoded.bodyStyle
            responseVINData["engine"] = decoded.engine
            responseVINData["curbWeight"] = decoded.curbWeight
        })
    }

    public getVendors() {
        //used for inventory source
        this.master.get(`contacts?type=vendor`, (res) => {
            this.vendors = [];
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.vendors = res.data.contacts
        })
    }

    public deleteVehicleToUpload(i) {
        this.vinImages.splice(i, 1)
    }

    public refreshInventoryList() {
        this.emitterInformation$.emit({ message: 'refreshInventoryList' })
    }

}