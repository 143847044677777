import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Injector, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MasterService } from '../../../../../../services/master.service';
import { MessageService } from 'src/app/services/message.service';
import * as languageLibrary from '../../../../../../services/language'
import { DynamicService } from 'src/app/services/dynamic.service';
import { token } from '../roles.component';
@Component({
  selector: '[role-cpi]',
  templateUrl: './role-cpi.component.html',
  styleUrls: ['./role-cpi.component.scss']
})

export class ContactCPIComponent implements OnInit {
  public contactType: string = null
  public contactId: number
  public contactRoleId: number = 0
  public words = languageLibrary.language
  public language: string = 'EN'
  public cpiInformation = new FormGroup({
    id: new FormControl(null),
    contactId: new FormControl(null, [Validators.required]),
    defaultDeductible: new FormControl(null),
    defaultPremium: new FormControl(null),
    policyNumber: new FormControl(null),
    primary: new FormControl(1),
  })
  public dataIn: string;
  public id: number = 0;
  public calledFrom: string = null;

  constructor(private master: MasterService, private ms: MessageService, private inject: Injector, private serv: DynamicService) {
  }

  deactivateRole() {
    this.serv.outputFromDynamicComponent({ action: "deactivate", contactRoleId: this.contactRoleId });
  }
  ngOnInit(): void {

    this.dataIn = this.inject.get(token);
    this.contactId = this.dataIn['contactId']
    this.contactRoleId = this.dataIn['contactRoleId']
    this.contactType = this.dataIn['contactType']
    this.calledFrom = this.dataIn['calledFrom']

    if (!this.contactId) {
      return
    }

    this.cpiInformation.get("contactId").setValue(this.contactId)

    this.getCPI()
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
  }

  public getCPI() {
    this.master.get(`contacts/${this.contactId}/cpi`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      if (res.data.contactCPI.length == 0) {
        if (this.calledFrom == "contactAdvancedSearch")
          this.serv.outputFromDynamicComponent({ action: "setupNeeded", setupNeeded: true, type:"cpiVendor" });
        return
      }
      
      this.setInformation(res.data.contactCPI[0])

      if (this.calledFrom == "contactAdvancedSearch") {
        this.serv.outputFromDynamicComponent({ action: "setupNeeded", setupNeeded: false, type:"cpiVendor" });
        return
      }
    })
  }

  public saveCPI() {
    if (!this.cpiInformation.valid) {
      return
    }

    if (this.id <= 0) {
      this.createCPI()
      return
    }

    if (this.id >= 1) this.modifyCPI()
  }

  createCPI() {
    this.master.post(`contacts/${this.contactId}/cpi`, { information: this.cpiInformation.value, }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
      this.setInformation(res.data.contactCPI)

      if (this.calledFrom == "contactAdvancedSearch")
        this.serv.outputFromDynamicComponent({ action: "setupSaved", setupSaved: true, type:"cpiVendor"  });
    })
  }

  modifyCPI() {
    this.master.put(`contacts/${this.contactId}/cpi/${this.id}`, { information: this.cpiInformation.value, }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
      
      if (this.calledFrom == "contactAdvancedSearch")
        this.serv.outputFromDynamicComponent({ action: "setupSaved", setupSaved: true, type:"cpiVendor"  });

    })
  }

  deleteCPI() {
    this.master.discard(`contacts/${this.contactId}/cpi/${this.id}`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.resetInformation()
    })
  }

  resetInformation() {
    this.cpiInformation.reset({
      contactId: this.contactId
    })
    this.id = null
  }

  setInformation(info) {
    this.id = info["id"]
    this.cpiInformation.setValue({
      id: info['id'],
      contactId: info['contactId'],
      defaultDeductible: info['defaultDeductible'],
      defaultPremium: info['defaultPremium'],
      policyNumber: info['policyNumber'],
      primary: info['primary'],
    })
  }
}