import { Component, OnInit, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { MessageService } from '../../../../services/message.service';
import { MasterService } from '../../../../services/master.service';
import { StoreService } from '../../../../services/store.service';
import * as words from '../../../../services/language';
import { formatCurrency } from 'src/app/utils/format';
import { ago } from 'src/app/utils/date';
import { clean } from 'src/app/utils/numeric';
import { PAYMENT_METHODS, PAYMENT_SOURCES } from 'src/app/constants/payments';
import { MIN_SEARCH_CHARS, SEARCH_DEBOUNCE_MILLIS } from 'src/app/constants/list';
import * as moment from 'moment';
import { Subject } from "rxjs";

@Component({
    selector: 'take-payment',
    templateUrl: './take-payment.component.html',
    styleUrls: ['./take-payment.component.scss']
})
export class TakePaymentComponent implements OnInit {

    // Unique internal identity of the contact whose soon due payments will be queried for, overrides the lotId.
    @Input() contactId: number = 0

    // Available payments for the given contact.
    @Input() pays: Object = null

    // Currently selected language
    @Input() language: string = 'EN'

    // Word replacements for each language.
    @Input() words: Object = words.language

    // A payment was taken so lets update our available payments and any suppporting displays.
    @Input() payThisAmount: Subject<number>

    // Communicate the processing of a payment to those who will listen.
    @Output() paymentTaken: EventEmitter<Object> = new EventEmitter()

    // Indicates whether the data is currently loading.
    public loading: boolean = false

    // Stored payment accounts for the current contact.
    public accounts: Array<any> = []

    // Top level payment form with basic details.
    public form: FormGroup;

    // Payment account input form when a saved account is not used.
    public account: FormGroup;

    // A list of available payment methods in the sysem
    public methods = PAYMENT_METHODS;

    // A list of potential payment sources currently accepted by the system.
    public sources = PAYMENT_SOURCES;

    // A list of zip codes matching the inquiry.
    public zipCodeList: Object[] = []

    // Latest zip code search inquiry
    public zipInquiry = null

    // The zip code serach timeout identity (debounce)
    private timeOutID: any;

    /**
     * Helper method to consistently get the list of form payments as an Array.
     */
    get payments() {
        return this.form.controls['payments'] as FormArray;
    }

    /**
     * Get the total amount the contact wants to pay today.
     */
    get amountToPay() { return clean(this.form.get('amount').value) }

    /**
     * A list of years available for card expiry date.
     */
    get years() {
        const current = (new Date()).getFullYear()
        
        const items = []
        for (let index=0; index <= 10; index++) {
            items.push(current + index)
        }

        return items
    }

    /**
     * Grab the current payment method type
     */
    get payMethodType(): string { 
        if (!this.account || !this.account.get('type') || !this.account.get('type').value) {
            return ''
        }

        return this.account.get('type').value
    }

    /**
     * Is the new payment method cash?
     */
    get cash(): boolean { 
        if (!this.payMethodType) {
            return false
        }

        return (this.payMethodType === 'cash')
    }

    /**
     * Is the new payment method a bank draft (cheecking, savings?
     */
    get bank(): boolean { 
        if (!this.payMethodType) {
            return false
        }

        return (this.payMethodType === 'bank draft')
    }

    /**
     * Is the new payment method a credit or debit card?
     */
    get card(): boolean { 
        if (!this.payMethodType) {
            return false
        }

        return (['credit card', 'debit card', 'pre-paid debit card'].includes(this.payMethodType))
    }

    /**
     * Is the new payment method a check?
     */
    get check(): boolean { 
        if (!this.payMethodType) {
            return false
        }

        return (['check', 'travelers check', 'cashiers check', 'money order'].includes(this.payMethodType))
    }

    /**
     * Is the new payment method from a third-party?
     */
    get thirdParty(): boolean { 
        if (!this.payMethodType) {
            return false
        }

        return (this.payMethodType === 'third-party')
    }

    constructor (private route: ActivatedRoute, private ms: MessageService, private master: MasterService, private store: StoreService, private fb: FormBuilder) {
        this.form = this.fb.group({
            amount: new FormControl(0, []),
            accountId: new FormControl(null, []),
            payments: this.fb.array([]) // expectedId, due, category, amount of desired allocation
        })

        this.account = this.fb.group({
            type: new FormControl('cash', [Validators.required]),
            source: new FormControl('walk-in', [Validators.required]),
            holder: new FormControl('', []),
            address: new FormControl('', []),
            city: new FormControl('', []),
            state: new FormControl('', []),
            zipCode: new FormControl('', []),
            route: new FormControl(null, []),
            number: new FormControl('', []),
            code: new FormControl(null, []),
            expiry_month: new FormControl(1, []),
            expiry_year: new FormControl((new Date()).getFullYear(), []),
            expires: new FormControl(null, []),
            checkNumber: new FormControl(null, []),
            paidIn100s: new FormControl(formatCurrency(0), []),
            confirmation: new FormControl(null, []),
            save: new FormControl(false, [])
        })
    }

    ngOnInit() {
        this.applyValidators()
        this.fetchPaymentAccounts()

        this.payThisAmount.subscribe((toPay: number) => {
            if (!toPay || (toPay <= 0)) {
                return
            }

            this.form.get('amount').setValue(formatCurrency(toPay))
            this.selectPays(undefined)
        })

        this.account.get('type').valueChanges.subscribe((_) => {
            this.applyValidators()
        })

        this.account.get('zipCode').valueChanges.subscribe((zip) => {
            if (!this.account.get('zipCode')) {
                return
            }

            const code = this.account.get('zipCode').value
            if (!code || (code.length < MIN_SEARCH_CHARS)) {
                return
            }

            if (this.zipInquiry && (code === this.zipInquiry)) { // hasn't changed?
                return
            }

            clearTimeout(this.timeOutID)

            this.timeOutID = setTimeout(() => {
                this.zipCodeList = []
                this.zipInquiry = code

                this.master.get(`readLocationByZipcode?zipcode=${code}`, res => {
                    if (!res) {
                        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                        return
                    }

                    if (res.status !== 200) {
                        return
                    }

                    this.zipCodeList = res.data.locations

                    // autocomplete
                    if ((code >= 5) && (this.zipCodeList.length === 1)) {
                        this.zipSelected(this.zipCodeList[0])
                    }
                })
            }, SEARCH_DEBOUNCE_MILLIS)
        })
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes) {
            return
        }

        const first = Object.values(changes).some(c => c.isFirstChange())
        if (first) {
            return
        }

        if (changes.contactId) {
            this.fetchPaymentAccounts()
        }

        if (changes.pays) {
            this.identifyAvailablePayments()
        }
    }

    /**
     * After a payment is taken let's reset all of the forms.
     */
    private reset = (): void => {
        this.payments.clear()
        this.form.reset()
        this.account.reset()

        this.form.get('amount').setValue(formatCurrency(0))
        this.account.get('type').setValue('cash')
        this.account.get('source').setValue('walk-in')
        this.account.get('expiry_month').setValue(1)
        this.account.get('expiry_year').setValue((new Date()).getFullYear())
    }

    /**
     * Add/remove validators based on the current payment method type.
     */
    private applyValidators = () => {
        if (!this.account || !this.account.get('type')) {
            return
        }

        const type = this.account.get('type').value
        if (!type || (type.length <= 0)) {
            return
        }

        this.account.get('checkNumber').clearValidators()
        this.account.get('expiry_month').clearValidators()
        this.account.get('expiry_year').clearValidators()
        this.account.get('number').clearValidators()
        this.account.get('code').clearValidators()
        this.account.get('route').clearValidators()
        this.account.get('confirmation').clearValidators()

        this.account.get('checkNumber').updateValueAndValidity()
        this.account.get('expiry_month').updateValueAndValidity()
        this.account.get('expiry_year').updateValueAndValidity()
        this.account.get('number').updateValueAndValidity()
        this.account.get('code').updateValueAndValidity()
        this.account.get('route').updateValueAndValidity()
        this.account.get('confirmation').updateValueAndValidity()

        switch (type) {
            case 'cash':
                break
            case 'check':
            case 'money order':
            case 'cashiers check':
            case 'travelers check':
                this.account.get('checkNumber').setValidators([Validators.pattern('^[0-9]*$'), Validators.required])
                this.account.get('checkNumber').updateValueAndValidity()
                break
            case 'credit card':
            case 'debit card':
                this.account.get('expiry_month').setValidators([Validators.pattern('^[0-9]{1,2}$'), Validators.required])
                this.account.get('expiry_year').setValidators([Validators.pattern('^[0-9]{4}$'), Validators.required])
                this.account.get('number').setValidators([Validators.pattern('^[0-9]{4,17}$'), Validators.required])
                this.account.get('code').setValidators([Validators.pattern('^[0-9]{3,4}$')])

                this.account.get('expiry_month').updateValueAndValidity()
                this.account.get('expiry_year').updateValueAndValidity()
                this.account.get('number').updateValueAndValidity()
                this.account.get('code').updateValueAndValidity()
                break
            case 'bank draft':
                this.account.get('number').setValidators([Validators.pattern('^[0-9]{4,17}$'), Validators.required])
                this.account.get('route').setValidators([Validators.pattern('^[0-9]{9}$'), Validators.required])

                this.account.get('number').updateValueAndValidity()
                this.account.get('route').updateValueAndValidity()
                break
            case 'third-party':
                this.account.get('confirmation').setValidators([Validators.required])
                this.account.get('confirmation').updateValueAndValidity()
                break
        }
    }

    /** 
     * Fetch the the stored payment accounts (tokens) for the curent contact.
    */
    private fetchPaymentAccounts = (): void => {
        if (this.loading) {
            return
        }

        if (!this.contactId || (this.contactId <= 0)) {
            return
        }

        this.loading = true

        this.accounts.length = 0
        this.accounts = []

        this.master.get(`contacts/${this.contactId}/accounts`, (res) => {
            this.loading = false
  
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }
  
            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.accounts = res.data
            if (!this.accounts || (this.accounts.length <= 0)) {
                return
            }

            this.form.get('accountId').setValue(this.accounts[0].id)
        })
    }

    /**
     * Determine what needs to be paid or what is due and
     * add them to the payments array in the form.
     */
    private identifyAvailablePayments = (): void => {
        if (!this.pays || (Object.keys(this.pays).length <= 0)) {
            return
        } 

        this.payments.clear()

        const today = this.pays['today']
        if (today && (today.total > 0)) {
            this.form.get('amount').setValue(formatCurrency(today.total))
            this.addPayments(today.items, true)
        }

        const next = this.pays['next']
        if (next && (next.total > 0)) {
            this.addPayments(next.items)
        }
    }

    /**
     * Add the given payments to the available payments.
     * @param pays A list of payments that are vailable to pay.
     * @param selected Indicates the payment will be selected, initially, for payment.
     */
    private addPayments = (pays: Array<Object>, selected: Boolean = false): void => {
        if (!pays || !Array.isArray(pays) || (pays.length <= 0)) {
            return
        }

        for (const pay of pays) {
            const payment = this.fb.group({
                id: new FormControl(pay['id'], []),
                category: new FormControl(pay['category'], []),
                label: new FormControl(pay['vehicle'], []),
                due: new FormControl(pay['due'], []),
                amount: new FormControl(formatCurrency(pay['amount']), []),
                full: new FormControl(formatCurrency(pay['amount']), []),
                url: new FormControl(pay['url'], []),
                selected: new FormControl(selected, [])
            })

            this.payments.push(payment)
        }
    }

    public dueToClass = (due: string): string => {
        return ago(due)
    }

    /**
     * Toggle the true/false value of the given payment selection
     * @param index Index of the payment whose selector button was checked.
     */
    public toggleSelectedPay = (index: number) => {
        if ((index < 0) || (index > this.payments.controls.length)) {
            return
        }

        const payment = this.payments.controls[index]
        if (!payment) {
            return
        }

        const amount = clean(payment.get('amount').value)

        const selected = !payment.get('selected').value
        if (selected && (amount <= 0)) {
            const fullPayAmt = clean(payment.get('full').value)
            payment.get('amount').setValue(formatCurrency(fullPayAmt))
        }

        payment.get('selected').setValue(selected)
        this.computePayAmount()
    }

    /**
     * After a payment selection changes or an amount changes 
     * we need to recompute the total payment amount (amount 
     * to pay).
     */
    private computePayAmount = () => {
        if (!this.payments || (this.payments.controls.length <= 0)) {
            this.form.get('amount').setValue(formatCurrency(0), {emitEvent: false})
            return
        }

        let amount = 0
        for (let payment of this.payments.controls) {
            if (!payment.get('selected').value) {
                continue
            }

            const payAmt = clean(payment.get('amount').value)
            if (payAmt <= 0) {
                payment.get('amount').setValue(formatCurrency(0), {emitEvent: false})
                payment.get('selected').setValue(false, {emitEvent: false})
                continue
            }

            amount += payAmt
        }

        this.form.get('amount').setValue(formatCurrency(amount), {emitEvent: false})
    }

    /**
     * If the total payment amount changes we need to toggle the 
     * selections of the payments that could be covered by the 
     * new amount.
     * @param {Event} e
     */
    public selectPays = (e: Event) => {
        if (!this.payments || (this.payments.controls.length <= 0)) {
            return
        }

        let amount = clean(this.form.get('amount').value)
        for (let payment of this.payments.controls) {
            payment.get('selected').setValue(false)
            const fullPayAmt = clean(payment.get('full').value)

            if (amount <= 0) {
                payment.get('amount').setValue(formatCurrency(0), {emitEvent: false})
                continue
            }

            if (amount < fullPayAmt) {
                payment.get('amount').setValue(formatCurrency(amount), {emitEvent: false})
                payment.get('selected').setValue(true)
                amount = 0
                continue
            }

            payment.get('amount').setValue(formatCurrency(fullPayAmt), {emitEvent: false})
            payment.get('selected').setValue(true)
            amount -= fullPayAmt
        }
    }

    /**
     * 
     * @param address
     */
    public zipSelected = (address: Object): void => {
        if (!address || !this.account) {
            return
        }

        this.account.get('city').setValue(address['city'])
        this.account.get('state').setValue(address['state'])
        this.account.get('zipCode').setValue(address['zipcode'])

        this.zipCodeList = []
    }

    /**
     * Attempt to process the payment with the current payment and method details.
     * @param {Event} e Associated event for the take payment action.
     * @param {Boolean} [withStoredAcct = false] Indicates the customer is paying with a stored account rather than a new one.
     */
    public take = (e: Event, withStoredAcct: Boolean = false) => {
        if (e) {
            e.preventDefault()
            e.stopPropagation()
        }

        if (!this.payments || (this.payments.controls.length <= 0)) {
            return
        }

        if (this.form.invalid || this.account.invalid) {
            return
        }

        const payload = {
            paid: moment().format('YYYY-MM-DD'),
            amount: clean(this.form.get('amount').value),
            source: this.account.get('source').value,
            payments: []
        }

        for (let payment of this.payments.controls) {
            if (!payment.get('selected').value) {
                continue
            }

            const amount = clean(payment.get('amount').value)
            if (!amount || isNaN(amount) || (amount <= 0)) {
                continue
            }

            const meta = {
                expectedId: parseInt(payment.get('id').value),
                amount: amount,
                type: this.account.get('type').value.toLowerCase()
            }

            const accountId = parseInt(this.form.get('accountId').value)
            if (withStoredAcct && accountId && !isNaN(accountId) && (accountId > 0)) {
                meta['accountId'] = accountId
                payload.payments.push(meta)
                continue
            }

            switch (meta.type) {
                case 'cash':
                    meta['paidIn100s'] = clean(this.account.get('paidIn100s').value)
                    break
                case 'check':
                case 'money order':
                case 'cashiers check':
                case 'travelers check':
                    meta['checkNumber'] = this.account.get('checkNumber').value
                    break
                case 'credit card':
                case 'debit card':
                    const year = parseInt(this.account.get('expiry_year').value)
                    const month = parseInt(this.account.get('expiry_month').value)
                    const expiry = moment(`${year}-${month}-01`).endOf('month').format('YYYY-MM-DD')
                    
                    meta['holder'] = this.account.get('holder').value
                    meta['address'] = this.account.get('address').value
                    meta['city'] = this.account.get('city').value
                    meta['state'] = this.account.get('state').value
                    meta['zipCode'] = this.account.get('zipCode').value
                    meta['number'] = parseInt(this.account.get('number').value)
                    meta['code'] = parseInt(this.account.get('code').value)
                    meta['expires'] = expiry
                    meta['save'] = (this.account.get('save').value === true)
                    break
                case 'bank draft':
                    meta['holder'] = this.account.get('holder').value
                    meta['address'] = this.account.get('address').value
                    meta['city'] = this.account.get('city').value
                    meta['state'] = this.account.get('state').value
                    meta['zipCode'] = this.account.get('zipCode').value
                    meta['route'] = parseInt(this.account.get('route').value)
                    meta['number'] = parseInt(this.account.get('number').value)
                    meta['code'] = parseInt(this.account.get('code').value)
                    meta['save'] = (this.account.get('save').value === true)
                    break
                case 'third-party':
                    meta['confirmation'] = this.account.get('confirmation').value
                    break
            }

            payload.payments.push(meta)
        }

        if (!payload || !payload.payments || (payload.payments.length <= 0)) {
            return
        }

        this.master.post(`payments/pay`, payload, (res) => {
            this.loading = false
  
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }
  
            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.paymentTaken.next({amount: payload.amount})
            this.reset()
        })
    }
}
