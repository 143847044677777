import { Injectable } from '@angular/core';
import { ContactCPIComponent } from '../views/contacts-views/contacts/components/roles/role-cpi/role-cpi.component';
import { ContactFloorPlanComponent } from '../views/contacts-views/contacts/components/roles/role-floorplan/role-floorplan.component';
import { ContactLeadComponent } from '../views/contacts-views/contacts/components/roles/role-lead/role-lead.component';
import { BusinessContactComponent } from '../views/contacts-views/contacts/components/roles/role-businessContact/role-businessContact.component';
import { RoleLienholderComponent } from '../views/contacts-views/contacts/components/roles/role-lienholder/role-lienholder.component';
import { RoleRelatedFinanceComponent } from '../views/contacts-views/contacts/components/roles/role-related-finance/role-related-finance.component';
import { RoleWarrantyComponent } from '../views/contacts-views/contacts/components/roles/role-warranty/role-warranty.component';
import { RoleLifeInsuranceComponent } from '../views/contacts-views/contacts/components/roles/role-lifeinsurance/role-lifeinsurance.component';
import { RoleGapComponent } from '../views/contacts-views/contacts/components/roles/role-gap/role-gap.component';
import { RoleInventorySourceComponent } from '../views/contacts-views/contacts/components/roles/role-inventory-source/role-inventory-source.component';
import { RoleVendorComponent } from '../views/contacts-views/contacts/components/roles/role-vendor/role-vendor.component';
import { RoleDCAComponent } from '../views/contacts-views/contacts/components/roles/role-dca/role-dca.component';
import { Type } from '@angular/core';

// Add Component Above
// Add Component to the declarations and entryComponents in app.module.ts

export class RoleItem {
  constructor(public component: Type<any>, public data: any, public isActive: boolean) { }
}

@Injectable()
export class RoleService {
  getRoles(roleArray, contactId, calledFrom) {
    const roleComponents = []

    if (roleArray.length == 0) {
      return
    }

    roleArray.forEach(role => {
      switch (role.contactType.key) {
        case "cpiVendor":
          roleComponents.push(new RoleItem(
            ContactCPIComponent,
            { contactId: contactId, name: role.contactType.type, contactRoleId: role.id, contactType: role.contactType.key, calledFrom: calledFrom },
            false
          ))
          break;
        case "autoInsurance":
          roleComponents.push(new RoleItem(
            ContactCPIComponent,
            { contactId: contactId, name: role.contactType.type, contactRoleId: role.id, contactType: role.contactType.key, calledFrom: calledFrom },
            false
          ))
          break;
        case "floorPlanVendor":
          roleComponents.push(new RoleItem(
            ContactFloorPlanComponent,
            { contactId: contactId, name: role.contactType.type, contactRoleId: role.id, calledFrom: calledFrom },
            false
          ))
          break;
        case "salesLead":
          roleComponents.push(new RoleItem(
            ContactLeadComponent,
            { contactId: contactId, name: role.contactType.type, contactRoleId: role.id, calledFrom: calledFrom },
            false
          ))
          break;
        case "businessContact":
          roleComponents.push(new RoleItem(
            BusinessContactComponent,
            { contactId: contactId, name: role.contactType.type, contactRoleId: role.id, calledFrom: calledFrom },
            false
          ))
          break;
        case "lienholder":
          roleComponents.push(new RoleItem(
            RoleLienholderComponent,
            { contactId: contactId, name: role.contactType.type, contactRoleId: role.id, calledFrom: calledFrom },
            false
          ))
          break;
        case "warranty":
          roleComponents.push(new RoleItem(
            RoleWarrantyComponent,
            { contactId: contactId, name: role.contactType.type, contactRoleId: role.id, calledFrom: calledFrom },
            false
          ))
          break;
        case "finance":
          roleComponents.push(new RoleItem(
            RoleRelatedFinanceComponent,
            { contactId: contactId, name: role.contactType.type, contactRoleId: role.id, calledFrom: calledFrom },
            false
          ))
          break;
        case "lifeInsurance":
          roleComponents.push(new RoleItem(
            RoleLifeInsuranceComponent,
            { contactId: contactId, name: role.contactType.type, contactRoleId: role.id, calledFrom: calledFrom },
            false
          ))
          break;
        case "gap":
          roleComponents.push(new RoleItem(
            RoleGapComponent,
            { contactId: contactId, name: role.contactType.type, contactRoleId: role.id, calledFrom: calledFrom },
            false
          ))
          break;
        case "vendor":
          roleComponents.push(new RoleItem(
            RoleVendorComponent,
            { contactId: contactId, name: role.contactType.type, contactRoleId: role.id, calledFrom: calledFrom },
            false
          ))
          break;
        case "inventorySource":
          roleComponents.push(new RoleItem(
            RoleInventorySourceComponent,
            { contactId: contactId, name: role.contactType.type, contactRoleId: role.id, calledFrom: calledFrom },
            false
          ))
          break;
        case "debtCancelation":
          roleComponents.push(new RoleItem(
            RoleDCAComponent,
            { contactId: contactId, name: role.contactType.type, contactRoleId: role.id, calledFrom: calledFrom },
            false
          ))
          break;
        default:
          break;
      }
    })

    return roleComponents
  }
}