import { Component, Input, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
// services
import { MessageService } from "../../../../services/message.service";
import { MasterService } from "../../../../services/master.service";
import * as languageLibrary from '../../../../services/language'
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
//

@Component({
  selector: 'collections-setup',
  templateUrl: './collections-setup.component.html',
  styleUrls: ['./collections-setup.component.scss']
})
export class CollectionsSetupComponent implements OnInit {
  // *******************
  // ** variables ******
  // *******************
  //
  // save the language
  @Input() language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  @Input() entityType: string
  @Input() entityId: number
  public paymentAllocationData = [];
  public paymentAllocationOrderTemplate: string = `[{"id":1,"name":"Initial Down","OrderNo":1,"flag":true,"draggable":false},{"id":2,"name":"Deferred Down","OrderNo":2,"flag":true,"draggable":false},{"id":3,"name":"MV Loan","OrderNo":3,"flag":false,"draggable":true},{"id":4,"name":"CPI","OrderNo":4,"flag":false,"draggable":true},{"id":5,"name":"After-Sale Charges","OrderNo":5,"flag":false,"draggable":true},{"id":6,"name":"Drive-Out Price","OrderNo":6,"flag":false,"draggable":true},{"id":7,"name":"Bounce Charge Amount","OrderNo":7,"flag":false,"draggable":true}]`
  public loading: boolean = false
  public expanded: boolean = false
  public collectionSetup: Object;
  public form: FormGroup = null

  // *******************
  // ** functions ******
  // *******************

  getCollectionSetup() {
    this.paymentAllocationData = JSON.parse(this.paymentAllocationOrderTemplate)
    if (isNaN(this.entityId) || this.entityId == 0) {
      return
    }
    this.master.get(`lots/${this.entityId}/collectionSetup`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.collectionSetup = res.data.collectionSetup
      if (!this.collectionSetup) {
        return
      }

      this.setInformationValues(this.collectionSetup)
    })

  }
  setInformationValues(collectionSetupData: Object) {
    this.paymentAllocationData = JSON.parse(collectionSetupData['paymentAllocationOrder'])
    this.form.setValue({
      id: collectionSetupData['id'],
      lotId: collectionSetupData['lotId'],
      dealershipId: collectionSetupData['dealershipId'],
      receiptStartingNumber: collectionSetupData['receiptStartingNumber'],
      paymentAllocationOrder: collectionSetupData['paymentAllocationOrder'],
      defaultCollectionMethod: collectionSetupData['defaultCollectionMethod']
    });
  }

  public save() {
    if (this.form.invalid) {
      return
    }

    this.form.get('paymentAllocationOrder').setValue(JSON.stringify(this.paymentAllocationData))

    if (!this.form.controls['id'].value) {
      this.createCollectionSetup()
      return
    }

    this.modifyCollectionSetup()

  }
  modifyCollectionSetup() {
    this.master.put(`lots/${this.entityId}/collectionSetup/${this.form.get('id').value}`, { collectionSetupData: this.form.value }, res => {
      this.loading = false
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

    })
  }
  createCollectionSetup() {
    this.loading = true

    this.form.get('lotId').setValue(this.entityType === 'lot' ? this.entityId : null)
    this.form.get('dealershipId').setValue(this.entityType === 'dealership' ? this.entityId : null)

    this.master.post(`lots/${this.entityId}/collectionSetup`, { collectionSetupData: this.form.value }, res => {
      this.loading = false
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.form.get('id').setValue(res.data.collectionSetup['id'])

    })
  }

  public resetDefaultPaymentAllocationOrder() {
    this.paymentAllocationData = JSON.parse(this.paymentAllocationOrderTemplate)
  }

  public drop(event: CdkDragDrop<string[]>) {
    if (event.currentIndex == 0 || event.currentIndex == 1 || event.previousIndex == event.currentIndex) {
      return
    }
    moveItemInArray(this.paymentAllocationData, event.previousIndex, event.currentIndex);
  }

  constructor(
    private ms: MessageService,
    private master: MasterService,
    private fb: FormBuilder, private route: ActivatedRoute
  ) {
    this.entityType = this.route.snapshot.params['type']
    this.entityId = parseInt(this.route.snapshot.params['id'])

    if (isNaN(this.entityId) || this.entityId == 0) {
      this.entityId = null
    }

    this.form = this.fb.group({
      id: new FormControl(0),
      dealershipId: this.entityType === 'dealership' ? new FormControl(this.entityId) : new FormControl(''),
      lotId: this.entityType === 'lot' ? new FormControl(this.entityId) : new FormControl(''),
      receiptStartingNumber: new FormControl(1000, [Validators.required, Validators.maxLength(10), Validators.max(2147483647)]),
      paymentAllocationOrder: new FormControl(null),
      defaultCollectionMethod: new FormControl('Scheduled Installment Earnings', [Validators.required])
    })
  }

  ngOnInit() {
    this.getCollectionSetup()

  }

}
