import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Location } from "@angular/common";
import { MasterService } from "../../../../services/master.service";
import { MessageService } from "../../../../services/message.service";
import { Router, ActivatedRoute } from "@angular/router";
import * as languageLibrary from '../../../../services/language'
import { StoreService } from "src/app/services/store.service";
import { SUPPORTED_IMAGE_TYPES } from "src/app/constants/file";

@Component({
  selector: "general-information-corporate",
  templateUrl: "./general-information.component.html",
  styleUrls: ["./general-information.component.scss"],
})
export class GeneralInformationCorporateComponent implements OnInit {
  /*
   * variables
   */
  @Output() childEmitter$: EventEmitter<Object> = new EventEmitter()
  // save the language
  @Input() language: string = 'EN'
  // set all words
  public words = languageLibrary.language
  //  define if loading state
  public loading: boolean = false;
  //  define if some changes not saved
  public savePending: boolean = false
  // entity
  @Input() entity: string

  // entity id
  @Input() entityId: number
  // save all data by type
  @Input() data: Object[] = [];
  // permissions
  @Input() permissions: Object[] = [];
  // define the contact type
  public type: string = "";
  // define the contact type
  public id: number = 0;
  // Unique internal identity of the associated contact
  @Input() contactId: number = 0
  // collapse/expand button
  public expanded: boolean = true;
  public generalDetails: Object[] = [];
  //
  public parsedType: string;
  public dealershipList: Object[];
  public image = {}
  // Group of inputs
  public information = new FormGroup({
    id: new FormControl(0, []),
    // entityType: new FormControl("entity", [Validators.required]),
    website: new FormControl(null, []),
    firstName: new FormControl(null, []),
    lastName: new FormControl(null, []),
    companyName: new FormControl(null, [Validators.required]),
    dbaName: new FormControl(null, []),
    dealerNumber: new FormControl(null, [Validators.required]),
    einNumber: new FormControl(null, []),
    dealershipId: new FormControl(0),
    contactId: new FormControl(0, [Validators.required]),
    displayLogoInternal: new FormControl(0),
    corporateLogoURL: new FormControl(null)
  });
  public dealershipId: any;
  // public contactId: number = 0;

  // get information of inputs
  get website() {
    return this.information.get("website");
  }
  get firstName() {
    return this.information.get("firstName");
  }
  get lastName() {
    return this.information.get("lastName");
  }
  get dbaName() {
    return this.information.get("dbaName");
  }
  get dealerNumber() {
    return this.information.get("dealerNumber");
  }
  get einNumber() {
    return this.information.get("einNumber");
  }
  get companyName() {
    return this.information.get("companyName");
  }
  /*
   * Functions
   */

  public getCorporationGeneralDetails() {
    this.master.get(`corporations/${this.id}`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.setInformation(res.data.corporateDetails, 'corporate')
    })
  }

  public getDealershipGeneralDetails() {
    this.master.get(`dealerships/${this.id}`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.setInformation(res.data.dealershipDetails, 'dealership')

    })
  }

  public getLotGeneralDetails() {
    this.master.get(`lots/${this.id}`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.setInformation(res.data.lotDetails, 'lot')
      this.getDealershipList()

    })
  }

  public setInformation(data: any, type: string): void {
    this.generalDetails = data
    this.id = parseInt(this.generalDetails['id'])
    this.contactId = parseInt(this.generalDetails['contactId'])
    this.setInformationValues()

    this.location.replaceState(`/corporations/${this.type}/${this.id}`);
    this.childEmitter$.emit({ message: 'setIds', contactId: this.contactId, id: this.id });
    this.childEmitter$.emit({ message: 'setDetailInfo', detailInfo: { createdBy: this.generalDetails['createdByUser'] ? this.generalDetails['createdByUser'].firstName + " " + this.generalDetails['createdByUser'].lastName : null, modifiedBy: this.generalDetails['modifiedByUser'] ? this.generalDetails['modifiedByUser'].firstName + " " + this.generalDetails['modifiedByUser'].lastName : null, createdAt: this.generalDetails['createdAt'], updatedAt: this.generalDetails['updatedAt'] } })

  }

  public setInformationValues() {
    this.information.setValue({
      id: this.id,
      contactId: this.contactId,
      // entityType: this.type,
      website: this.generalDetails["contact"]["website"] || null,
      firstName: this.generalDetails["contact"]["firstName"] || null,
      lastName: this.generalDetails["contact"]["lastName"] || null,
      companyName: this.generalDetails["contact"]["name"] ? this.generalDetails["contact"]["name"] : this.generalDetails["contact"]["companyName"],
      dbaName: this.generalDetails["dbaName"] || null,
      dealerNumber: this.generalDetails["dealerNumber"] || null,
      einNumber: this.generalDetails["einNumber"] || null,
      dealershipId: this.generalDetails["dealershipId"] || null,
      displayLogoInternal: this.generalDetails["displayLogoInternal"] || false,
      corporateLogoURL: this.generalDetails["corporateLogoURL"] || null
    })
  }

  public getDealershipList() {
    this.master.get(`dealerships`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.dealershipList = res.data.dealershipList
    })
  }

  public save() {
    const identity = this.id
    if (isNaN(identity)) {
      return
    }

    if (identity <= 0) {
      // SAVE
      if (this.type === 'corporation') {
        return
      }
      if (this.type === 'dealership') {
        this.saveDealershipGeneralDetails()
        return
      }
      if (this.type === 'lot') {
        this.saveLotGeneralDetails()
        return
      }
    }

    // MODIFY
    if (this.type === 'corporation') {
      this.modifyCorporationGeneralDetails()
      return
    }
    if (this.type === 'dealership') {
      this.modifyDealershipGeneralDetails()
      return
    }
    if (this.type === 'lot') {
      this.modifyLotGeneralDetails()
      return
    }
  }

  saveLotGeneralDetails() {
    if (this.information.invalid) {
      return
    }

    const payload = Object.assign({}, this.information.value)

    this.master.post(`lots/`, { information: payload }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.getDealershipList()
      this.setInformation(res.data.lotDetails, 'lot')

    })
  }

  saveDealershipGeneralDetails() {
    if (this.information.invalid) {
      return
    }

    const payload = Object.assign({}, this.information.value)

    this.master.post(`dealerships/`, { information: payload }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.setInformation(res.data.dealershipDetails, 'dealership')
    })
  }


  modifyLotGeneralDetails() {
    if (this.information.invalid) {
      return
    }

    const payload = Object.assign({}, this.information.value)

    this.master.put(`lots/${this.id}`, { information: payload }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.setInformation(res.data.lotDetails, 'lot')
    })
  }

  modifyDealershipGeneralDetails() {
    if (this.information.invalid) {
      return
    }

    const payload = Object.assign({}, this.information.value)

    this.master.put(`dealerships/${this.id}`, { information: payload }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.setInformation(res.data.dealershipDetails, 'dealership')
    })
  }

  modifyCorporationGeneralDetails() {
    if (this.information.invalid) {
      return
    }

    const payload = Object.assign({}, this.information.value)

    this.master.put(`corporations/${this.id}`, { information: payload }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.setInformation(res.data.corporationDetails, 'corporate')
    })
  }

  // get iamege and convert to base64
  public changeImage = (e): void => {
    let files = []
    let reader = []
    let vm = this
    files = e.target.files
    for (let index = 0; index < files.length; index++) {
      // check if some file is invalid
      if (SUPPORTED_IMAGE_TYPES.includes(files[index].type)) {
        // * format valid
        reader[index] = new FileReader();
        reader[index].readAsDataURL(files[index]);
        reader[index].onload = function (event) {
          vm.image = {
            'corporateLogoURL': reader[index].result,
            'display': true,
          }
          vm.uploadImage(vm.image['corporateLogoURL'])
        }

      } else {
        // ! invalid format
        this.ms.sendMessage("alert", { type: "danger", text: 'Invalid image format' });
      }
    }
  }
  // remove image
  public removeImage = () => {
    (document.getElementById('image-upload-input') as HTMLInputElement).value = "";
    let file = this.information.get('corporateLogoURL').value
    this.master.discard(`logo?key=${file}`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.image = {}
      this.information.patchValue({
        corporateLogoURL: null,
        displayLogoInternal: 0
      })

      this.ms.channelComponents$.emit({ message: 'reloadLogo', value: {} })

    });
  }
  // upload image
  public uploadImage = (url: string): void => {
    this.loading = true
    let from = url.indexOf(',')
    let urlShort = url.substr(from + 1, url.length)
    this.master.post(`logo`, { logo: urlShort }, res => {
      this.loading = false
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.image = { corporateLogoURL: res.data.logoUrl, display: true }
      this.information.patchValue({
        corporateLogoURL: res.data.logoUrl,
        displayLogoInternal: 1
      })

      this.ms.channelComponents$.emit({ message: 'reloadLogo', value: this.image })
    });

  };
  // upload image
  public toggleDisplay = (display: boolean): void => {
    this.master.patch(`logo`, { display }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.image = { corporateLogoURL: this.information.get('corporateLogoURL').value, display: display }
      this.ms.channelComponents$.emit({ message: 'reloadLogo', value: this.image })
    });
  }

  /*
   * Life cycles
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ms: MessageService,
    private master: MasterService,
    private store: StoreService,
    private location: Location
  ) {
    this.type = this.route.snapshot.params["type"];
    if (this.route.snapshot.params["id"] === 'add') {
      this.id = 0
      if (this.type === 'lot') {
        this.information.value["dealershipId"] = this.route.snapshot.queryParams['dealer']
        this.getDealershipList()
      }
      return
    }
    this.id = parseInt(this.route.snapshot.params["id"]);

  }

  ngOnInit() {
    // this.image = { corporateLogoURL: this.data['corporateLogoURL'], display: this.data['displayLogoInternal'] }
    this.id = this.entityId
    if (!this.id || (Object.keys.length == 0)) {
      // this.information.get('entityType').setValue(this.type)
      this.information.get('dealershipId').setValue(this.route.snapshot.queryParams['dealer'])
      return
    }

    if (isNaN(this.contactId) || (this.contactId <= 0)) {
      this.contactId = 0
    }


    if (this.type === 'corporation') {
      this.information.get('id').setValidators([Validators.required]);
      this.information.get('id').updateValueAndValidity();
      this.getCorporationGeneralDetails()
    }
    if (this.type === 'dealership') {
      this.getDealershipGeneralDetails()
    }
    if (this.type === 'lot') {
      this.getLotGeneralDetails()
    }
  }
}
