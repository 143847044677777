import { Component, Injector, OnInit } from "@angular/core";
import { DynamicService } from "src/app/services/dynamic.service";
import { token } from '../../roles/roles.component';

@Component({
    selector: 'role-lead',
    templateUrl: './role-lead.component.html'
})
export class ContactLeadComponent implements OnInit {
    public contactRoleId: number = 0
    public dataIn: string;

    constructor(private serv: DynamicService, private inject: Injector) {
    }
    ngOnInit(): void {
        this.dataIn = this.inject.get(token);
        this.contactRoleId = this.dataIn['contactRoleId']
    }

    reactivateRole() {
        this.serv.outputFromDynamicComponent({action: "reactivate", roleName: "salesLead"});
    }

    deactivateRole() {
        this.serv.outputFromDynamicComponent({ action: "deactivate", contactRoleId: this.contactRoleId });
    }
}