import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
//services
import { MasterService } from '../../../services/master.service'
import { MessageService } from '../../../services/message.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public message: string = null
  public loading: boolean = false;
  public resetPassForm = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email, Validators.maxLength(150)]),
  })

  get email() { return this.resetPassForm.get('email') }

  constructor(private master: MasterService,private ms: MessageService) { }

  ngOnInit() {
  }
  /**
   * Functions
   */
  public resetPassword() {
    this.master.post(`reset?`, {email: this.resetPassForm.get('email').value}, res => {
      this.loading = false;
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: 'An unknown error occurred.' });
        return
      }
        
      if (res.status !== 200) {
        this.message = res.data.error
        this.ms.sendMessage("alert", { type: "warning", text: res.data.error })
        return
      }

      this.ms.sendMessage("alert", { type: "success", text: "We've emailed you a reset password link." })
      this.message = "We've emailed you a reset password link."
    })
  }
}
