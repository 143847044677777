import { Component, OnInit, OnChanges, SimpleChanges, Input } from "@angular/core";
import { Location } from '@angular/common';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { Subject, Observable } from 'rxjs';

import * as languageLibrary from '../../../services/language'
import { MessageService } from "../../../services/message.service";
import { MasterService } from "../../../services/master.service";
import { StoreService } from "../../../services/store.service";
import { SecurityService } from "../../../services/security.service";

import { SALE_TYPES } from "src/app/constants/sale";

@Component({
    selector: "sales-detail",
    templateUrl: "./sales-detail.component.html",
    styleUrls: ["./sales-detail.component.scss"]
})

export class SalesDetailComponent implements OnInit, OnChanges {
    public createSaleEvent: Subject<any> = new Subject();
    public saleSetupChangeEvent: Subject<any> = new Subject();
    public saleTypeChangeEvent: Subject<any> = new Subject();
    public saleStageChangeEvent: Subject<any> = new Subject();
    public buyerChangeEvent: Subject<Object> = new Subject();
    public vehicleChangeEvent: Subject<Object> = new Subject();
    public tradeChangeEvent: Subject<any> = new Subject();

    public language: any = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
    public words = languageLibrary.language
    
    public salePrice: number = 0
    public loading: boolean = false
    public saving: boolean = false

    // Indicates the save button was pressed to save the detail manually
    private manual: boolean = false

    public buyer = {}
    public inventory = {}
    
    public saleTypes: Object = SALE_TYPES
    public saleType: number = 0
    
    public saleId: number
    public lotId: number
    public buyerType = null
    public salePresets: Object = null
    public enableBHPH: boolean = false
    public isQuote: boolean = true

    public buyerId = 0

    // A debounce timer for search events. Used to wait for 
    // the user to stop typing before we fetch.
    private watcher: ReturnType<typeof setTimeout> = null

    public form: FormGroup = this.fb.group({
        locked: [false.toString()], // Unlocked
    })

    // Indicates whether or not this sale is a BHPH sale
    get bhph (): boolean { return SALE_TYPES[this.saleType] === 'BHPH' }

    // Indicates whether or not the current sale is locked (no changes allowed)
    get locked (): boolean { return this.form.get('locked').value === 'true' }

    // Indicates whether or not the current sale is currently a quote
    get quote (): boolean { return this.isQuote }

    // Returns a router path based on whether this is a quote or sale
    get path (): string { return this.isQuote ? 'quotes' : 'sales' }

    constructor(private route: ActivatedRoute, private ms: MessageService, private router: Router, private master: MasterService, private store: StoreService, private security: SecurityService, private fb: FormBuilder, private location: Location) {}

    ngOnInit() {
        this.saleId = 0
        const id = parseInt(this.route.snapshot.params['id'])
        if (id && !isNaN(id) && (id > 0)) {
            this.saleId = id
        }

        const path = this.route.snapshot.url[0].path
        const pattern = new RegExp('quote', 'i')
        this.isQuote = (path && pattern.test(path))
        
        this.lotId = parseInt(localStorage.getItem('lot') || this.store.lotSelected)

        this.populate()
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    private populate = async () => {
        await this.readSalesSetup()

        if (this.saleId <= 0) {
            return
        }

        await this.read()
    }

    private readSalesSetup = async () => {
        if (!this.lotId && (this.lotId <= 0)) {
            return
        }

        await this.master.getAsync(`lots/${this.lotId}/salesSetup`).then( async (res) => {
            this.salePresets = res.data.salesSetup
            if (!this.salePresets) {
                return
            }

            this.saleSetupChangeEvent.next(this.salePresets)
        })
    }

    public read = async () => {
        if (!this.saleId || (this.saleId <= 0)) {
            return
        }

        await this.master.getAsync(`sales/${this.saleId}`).then(res => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            const sale = res.data.sale
            this.form.setValue({
                locked: ((sale['locked'] == true) || this.security.notPermitted('sales', 'sale detail', 'update')).toString()
            })

            this.salePrice = parseFloat(sale['salePrice'])
            if (this.locked) {
                this.form.disable()
            }

            this.isQuote = (parseInt(sale['saleStageId']) === 1) // 1 = Quote
        })
    }

    /**
     * 
     * @param e 
     */
    public lockUnlock = (e: Event) => {
        e.preventDefault()
        e.stopPropagation()

        if (this.security.notPermitted('sales', 'sale detail', 'update')) {
            return
        }

        this.form.get('locked').setValue((!this.locked).toString())

        this.form.enable()
        if (this.locked) {
            this.form.disable()
        }
        this.save(e)
    }

    public saleTypeChanged = (id: number): void => {
        this.saleType = id
        this.saleTypeChangeEvent.next(id)
    }

    public saleStatusChanged = (id: number): void => {
        this.read()
    }

    // Hanlde sale price changes
    public salePriceChanged = (e) => {
        this.salePrice = e.value
    }

    public buyerChange = async (buyer) => {
        // Ensure we have a saleId if a buyer is added
        if (this.saleId <= 0) {
            await this.save()
        }

        const id: number = parseInt(buyer['id'])
        if (!id || isNaN(id) || (id <= 0)) {
            this.buyerId = 0
            return
        }

        if (this.buyerId == id) {
            return
        }

        this.buyerId = id
        this.buyerChangeEvent.next(buyer)
    }

    public vehicleChange = async (vehicle) => {
        // Ensure we have a saleId if a vehicle is added
        if (this.saleId <= 0) {
            await this.save()
            return
        }

        const id: number = parseInt(vehicle['id'])
        if (!id || isNaN(id) || (id <= 0)) {
            return
        }

        this.vehicleChangeEvent.next(vehicle)
    }

    public tradeChange = async (trades) => {
        // Ensure we have a saleId if a trade is added
        if (this.saleId <= 0) {
            await this.save()
            return
        }

        this.tradeChangeEvent.next(trades)
    }

    // todo: listen nav messages
    public listenerNav = (e) => {
        switch (e.message) {
            case 'changeLot':
                this.readSalesSetup()
                break;
            case 'changeLanguage':
                this.language = e.value
                break;
        }
    }

    /**
     * Send a post request to the api to create a new sale or 
     * a put request to update an existing sale
     * @param {Event} [e = null] An optional event associated with the save (from a button press or other user action)
     */
    public save = async (e: Event = null) => {
        if (this.saving) { // pristine prevents a save on initial setup/read
            return
        }

        const body = this.form.value

        body.lotId = this.lotId
        body.locked = (body.locked === 'true')
        body.saleFinancingTypeId = this.saleType
        body.saleStageId = this.isQuote ? 1 : 2 // 1 = Quote, 2 = Sale
        body.saleStatusId = 1 // 1 = Sold

        this.saving = true
        if (e) {
            this.manual = true
        }

        if (this.saleId <= 0) {
            await this.add(body)
            return
        }

        await this.modify(body)
    }

    public add = async (meta) => {
        if (this.saleId > 0) {
            this.modify(meta)
            return 
        }

        return await this.master.post(`sales`, { sale: meta }, (res) => {
            this.saving = false
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.saleId = res && res.data && res.data.id ? parseInt(res.data.id) : 0
            if (!this.saleId || (this.saleId <= 0)) {
                return
            }

            if (this.manual) { // manually saved? let them know it worked
                this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] })
                this.manual = false
            }

            this.createSaleEvent.next(this.saleId)
            this.location.replaceState(`/${this.path}/${this.saleId}`);
            this.read()
        });
    }

    public modify = async (meta) => {
        if (this.saleId <= 0) {
            return await this.add(meta) 
        }

        return await this.master.put(`sales/${this.saleId}`, meta, (res) => {
            this.saving = false
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }
            
            if (this.manual) { // manually saved? let them know it worked
                this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] })
                this.manual = false
            }
        });
    }

    public cancel = () => {
        if (this.saleId > 0) {
            return
        }

        this.router.navigate([`/${this.path}`])
    }

    public discard = () => {
        if (this.saleId <= 0) {
            this.router.navigate([`/${this.path}`])
            return 
        }

        this.master.discard(`sales/${this.saleId}`, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }
            
            this.router.navigate([`/${this.path}`])
        })
    }

    /**
     * Promote a quote to a sale.
     */
    public promote = () => {
        if (this.saleId <= 0) {
            return 
        }

        if (!this.isQuote) {
            return
        }

        this.master.patch(`quotes/${this.saleId}/promote`, {}, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }
            
            this.router.navigate([`/sales/${this.saleId}`])
        })
    }

    public title = () => {
        if (this.saleId <= 0) {
            return `${this.words[this.language]['add']} ${this.isQuote ? this.words[this.language]['Quote'] : this.words[this.language]['Sale']}`
        }
        
        return `${this.isQuote ? this.words[this.language]['Quote'] : this.words[this.language]['Sale']} ${this.words[this.language]['detail']}`
    }

}
