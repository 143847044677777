import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, Injector } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MasterService } from '../../../../../../../services/master.service'
import { MessageService } from '../../../../../../../services/message.service'
import * as languageLibrary from '../../../../../../../services/language'
import { SEARCH_DEBOUNCE_MILLIS, MIN_SEARCH_CHARS } from 'src/app/constants/list';
import { RELATIONSHIP_TYPES } from 'src/app/constants/contact';
import { token } from '../../roles.component';
import { DynamicService } from 'src/app/services/dynamic.service';

@Component({
  selector: 'references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss']
})

export class ReferencesComponent implements OnInit {
  @Input() contactId: number = 0
  public words = languageLibrary.language
  public language: string = 'EN'
  // table headers
  public headers: Object[] = [
    { name: 'firstName', param: 'firstName' },
    { name: 'lastName', param: 'lastName' },
    { name: 'relationship', param: 'relationship' },
    { name: 'startDate', param: 'start' },
    { name: 'endDate', param: 'end' },
    { name: 'duration', param: 'duration' },
    { name: 'phoneNumber', param: 'phoneNumber' },
    { name: 'emailAddress', param: 'emailAddress' },
    { name: 'actions' }]
  public relationshipTypes = RELATIONSHIP_TYPES
  public relationshipList: object = {}
  // public relations: string[] = []
  public relationshipId: number = null
  public loading: boolean = false
  public viewDetail: boolean = false
  private timeOutID: any;
  public contactList: Object[] = []
  public contactSelected: Object = null

  // reference selected
  public isContactSelected: boolean = false

  // The current contact search inquiry
  public inquiry: string = null

  public auxContact = {}
  // Indicates the system is already checking for matching contacts.
  public searching: boolean = false

  // Indicates the system won't be able to find any more 
  // mathcing contacts. The last list received was already empty.
  public exhausted: boolean = false

  public dataIn: string;
  // Group of inputs
  public form = new FormGroup({
    fromContactId: new FormControl(0),
    firstName: new FormControl(null, [Validators.required]),
    lastName: new FormControl(null, [Validators.required]),
    relationship: new FormControl(null, [Validators.required]),
    startDate: new FormControl(new Date().toISOString().substring(0, 10), []),
    endDate: new FormControl(new Date().toISOString().substring(0, 10), []),
    phoneNumber: new FormControl(null),
    emailAddress: new FormControl(null),
  },
    {
      validators: Validators.compose([this.dateLessThan('startDate', 'endDate')])
    }
  )

  // get information of inputs
  get firstName() { return this.form.get('firstName') }
  get lastName() { return this.form.get('lastName') }
  get relation() { return this.form.get('relation') }
  // get relationshipTypeId() { return this.form.get('relationshipTypeId') }
  get start() { return this.form.get('startDate') }
  get end() { return this.form.get('endDate') }
  get phoneNumber() { return this.form.get('phoneNumber') }
  get emailAddress() { return this.form.get('emailAddress') }

  /*
* functions
*/

  constructor(private master: MasterService, private ms: MessageService, private inject: Injector, private serv: DynamicService) {
    // this.getRelationshipTypes()
    // this.watchRelation()
    this.watchFirstName()
  }

  ngOnInit() {
    this.reset()
    this.dataIn = this.inject.get(token);
    this.contactId = this.dataIn['contactId']

    if (!this.contactId) {
      return
    }
    this.readRelationships()
  }

  public dateLessThan(startDate: string, endDate: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let start = group.controls[startDate];
      let end = group.controls[endDate];
      if (end.value !== '') {
        if (start.value > end.value) {
          return {
            dates: "Date from should be less than Date to"
          };
        }
        return
      }
      return {};
    }
  }

  // todo: listener to childrens
  public listenerChildrens = (e): void => {
    if (e.message === 'reloadReferenceDetail') {
      this.readRelationships()
    }
  }


  // todo:get data for contact selected
  public getContactRelationshipDetail = (relationship: Object, openDetail: boolean) => {
    this.loading = true
    this.master.get(`contacts/${relationship['fromContactId']}`, res => {
      this.loading = false
      this.viewDetail = true

      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
      this.auxContact = res.data.contact

      setTimeout(() => {
        const btn = (document.getElementById('contactReferenceDetailModal') as HTMLButtonElement)
        if (btn) {
          btn.click()
        }
      }, 100)
    })
  }

  // clean Fields
  public reset = (): void => {
    this.form.reset()
    this.form.get('firstName').enable()
    this.form.get('lastName').enable()
    this.form.get('phoneNumber').enable()
    this.form.get('emailAddress').enable()
    this.contactList = []
    this.contactSelected = {}
    this.isContactSelected = false
    this.exhausted = false
    this.searching = false
    this.inquiry = null
    this.loading = false
  }

  public createContact = (closeAfter) => {
    this.master.post(`contacts/${this.contactId}/relationships`, this.form.value, res => {
      if (!res) {
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.readRelationships()
      const btn = (document.getElementById('btn-cancel-references-new') as HTMLButtonElement)
      if (btn && closeAfter) {
        btn.click()
      }
      this.reset()

    })
  }

  public setRelationship = (relationship: Object, action: string): void => {
    this.relationshipId = relationship['id']
    if (action = 'modify') {
      this.form.get('firstName').disable()
      this.form.get('lastName').disable()
      this.form.get('phoneNumber').disable()
      this.form.get('emailAddress').disable()
      this.form.setValue({
        fromContactId: relationship['id'],
        firstName: relationship['contact']['firstName'],
        lastName: relationship['contact']['lastName'],
        relationship: relationship['relationship'],
        startDate: relationship['startDate'],
        endDate: relationship['endDate'],
        phoneNumber: this.filterForCommunicationModify(relationship, 'phone'),
        emailAddress: this.filterForCommunicationModify(relationship, 'email')
      })
    }
  }

  // getRelationshipInfo() {
  //   return this.relationshipTypes;
  // }


  // delete reference
  public discard = (): void => {
    this.loading = true
    this.master.discard(`contacts/${this.contactId}/relationships/${this.relationshipId}`, res => {
      this.loading = false
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.readRelationships()

      let btn = (document.getElementById('close-delete-modal-reference') as HTMLButtonElement)
      if (btn) {
        btn.click()
      }

    })
  }

  // public saveContactInformation = () => {
  //   const btnGen = (document.getElementById("btn-save-generalInformation") as HTMLButtonElement)
  //   btnGen.click()
  //   const btnComm = (document.getElementById("btn-save-communications-new") as HTMLButtonElement)
  //   btnComm.click()
  //   const btnID = (document.getElementById("btn-save-identifications-new") as HTMLButtonElement)
  //   btnID.click()

  //   let btn = (document.getElementById('btn-close-modal-delete') as HTMLButtonElement)
  //   if (btn) {
  //     btn.click()
  //   }

  //   this.readRelationships()
  // }
  /*
  todo: update functions
  */
  public update = (): void => {
    if (this.form.invalid) {
      return
    }

    const id = this.relationshipId
    if (!id || isNaN(id) || (id <= 0)) {
      return
    }

    this.loading = true
    const payload = this.form.value
    delete (payload.relation)
    this.master.put(`contacts/${this.contactId}/relationships/${this.relationshipId}`, payload, res => {
      this.loading = false

      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.readRelationships()
      let btn = (document.getElementById('close-update-modal-reference') as HTMLButtonElement)
      if (btn) {
        btn.click()
      }

    })
  }

  /**
   * Verify if the contact already exists
   */
  public contactExists(): void {
    this.contactList = []
    this.master.get(`readForDuplicateContact?firstName=${this.inquiry}`, (res: { status: number; data: { contacts: Object[]; }; }) => {
      this.searching = false

      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        return
      }

      this.contactList = res.data.contacts
      this.exhausted = (this.contactList.length <= 0)
    })
  }

  /**
   * An existing contact was chosen from the data list
   * @param contact The existing contact metda data chosen from the data list.
   */
  public selectContact = (contact: Object) => {
    if (!contact) {
      return
    }

    this.contactSelected = contact
    this.isContactSelected = true

    this.firstName.setValue(contact['firstName'])
    this.lastName.setValue(contact['lastName'])
  }

  private readRelationships = (): void => {
    this.master.get(`contacts/${this.contactId}/relationships`, (res: { status: number; data: { error: any; relationships: object; }; }) => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
      this.relationshipList = res.data.relationships
    })
  }

  filterForCommunication(relationship, searchFor): any[] {
    let relationshipSearchList = relationship.contact.contactCommunications ? relationship.contact.contactCommunications : null
    if (relationshipSearchList.length == 0) { return null }
    return relationshipSearchList.filter(comms => comms.type === searchFor);
  }

  filterForCommunicationModify(relationship, searchFor): any[] {
    let relationshipSearchList = relationship.contact.contactCommunications ? relationship.contact.contactCommunications : null
    if (relationshipSearchList.length == 0) { return null }
    const returnedValue = relationshipSearchList.find(comms => comms.type === searchFor);
    if (typeof returnedValue !== 'undefined') {
      return returnedValue.value
    }
    return null
  }

  // private getRelationshipTypes = (): void => {
  //   this.master.get('collections/contacts/relationshipTypes', (res: { status: number; data: { error: any; RelationshipTypes: any[]; }; }) => {
  //     if (!res) {
  //       this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
  //       return
  //     }

  //     if (res.status !== 200) {
  //       this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
  //       return
  //     }

  //     this.relationshipTypes = {}
  //     res.data.RelationshipTypes.forEach((item: { type: string | number; id: any; relation: any; }) => {
  //       if (!this.relationshipTypes[item.type]) {
  //         this.relationshipTypes[item.type] = []
  //       }

  //       this.relationshipTypes[item.type].push({
  //         id: item.id,
  //         relation: item.relation
  //       })
  //     })

  //     this.relations = Object.keys(this.relationshipTypes)
  //   })
  // }

  // private watchRelation = () => {
  //   this.relation.valueChanges.subscribe(relation => {
  //     if (!this.relationshipTypes[relation]) {
  //       return
  //     }

  //     this.relationshipTypeId.setValue(this.relationshipTypes[relation][0]['id'])
  //   })
  // }

  private watchFirstName = () => {
    this.firstName.valueChanges.subscribe(name => {
      if (this.timeOutID) {
        clearTimeout(this.timeOutID)
      }

      if (this.isContactSelected) {
        return
      }

      if (!name || (name.length < MIN_SEARCH_CHARS)) {
        this.exhausted = false
        return
      }

      if (this.inquiry && (!name.startsWith(this.inquiry))) {
        this.exhausted = false
      }

      if (this.searching || this.exhausted) {
        return
      }

      this.timeOutID = setTimeout(() => {
        this.inquiry = name
        this.searching = true
        // this.contactExists()
      }, SEARCH_DEBOUNCE_MILLIS)
    })
  }
}
