import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { MasterService } from "src/app/services/master.service";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: 'contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss']
})

export class ContactModalComponent implements OnInit {

  @Input() contact = []
  // save the language
  @Input() language: string = 'EN'
  // set all words
  @Input() words = {}
  @Output() updateDetails$: EventEmitter<Object> = new EventEmitter()

  public contactInformation = []
  // permissions
  @Input() permissions: string[] = []
  @Input() isFlat: boolean = false
  public fromContactId: number;

  public listenerChildrens = (e): void => {
    if (e.message !== 'reloadContactDetail') {
      return
    }
  }

  public saveContactInformation = () => {
    const btnGen = (document.getElementById("btn-save-contactGeneralInformation_" + this.contact['id']) as HTMLButtonElement)
    if (btnGen) {
      btnGen.click()
    }
    const btnComm = (document.getElementById("btn-save-communications-new") as HTMLButtonElement)
    if (btnComm) {
      btnComm.click()
    }
    const btnID = (document.getElementById("btn-save-identifications-new") as HTMLButtonElement)
    if (btnID) {
      btnID.click()
    }

    let btn = (document.getElementById('btn-close-contact-detail-modal') as HTMLButtonElement)
    if (btn) {
      btn.click()
    }

    this.updateDetails$.emit({ message: 'reloadReferenceDetail' })
    // this.readRelationships()
  }

  constructor(private master: MasterService, private ms: MessageService) { }
  ngOnInit(): void {
    if (!this.contact['id']) {
      return
    }
    this.contactInformation = this.contact
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (!this.contact['id']) {
      return
    }
    this.contactInformation = this.contact
  }
}