import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from '../../../../services/message.service'
import { MasterService } from '../../../../services/master.service'
import { StoreService } from '../../../../services/store.service'
import * as languageLibrary from '../../../../services/language'
import axios from 'axios'

@Component({
    selector: 'app-corporations',
    templateUrl: './corporations.component.html',
    styleUrls: ['./corporations.component.scss']
})
export class CorporationsComponent implements OnInit {
    /*
    * Variables
    */
    // save the language
    public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
    // set all words
    public words = languageLibrary.language
    // save all data by type
    public data: Object[] = []
    // define the contact type
    public type: string = ''
    // table headers
    public headers: Object[] = [
        { name: 'Name', param: 'companyName' },
        { name: 'Dealer Number', param: 'dealerNumber' },
        { name: 'Address', param: 'address' },
        { name: 'Phone', param: 'phone' },
        { name: 'Doing-Business-As Name', param: 'dba' },
        { name: 'Status', param: 'status' }]

    public corporation: Object[] = [];
    // define if is loading
    public loading: boolean = false
    // define if is adding
    public adding: boolean = false
    // define item Selected
    public itemSelected: Object = {}
    // permissions
    public permissions: Object[] = []
    // expanded/collapsed
    public expanded = true
    public selectedDealershipId: number;
    public selectedLotId: number;

    /*
    * life cycles
    */
    constructor(private route: ActivatedRoute, private store: StoreService, private router: Router, private ms: MessageService, private master: MasterService) { }

    ngOnInit() {
        this.type = this.route.snapshot.params['type']

        if (this.store.userAccount["permissions"]) {
            this.permissions = this.store.userAccount['permissions'][2].childrens[0].permissions
        }

        if (!this.permissions.includes("view")) {
            return
        }

        this.getCorporationEntities()
    }

    /*
  * Functions
  */
    // list of company entities
    public getCorporationEntities = () => {
        this.master.get(`corporations`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.corporation = res.data.corporate
            this.reloadLots()
        })
    }
    // add new dealership
    public createDealership = (): void => {
        this.router.navigate([`/corporations/dealership/add`])
        return
    }

    // add new lot
    public createLot = (dealershipId: number): void => {
        this.router.navigate(['/corporations/lot/add'], { queryParams: { dealer: dealershipId } });
        return
    }

    // read dealership
    public editCorp = (id: number): void => {
        if (!this.permissions.includes('edit')) {
            return
        }

        this.router.navigate([`/corporations/corporation/${id}`])
        return
    }

    // read dealership
    public editDealer = (id: number): void => {
        if (!this.permissions.includes('edit')) {
            return
        }

        this.router.navigate([`/corporations/dealership/${id}`])
        return
    }

    // read lot
    public editLot = (id: number): void => {
        if (!this.permissions.includes('edit')) {
            return
        }

        this.router.navigate([`/corporations/lot/${id}`]);
        return
    }

    public deleteDealership = (): void => {
        this.master.discard(`dealerships/${this.selectedDealershipId}`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }
            this.selectedDealershipId = null
            document.getElementById('btn-close-modal-deleteDealership').click()
            this.getCorporationEntities()
        })
    }

    public deleteLot = (): void => {
        this.master.discard(`lots/${this.selectedLotId}`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }
            this.selectedLotId = null
            document.getElementById('btn-close-modal-deleteLot').click()
            this.getCorporationEntities()
        })
    }

    public restoreDealership = (): void => { }
    public restoreLot = (): void => { }

    public checkDealershipRelationships = (id: number): void => {
        this.selectedDealershipId = id
        this.master.get(`dealerships/${id}/relationships`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.data.error == 'Error: The dealership has relationships') {
                (document.getElementById('btn-deactivate-dealership-modal') as HTMLButtonElement).click();
                return
            }
            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }
            (document.getElementById('btn-deleteDealership-modal') as HTMLButtonElement).click();
        })
    }

    public setLotId(lotId){
        this.selectedLotId = lotId
    }
    public setDealershipId(dealershipId){
        this.selectedDealershipId = dealershipId
    }

    public checkLotRelationships = (id: number): void => {
        this.selectedLotId = id
        this.master.get(`lots/${id}/relationships`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.data.error == 'Error: The lot has relationships') {
                (document.getElementById('btn-deactivate-lot-modal') as HTMLButtonElement).click();
                return
            }
            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            (document.getElementById('btn-deleteLot-modal') as HTMLButtonElement).click();
        })
    }

    public restore = () => {
        (document.getElementById('btn-active-modal') as HTMLButtonElement).click();
    }

    // select a item
    public selectItem = (entity: string, id: number, active: string): void => {
        if (!active)
            this.itemSelected = { entity: entity, entityId: id, type: active }
        this.itemSelected['type'] == 'Active' ? this.itemSelected['type'] = 'Disabled' : this.itemSelected['type'] = 'Active'
        if (active == 'Active') {
            // ? only active
            this.master.post('checkEntityRealtionship', { entity: entity, entityId: id }, res => {
                if (res) {
                    if (res.status == 200) {
                        // ? normal
                        (document.getElementById('btn-delete-modal') as HTMLButtonElement).click();

                    } else {
                        // ! important
                        (document.getElementById('btn-deactivate-modal') as HTMLButtonElement).click();

                    }
                } else {
                    // api no responde
                    this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                }
            })
        } else {
            // ? only disabled
            (document.getElementById('btn-active-modal') as HTMLButtonElement).click();

        }

    }

    /*
  ! DELETE functions
  */
    public deactivateLot = (): void => {
        this.master.patch(`lots/${this.selectedLotId}/deactivate`, null ,res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.selectedLotId = null
            document.getElementById('btn-close-modal-deactivate').click()
            this.getCorporationEntities()
        })
    }

    public activateLot = (): void => {
        this.master.patch(`lots/${this.selectedLotId}/activate`, null ,res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.selectedLotId = null
            document.getElementById('btn-close-modal-activate-lot').click()
            this.getCorporationEntities()
        })
    }
    
    public deactivateDealership = (): void => {
        this.master.patch(`dealerships/${this.selectedDealershipId}/deactivate`, null ,res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.selectedDealershipId = null
            document.getElementById('btn-close-modal-deactivate-dealership').click()
            this.getCorporationEntities()
        })
    }
    
    public activateDealership = (): void => {
        this.master.patch(`dealerships/${this.selectedDealershipId}/activate`, null ,res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.selectedDealershipId = null
            document.getElementById('btn-close-modal-activate-dealership').click()
            this.getCorporationEntities()
        })
    }

    public reloadLots = (): void => {
        this.master.get('getUserAccount', res => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            // send permissions for all components
            this.ms.channelComponents$.emit({ message: 'updateLotList', lots: res['data']['user'].lots })
        })
    }

    // todo: listen to output of navbar
    public listenerNav = (e): void => {

        switch (e['message']) {
            case 'changeLanguage':
                this.language = e['value']
                break;
            case 'setPermissions':
                // permissions on user list
                this.permissions = e.permissions[2].childrens[0].permissions
                // if not have permissions, return to account settings

                if (this.permissions.includes('view') && this.data.length == 0) {
                    // this.chargeData(true)
                    this.getCorporationEntities()
                }
                break;

            default:
                break;
        }
    }

}
