import { Component, Input, OnInit, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, RequiredValidator, Validators } from '@angular/forms';
// services
import { MessageService } from '../../../../services/message.service'
import { MasterService } from '../../../../services/master.service'
import * as languageLibrary from '../../../../services/language'
import { ActivatedRoute } from '@angular/router';
import { INVENTORY } from '../../../../constants/inventory'
import { isNull } from 'util';
import { StoreService } from 'src/app/services/store.service';
@Component({
  selector: 'inventory-setup',
  templateUrl: './inventory-setup.component.html',
  styleUrls: ['./inventory-setup.component.scss']
})
export class InventorySetupComponent implements OnInit {
  /*
  * Variables
  */
  @Output() childEmitter$: EventEmitter<Object> = new EventEmitter()

  //  state of loading
  public loading: boolean = false
  //  define if some changes not saved
  public savePending: boolean = false

  @Input() entityType: string
  @Input() entityId: number
  // save the language
  @Input() language: string = 'EN'
  @Input() calledFromInventoryList: boolean = false
  @Input() inventorySetupData: Object
  @Input() inventoryStatusList: any[];
  // set all words
  public words = languageLibrary.language
  // define expanded
  public expanded: boolean = false
  public inventorySetupId: number = 0
  // define the stages
  public stages: Object[] = []
  public stagesTemplate: string = `{"Trade-in": {"prefix": "","suffix": "T"},"Repossession": {"prefix": "","suffix": "R"},"Purchase": {"prefix": "","suffix": ""}}`
  // id inventory
  public inventoryId: string = null
  public form: FormGroup = null
  public inventoryStatuses: any[];
  @Input() inventory: boolean = false
  public inventorySetup: Object;
  public reconDescriptions: Object;
  public lastUsedStockNumber: string;
  public formDesc: FormGroup = null
  public reconTypes: {} = INVENTORY.RECONDITIONING_TYPES
  /*
  * functions
  */

  public getInventorySetup = (): void => {
    this.stages = JSON.parse(this.stagesTemplate)
    if (isNaN(this.entityId) || this.entityId == 0) {
      return
    }

    if (this.calledFromInventoryList) {
      this.form.get('defaultFormat').setValue(null)
      return
    }


    this.master.get(`lots/${this.entityId}/inventorySetup`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.inventorySetup = res.data.inventorySetup
      if (!this.inventorySetup) {
        if (this.calledFromInventoryList) {
          this.form.get('id').setValue(null)
        }
        return
      }


      this.setInformationValues(this.inventorySetup)
    })
  }

  public getReconditioningDescriptions = (): void => {
    this.stages = JSON.parse(this.stagesTemplate)
    if (isNaN(this.entityId) || this.entityId == 0) {
      return
    }

    this.master.get(`corporations/${this.entityId}/reconDesc`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.reconDescriptions = res.data.reconDescriptions
      this.setDescInformation(this.reconDescriptions)
    })
  }

  public setDescInformation(reconDescriptions) {
    this.methods.clear()
    for (let i = 0; i < reconDescriptions.length; i++) {
      this.add(reconDescriptions[i])
    }
  }


  public editReconditioningDescriptions = (i, action): void => {
    switch (action) {
      case 'edit':
        document.getElementById("reconDescriptionLabel_" + i).classList.add("d-none");
        document.getElementById("reconDescriptionRow_" + i).classList.remove("d-none");
        document.getElementById("reconTypeRow_" + i).classList.remove("d-none");
        document.getElementById("reconTypeLabel_" + i).classList.add("d-none");
        document.getElementById("reconSave_" + i).classList.remove("d-none");
        document.getElementById("reconCancel_" + i).classList.remove("d-none");
        document.getElementById("reconEdit_" + i).classList.add("d-none");
        document.getElementById("reconDelete_" + i).classList.add("d-none");
        break
      case 'cancel':
        this.setDescInformation(this.reconDescriptions)
        document.getElementById("addMethod").classList.remove("d-none");
        document.getElementById("reconDescriptionRow_" + i).classList.add("d-none");
        document.getElementById("reconDescriptionLabel_" + i).classList.remove("d-none");
        document.getElementById("reconTypeRow_" + i).classList.add("d-none");
        document.getElementById("reconTypeLabel_" + i).classList.remove("d-none");
        document.getElementById("reconSave_" + i).classList.add("d-none");
        document.getElementById("reconCancel_" + i).classList.add("d-none");
        document.getElementById("reconEdit_" + i).classList.remove("d-none");
        document.getElementById("reconDelete_" + i).classList.remove("d-none");
        break
    }
  }

  public setInformationValues(inventorySetupData: Object) {
    this.lastUsedStockNumber = inventorySetupData['lastUsedStockNumber']
    // if (!isNull(this.lastUsedStockNumber) ) {
    //   this.form.get('defaultFormat').disable();
    //   this.form.get('incrementStartingAt').disable();
    //   this.form.get('incrementBy').disable();
    //   this.form.get('lastDigitsofVIN').disable();
    // } else {
    //   this.form.get('defaultFormat').enable();
    //   this.form.get('incrementStartingAt').enable();
    //   this.form.get('incrementBy').enable();
    //   this.form.get('lastDigitsofVIN').enable();
    // }
    this.form.get('defaultFormat').updateValueAndValidity({ onlySelf: true, emitEvent: false });
    this.form.setValue({
      id: inventorySetupData['id'],
      lotId: inventorySetupData['lotId'],
      dealershipId: inventorySetupData['dealershipId'],
      defaultFormat: inventorySetupData['defaultFormat'],
      lastDigitsofVIN: inventorySetupData['lastDigitsofVIN'],
      incrementBy: inventorySetupData['incrementBy'],
      incrementStartingAt: inventorySetupData['incrementStartingAt'],
      useOriginalOnTrade: inventorySetupData['useOriginalOnTrade'],
      defaultStatusId: inventorySetupData['defaultStatusId'] || null,
      stages: inventorySetupData['stages'] ? JSON.parse(inventorySetupData['stages']) : JSON.parse(this.stagesTemplate)
    }),
    {
      validators: Validators.compose([this.checkValidation('defaultFormat')])
    };
    this.stages = this.form.get('stages').value
  }

  // change value to stock number setup
  public save() {
    let repeat = this.doSuffixPrefixRepeat(this.stages)
    if (repeat) {
      this.ms.sendMessage("alert", { type: "danger", text: 'There cannot be duplicate Add-Ons. Please update the Stock Number Add-Ons and try again.' })
      return
    }
    if (this.form.invalid) {
      return
    }

    this.form.get('stages').setValue(JSON.stringify(this.stages))

    if (!this.form.get('id').value) {
      this.createInventorySetup()
      return
    }

    this.modifyInventorySetup()

  }

  // change value to stock number setup
  public saveDesc(descForm: FormGroup, i) {

    if (!descForm.value.id) {
      this.createReconDesc(descForm.value);
      document.getElementById("addMethod").classList.remove("d-none");
      this.editReconditioningDescriptions(i, 'cancel')
      return
    }

    this.modifyReconDesc(descForm.value);
    this.editReconditioningDescriptions(i, 'cancel')
  }

  public modifyReconDesc(descForm) {
    this.master.put(`corporations/${this.entityId}/reconDesc/${descForm.id}`, { information: descForm }, res => {
      this.loading = false
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.reconDescriptions = res.data.reconDescriptions
      this.setDescInformation(this.reconDescriptions)
    })
  }

  public createReconDesc(descForm) {
    this.master.post(`corporations/${this.entityId}/reconDesc`, { information: descForm }, res => {
      this.loading = false
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
      this.reconDescriptions = res.data.reconDescriptions
      document.getElementById("addMethod").classList.remove("d-none")
      this.setDescInformation(this.reconDescriptions)
    })
  }

  public deleteReconDesc(deleteId) {
    this.master.discard(`corporations/${this.entityId}/reconDesc/${deleteId}`, res => {
      this.loading = false
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
      this.reconDescriptions = res.data.reconDescriptions
      this.setDescInformation(this.reconDescriptions);
    })
  }

  modifyInventorySetup() {
    this.loading = true

    this.form.get('lotId').setValue(this.entityType === 'lot' ? this.entityId : null)
    this.form.get('dealershipId').setValue(this.entityType === 'dealership' ? this.entityId : null)
    this.master.put(`lots/${this.entityId}/inventorySetup/${this.form.get('id').value}`, { inventorySetupData: this.form.value }, res => {
      this.loading = false
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      if (this.calledFromInventoryList) {
        this.childEmitter$.emit({ message: 'closeModal' })
      }
    })
  }

  public createInventorySetup() {
    this.loading = true

    this.form.get('lotId').setValue(this.entityType === 'lot' ? this.entityId : null)
    this.form.get('dealershipId').setValue(this.entityType === 'dealership' ? this.entityId : null)

    this.master.post(`lots/${this.entityId}/inventorySetup`, { inventorySetupData: this.form.value }, res => {
      this.loading = false
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
      this.form.get('id').setValue(res.data.inventorySetup['id'])

      if (this.calledFromInventoryList) {
        this.childEmitter$.emit({ message: 'closeModal' })
      }
    })

  }

  /**
   * Using the setup type determine the other details.
   * @returns A string representing the inventory setup method detail
   */
  private determineSetupValue = () => {
    const method = this.form.get('method').value
    let value = null

    switch (method) {
      case 'vinNumber':
        value = parseInt(this.form.get('digits').value)
        break
      case 'increaseBy':
        const inc = parseInt(this.form.get('increment').value)
        const start = parseInt(this.form.get('start').value)
        value = `${inc}-${start}`
        break
    }

    return value
  }

  /**
   * Check to see if prefixes and suffixes repeat between stages.
   * @param stages A list of prefixes and suffixes to check for repeats
   * @returns Indicates if repeats were found (true) or not (false)
   */
  public doSuffixPrefixRepeat = (stages: any[]): boolean => {
    const prefixes = {}
    const suffixes = {}
    const keys = Object.values(stages);
    let repeat = keys.map(addOn => {
      const prefix = (addOn['prefix'] && (addOn['prefix'].trim().length > 0)) ? addOn['prefix'].toLowerCase() : null
      const suffix = (addOn['suffix'] && (addOn['suffix'].trim().length > 0)) ? addOn['suffix'].toLowerCase() : null

      if (prefix && prefixes[prefix]) {
        return true
      }

      if (suffix && suffixes[suffix]) {
        return true
      }

      if (prefix) {
        prefixes[prefix] = true
      }

      if (suffix) {
        suffixes[suffix] = true
      }
    })
    return repeat.includes(true)
  }
  /**
      * Add a new communincation method row to the form array
      * @param e Event that triggerd this method, if any
      */
  public async add(reconDesc) {
    const method = this.fb.group({
      id: [reconDesc ? reconDesc.id : null],
      description: [reconDesc ? reconDesc.description : null, [Validators.required]],
      type: [reconDesc ? reconDesc.type : null],
    })

    this.methods.push(method)
    if (!reconDesc) {
      document.getElementById("addMethod").classList.add("d-none")
      setTimeout(() => {
        this.editReconditioningDescriptions(this.methods.length - 1, 'edit')
      }, 1);

    }
  }
  /*
  * life cycles
  */
  constructor(private ms: MessageService, private master: MasterService, private store: StoreService, private route: ActivatedRoute, private fb: FormBuilder) {
    this.entityType = this.route.snapshot.params['type']
    this.entityId = parseInt(this.route.snapshot.params['id'])

    if (isNaN(this.entityId) || this.entityId == 0) {
      this.entityId = null
    }

    this.formDesc = this.fb.group({
      methods: this.fb.array([])
    })

    this.form = this.fb.group({
      id: new FormControl(0),
      dealershipId: this.entityType === 'dealership' ? new FormControl(this.entityId) : new FormControl(''),
      lotId: this.entityType === 'lot' ? new FormControl(this.entityId) : new FormControl(''),
      defaultFormat: new FormControl(null, [Validators.required, Validators.pattern('(manually|lastDigitsofVIN|increment)')]),
      lastDigitsofVIN: new FormControl(6, [Validators.min(6), Validators.max(17)]),
      incrementBy: new FormControl(1, [Validators.min(1)]),
      incrementStartingAt: new FormControl(10000, [Validators.min(1)]),
      useOriginalOnTrade: new FormControl(0),
      defaultStatusId: new FormControl(1),
      stages: new FormControl(this.stagesTemplate, [Validators.required])
    },
      {
        validators: Validators.compose([this.checkValidation('defaultFormat')])
      }
    )
  }

  /**
   * Helper method to consistently get the list of form methods as an Array.
   */
  get methods() {
    return this.formDesc.controls['methods'] as FormArray;
  }

  public getInventoryStatuses() {
    this.master.get(`collections/inventory/statuses?selectable=true`, (res) => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.inventoryStatuses = res.data.inventoryStatuses
    })
  }

  checkValidation(defaultFormat: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let defaultFormatValue = group.controls[defaultFormat];
      group.get('lastDigitsofVIN').clearValidators();
      group.get('incrementStartingAt').clearValidators();
      group.get('incrementBy').clearValidators();

      group.get('lastDigitsofVIN').updateValueAndValidity({ onlySelf: true, emitEvent: false });
      group.get('incrementStartingAt').updateValueAndValidity({ onlySelf: true, emitEvent: false });
      group.get('incrementBy').updateValueAndValidity({ onlySelf: true, emitEvent: false });
      if (!defaultFormatValue.value) {
        return
      }
      if (defaultFormatValue.value === 'lastDigitsofVIN') {
        group.get('lastDigitsofVIN').setValidators([Validators.required]);
        group.get('lastDigitsofVIN').updateValueAndValidity({ onlySelf: true, emitEvent: false });
        return
      }
      if (defaultFormatValue.value === 'increment') {
        group.get('incrementBy').setValidators([Validators.required]);
        group.get('incrementBy').updateValueAndValidity({ onlySelf: true, emitEvent: false });

        group.get('incrementStartingAt').setValidators([Validators.required]);
        group.get('incrementStartingAt').updateValueAndValidity({ onlySelf: true, emitEvent: false });
        return
      }
      return
    }
  }


  ngOnInit() {
    if (this.entityType == 'lot') {
      this.getInventoryStatuses()
      this.getInventorySetup()
    }
    this.getReconditioningDescriptions()
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.calledFromInventoryList) {
      this.inventorySetup = this.inventorySetupData
    }
  }

}
