import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MasterService } from 'src/app/services/master.service';
import { MessageService } from 'src/app/services/message.service';
import { StoreService } from 'src/app/services/store.service';
import { Product } from 'src/app/views/contacts-views/contacts/components/roles/role-warranty/role-warranty.component';
import * as languageLibrary from '../../../services/language'

@Component({
  selector: 'corporate-products',
  templateUrl: './corporate-products.component.html',
  styleUrls: ['./corporate-products.component.scss']
})
export class CorporateProductsComponent implements OnInit {
  public products: Product[] = [];
  private form: FormGroup = null
  
  // Current saved value in the database
  @Input() productIdSaved: number;
  
  // Current saved value in the database
  @Input() productNameSaved: string;

  // Indicates whether or not to display the label on the input.
  @Input() displayLabel: boolean = true;

  // Indicates whether or not to display the label on the input.
  @Input() disabled: boolean = false;
  
  // Send the child the product id selected
  @Output() setProductId: EventEmitter<Number> = new EventEmitter()
  
  // Current select value
  public productIdSelected: number;
  // A debounce timer for search events. Used to wait for 
  // the user to stop typing before we fetch.
  private watcher: ReturnType<typeof setTimeout> = null
  // The current search text to filter the list by.
  public inquiry: string = null
  public contactWarrantyProducts: [];
  public words = languageLibrary.language
  public language: string = 'EN'
  public ignoreSavedProduct: boolean = true;

  constructor(private master: MasterService, private ms: MessageService, private store: StoreService, private fb: FormBuilder) {
    this.form = this.fb.group({
      inquiry: new FormControl('', [Validators.required])
    })

    this.form.get('inquiry').valueChanges.subscribe(inquiry => {
      if (this.watcher) {
        clearTimeout(this.watcher)
        this.watcher = null
      }

      if (this.inquiry === inquiry) {
        return
      }

      this.watcher = setTimeout(() => {
        this.inquiry = inquiry
        this.getCorporateProducts()
      }, 301)
    })

    addEventListener('keyup', (event: KeyboardEvent) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        this.productIdSelected = null
        if (this.productIdSaved) {
          this.ignoreSavedProduct = true
        }
      }
    });
  }

  ngOnInit() {
    this.getCorporateProducts()
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes && changes.productIdSaved) {
      if (!changes.productIdSaved.currentValue) {
        this.form.get('inquiry').setValue("")
      } else {
        if (this.productIdSaved != changes.productIdSaved.currentValue) {
          this.productIdSaved = changes.productIdSaved.currentValue
          this._filterId(this.productIdSaved)
        }
      }

      if (changes && changes.productNameSaved) {
        this.productNameSaved = changes.productNameSaved.currentValue
        this.form.get('inquiry').setValue(this.productNameSaved)
      }

      if (changes && changes.disabled) {
        this.disabled = changes.disabled.currentValue
        this.disabled == true ? this.form.disable() : this.form.enable()
      }
    }
  }

  private _filterId(id: number) {
    if (this.products.length == 0) {
      return
    }
    const filterValue = id

    if (isNaN(filterValue)) {
      return
    }

    let filterName = this.products
      .filter(option => option.id == filterValue
      ).filter(name => { return name.name })

    this.selectProduct(filterName[0])

  }

  // PRODUCTS
  public getCorporateProducts() {
    if (!this.ignoreSavedProduct){
      return
    }
    if (this.productIdSaved > 0){
      return
    }
    let inquiryParam = ''
    if (this.inquiry && (this.inquiry)) {
      inquiryParam = `&name=${encodeURIComponent(this.inquiry)}`
    }

    let categoryParam = `&category=warranty`

    this.master.get(`corporation/products?${inquiryParam}${categoryParam}`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      if (!res.data.products || res.data.products.length == 0) {
        this.products = []
        return
      }

      this.products = res.data.products
      if (this.productIdSaved && this.productIdSaved !== 0 && this.productIdSaved !== null && this.products.length > 0 && !this.ignoreSavedProduct) {
        this._filterId(this.productIdSaved)
      }

    })
  }

  public createNewProduct() {
    const productName = this.form.get("inquiry").value
    if (productName == null || productName == '') {
      return
    }
    this.master.post(`corporations/product`, { information: { name: this.form.get("inquiry").value }, type: 'warranty' }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
      }

      if (!res.data.newProductId && res.data.newProductId == null) {
        return
      }

      this.productIdSaved = res.data.newProductId
      this.setProductId.emit(this.productIdSaved)
      this.getCorporateProducts()

    })

  }

  public selectProduct(productSelected: Object) {
    if (!productSelected) {
      return
    }

    if (this.productIdSelected == productSelected['id']){
      return
    }

    this.productIdSelected = productSelected['id']
    this.form.get('inquiry').setValue(productSelected['name'])
    this.setProductId.emit(productSelected['id'])
  }

}
