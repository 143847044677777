import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as words from '../../../../services/language'
import { MessageService } from '../../../../services/message.service';
import { MasterService } from '../../../../services/master.service';
import { StoreService } from '../../../../services/store.service';
import { ago } from 'src/app/utils/date';

@Component({
    selector: 'pays-due-soon',
    templateUrl: './pays-due-soon.component.html',
    styleUrls: ['./pays-due-soon.component.scss']
})
export class PaysDueSoonComponent implements OnInit {

    // **************************
    // ****** variables *********
    // **************************

    // Unique internal identity of the lot whose soon due payments will be queried for.
    @Input() lotId: number = 0

    // Unique internal identity of the contact whose soon due payments will be queried for, overrides the lotId.
    @Input() contactId: number = 0

    // Unique internal identity of the sale finance type to filter the queried soon due payments with.
    @Input() financeTypeId: number = 0

    // Currently selected language
    @Input() language: string = 'EN'

    // Word replacements for each language.
    @Input() words: Object = words.language

    //
    public payments: Array<any> = []

    //
    public records: number = 0

    //
    public page: number = 1

    // Indicates whether the data is currently loading.
    public loading: boolean = false

    // Indicates whether any more data is available
    public exhausted: boolean = false

    constructor (private route: ActivatedRoute, private router: Router, private ms: MessageService, private master: MasterService, private store: StoreService) {}

    ngOnInit() {
        this.fetch()
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes) {
            return
        }

        const first = Object.values(changes).some(c => c.isFirstChange())
        if (first) {
            return
        }

        if (changes.lotId || changes.contactId || changes.financeTypeId) {
            this.reset()
            this.fetch()
        }
    }

    private reset = (): void => {
        this.page = 1
        this.exhausted = false
        this.records = 0
    }

    private fetch = (): void => {
        if (this.loading) {
            return
        }

        if (this.exhausted) { // currently showing all available records?
            return
        }

        let lotParam = ''
        if (this.lotId && (this.lotId > 0)) {
            lotParam = `&lot=${this.lotId}`
        }

        let contactParam = ''
        if (this.contactId && (this.contactId > 0)) {
            contactParam = `&contact=${this.contactId}`
            lotParam = ''
        }

        let financeTypeParam = ''
        if (this.financeTypeId && (this.financeTypeId > 0)) {
            financeTypeParam = `&financeType=${this.financeTypeId}`
        }

        this.loading = true
        if (this.page === 1) {
            this.payments = []
        }
        
        this.master.get(`payments/expected?page=${this.page}&sort=due|ASC${lotParam}${contactParam}${financeTypeParam}&notCategories=deposit,initial%20down,deferred%20down&statuses=unpaid`, (res) => {
            this.loading = false
  
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }
  
            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }
  
            this.records = res.data.records
            this.exhausted = (res.data.next === null)
  
            if (this.page > 1) {
                this.payments = this.payments.concat(res.data.payments)
                return
            }
  
            this.payments = res.data.payments
        })
    }

    public icon = (category: string) => {
        if (!category || (category.length <= 0)) {
            return 'monetization_on'
        }

        switch(category.toLowerCase()) {
            case 'mv loan':
                return 'directions_car'
            case 'service':
                return 'car_repair'
            default:
                return 'monetization_on'
        }
    }

    public dueToClass = (due: string): string => {
        return ago(due)
    }

    /**
     * A payment in the list was clicked. 
     * @param payment The payment object that was chosen by the user.
     */
    public selected = (e: Event, payment: Object): void => {
        e.preventDefault()
        e.stopPropagation()
        
        if (!payment || !payment['contactId']) {
            return
        }

        this.router.navigateByUrl(`/collections/${payment['contactId']}?pay=${payment['id']}`)
    }
}
