import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// services
import { MasterService } from '../../services/master.service'
import { MessageService } from '../../services/message.service';
import { StoreService } from '../../services/store.service';
import * as languageLibrary from '../../services/language'

@Component({
  selector: 'account-settings-form',
  templateUrl: './account-settings-form.component.html',
  styleUrls: ['./account-settings-form.component.scss']
})
export class AccountSettingsFormComponent implements OnInit {
  /*
  * Variables
  */

  // user informaion
  @Input() user

  public dataCorporate = {}
 
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  
  // set all words
  public words = languageLibrary.language
  
  // define the status of end-point
  public loading: boolean = false
  
  // define if the security is enabled
  public isDisabled: boolean = true
  
  // list of languages
  public languagesList: object[] = [{ name: 'english', value: 'EN' }, { name: 'spanish', value: 'ES' }]

  // A handle to navbar event messages so we can unsubscribe later.
  private navbarWatcher: any = null
  
  // Group of inputs
  public settingsGroup = new FormGroup({
    name: new FormControl({value: null, disabled: true}, [Validators.required]),
    dbaName: new FormControl({value: null, disabled: true}, []),
    GDN: new FormControl({value: null, disabled: true}, [Validators.required]),
    corpId: new FormControl({value: null, disabled: true}, [Validators.required]),
    languagePreference: new FormControl({value: 'EN', disabled: true}, [Validators.required])
  })

  // Group of inputs
  public billingInformation = new FormGroup({
    contactFirstName: new FormControl({value: null, disabled: true}, [Validators.required]),
    contactLastName: new FormControl({value: null, disabled: true}, [Validators.required]),
    billingAdmin: new FormControl(0, [Validators.required]),
    email: new FormControl({value: null, disabled: true}, [Validators.email]),
    phoneNumber: new FormControl({value: null, disabled: true}, [Validators.required]),
    address: new FormControl({value: null, disabled: true}, [Validators.required]),
    address2: new FormControl({value: null, disabled: true}, [Validators.required]),
    zipCode: new FormControl({value: null, disabled: true}, [Validators.required]),
    city: new FormControl({value: null, disabled: true}, [Validators.required]),
    state: new FormControl({value: null, disabled: true}, [Validators.required]),
  })

  // get information of inputs
  get email() { return this.settingsGroup.get('email') }

  // get information of language  
  get languagePreference() { return this.settingsGroup.get('languagePreference') }
  
  // get nformation of method recovery  
  get methodControl() { return this.settingsGroup.get('methodControl') }
  
  // get information to code access security
  get codeSecurity() { return this.settingsGroup.get('codeSecurity') }

  /*
  * functions
  */


  /*
  todo: change the language preferences
  */
  public changeLanguage = (e): void => {
    if (!e || !e.target || !e.target.value) {
      return
    }

    this.settingsGroup.get('languagePreference').setValue(e.target.value, {onlySelf: true})
  }

  /*
  todo: enable the security inputs
  */

  public enableSecurity = (): void => {
    this.isDisabled = !this.isDisabled
    let input = (document.getElementById('smsrb') as HTMLInputElement)
    input.disabled = this.isDisabled
    input = (document.getElementById('emailrb') as HTMLInputElement)
    input.disabled = this.isDisabled
  }

  // get data
  public getData = (id): void => {
    this.master.post(`corporateBillingAdminDispatcher`, {params: {corporateId: id}, activity: 'getCorporateBillingAdmin'}, res => {
      this.loading = false
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.dataCorporate = res.data
      this.billingInformation.get('contactFirstName').setValue(res.data.billingAdmin[0].corporatesUsers[0].contact.firstName)
      this.billingInformation.get('contactLastName').setValue(res.data.billingAdmin[0].corporatesUsers[0].contact.lastName)
      this.billingInformation.get('email').setValue(res.data.billingAdmin[0].email)
      this.billingInformation.get('phoneNumber').setValue(res.data.billingAdmin[0].corporatesUsers[0].corporate.phone)
      this.billingInformation.get('billingAdmin').setValue(res.data.billingAdmin[0].corporatesUsers[0].id)
      this.getCorporateDetail(id)
    })
  }
  // change billing
  public changeBilling = () => {
    const user = this.dataCorporate['allUsers'].find(el => el.id === +this.billingInformation.value['billingAdmin'])
    if (!user || !user.corporatesUsers || (user.corporatesUsers.length <= 0)) {
      return
    }

    this.billingInformation.get('contactFirstName').setValue(user.corporatesUsers[0].contact.firstName)
    this.billingInformation.get('contactLastName').setValue(user.corporatesUsers[0].contact.lastName)
    this.billingInformation.get('email').setValue(user.email)
  }

  public save=()=>{
    this.loading=true
    this.master.put('corporateBillingAdminDispatcher', { params: {old:this.dataCorporate['billingAdmin'][0].id,new:+this.billingInformation.value['billingAdmin']}, activity: 'changeBillingAdmin' }, res => {
      this.loading = false
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
          
      this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] })
    })
  }

  /*
  ?: get corporate detail
  */
  public getCorporateDetail = (id): void => {
    const identity = parseInt(id)
    if (isNaN(identity) || (identity <= 0)) {
      return
    }

    this.master.get(`corporations/${identity}`, res => {
      this.loading = false
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      const detail = res.data.corporateDetails
      if (!detail) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      this.settingsGroup.setValue({
        name: detail.contact.companyName,
        dbaName: detail.dbaName,
        GDN: detail.dealerNumber,
        corpId: detail.id,
        languagePreference: this.language
      })

      // select address
      let address = null
      if (detail.contact.contactAddresses && (detail.contact.contactAddresses.length > 0)) {
        address = detail.contact.contactAddresses.find(el => el.primaryAddress == 1 || el.addressType.type == 'Billing')
      }

      this.billingInformation.get('address').setValue(address ? address.address : '')
      this.billingInformation.get('address2').setValue(address ? address.address2 : '')
      this.billingInformation.get('zipCode').setValue(address ? address.zipCode : '')
      this.billingInformation.get('city').setValue(address ? address.city : '')
      this.billingInformation.get('state').setValue(address ? address.state : '')
    })
  }

  /*
  todo: life cycles
  */
  constructor(private master: MasterService, private ms: MessageService, private store: StoreService) {
    // detect change language
    this.navbarWatcher = this.ms.channelComponents$.subscribe(res => {
      if (res.message == "changeLanguage") {
        this.language = res.value
      }
    })
  }

  ngOnInit() {
    this.settingsGroup.disable()
    this.getData(this.user.corporateId)
  }

  ngOnDestroy() {
    if (!this.navbarWatcher) {
      return
    }

    this.navbarWatcher.unsubscribe()
  }
}
