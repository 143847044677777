import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MIN_SEARCH_CHARS, SEARCH_DEBOUNCE_MILLIS } from 'src/app/constants/list';
import { MasterService } from 'src/app/services/master.service';
import { MessageService } from 'src/app/services/message.service';
import * as words from '../../../../services/language'
import { ISSUERS } from 'src/app/constants/contact';
import { SCROLL_BOTTOM_OUT } from 'src/app/constants/list';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export interface Contact {
  name: string;
  id: number;
}
@Component({
  selector: 'contact-advanced-search',
  templateUrl: './contact-advanced-search.component.html',
  styleUrls: ['./contact-advanced-search.component.scss'],
})

export class ContactAdvancedSearchComponent implements OnInit, OnChanges {

  // Current type filter value.
  @Input() calledFrom: string = "contactAdvancedSearch"

  // Current type filter value.
  @Input() type: string = null

  // Current disabled value.
  @Input() isDisabled: boolean = false

  // Current saved value in the database
  @Input() contactIdSaved: number;

  // Current saved value in the database
  @Input() contactNameSaved: string;

  /** Indicates whether ot not the label should show for the input. (default: true) */
  @Input() displayLabel: boolean = true;

  // Send the child the contact id selected
  @Output() setContactId: EventEmitter<Number> = new EventEmitter()

  public typesWithNoSetup: Object[] = ['businessContact', 'salesLead', 'inventorySource', 'vendor', 'autoInsurance', 'warranty'];

  // Current selected value in front end
  public contactIdSelected: number;

  // The current search text to filter the list by.
  public inquiry: string = null

  public contacts: Contact[] = [];

  // The search form group
  private form: FormGroup = null

  // A debounce timer for search events. Used to wait for 
  // the user to stop typing before we fetch.
  private watcher: ReturnType<typeof setTimeout> = null

  // Current page the user is viewing (last loaded).
  public page = 1
  
  // The number contacts currently being displayed.
  public displayed = 0
  
  // Indicates whether or not there are no more records to load.
  public exhausted = false
  
  // define if is loading
  public loading: boolean = false;
  
  // Keep track of how the user wants to sort list.
  private sortables: Object = { 'name': 'ASC' }
  
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  
  // set all words
  public words = words.language
  
  // Total number of records available
  public records: number

  public ignoreType: boolean = false;
  
  public contactCreated: boolean = false;
  
  public enterClicked: boolean;
  
  public showSaveButton: boolean = false;
  
  public zipCodeList: Object[] = []
  
  // set time out
  private timeOutID: any;
  
  public types: string[] = []
  
  // latest search inquiry
  public zipInquiry = null
  
  public issuers = ISSUERS
  
  public contactQuickAddInfo = new FormGroup({
    id: new FormControl(null),
    isIndividual: new FormControl(null, [Validators.required]),
    taxPayerNumber: new FormControl(null),
    identificationTypeId: new FormControl(null),
    number: new FormControl(null),
    issuer: new FormControl(null),
    expirationDate: new FormControl(null),
    firstName: new FormControl(null),
    lastName: new FormControl(null),
    middleName: new FormControl(null),
    companyName: new FormControl(null),
    address: new FormControl(null),
    address2: new FormControl(null),
    city: new FormControl({ value: null, disabled: true }),
    state: new FormControl({ value: null, disabled: true }),
    zipCode: new FormControl(null, [Validators.minLength(5),]),
    county: new FormControl({ value: null, disabled: true }),
    country: new FormControl({ value: null, disabled: true }),
    phoneNumber: new FormControl(null),
    emailAddress: new FormControl(null),
    roles: new FormControl(null, [Validators.required])
  })

  constructor(private ms: MessageService, private fb: FormBuilder, private master: MasterService) {
    this.form = this.fb.group({
      inquiry: new FormControl(null, [Validators.required])
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (!changes) {
      return
    }

    if (changes.isDisabled) {
      this.form.get('inquiry').enable()
      if (this.isDisabled) {
        this.form.get('inquiry').disable()
      }
    }

    if (changes.contactNameSaved) {
      this.form.get('inquiry').setValue(changes.contactNameSaved.currentValue)
      this.contactNameSaved = changes.contactNameSaved.currentValue
      this.inquiry = changes.contactNameSaved.currentValue
    }

    if (changes.contactIdSaved) {
      this.contactIdSelected = changes.contactIdSaved.currentValue
      this.contactIdSaved = changes.contactIdSaved.currentValue
    }
  }

  ngOnInit() {
    this.form.get('inquiry').enable()
    if (this.isDisabled) {
      this.form.get('inquiry').disable()
    }

    this.reset()
    this.fetch()

    this.form.get('inquiry').valueChanges.subscribe(inquiry => {
      if (this.watcher) {
        clearTimeout(this.watcher)
        this.watcher = null
      }
      // if (inquiry == null || inquiry == '') this.setContactId.emit(null)

      if (typeof inquiry == 'object' && inquiry !== null) inquiry = inquiry['name']        //if inquiry is an object, assign name to inquiry rather than the object

      if (this.inquiry === inquiry) { // no change?
        return
      }

      this.watcher = setTimeout(() => {
        this.inquiry = inquiry
        this.reset()
        this.fetch()
      }, SEARCH_DEBOUNCE_MILLIS)
    })

    this.contactQuickAddInfo.get('zipCode').valueChanges.subscribe(zipCode => {
      this.searchZipCode()
    })
  }

  public selectContact = (event) => {
    this.contactIdSelected = event.id
    this.setContactId.emit(this.contactIdSelected)
    if (this.ignoreType) this.assignRole(event)
    this.getContactDetails(false);
  }

  public displayFn(contact: Contact) {
    return contact ? contact['name'] ? contact['name'] : contact : undefined;
  }

  reset() {
    this.page = 1
    this.exhausted = false
    this.displayed = 0
    this.showSaveButton = false
    this.contacts = []
  }

  fetch() {
    if (this.contactIdSaved && this.inquiry !== '') {     //if the contact has been selected or saved, no need to search again
      return
    }

    if (this.isDisabled) {          //if the user cannot select from the dropdown, no need to search
      return
    }

    if (this.loading) { // already getting next page
      return
    }

    if (this.exhausted) { // currently showing all available records?
      return
    }

    this.loading = true

    let typeParam = ''
    if (!this.ignoreType || !this.inquiry) {
      if (this.type && (this.type.length > 0)) {
        typeParam = `type=${encodeURIComponent(this.type)}`
      }
    }

    let inquiryParam = ''
    if (this.inquiry && (this.inquiry)) {
      inquiryParam = `&q=${encodeURIComponent(this.inquiry)}`
    }

    let sortParam = ''
    if (this.sortables && (Object.keys(this.sortables).length > 0)) {
      const sorts = []
      for (const key of Object.keys(this.sortables)) {
        let direction = ''
        if (this.sortables[key] && this.sortables[key].length > 0) {
          direction = `|${this.sortables[key]}`
        }

        sorts.push(`${key}${direction}`)
      }

      sortParam = `&sort=${sorts.join(',')}`
    }

    this.master.get(`contacts?${typeParam}&page=${this.page}${inquiryParam}${sortParam}`, (res) => {
      this.loading = false

      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.records = res.data.records
      this.displayed = Math.min(this.records, (res.data.page * res.data.ipp))
      this.exhausted = (res.data.next === null)

      if (this.page > 1) {
        this.contacts = this.contacts.concat(res.data.contacts)
        return
      }

      this.contacts = res.data.contacts

      if (this.contacts.length == 0 && !this.ignoreType) {
        this.ignoreType = true
        this.reset()
        this.fetch()
      }
    })
  }

  public getRoles(roleSelected) {
    if (this.ignoreType == true) {
      return " | Roles: " + roleSelected
    }

    return null
  }

  /**
     * Handle scroll events of the list container and load 
     * the next page when avilable and the user scrolled 
     * to the bottom.
     * @param e The scroll event that trigggered this handler.
     */
  public scrolled = (e): void => {
    if (!e || !e.target) {
      return
    }

    if (this.exhausted) {
      return
    }

    if (this.loading) {
      return
    }

    const location = Math.abs(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop)

    if (location >= SCROLL_BOTTOM_OUT) {
      return
    }

    this.page = this.page + 1
    this.fetch()
  }

  public resetInformation() {
    this.contactQuickAddInfo.reset()
    this.contactQuickAddInfo.get("roles").setValue(JSON.stringify([this.type]))
    this.contactQuickAddInfo.get("isIndividual").setValue(false)
    this.contactIdSaved = 0
    this.contactNameSaved = null
    this.contactIdSelected = null
    this.showSaveButton = false
    this.ignoreType = false
    this.contactCreated = false
  }

  public getContactDetails(open) {
    const identity = this.contactIdSelected ? this.contactIdSelected : this.contactIdSaved ? this.contactIdSaved : null
    if (isNaN(identity) || identity == null) {
      return
    }

    this.master.get(`contacts/${identity}`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
      }

      if (!res.data.contact) {
        return
      }

      const btn = (document.getElementById("createNewContactBtn_" + this.type) as HTMLButtonElement)      //opens the contact info
      if (btn && open) { btn.click() }
      this.setContactQuickAddInfo(res.data.contact)
    })
  }

  public saveContactQuickAdd() {
    this.resetInformation()
    this.contactQuickAddInfo.get("companyName").setValue(this.form.get("inquiry").value)
    if (this.contactIdSelected <= 0) {
      this.createContact()
      return
    }

    this.modifyContact()
  }

  public createContact() {
    const information = this.contactQuickAddInfo.getRawValue()
    this.contactCreated = true
    this.master.post(`contacts/quick`, { information: information }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
      }

      if (!res.data.contactCreated) {
        return
      }

      this.contactIdSelected = res.data.contactCreated['id']
      this.inquiry = information.companyName
      this.setContactId.emit(this.contactIdSelected)
      this.contactQuickAddInfo.get("id").setValue(this.contactIdSelected)
      this.contactCreated = false
    })
  }

  public modifyContact() {
    this.contactCreated = false
    if (this.contactQuickAddInfo.invalid) {
      return
    }

    const information = this.contactQuickAddInfo.getRawValue()
    this.master.put(`contacts/quick/${this.contactIdSelected}?roleType=${this.type}`, { information: information }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
      }

      const btn = (document.getElementById("btn-close-modal-create-new-contact_" + this.type) as HTMLButtonElement)
      if (btn) { btn.click() }
    })
  }

  public assignRole(contactSelected) {
    const role = this.words[this.language][this.type]
    const foundRole = contactSelected['roles'].search(role)
    if (foundRole !== -1) {
      return
    }
    this.master.post(`contacts/${contactSelected['id']}/roles`, { information: this.type }, res => {
      if (!res) {
        return;
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return;
      }

    })
  }

  public setContactQuickAddInfo(contact: Object) {
    if (!contact) {
      return
    }

    this.contactNameSaved = contact['name']
    this.contactIdSaved = contact['id']

    this.contactQuickAddInfo.patchValue({
      id: contact['id'],
      companyName: contact['companyName'],
      firstName: contact['firstName'],
      lastName: contact['lastName'],
      middleName: contact['middleName'],
      isIndividual: contact['isIndividual'],
      address: contact['address'] ? contact['address'] : contact['contactAddresses'] && contact['contactAddresses'].length > 0 ? contact['contactAddresses'][0].address : null,
      address2: contact['address2'] ? contact['address2'] : contact['contactAddresses'] && contact['contactAddresses'].length > 0 ? contact['contactAddresses'][0].address2 : null,
      city: contact['city'] ? contact['city'] : contact['contactAddresses'] && contact['contactAddresses'].length > 0 ? contact['contactAddresses'][0].city : null,
      state: contact['state'] ? contact['state'] : contact['contactAddresses'] && contact['contactAddresses'].length > 0 ? contact['contactAddresses'][0].state : null,
      zipCode: contact['zipCode'] ? contact['zipCode'] : contact['contactAddresses'] && contact['contactAddresses'].length > 0 ? contact['contactAddresses'][0].zipCode : null,
      county: contact['county'] ? contact['county'] : contact['contactAddresses'] && contact['contactAddresses'].length > 0 ? contact['contactAddresses'][0].county : null,
      country: contact['country'] ? contact['country'] : contact['contactAddresses'] && contact['contactAddresses'].length > 0 ? contact['contactAddresses'][0].country : null,
      phoneNumber: contact['phoneNumber'] ? contact['phoneNumber'] : this.filterForCommunication(contact, 'phone'),
      emailAddress: contact['emailAddress'] ? contact['emailAddress'] : this.filterForCommunication(contact, 'email'),
      taxPayerNumber: contact['taxPayerNumber'],
      roles: contact['roles'],
    })
  }

  filterForCommunication(contact, searchFor): any[] {
    let relationship = contact.contactCommunications ? contact.contactCommunications : null
    if (!relationship || relationship.length == 0) { return null }
    const returnedValue = relationship.find(comms => comms.type === searchFor);
    if (typeof returnedValue !== 'undefined') {
      return returnedValue.value
    }
    return null
  }

  public searchZipCode(): void {
    const code = this.contactQuickAddInfo.get('zipCode').value
    if (!code || code.length < MIN_SEARCH_CHARS) {
      this.clearZipCode()
      return
    }

    if (this.zipInquiry && (code === this.zipInquiry)) { // hasn't changed?
      return
    }

    clearTimeout(this.timeOutID)

    this.timeOutID = setTimeout(() => {
      this.zipCodeList = []
      this.zipInquiry = code

      this.master.get(`readLocationByZipcode?zipcode=${code}`, res => {
        if (!res) {
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
          return
        }

        if (res.status !== 200) {
          return
        }

        this.zipCodeList = res.data.locations

        // autocomplete
        if ((code >= 5) && (this.zipCodeList.length === 1)) {
          this.selectZipCode(this.zipCodeList[0])
        }
      })
    }, SEARCH_DEBOUNCE_MILLIS)
  }

  // set the address exist
  public selectZipCode = (address: Object): void => {
    if (!address || !this.contactQuickAddInfo) {
      return
    }

    this.contactQuickAddInfo.get('city').setValue(address['city'])
    this.contactQuickAddInfo.get('state').setValue(address['state'])
    this.contactQuickAddInfo.get('county').setValue(address['county'])
    this.contactQuickAddInfo.get('zipCode').setValue(address['zipcode'])
    this.contactQuickAddInfo.get('country').setValue('United States')

    this.zipCodeList = []
  }

  // set the address exist
  public clearZipCode() {
    this.contactQuickAddInfo.get('city').setValue(null)
    this.contactQuickAddInfo.get('state').setValue(null)
    this.contactQuickAddInfo.get('county').setValue(null)
    this.contactQuickAddInfo.get('country').setValue(null)
    this.zipCodeList = []
  }
  /*
  todo: get types of identifications
  */
  public getTypes = (): void => {
    this.master.get('collections/contacts/identificationTypes', res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
      }
      this.types = res.data.IdentificationTypes
    })
  }

  public showSaveAndUseButton() {
    if (this.typesWithNoSetup.includes(this.type)) {
      return true
    }

    return false
  }

}

