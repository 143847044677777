import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, } from '@angular/router';
import { MasterService } from '../../../../../../services/master.service';
import { MessageService } from '../../../../../../services/message.service'
import { StoreService } from '../../../../../../services/store.service'
import * as languageLibrary from '../../../../../../services/language'
import { Observable } from 'rxjs';

@Component({
    selector: 'contact-detail',
    templateUrl: './contact-detail.component.html',
    styleUrls: ['./contact-detail.component.scss']
})

export class ContactDetailComponent implements OnInit {
    //  sections pending save
    private sections = [
        { name: 'generalInformationIndividual', savePending: false, btn: 'btn-save-generalInformation' },
        { name: 'generalInformationNonIndividual', savePending: false, btn: 'btn-save-generalInformation' },
        { name: 'addresses', savePending: false, btn: 'btn-save-addresses' },
        { name: 'communications', savePending: false, btn: 'btn-save-communications' },
        { name: 'identifications', savePending: false, btn: 'btn-save-identifications' },
        { name: 'income', savePending: false, btn: 'btn-save-income' },
        { name: 'references', savePending: false, btn: 'btn-save-references-new' }
    ]

    // define if exist
    public savePending: boolean = false

    // save the language
    public language: string = 'EN'

    // set all words
    public words = languageLibrary.language

    // define the contact type (singular)
    public typeSingular: Object = {
        'leads': 'leads',
        'employees': 'employees',
        'buyers': 'buyers',
        'vendors': 'vendors',
        'finance': 'financeCompanies',
        'lenders': 'floorPlans',
        'insurance': 'insurance',
        'lienholders': 'lienholder',
    }

    // Unique internal ientity of the contact or 0 if new add
    public contactId: number = 0

    //  save the contact
    public contact: Object

    // define ir loading state
    public loading: boolean = false

    // save the email (if exist)
    public email: string = ""

    // define if view the alert
    public viewAlert: boolean = true

    // permissions
    public permissions: Object[] = []

    // define if some field was updated
    public isGhost: boolean = false

    // error type
    public error: string = ''

    //
    public individualValue: boolean = true

    /*
    * life cycles
    */
    constructor(private router: Router, private route: ActivatedRoute, private master: MasterService, private ms: MessageService, public store: StoreService) {
        // normal
        if (!this.store || !this.store.userAccount || !store.userAccount['permissions']) {
            return
        }

        // permissions on user list
        this.permissions = store.userAccount['permissions']
        if (!this.permissions || (this.permissions.length <= 0)) {
            //return
        }

        // if not have permissions, return to account settings
        if (!this.permissions[1]['childrens'][0].permissions.includes('view')) {
            //window.location.href = "#/contacts/" + this.type + "/";
            //this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['noPermission'] })
        }

    }

    ngOnInit() {
        this.contactId = 0 //parseInt(this.route.snapshot.params['id'])
        if (isNaN(this.contactId) || (this.contactId <= 0)) {
            this.contactId = 0
        }

        this.reloadData(true)
    }

    // ! before exit
    canDeactivate(): Observable<boolean> | boolean {
        let caseLeave = 'justView'
        // case valid information but save pending
        let buttons = this.sections.filter(el => el['savePending'] == true)
        if (buttons.length > 0) {
            caseLeave = 'savePending'
        }
        // case invalid information

        if (this.contact != undefined) {
            if ((this.contact['firstName'] == null || this.contact['firstName'] == '') && this.contact['isIndividual'] == 1) {
                caseLeave = 'invalidInformation'
            }
            if ((this.contact['companyName'] == null || this.contact['companyName'] == '') && this.contact['isIndividual'] == 0) {
                caseLeave = 'invalidInformation'
            }
        }
        let result

        switch (caseLeave) {
            // phantom row
            case 'invalidInformation':
                result = window.confirm('The information is not valid, if it leaves the view the contact will be eliminated, do you want to leave?');
                if (result) {
                    this.master.discard(`deleteContact?contactId=${this.contact['id']}&query=false`, res => {
                        if (res) {
                            if (res.status != 200) {
                                // * success
                                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                            }
                        } else {
                            // in case API no response
                            this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                        }
                    })
                    return true
                } else {
                    return false
                }
                break;
            // changes pending
            case 'savePending':
                result = window.confirm('Do you want to save the changes before exiting?');
                if (result) {
                    buttons.forEach(el => {
                        (document.getElementById(el['btn']) as HTMLButtonElement).click();
                    });
                    (document.getElementById('btn-cancel-addresses') as HTMLButtonElement).click();
                    (document.getElementById('close-modal-communications') as HTMLButtonElement).click();
                    // (document.getElementById('close-modal-identifications') as HTMLButtonElement).click();
                    // (document.getElementById('btn-cancel-income') as HTMLButtonElement).click();
                    // (document.getElementById('btn-cancel-references-new') as HTMLButtonElement).click();
                    // window.location.reload()
                    return true
                } else {
                    return false
                }
                break;
            // just view
            default:
                return true
        }
    }

    /*
    * functions
    */
    //  reload data
    public reloadData = (loading?): void => {
        if (this.contactId <= 0) {
            this.loading = false
            return
        }

        if (this.loading) {
            return
        }

        this.loading = true

        this.master.get(`contacts/${this.contactId}`, res => {
            this.loading = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                if (res.data.error.indexOf('not exist') >= 0) {
                    this.error = 'not exist'
                    window.location.href = '#/contacts'
                }
                return
            }

            if (!res.data.contact) {
                return
            }

            this.contact = res.data.contact
            this.ms.channelComponents$.emit({ message: 'reloadContactObject', object: this.contact })
        })
    }

    // todo: listen to output of navbar
    public listenerNav = (e): void => {
        switch (e['message']) {
            case 'changeLanguage':
                this.language = e['value']
                break
            case 'setPermissions':
                // permissions on user list

                this.permissions = e['permissions'];
                if (this.permissions[1]['childrens'][0].permissions.includes('view')) {
                    this.reloadData(true)
                }
                break
        }
    }

    // todo: listener to childrens
    public listenerChildrens = (e): void => {
        switch (e.message) {
            case 'changeLanguage':
                this.language = e['value']
                break;
            case 'reloadContactDetail':
                this.contactId = parseInt(e.contactId || 0)
                this.viewAlert = false
                this.reloadData()
                break
            // case 'setContactId':
            //   this.contactId = parseInt(e.contactId || 0)
            //   break
            case 'individualValue':
                this.individualValue = e.value
                break
            case 'reloadEmail':
                this.email = e.email
                break
            case 'changesPendingContacts':
                // assing the values to sections
                switch (e.section) {
                    case 'generalInformationIndividual':
                        this.sections[0].savePending = e.value
                        break;
                    case 'generalInformationNonIndividual':
                        this.sections[1].savePending = e.value
                        break;
                    case 'addresses':
                        this.sections[2].savePending = e.value
                        break
                    case 'communications':
                        this.sections[3].savePending = e.value
                        break
                    case 'identifications':
                        this.sections[4].savePending = e.value
                        break
                    case 'incomes':
                        this.sections[5].savePending = e.value
                        break
                    case 'references':
                        this.sections[6].savePending = e.value
                        break;
                }
                break
        }
    }
}
