import { Component, Injector, Input, OnInit } from '@angular/core';
import { DynamicService } from 'src/app/services/dynamic.service';
import { token } from '../roles.component';
import { MessageService } from 'src/app/services/message.service';
import { MasterService } from 'src/app/services/master.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as languageLibrary from '../../../../../../services/language'
import { BUYING_CRITERIA } from 'src/app/constants/contact';

@Component({
  selector: '[role-related-finance]',
  templateUrl: './role-related-finance.component.html',
  styleUrls: ['./role-related-finance.component.scss']
})
export class RoleRelatedFinanceComponent implements OnInit {
  public contactId: number = 0
  public contactRoleId: number = 0
  public related: boolean
  public dataIn: string;
  public id: number = 0;
  public buyingCriterias = BUYING_CRITERIA
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  public words = languageLibrary.language
  public relatedFinanceInformation = new FormGroup({
    id: new FormControl(null),
    contactId: new FormControl(null, [Validators.required]),
    type: new FormControl(null, [Validators.required]),
    buyingCriteriaBasedOn: new FormControl(null, [Validators.required]),
    buyingRate: new FormControl(null, [Validators.required]),
    federalId: new FormControl(null, [Validators.required]),
  })
  public calledFrom: string;

  constructor(private master: MasterService, private ms: MessageService, private inject: Injector, private serv: DynamicService) {
  }

  ngOnInit() {
    this.dataIn = this.inject.get(token);
    this.contactId = this.dataIn['contactId']
    this.related = this.dataIn['related']
    this.contactRoleId = this.dataIn['contactRoleId']
    this.calledFrom = this.dataIn['calledFrom']

    if (!this.contactId) {
      return
    }

    this.relatedFinanceInformation.get("contactId").setValue(this.contactId)
    this.relatedFinanceInformation.get("type").setValue("Non-Related")
    this.getRoleRelatedFinance()

  }
  getRoleRelatedFinance() {
    this.master.get(`contacts/${this.contactId}/relatedFinance`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      if (res.data.contactRelatedFinance.length == 0) {
        if (this.calledFrom == "contactAdvancedSearch")
          this.serv.outputFromDynamicComponent({ action: "setupNeeded", setupNeeded: true, type:"finance" });
        return
      }

      this.setInformation(res.data.contactRelatedFinance[0])

      if (this.calledFrom == "contactAdvancedSearch") {
        this.serv.outputFromDynamicComponent({ action: "setupNeeded", setupNeeded: false, type:"finance" });
        return
      }
    })
  }

  setInformation(relatedFinance: any) {
    this.id = relatedFinance['id']
    this.relatedFinanceInformation.setValue({
      id: relatedFinance['id'],
      contactId: relatedFinance['contactId'],
      type: relatedFinance['type'],
      buyingCriteriaBasedOn: relatedFinance['buyingCriteriaBasedOn'],
      buyingRate: relatedFinance['buyingRate'],
      federalId: relatedFinance['federalId'],
    })
  }

  public save() {
    if (!this.relatedFinanceInformation.valid) {
      return
    }

    if (this.id <= 0) {
      this.create()
      return
    }

    if (this.id >= 1) this.modify()
  }
  create() {
    this.master.post(`contacts/${this.contactId}/relatedFinance`, { information: this.relatedFinanceInformation.value }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
      this.setInformation(res.data.contactRelatedFinance)

      if (this.calledFrom == "contactAdvancedSearch")
        this.serv.outputFromDynamicComponent({ action: "setupSaved", setupSaved: true, type:"finance" });

    })
  }
  modify() {
    this.master.put(`contacts/${this.contactId}/relatedFinance/${this.id}`, { information: this.relatedFinanceInformation.value }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
      if (this.calledFrom == "contactAdvancedSearch")
        this.serv.outputFromDynamicComponent({ action: "setupSaved", setupSaved: true, type:"finance" });
    })
  }
  deactivateRole() {
    this.serv.outputFromDynamicComponent({ action: "deactivate", contactRoleId: this.contactRoleId });
  }

}
