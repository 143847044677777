import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'inventory-files',
  templateUrl: './inventory-files.component.html',
  styleUrls: ['./inventory-files.component.scss']
})
export class InventoryFilesComponent implements OnInit {
   /*
    * Variables
    */
  public images = []
  //  save headers
  public headers: Object[] = [
    { name: 'Image' }, { name: 'Size' },
    { name: 'Document Type' }, { name: 'Uploaded by' },
    { name: 'Upload Date' }, { name: 'Modified by' },
    { name: 'Modification Date' }
  ]
  // loading state
  public loading: boolean = false
  //  save all data
  public data: Object[] = [
    { img: 'img.png', caption: 'caption1', description: 'description', uploadedBy: 'juan', uploadDate: '2021-04-22', modifiedBy: 'kevin', modifiedDate: '2021-04-22' },
    { img: 'img.png', caption: 'caption2', description: 'description', uploadedBy: 'juan', uploadDate: '2021-04-22', modifiedBy: 'kevin', modifiedDate: '2021-04-22' },
    { img: 'img.png', caption: 'caption3', description: 'description', uploadedBy: 'juan', uploadDate: '2021-04-22', modifiedBy: 'kevin', modifiedDate: '2021-04-22' }
  ]
   /*
    * Functions
    */
  public formatBytes=(bytes:number):string=>{
    return (bytes/1000) + " MB"
  }
  public changeImage = (e): void => {
    let file = e.target.files[0]
    let reader = new FileReader();
    reader.readAsDataURL(file);
    let vm = this
    reader.onload = function (event) {
      
      vm.images.push({
        image: `${reader.result}`,
        name: file.name,
        size: vm.formatBytes(file.size)
      })
    };
  }

  public delete=(index:number):void=>{
    this.images.splice(index,1)
  }
   /*
    * Life cycles
    */
  constructor() { }

  ngOnInit() {
  }

}
