
import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MasterService } from '../../../../../../../../services/master.service'
import { StoreService } from '../../../../../../../../services/store.service'
import { MessageService } from '../../../../../../../../services/message.service'
import * as languageLibrary from '../../../../../../../../services/language'
import { formatCurrency } from 'src/app/utils/format';
import { clean } from 'src/app/utils/numeric';
import * as moment from 'moment';
import { Subject } from "rxjs";


@Component({
selector: 'payments-terms',
templateUrl: './payments-terms.component.html',
styleUrls: ['./payments-terms.component.scss']
})
export class PaymentsTermsComponent implements OnInit, OnChanges {

    /************
     * todo: variables
     **********/

    /**  */
    @Input() saleId: number = 0

    /** */
    @Input() saleType: number = 0

    // Indicates whether or not the sale is unlocked and can accept changes.
    @Input() isUnLock: boolean = true

    /** Contract/Interest state date */
    @Input() sold: string = null

    /** Stage: Quote or Sale */
    @Input() stage: string = null

    /**  */
    @Output() paymentsChanged: EventEmitter<Object> = new EventEmitter()
    
    /** A hash (lookup) table of (statusId, status name) */
    public statuses = {}

    /** A hash (lookup) table of (categoryId, category name) */
    public categories = {}

    /**  */
    public loading: boolean = false

    public saving: boolean = false

    /**  */
    public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'

    /**  */
    public words = languageLibrary.language 

    public form: FormGroup = null

    /************
     * todo: life cycles
     **********/
    constructor(private master: MasterService, private ms: MessageService, private store: StoreService, private fb: FormBuilder) {
        this.form = this.fb.group({
            payments: this.fb.array([])
        })
    }

    ngOnInit() {
        this.fetchPayments()
    }

    ngOnDestroy(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        //Add '${implements OnChanges}' to the class
        if (!changes) {
            return
        }

        const first = Object.values(changes).some(c => c.isFirstChange())
        if (first) {
            return
        }

        if (changes.saleId) {
            this.fetchPayments()
        }

        if (changes.isUnLock) {
            this.form.disable()
            if (this.isUnLock) {
                this.form.enable()
            }
        }

        if (changes.sold) {
            this.computeDueDates()
        }
    }

    /**
     * Helper method to consistently get the list of form fees as an Array.
     */
    get payments() {
        return this.form.controls['payments'] as FormArray;
    }

    /************
     * todo: functions
     **********/

    /*
        Reload and clean the inputs
    */
    public reset = (): void => {
        this.form.reset()
        this.payments.clear()
    }

    public fetchPayments = () => {
        if (isNaN(this.saleId) || (this.saleId <= 0)) {
            return
        }

        this.reset()

        this.loading = true
        this.master.get(`payments/expected?sale=${this.saleId}&sort=due|ASC&categories=deposit,initial%20down,deferred%20down`, res => {
            this.loading = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            if (!res.data) {
                return
            }

            const statusCollection = res.data.statusList
            if (statusCollection && (statusCollection.length > 0)) {
                for (let status of statusCollection) {
                    this.statuses[status.id] = status.name
                }    
            }

            const categoryCollection = res.data.categories
            if (categoryCollection && (categoryCollection.length > 0)) {
                for (let category of categoryCollection) {
                    this.categories[category.id] = category.name
                }    
            }

            if (!res.data.payments || (res.data.payments.length <= 0)) {
                return
            }

            for (let payment of res.data.payments) {
                this.payments.push(this.fb.group({
                    id: [parseInt(payment['id']), []],
                    saleId: [this.saleId, [Validators.required]],
                    category: [payment['category'], [Validators.required]], // 1 = Initial Down, 2 = Deferred Down
                    status: [payment['status'], []], // 1 = Unpaid
                    type: [payment['type'], []], // 1 = Down payment
                    amount: [formatCurrency(payment['amount']), []],
                    due: [payment['due'], []],        
                    reference: [payment['reference'] || null, []],
                    memo: [payment['memo'] || null, []]        
                }))
            }

            this.emit(false)
        })
    }

    /**
     * Let any listeners know that the sale down payments changed.
     * @param {boolean} [save = true] Indicates whether or not the recipient of the event needs to recompute or handle an actual change (not just a load).
     */
    public emit = (save: boolean = true) => {
        if (!this.payments) {
            return
        }

        let initial = 0
        let deferred = 0
        let last = null // last deferred/down payment due date
        let max = null

        for (let payment of this.payments.controls) {
            const id = parseInt(payment.get('id').value)
            if (!id || (id < 0)) { // only include saved/commited payments
                continue
            }

            const category = payment.get('category').value
            switch (category) {
                case 'Initial Down':
                    initial += clean(payment.get('amount').value)
                    break
                default: // deferred
                    deferred += clean(payment.get('amount').value)
                    break
            }

            if (last == null) {
                last = moment(payment.get('due').value).format('yyyy-MM-DD')
                max = new Date(payment.get('due').value)
                continue
            }

            const due = new Date(payment.get('due').value)
            if (max >= due) {
                continue
            }

            last = moment(payment.get('due').value).format('yyyy-MM-DD')
        }

        this.paymentsChanged.emit({ value: (initial + deferred), initial: initial, deferred: deferred, last: last, save: save })
    }

    /**
     * Save all payments that are pending a save.
     */
    public saveAll = (): Promise<void> => {
        if (this.saving) {
            return
        }

        for (const payment of this.payments.controls) {
            if (payment.invalid || payment.pristine) {
                continue
            }

            this.upsert(payment as FormGroup)
        }

        this.emit()
    }

    /**
     * Save (upsert) a payment from the form array
     * @param index The index of the payment to save
     */
    public save = (index: number): Promise<void> => {
        if ((index === undefined) || (index === null) || (index < 0) || (index > this.payments.length - 1)) {
            return
        }

        if (this.saving) {
            return
        }

        const payment = this.payments.controls[index] as FormGroup
        this.upsert(payment)
    }

    /**
     * Determine if the API call should be an insert or 
     * modify and call the proper method.
     * @param payment 
     * @returns 
     */
    private upsert = (payment: FormGroup) => {
        const id = parseInt(payment.get('id').value)
        if ((isNaN(id)) || (id < 0)) {
            return
        }

        if (id === 0) {
            this.insert(payment)            
            return
        }

        this.modify(id, payment)
    }

    /**
     * Add a new sale down payment to the current sale.
     * @param payment Down payment meta data to add to the sale.
     */
    private insert = (payment: FormGroup): Promise<void> => {
        if (!this.saleId || !payment) {
            return
        }

        const payload = {
            saleId: this.saleId,
            category: payment.get('category').value,
            status: payment.get('status').value,
            type: payment.get('type').value,
            amount: clean(payment.get('amount').value),
            due: payment.get('due').value,
            reference: payment.get('reference').value,
            memo: payment.get('memo').value 
        }

        this.saving = true
        this.master.post(`sales/${this.saleId}/payments`, {payload: payload}, res => {
            this.saving = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            payment.markAsPristine()

            if (!res.data || !res.data.payments || (res.data.payments.length <= 0)) {
                return
            }

            payment.get('id').setValue(res.data.payments[0].id)
            this.emit()
        })
    }

    /**
     * Modify an existing down payment for the current sale.
     * @param id Unique internal identtiy of the exising down payment.
     * @param payment Down payment meta data to modify the existing one with.
     */
    private modify = (id: number, payment: FormGroup): Promise<void> => {
        if (!id || !this.saleId || !payment || (id <= 0)) {
            return
        }

        const payload = {
            saleId: this.saleId,
            category: payment.get('category').value,
            status: payment.get('status').value,
            type: payment.get('type').value,
            amount: clean(payment.get('amount').value),
            due: payment.get('due').value,
            reference: payment.get('reference').value,
            memo: payment.get('memo').value 
        }

        this.saving = true
        this.master.put(`sales/${this.saleId}/payments/${id}`, {payload: payload}, res => {
            this.saving = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            payment.markAsPristine()
            this.emit()
        })
    }

    /**
     * Add a new sale down payment to the array.
     * @param e 
     */
    public add = (e) => {
        let due = new Date(this.sold + ' 00:00:00')
        if (this.payments && this.payments.length > 0) {
            const prevDue = new Date(`${this.payments.controls[this.payments.length - 1].get('due').value}T00:00:00`)
            due = new Date(prevDue.getFullYear(), prevDue.getMonth(), prevDue.getDate() + 7, 0, 0, 0, 0)
        }

        let category = 'Deferred Down'
        let depositExists = this.payments.controls.find(payment => payment.value.category === 'Deposit' ? true : false)

        if ((this.payments.length <= 0) && (this.stage == 'Quote')) {
            category = 'Deposit'
        }

        if (((this.payments.length <= 0) && (this.stage !== 'Quote')) || 
            ((this.payments.length === 1) && depositExists)) {
            category = 'Initial Down'
        }

        this.payments.push(this.fb.group({
            id: [0, []],
            saleId: [this.saleId, [Validators.required]],
            category: [category, [Validators.required]],
            status: ['Unpaid', []],
            type: ['Payment', []],
            amount: [formatCurrency(0), []],
            due: [moment(due).format('yyyy-MM-DD'), []],        
            reference: [null, []],
            memo: [null, []]    
        }))
    }

    /**
     * Remove a sale down payment from the form array
     * @param index The index of the payment to remove
     */
    public discard = (index: number): void => {
        if ((index < 0) || (index > this.payments.length - 1)) {
            return
        }

        const id = parseInt(this.payments.at(index).get('id').value)
        this.payments.removeAt(index)

        if (!id || isNaN(id) || id <= 0) {
            return
        }

        this.master.discard(`sales/${this.saleId}/payments/${id}`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }
        })
    }

    /**
    * When the sale date changes lets compute the due dates of any existing down payments.
    */
    private computeDueDates(): void {
        if (!this.isUnLock) {
            return
        }

        if (!this.payments || (this.payments.length <= 0)) {
            return
        }

        let due = moment(this.sold)
        for (const payment of this.payments.controls) {
            payment.get('due').setValue(due.format('yyyy-MM-DD'))
            payment.markAsDirty()
            
            due = moment(due).add(7, 'days')
        }

        this.saveAll()
    }

}
