import { Component, Injector, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DynamicService } from 'src/app/services/dynamic.service';
import { MasterService } from 'src/app/services/master.service';
import { MessageService } from 'src/app/services/message.service';
import { token } from '../roles.component';
import * as languageLibrary from '../../../../../../services/language'

@Component({
  selector: '[role-gap]',
  templateUrl: './role-gap.component.html',
  styleUrls: ['./role-gap.component.scss']
})
export class RoleGapComponent implements OnInit {
  public contactId: number
  public contactRoleId: number = 0
  public words = languageLibrary.language
  public language: string = 'EN'
  public gapInformation = new FormGroup({
    id: new FormControl(null),
    contactId: new FormControl(null, [Validators.required]),
    premiumRateApplied: new FormControl("flatPremium"),
    termRange: new FormControl(null),
  })
  public dataIn: string;
  public id: number = 0;
  public termRange = [];
  public termRangeTemplate: string = `[{"termMonthsFrom":0,"termMonthsTo":12,"cost": 100,"premium": 100},{"termMonthsFrom":12,"termMonthsTo":24,"cost": 200,"premium": 100}]`
  public calledFrom: string;

  constructor(private master: MasterService, private ms: MessageService, private inject: Injector, private serv: DynamicService) {
  }

  deactivateRole() {
    this.serv.outputFromDynamicComponent({ action: "deactivate", contactRoleId: this.contactRoleId });
  }
  ngOnInit(): void {

    this.dataIn = this.inject.get(token);
    this.contactId = this.dataIn['contactId']
    this.contactRoleId = this.dataIn['contactRoleId']
    this.calledFrom = this.dataIn['calledFrom']

    if (!this.contactId) {
      return
    }

    this.gapInformation.get("contactId").setValue(this.contactId)

    this.getGAP()
  }
  public getGAP() {
    this.termRange = JSON.parse(this.termRangeTemplate)
    this.master.get(`contacts/${this.contactId}/gap`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      if (res.data.contactGAP.length == 0) {
        if (this.calledFrom == "contactAdvancedSearch")
          this.serv.outputFromDynamicComponent({ action: "setupNeeded", setupNeeded: true, type:"gap" });
        return
      }

      this.setInformation(res.data.contactGAP[0])

      if (this.calledFrom == "contactAdvancedSearch") {
        this.serv.outputFromDynamicComponent({ action: "setupNeeded", setupNeeded: false, type:"gap" });
        return
      }
    })
  }

  public saveGAP() {
    this.gapInformation.get("termRange").setValue(JSON.stringify(this.termRange))
    if (!this.gapInformation.valid) {
      return
    }

    if (this.id <= 0) {
      this.createGAP()
    }

    if (this.id >= 1) this.modifyGAP()
  }

  createGAP() {
    this.master.post(`contacts/${this.contactId}/gap`, { information: this.gapInformation.value, }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
      this.setInformation(res.data.contactGAP)

      if (this.calledFrom == "contactAdvancedSearch")
        this.serv.outputFromDynamicComponent({ action: "setupSaved", setupSaved: true, type:"gap" });
    })
  }

  modifyGAP() {
    this.master.put(`contacts/${this.contactId}/gap/${this.id}`, { information: this.gapInformation.value, }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
      if (this.calledFrom == "contactAdvancedSearch")
        this.serv.outputFromDynamicComponent({ action: "setupSaved", setupSaved: true, type:"gap" });

    })
  }

  deleteGAP() {
    this.master.discard(`contacts/${this.contactId}/gap/${this.id}`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.resetInformation()
    })
  }

  resetInformation() {
    this.gapInformation.reset({
      contactId: this.contactId
    })
    this.id = null
  }

  setInformation(info) {
    this.id = info["id"]
    this.termRange = info['termRange'] ? JSON.parse(info['termRange']) : JSON.parse(this.termRangeTemplate)
    this.gapInformation.setValue({
      id: info['id'],
      contactId: info['contactId'],
      premiumRateApplied: info['premiumRateApplied'] || "flatPremium",
      termRange: this.termRange
    })
  }

}

