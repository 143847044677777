import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { StoreService } from '../../../../../../services/store.service'
import { MessageService } from "../../../../../../services/message.service";
import { MasterService } from "../../../../../../services/master.service";
import { ActivatedRoute } from "@angular/router";
import { FormGroup, FormArray, Validators, FormBuilder } from '@angular/forms';
import { debounceTime } from "rxjs/operators";
import { Subject } from 'rxjs';
import * as languageLibrary from '../../../../../../services/language'
import { SAVE_DEBOUNCE_MILLIS, TRADE_IN, SALE_TYPES } from "src/app/constants/sale";
import { formatCurrency } from "../../../../../../../app/utils/format"
import { clean } from "../../../../../../../app/utils/numeric"

@Component({
    selector: 'trade-in',
    templateUrl: './trade-in.component.html',
    styleUrls: ['./trade-in.component.scss']
})
export class TradeInComponent implements OnInit {

    @Input() isLocked: boolean = false

    @Input() saleVehicleId = 0

    @Input() isQuote: boolean = true

    @Input() buyerId: number = 0

    //
    @Input() saleType = null

    @Input('createSaleEvent') createSaleEvent: Subject<number>

    @Input('addTradeEvent') addTradeEvent: Subject<number>

    @Input('pastPurchaseTradedEvent') pastPurchaseTradedEvent: Subject<number>

    @Input('buyerChangeEvent') buyerChangeEvent: Subject<number>

    @Output() tradeChangeEvent = new EventEmitter<Object>()

    public loading: boolean = false

    public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'

    public words: any = languageLibrary.language

    public saleId = 0

    public lotId = 0

    public previousPurchases: Object[] = []

    public previousPurchasesComplete: any[] = []

    public tradeIns: Object[] = []

    public auxVehicle: Object = {}

    public viewDetail: boolean = false

    public isAdmin: boolean = false

    public deleteIndex: number = -1

    public deleteSaleVehicleId = 0

    public deleteInventoryId = 0

    public numSaves: number = 0

    public tradeInListForm: FormGroup = this.fb.group({
        trades: this.fb.array([])
    })

    public lienholders: Array<Object> = []

    public inventoryId = 0

    public displayList: boolean = false
    
    private saleVehicleTransactionTypes = []

    //  An associative array of sale types with the id as the key
    public saleTypes: Object = SALE_TYPES

    // A debounce timer for change events. Used to wait for 
    // the user to stop typing before we save.
    private watcher: ReturnType<typeof setTimeout> = null

    get wholesale(): boolean { return ((this.saleType) && (this.saleTypes[this.saleType] === 'Wholesale')) }
    
    constructor(private ms: MessageService, private master: MasterService, private route: ActivatedRoute, private store: StoreService, private fb: FormBuilder,) {}

    ngOnInit() {
        this.saleId = 0
        const id = parseInt(this.route.snapshot.params['id'])
        if (id && !isNaN(id) && (id > 0)) {
            this.saleId = id
        }
        
        this.lotId = parseInt(localStorage.getItem('lot'))
        
        this.createSaleEvent.subscribe(saleId => {
            this.saleCreated(saleId)
        });

        this.buyerChangeEvent.subscribe(buyerId => {
            this.buyerChange(buyerId)
        });

        this.addTradeEvent.subscribe(saleId => {
            const btn = (document.getElementById('btn-add-trade') as HTMLButtonElement)
            if (!btn) {
                return
            }

            btn.click()
        });

        this.pastPurchaseTradedEvent.subscribe(inventoryId => {
            this.vehicleAdded(inventoryId)
        })

        this.populate()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes) {
            return
        }

        const first = Object.values(changes).some(c => c.isFirstChange())
        if (first) {
            return
        }

        if (changes.isLocked && this.trades && (this.trades.length > 0)) {
            this.trades.enable()
            if (this.isLocked) {
                this.trades.disable()
            }
        }

        if (changes.saleType && this.wholesale) {
            this.discardTrades()
            return
        }
    }

    ngOnDestroy() {
        this.buyerChangeEvent.unsubscribe()
        this.createSaleEvent.unsubscribe()
    }

    // listener childrens
    public listenerChildrens = (e): void => {
        switch (e.message) {
            // new trade-in created for this sale
            case 'addedVehicle':
                this.vehicleAdded(e.inventoryId)
                this.close()
                break
            // trade-in selected from inventory
            case 'choseVehicle':
                this.vehicleAdded(e.inventoryId)
                break
        }
    }

    get trades() {
        return this.tradeInListForm.get('trades') as FormArray
    }
    
    /**
     * Acquire the data needed for the view
     */
    private populate = async () => {
        await this.fetchSaleTrades()

        if (this.trades.length <= 0) { // only need next steps if we are displaying trades
            return
        }

        await this.fetchLienholders()
    }

    private fetchLienholders = async () => {
        if (this.lienholders && (this.lienholders.length > 0)) {
            return
        }

        return await this.master.getAsync(`contacts?type=lienholder&page=1&sort=name|asc`).then(res => {
            this.lienholders = res.data.contacts ? res.data.contacts.slice() : []
        })
    }

    private fetchTransactionTypes = async () => {
        if (this.saleVehicleTransactionTypes && (this.saleVehicleTransactionTypes.length > 0)) {
            return
        }

        return await this.master.getAsync(`sales/vehicletransactiontypes`).then(res => {
            this.saleVehicleTransactionTypes = res.data.VehicleTransactionTypes ? res.data.VehicleTransactionTypes : []
        })
    }

    /**
     * Acquire the trade-ins for the selected sale
     */
    private fetchSaleTrades = async () => {
        if (!this.saleId || (this.saleId <= 0)) {
            return
        }

        this.loading = true
        this.trades.clear()

        await this.master.getAsync(`sales/${this.saleId}/vehicles?type=trade`).then((res) => {
            this.loading = false
            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }
            
            if (!res.data.vehicles || (res.data.vehicles.length <= 0)) {
                return
            }
            
            for (let trade of res.data.vehicles) {
                this.createFormItem({
                    saleVehicleId: trade.id,
                    inventoryId: trade.inventoryId,
                    saleVehicleTransactionTypeId: trade.saleVehicleTransactionTypeId,
                    stockNumber: trade.inventory.stockNumber,
                    vinNumber: trade.inventory.vehicle.vinNumber,
                    year: trade.inventory.vehicle.modelYear,
                    make: trade.inventory.vehicle.make,
                    model: trade.inventory.vehicle.model,
                    tradeInPayoff: formatCurrency(parseFloat(trade.tradeInPayoff)),
                    lienholderContactId: trade.lienholderContactId,
                    tradeInAllowance: formatCurrency(parseFloat(trade.tradeInAllowance)),
                    tradeInAcv: formatCurrency(parseFloat(trade.tradeInAcv))
                })
            }

            this.disptachTradeChange()
        })
    }

    /**
     * Let any trade change listeners know new data is available.
     */
    private disptachTradeChange = () => {
        let allowance = 0
        let payoff = 0
        const ids = []

        if (!this.trades || this.trades.length <= 0) {
            this.tradeChangeEvent.next({allowance, payoff, ids})
            return
        }

        for (let trade of this.trades.controls) {
            allowance += clean(trade.get('tradeInAllowance').value)
            payoff += clean(trade.get('tradeInPayoff').value)
            ids.push(parseInt(trade.get('saleVehicleId').value))
        }

        this.tradeChangeEvent.next({allowance, payoff, ids})
    }
    
    /**
     * Acquire the inventory data from the store
     * @param { Number } inventoryId The id of the selected inventory item.
     */
    private fetchInventoryItem = async (inventoryId: number): Promise<Object> => {
        if (!inventoryId || (inventoryId <= 0)) {
            return
        }

        this.loading = true
        return await this.master.getAsync(`inventory/${inventoryId}`).then((res) => {
            this.loading = false
            if (!res || !res.data) {
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            return res.data.inventory
        })
    }
    
    /**
     * Create the trade form group item and add it to the form array,
     * create change listeners on the form fields
     * @param { Object } trade The trade object to add to the array.
     */
    private createFormItem = (trade: any) => {
        const formGroup = this.fb.group({
            saleVehicleId: [trade.saleVehicleId, [Validators.required]],
            inventoryId: [trade.inventoryId, [Validators.required]],
            saleVehicleTransactionTypeId: [trade.saleVehicleTransactionTypeId, [Validators.required]],
            stockNumber: [trade.stockNumber, [Validators.required]],
            vinNumber: [trade.vinNumber, [Validators.required]],
            year: [trade.year, [Validators.required]],
            make: [trade.make, [Validators.required]],
            model: [trade.model, [Validators.required]],
            tradeInPayoff: [trade.tradeInPayoff],
            lienholderContactId: [trade.lienholderContactId],
            tradeInAllowance: [trade.tradeInAllowance],
            tradeInAcv: [trade.tradeInAcv]
        })

        formGroup.valueChanges.subscribe(() => {
            if (this.watcher) {
                clearTimeout(this.watcher)
                this.watcher = null
            }

            this.watcher = setTimeout(() => {
                this.save(formGroup.value)
                this.watcher = null
            }, SAVE_DEBOUNCE_MILLIS)
        })

        if (this.isLocked) {
            formGroup.disable()
        }

        this.trades.push(formGroup)
    }

    /**
     * A saleCreated event from parent, assign saleId 
     * @param { number } saleId The id of the sale from the parent.
     */
    public saleCreated = (saleId: number): void => {
        if (saleId <= 0) {
            return
        }

        if (this.saleId != saleId) {
            this.saleId = saleId
        }
    }

    public buyerChange = (buyerId) => {
        this.buyerId = parseInt(buyerId) || 0
    }

    /**
     * Map db compatible object to post.
     * @param { Object } inventoryItem The inventory data.
     * @returns { Promise<Object> } A promise with the value of the trade object.
     */
    private mapTrade = async (inventoryItem): Promise<Object> => {
        return {
            saleId: this.saleId,
            stockNumber: inventoryItem.stockNumber,
            vinNumber: inventoryItem.vehicle.vinNumber,
            saleIdTradedOn: this.saleId,
            saleIdTradedFrom: this.saleId,
            saleVehicleTransactionTypeId: 2, // 2 = trade-in
            inventoryId: inventoryItem.id,
            vehicleId: inventoryItem.vehicle.id,
            year: inventoryItem.vehicle.modelYear,
            make: inventoryItem.vehicle.make,
            model: inventoryItem.vehicle.model,
            lienholderContactId: inventoryItem.lienholderContactId,
            tradeInPayoff: inventoryItem.tradeInPayoff,
            tradeInAllowance: inventoryItem.tradeInAllowance,
            tradeInAcv: inventoryItem.tradeInAcv,
            meta: null
        }
    }

    /**
     * A new vehicle was added to inventory as a trade-in
     * @param { Number } inventoryId The id of the added inventory item.
     */
    public vehicleAdded = async (inventoryId: number) => {
        if (inventoryId <= 0) {
            return
        }

        if (!this.lienholders || (this.lienholders.length <= 0)) {
            await this.fetchLienholders()
        }

        const inventoryItem = await this.fetchInventoryItem(inventoryId)
        if (!inventoryItem) {
            return
        }

        let trade = await this.mapTrade(inventoryItem)
        
        await this.save(trade) // need to await here so the trade object has the saleVehicleId
    }

    /**
     * Assign inventoryId to local var to load in modal
     * @param { Event } e The event that triggered the edit.
     * @param { Number } id The inventory id of the selected trade.
     */
    public edit = (e, id) => {
        this.inventoryId = id
    }

    /**
     * Save the new trade-in as a sale vehicle
     * @param { Object } trade The data to save to the store.
     */
    public save = async (trade: Object) => {
        if (this.saleId <= 0 || !trade || (trade['inventoryId'] <= 0)) {
            return
        }

        this.loading = true
        
        trade['saleId'] = this.saleId
        trade['tradeInPayoff'] = clean(trade['tradeInPayoff'])
        trade['tradeInAllowance'] = clean(trade['tradeInAllowance'])
        trade['tradeInAcv'] = clean(trade['tradeInAcv'])

        await this.fetchTransactionTypes()

        const tradeInType = this.saleVehicleTransactionTypes.find(el => el.name === TRADE_IN)        
        trade['saleVehicleTransactionTypeId'] = tradeInType ? tradeInType['id'] : 0       

        const identity = parseInt(trade['saleVehicleId'])
        if (!identity || isNaN(identity) || (identity <= 0)) {
            await this.add(trade)
            return
        }

        await this.modify(identity, trade)
    }

    /**
     * Create the new asle vehicle trade-in
     * @param trade 
     * @returns 
     */
    private add = async (trade: Object) => {
        if (!this.saleId || this.saleId <= 0) {
            return
        }

        return await this.master.post(`sales/${this.saleId}/vehicles`, { payload: trade }, (res) => {
            this.loading = false

            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.fetchSaleTrades()
        })
    }

    /**
     * Modify the exissting sale vehicle trade-in
     * @param id
     * @param trade 
     * @returns 
     */
    private modify = async (id: number, trade: Object) => {
        if (!this.saleId || this.saleId <= 0) {
            return
        }

        if (!id || (id <= 0)) {
            return
        }

        delete(trade['saleVehicleId'])

        return await this.master.put(`sales/${this.saleId}/vehicles/${id}`, {payload: trade}, (res) => {
            this.loading = false

            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return trade
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return trade
            }

            trade['saleVehicleId'] = res.data.id
            return trade
        })
    }
        
    /**
     * Assign index to delete for use
     * after confirmation modal
     * @param { Number } index The form array index of the selected item.
     */
    public selectForDelete = (index: number) => {
        if ((index < 0) || (index > this.trades.length-1)) {
            return
        }

        this.deleteIndex = index
        this.deleteSaleVehicleId = parseInt(this.trades.at(index).get('saleVehicleId').value)
    }

    /**
     * Remove an item from saleVehicles
     */
    private discardSaleVehicle = async () => {
        if (!this.deleteSaleVehicleId || (this.deleteSaleVehicleId <= 0)) {
            return
        }

        this.loading = true
        return await this.master.discard(`sales/${this.saleId}/vehicles/${this.deleteSaleVehicleId}`, (res) => {
            this.loading = false
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] })

            this.trades.removeAt(this.deleteIndex)
            this.tradeChangeEvent.emit()
            this.deleteIndex = -1
        });
    }
    
    /**
     * Remove a trade-in
     */
    public deleteTradeIn = (): void => {
        this.closeDiscardModal()

        if (this.deleteIndex < 0 || (this.deleteIndex > this.trades.length-1)) {
            return
        }

        this.discardSaleVehicle()
    }

    /**
     * Discard all trades associated with the sale, 
     * typically when the sale type is switched to 
     * wholesale.
     */
    private discardTrades = () => {
        if (!this.saleId || this.saleId <= 0) {
            return
        }

        this.master.discard(`sales/${this.saleId}/trades`, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
            }

            this.trades.clear()
            this.disptachTradeChange()
        })
    }

    /**
     * When certain inputs change value (allowance and payoff) 
     * we need to trigger a change event.
     */
    public changed = ($event) => {
        if (!$event || !$event.target) {
            return
        }

        if (this.trades.pristine) {
            return
        }
        
        this.disptachTradeChange()
        this.trades.markAsPristine()
    }

    private close = (): void => {
        const btn: HTMLButtonElement = (document.getElementById('btn-add-trade') as HTMLButtonElement)
        if (!btn) {
            return
        }

        btn.click()
    }

    private closeDiscardModal = (): void => {
        const btn = (document.getElementById('btn-close-modal-delete-trade') as HTMLButtonElement)
        if (!btn) {
            return
        }

        btn.click()
    }
    
}

