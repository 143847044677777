import { Component, Input, OnInit, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MasterService } from '../../../../../../services/master.service'
import { MessageService } from '../../../../../../services/message.service'
import { StoreService } from '../../../../../../services/store.service'
import * as languageLibrary from '../../../../../../services/language'
import { Subject } from 'rxjs';
import { SAVE_DEBOUNCE_MILLIS, TAX_TYPES, SALE_TYPES } from '../../../../../../constants/sale'
import { formatCurrency, formatPercent } from 'src/app/utils/format';
import { clean } from 'src/app/utils/numeric';

@Component({
    selector: 'ttl',
    templateUrl: './ttl.component.html',
    styleUrls: ['./ttl.component.scss']
})
export class TtlComponent implements OnInit, OnChanges {

    // =============================
    // todo: Variables
    // =============================

    //
    @Input() driveOutPrice: number = 0

    //
    @Input() isUnLock = true

    //
    @Input() totalSalePrice: number = 0

    //
    @Input() baseSalePrice: number = 0

    // Total cost of any sale accessories.
    @Input() accessories: number = 0

    //
    @Input() saleType: string = null

    //
    @Input() saleId = null

    //
    @Input() compute: Subject<any>

    //
    @Output() ttlEmiter$: EventEmitter<Object> = new EventEmitter()

    //
    public inventoryTaxRate: number = 0

    //
    public taxTypes: object[] = TAX_TYPES

    //  An associative array of sale types with the id as the key
    public saleTypes: Object = SALE_TYPES
    
    public feesId: number = 0

    // define loading status
    public loading: boolean = false

    // save the language
    public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'

    // set all words
    public words = languageLibrary.language

    /** A setTimeout watcher to delay computations during times of rapid changes (first load) */
    private watcher: ReturnType<typeof setTimeout> = null

    public form: FormGroup = null

    get feeable (): boolean { return ((this.saleType) && (['Cash deal', 'Outside', 'BHPH'].includes(this.saleTypes[this.saleType]))) }
    get outside(): boolean { return ((this.saleType) && (this.saleTypes[this.saleType] === 'Outside')) }
    get bhph(): boolean { return ((this.saleType) && (this.saleTypes[this.saleType] === 'BHPH')) }

    // =============================
    // todo: life cycles
    // =============================
    constructor(private master: MasterService, private ms: MessageService, private store: StoreService, private fb: FormBuilder) { 
        this.form = this.fb.group({
            outOfState: this.fb.control([0, []]),
            fees: this.fb.array([]),
            inventoryTaxType: this.fb.control([null, []]),
        })
    }

    ngOnInit() {
        this.read()

        this.form.get('outOfState').valueChanges.subscribe((newValue) => {
            if (!this.fees || (this.fees.length <= 0)) {
                return
            }

            this.rebuild()
            this.calculate()
            this.save()
        })

        this.form.get('inventoryTaxType').valueChanges.subscribe((newValue) => {
            if (!this.fees || (this.fees.length <= 0)) {
                return
            }

            this.updateInventoryTaxRate()
            this.calculate()
            this.save()
        })

        this.compute.subscribe(() => {
            this.reset()
            this.generate()
        })
    }

    public updateInventoryTaxRate = () => {
        let inventoryTaxRateFee = this.fees.controls.find(fee => fee.value['category'] == "Inventory Tax")
        let inventoryTaxRateFeeIndex = this.fees.controls.findIndex(fee => fee.value['category'] == "Inventory Tax")
        switch (this.form.get("inventoryTaxType").value) {
            case "MV":
                this.restore(inventoryTaxRateFeeIndex)
                break;
            case "SS":
                // inventoryTaxRateFee.get('custom').setValue(formatPercent(0), { emitEvent: true })
                break;
            case "FL":
                inventoryTaxRateFee.get('custom').setValue(formatPercent(0), { emitEvent: true })
                break;
            case "DL":
                inventoryTaxRateFee.get('custom').setValue(formatPercent(0), { emitEvent: true })
                break;

            default:
                break;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes) {
            return
        }

        const first = Object.values(changes).some(c => c.isFirstChange())
        if (first) {
            return
        }

        if (changes.isUnLock) {
            this.form.disable({ emitEvent: false })
            if (this.isUnLock) {
                this.form.enable({ emitEvent: false })
            }
        }

        if (changes.saleType) {
            this.reset()
            this.generate()
        }
        
        if (changes.totalSalePrice || changes.baseSalePrice) {
            if (this.watcher) {
                clearTimeout(this.watcher)
            }

            if (this.loading) {
                return
            }
            
            this.watcher = setTimeout(() => {
                this.reset()
                this.generate()
            }, SAVE_DEBOUNCE_MILLIS)
        }   
    }

    ngOnDestroy() {
        this.compute.unsubscribe()
    }

    /**
     * Helper method to consistently get the list of form fees as an Array.
     */
    get fees() {
        return this.form.controls['fees'] as FormArray;
    }
    
    // =============================
    // todo: functions
    // =============================

    /*
        reload and clean the inputs
    */
    public reset = (): void => {
        this.form.reset()
        this.fees.clear()
    }

    // =============================
    // read sale taxes and fees
    // =============================
    public read = (): void => {
        if (this.loading) {
            return
        }

        if (!this.feeable) {
            this.fees.clear()
            return
        }

        const identity = parseInt(this.saleId)
        if (isNaN(identity) || identity <= 0) {
            return
        }

        this.loading = true
        this.master.get(`sales/${identity}/fees`, res => {
            this.loading = false
            this.reset()

            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            if (res.data.saleTaxAndFees) {
                this.form.get('outOfState').setValue(res.data.saleTaxAndFees.outOfState, { emitEvent: false })
                this.form.get('inventoryTaxType').setValue(res.data.saleTaxAndFees.inventoryTaxType !== null ? res.data.saleTaxAndFees.inventoryTaxType : "MV", { emitEvent: false })
            }
            this.assemble(res.data)
        })
    }

    // =============================
    // Create sale taxes and fees
    // =============================
    public generate = (): void => {
        if (this.loading) {
            return
        }

        if (!this.saleId || (this.saleId <= 0)) {
            return
        }

        if (this.totalSalePrice <= 0) {
            return
        }

        if (!this.feeable) {
            this.fees.clear()
            return
        }

        this.loading = true
        this.master.post(`sales/${this.saleId}/fees`, { inventoryTaxType: this.form.get("inventoryTaxType").value }, res => {
            this.loading = false

            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            if (res.data.saleTaxAndFees) {
                this.form.get('outOfState').setValue(res.data.saleTaxAndFees.outOfState, { emitEvent: false })
                this.form.get('inventoryTaxType').setValue(res.data.saleTaxAndFees.inventoryTaxType, { emitEvent: false })
            }
            this.assemble(res.data)
            this.save()
        })
    }

    private assemble = (meta) => {
        if (!meta || !meta.saleTaxAndFees || !meta.saleTaxAndFees.data) {
            this.fees.clear()
            return
        }

        const saleTaxAndFees = meta.saleTaxAndFees.data
        this.feesId = parseInt(meta.saleTaxAndFees.id)

        saleTaxAndFees['Taxes'].forEach(el => {
            el['Amount'] = ((+el['Rate'] * (+this.totalSalePrice)) / 100)
            el['newRate'] = ((el['newRate'] !== undefined) && (el['newRate'] !== null)) ? (+el['newRate']).toFixed(4) : el['Rate']

            if (el['Category'] == 'Inventory Tax') {
                el['Amount'] = ((+el['Rate'] * (this.baseSalePrice + this.accessories)) / 100)
                this.inventoryTaxRate = clean((el['Rate'] || 0))
                if (this.form.get("inventoryTaxType").value == "SS"){
                    el['newRate'] = 0
                }
            }

            this.fees.push(this.fb.group({
                titleId: [el['TitleId'], []],
                name: [el['Name'], []],
                amount: [formatCurrency(el['Amount']), []],
                category: [el['Category'], []],
                rate: [formatPercent(el['Rate']), []],
                custom: [formatPercent(el['newRate']), []],
                formula: [el['FeeFormula'] || el['TaxFormula'], []],
                source: [el['SourceType'], []],
                tax: [true, []],
                sticky: [el['sticky'] || false]
            }))

            const fee = this.fees.at(this.fees.length-1)
            if (fee.get('rate').value != fee.get('custom').value) {
                fee.get('custom').markAsDirty()
            }
        })

        // initialize new fields of fees
        saleTaxAndFees['Fees'].forEach(el => {
            el['Amount'] = parseFloat(el['Amount']).toFixed(2)
            el['newAmount'] = ((el['newAmount'] !== undefined) && (el['newAmount'] !== null)) ? el['newAmount'] : el['Amount']
            

            this.fees.push(this.fb.group({
                titleId: [el['TitleId'], []],
                name: [el['Name'], []],
                amount: [formatCurrency(el['Amount']), []],
                custom: [formatCurrency(el['newAmount']), []],
                category: [el['Category'], []],
                rate: [formatPercent(el['Rate']), []],
                formula: [el['FeeFormula'], []],
                source: [el['SourceType'], []],
                tax: [false, []],
                sticky: [el['sticky'] || false]
            }))

            const fee = this.fees.at(this.fees.length-1)
            if (fee.get('amount').value != fee.get('custom').value) {
                fee.get('custom').markAsDirty()
            }
        })

        this.calculate()
    }

    /**
     * When outOfState changes we need to zero out or 
     * put back the old fees based on its current value.
     */
    private rebuild = () => {
        if (!this.fees || (this.fees.length <= 0) || !this.feesId || (this.feesId <= 0)) {
            return
        }

        const stateSources = ['state', 'county', 'city']

        for (let fee of this.fees.controls) {
            const source: string = fee.get('source').value || ''
            if (!source || !stateSources.includes(source)) {
                continue
            }

            if (fee.get('sticky').value == true) {
                continue
            }

            const isRate: boolean = (fee.get('formula').value && (fee.get('formula').value.length > 0))

            if (this.form.get('outOfState').value) {
                if (isRate) {
                    fee.get('custom').setValue(formatPercent(0))
                    continue
                }

                fee.get('custom').setValue(formatCurrency(0))
                continue
            }

            if (isRate) {
                fee.get('custom').setValue(fee.get('rate').value)
                continue
            }

            fee.get('custom').setValue(fee.get('amount').value)
        }
    }

    // todo: recalculate
    public calculate = () => {
        if (!this.fees || (this.fees.length <= 0) || !this.feesId || (this.feesId <= 0) && this.feeable) {
            return
        }

        if (!this.feeable) {
            this.fees.clear()
        }

        let total: number = 0
        let salesTax: number = 0
        let useTax: number = 0
        let salesTaxRate: number = 0
        let useTaxRate: number = 0
        const fees = []

        for (let fee of this.fees.controls) {
            const isTax: boolean = (fee.get('tax').value == true)
            const name: string = fee.get('name').value
            const category = fee.get('category').value
            
            if (isTax) {
                const rate = clean(fee.get('custom').value)
                fee.get('amount').setValue(formatCurrency(this.totalSalePrice * (rate / 100)))

                switch (category) {
                    case 'Sales Tax':
                        salesTax = clean(fee.get('amount').value)
                        salesTaxRate = rate
                        break
                    case 'Inventory Tax':
                        fee.get('amount').setValue(formatCurrency((this.baseSalePrice + this.accessories) * (rate / 100)))
                        break
                    case 'Use Tax':
                        useTax = clean(fee.get('amount').value)
                        useTaxRate = rate
                        break
                }

                total += clean(fee.get('amount').value)

                continue
            }

            const formula = fee.get('formula').value
            if (formula && (formula.length > 0)) { // we have a calculated fee
                const rate = clean(fee.get('custom').value)
                fee.get('amount').setValue(formatCurrency(this.baseSalePrice * (rate / 100)))

                fees.push({
                    TitleId: fee.get('titleId').value,
                    Name: name,
                    Amount:	clean(fee.get('amount').value),
                    newAmount: clean(fee.get('amount').value),
                    Category: fee.get('category').value,
                    Rate: rate,
                    newRate: clean(fee.get('custom').value),
                    FeeFormula:	fee.get('formula').value,
                    SourceType: fee.get('source').value
                })

                total += clean(fee.get('amount').value)

                continue
            }

            total += clean(fee.get('custom').value)

            fees.push({
                TitleId: fee.get('titleId').value,
                Name: name,
                Amount:	clean(fee.get('amount').value),
                newAmount: clean(fee.get('custom').value),
                Category: fee.get('category').value,
                Rate: clean(fee.get('rate').value),
                FeeFormula:	fee.get('formula').value,
                SourceType: fee.get('source').value
            })
        }

        this.ttlEmiter$.emit({ value: total, salesTax: salesTax + useTax, salesTaxRate: salesTaxRate + useTaxRate, fees: fees, save: true, inventoryTaxRate: this.inventoryTaxRate })
    }

    // =============================
    // Update taxes and fees record in storage.
    // =============================
    public save = (): void => {
        if (!this.fees || (this.fees.length <= 0) || !this.feesId || (this.feesId <= 0)) {
            return
        }

        const meta = { Taxes: [], Fees: [] }

        for (let fee of this.fees.controls) {
            const isTax: boolean = (fee.get('tax').value == true)
            const isCalculated: boolean = ((fee.get('formula').value) && (fee.get('formula').value.length > 0))
            
            if (isTax) {
                meta.Taxes.push({
                    TitleId: fee.get('titleId').value,
                    Name: fee.get('name').value,
                    Amount:	clean(fee.get('amount').value),
                    newAmount: clean(fee.get('amount').value),
                    Category: fee.get('category').value,
                    Rate: clean(fee.get('rate').value),
                    newRate: clean(fee.get('custom').value),
                    TaxFormula:	fee.get('formula').value,
                    SourceType: fee.get('source').value
                })

                continue
            }

            if (isCalculated) { // Inventory Tax and similar
                meta.Fees.push({
                    TitleId: fee.get('titleId').value,
                    Name: fee.get('name').value,
                    Amount:	clean(fee.get('amount').value),
                    newAmount: clean(fee.get('amount').value),
                    Category: fee.get('category').value,
                    Rate: clean(fee.get('rate').value),
                    newRate: clean(fee.get('custom').value),
                    FeeFormula:	fee.get('formula').value,
                    SourceType: fee.get('source').value
                })

                continue
            }

            meta.Fees.push({
                TitleId: fee.get('titleId').value,
                Name: fee.get('name').value,
                Amount:	clean(fee.get('amount').value),
                newAmount: clean(fee.get('custom').value),
                Category: fee.get('category').value,
                Rate: clean(fee.get('rate').value),
                FeeFormula:	fee.get('formula').value,
                SourceType: fee.get('source').value
            })
        }

        this.master.put(`sales/${this.saleId}/fees/${this.feesId}`, { meta: meta, outOfState: this.form.get('outOfState').value, inventoryTaxType: this.form.get('inventoryTaxType').value }, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }
        })
    }

    public changed = (e): void => {
        this.calculate()
        this.save()
    }

    /**
     * Restore the default value for a fee.
     * @param index The index of the fee to restore
     */
    public restore = (index: number): void => {
        if ((index < 0) || (index > this.fees.length - 1)) {
            return
        }

        const formula = this.fees.at(index).get('formula').value
        const rateBased = (formula && (formula.length > 0))

        if (rateBased) {
            this.fees.at(index).get('custom').setValue(this.fees.at(index).get('rate').value)
            this.fees.at(index).get('custom').markAsPristine()
            this.calculate()
            return    
        }

        this.fees.at(index).get('custom').setValue(this.fees.at(index).get('amount').value)
        this.fees.at(index).get('custom').markAsPristine()
        this.calculate()
    }

    /**
     * Remove a communication method from the form array
     * @param index The index of the fee to discard
     */
    public discard = (index: number): void => {
        if ((index < 0) || (index > this.fees.length - 1)) {
            return
        }

        this.fees.removeAt(index)
    }

}
