import { Component, OnInit, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as words from '../../../../services/language'
import { MessageService } from '../../../../services/message.service';
import { MasterService } from '../../../../services/master.service';
import { StoreService } from '../../../../services/store.service';
import { Subject } from "rxjs";
import { ago, daysAgo } from 'src/app/utils/date';

@Component({
    selector: 'pays',
    templateUrl: './pays.component.html',
    styleUrls: ['./pays.component.scss']
})
export class PaysComponent implements OnInit {

    // Unique internal identity of the contact whose soon due payments will be queried for, overrides the lotId.
    @Input() contactId: number = 0

    // Currently selected language
    @Input() language: string = 'EN'

    // Word replacements for each language.
    @Input() words: Object = words.language

    // A payment was taken so lets update our available payments and any suppporting displays.
    @Input() paymentTaken: Subject<Object>

    // Communicate payment updates to the parent component.
    @Output() paysChanged: EventEmitter<Object> = new EventEmitter()

    //
    public payments: Array<any> = []

    // Groups of related payments so we can build tabs.
    public groups: Object = {}

    //
    public records: number = 0

    //
    public page: number = 1

    // Indicates whether the data is currently loading.
    public loading: boolean = false

    // Indicates whether any more data is available
    public exhausted: boolean = false

    // A handle to a payment taken subscription so we can unsubscribe later.
    private paymentTakenWatcher: any = null

    get groupKeys() { return Object.keys(this.groups) }

    constructor (private route: ActivatedRoute, private ms: MessageService, private master: MasterService, private store: StoreService) {}

    ngOnInit() {
        this.fetch()

        this.paymentTakenWatcher = this.paymentTaken.subscribe((payment) => {
            this.reset()
            this.fetch()
        })
    }

    ngOnDestroy() {
        if (this.paymentTakenWatcher) {
            this.paymentTakenWatcher.unsubscribe()
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes) {
            return
        }

        const first = Object.values(changes).some(c => c.isFirstChange())
        if (first) {
            return
        }

        if (changes.contactId) {
            this.reset()
            this.fetch()
        }
    }

    private reset = (): void => {
        this.page = 1
        this.exhausted = false
        this.records = 0
    }

    /** 
     * Fetch the the pending payments for the current contact.
    */
    private fetch = (): void => {
        if (this.loading) {
            return
        }

        if (this.exhausted) { // currently showing all available records?
            return
        }

        let contactParam = ''
        if (this.contactId && (this.contactId > 0)) {
            contactParam = `&contact=${this.contactId}`
        }

        this.loading = true
        if (this.page === 1) {
            this.payments = []
        }
        
        this.master.get(`payments/expected?page=${this.page}&sort=due|ASC${contactParam}&statuses=unpaid&ipp=9`, (res) => {
            this.loading = false
  
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }
  
            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }
  
            this.records = res.data.records
            this.exhausted = (res.data.next === null)
  
            if (this.page > 1) {
                this.payments = this.payments.concat(res.data.payments)
                this.constructGroups()
                return
            }
  
            this.payments = res.data.payments
            this.constructGroups()
        })
    }

    /**
     * Groups payments into their respective categories and 
     * loans for the tabbed display in the UI.
     */
    private constructGroups = () => {
        if (!this.payments || (this.payments.length <= 0)) {
            this.groups = {}
            return
        }
        
        if (this.page <= 1) {
            this.groups = {}
        }

        // Keep track of the groups we already have a payment for in the pays object
        const processed = {}

        const pays = {
            past: {
                total: 0,
                items: []
            },
            today: {
                total: 0,
                items: []
            },
            next: {
                total: 0,
                items: []
            }
        }

        for (const payment of this.payments) {
            const key = `${payment.saleId || 0}-${payment.serviceId || 0}-${payment.insuranceId || 0}`
            if (!this.groups[key]) {
                this.groups[key] = {}
                this.groups[key].payments = []
                this.groups[key].terms = null
                this.groups[key].url = payment.url
                this.groups[key].category = payment.category
                this.groups[key].title = `${payment.contact}`
                this.groups[key].subtitle = ''

                switch (payment.category.toLowerCase()) {
                    case 'mv loan':
                    case 'initial down':
                    case 'deferred down':
                    case 'deposit':
                    case 'service':
                        this.groups[key].title = `${payment.vehicle}`
                        this.groups[key].subtitle = `${payment.stock} | ${payment.vin}`
                        break
                }

                if (payment.saleId) {
                    this.readTerms(key, payment.saleId)
                }
            }

            this.groups[key].payments.push(payment)

            const timeline = ago(payment.due)
            if (processed[key] && (['soon', ''].includes(timeline))) {
                continue
            }

            processed[key] = true

            switch (timeline) {
                case 'past':
                    pays.past.total += parseFloat(payment.amount)
                    pays.past.items.push(payment)

                    pays.today.total += parseFloat(payment.amount)
                    pays.today.items.push(payment)
                    break
                case 'today':
                    pays.today.total += parseFloat(payment.amount)
                    pays.today.items.push(payment)
                    break
                default: // soon (next)
                    pays.next.total += parseFloat(payment.amount)
                    pays.next.items.push(payment)
                    break
            }
        }

        this.paysChanged.emit(pays)
    }

    /**
     * Obtain the active terms for the given sale and store them in the given group.
     * @param group The group key where the terms will be stored if available.
     * @param saleId The sale identity to query for the active terms.
     */
    private readTerms = (group: string, saleId: number): void => {
        if (!saleId || (saleId <= 0)) {
            return
        }
        
        this.master.get(`sales/${saleId}/terms`, (res) => {
            this.loading = false
  
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }
  
            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            if (!this.groups || !this.groups[group]) {
                return
            }

            this.groups[group].terms = {
                payoff: parseFloat(res.data.saleTerms['loanPayoff']),
                balance: parseFloat(res.data.saleTerms['principalBalance']),
                method: res.data.saleTerms['collectionMethod'],
                tax: parseFloat(res.data.saleTerms['taxBalance']),
                apr: parseFloat(res.data.saleTerms['APR'])
            }
        })
    }

    /**
     * Determine an tab icon to show based on the payment category.
     * @param category The payment category.
     */
    public icon = (category: string) => {
        if (!category || (category.length <= 0)) {
            return 'monetization_on'
        }

        switch(category.toLowerCase()) {
            case 'mv loan':
                return 'directions_car'
            case 'service':
                return 'car_repair'
            default:
                return 'monetization_on'
        }
    }

    public dueToClass = (due: string): string => {
        return ago(due)
    }

    public daysPastDue = (due: string): number => {
        return daysAgo(due)
    }
}
