import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MasterService } from '../../../../../../../../services/master.service'
import { StoreService } from '../../../../../../../../services/store.service'
import { MessageService } from '../../../../../../../../services/message.service'
import * as languageLibrary from '../../../../../../../../services/language'
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as moment from 'moment'
import { PAYMENT_CYCLE_OPTIONS } from 'src/app/constants/sale';

@Component({
  selector: 'card-compare',
  templateUrl: './card-compare.component.html',
  styleUrls: ['./card-compare.component.scss']
})
export class CardCompareComponent implements OnInit {
  // **********************
  // variables
  // *******************
  
  @Input() inventoryTaxRate:number=0
  
  //
  @Input() paymentsList=[]
  
  //
  @Input() fees: Object[] = []
  
  //
  @Input() catalogCycles = []
  
  //
  @Input() loanTerms = []
  
  //
  @Output() loanTermsCompare$: EventEmitter<object> = new EventEmitter()
  
  //
  @Output() loanTermsCompareChoose$: EventEmitter<object> = new EventEmitter()
  
  //
  @Input() saleId = ''
  
  //
  @Input() saleType = ''
  
  //
  @Input() totalSalePrice = ''

  //
  @Input() driveOutPrice = ''
  
  //
  @Input() buyer = ''
  
  //
  @Input() inventory = ''

  //
  @Input() tradeAllowance: number = 0

  //
  @Input() tradePayoff: number = 0
  
  //
  @Input() initialDown: Object[] = []
  
  //
  @Input() deferredDownPayments: Object[] = []
  
  //
  public loading: boolean = false
  
  //
  public dictionaryCycles = PAYMENT_CYCLE_OPTIONS
  
  //
  public edit: boolean = false
  
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  
  // set all words
  public words = languageLibrary.language
  
  //
  public minDate:string=moment().format('yyyy-MM-DD')

  // A handle to navbar event messages so we can unsubscribe later.
  private navbarWatcher: any = null
  
  //
  public information = new FormGroup({
    id: new FormControl(null, []),
    optionIndex: new FormControl(-1, [Validators.required]),
    saleId: new FormControl('', [Validators.required]),
    contractRate: new FormControl(0, []),
    months: new FormControl(0, []),
    scheduledPaymentAmount: new FormControl('', []),
    paymentCycle: new FormControl('Monthly', [Validators.required]),
    firstScheduledPaymentDate: new FormControl(moment().format('yyyy-MM-DD'), [Validators.required]),
    numberOfRegularPayments: new FormControl('', []),
    lastPaymentAmount: new FormControl({value: null, readonly: true}, []),
    totalPaymentAmount: new FormControl({value: null, readonly: true}, []),
    response: new FormControl(null, []),
    request: new FormControl(null, [])
  })

  // **********************
  // functions
  // *******************
  
  // =============================
  // ? get type of solve
  // =============================
  public getTypeSolve = (apr: number, paymentAmount: number, numberOfPmt: number): string => {
    if (apr && paymentAmount) {
      return 'Term'   
    }
    
    if (numberOfPmt && paymentAmount) {
      return 'Rate'
    }

    if (numberOfPmt && apr) {
      return 'Payment'
    } 
    
    return 'error'
  }

  // =============================
  // ? get type of engine
  // =============================
  public getEngine = (engine: string): string => {
    switch (engine) {
      case 'Diesel':
        return engine = 'Dl'
      case 'Gas':
        return engine = 'G'
      case 'Electric':
        return engine = 'E'
      case 'Hybrid':
        return engine = 'H'
      default:
        return engine = 'G'
    }
  }

  // =============================
  // ? create taxes
  // =============================
  public calculate = async () => {
    if (this.store.userAccount['corporateAddresses'].length == 0) {
      this.ms.sendMessage("alert", { type: "danger", text: 'Corporate address not exist' });
      return
    }

    if (!this.inventory['stockNumber']) {
      this.ms.sendMessage("alert", { type: "danger", text: 'Vehicle not selected' });
      return
    }

    if (this.buyer['county'] == "") {
      this.ms.sendMessage("alert", { type: "danger", text: 'Buyer not selected or not address' });
      return
    }

    if (this.inventory['datePurchased'] == '') {
      this.ms.sendMessage("alert", { type: "danger", text: 'Data purchased not defined' });
      return
    }

    if (parseFloat(this.totalSalePrice) <= 0) {
      this.ms.sendMessage("alert", { type: "danger", text: 'Base sale price most be greater than 0' });
      return
    }

    //
    let engine = this.getEngine(this.inventory['vehicle']['engine'])
    let paramFees = this.initialDown.concat(this.fees)

    paramFees.push({Amount: this.inventoryTaxRate, TitleId: '1069', Base:'Cash'})
    let lotSelected = this.store.userAccount['user'].lots.find(el => el['id'] == this.store.lotSelected)
    let typeOfSolve = this.getTypeSolve(this.information.value['contractRate'], this.information.value['scheduledPaymentAmount'], this.information.value['numberOfRegularPayments'])

    if (typeOfSolve == 'error') {
      this.ms.sendMessage("alert", { type: "danger", text: 'Invalid values on APR, amount by month or number of payments' });
      return
    }

    let cycle = this.catalogCycles.find(el => (this.information.value['paymentCycle']) == el['name'])
    let desiredPayment = parseFloat(this.information.value['scheduledPaymentAmount'])
    if (!desiredPayment || isNaN(desiredPayment) || (desiredPayment <= 0)) {
      desiredPayment = 0
    }

    const initialDown = this.initialDown.reduce((total, down) => { return total + down['Amount'] }, 0)
    const deferredDown = this.deferredDownPayments.reduce((total, down) => { return total + down['Payment'] }, 0)
    const financed = parseFloat(this.driveOutPrice) + this.tradePayoff - (Math.abs(initialDown) + Math.abs(deferredDown))

    this.loading = true
    let res = await this.master.getAsync(`vendors/terms/compute?saleId=${this.saleId}&solve=${typeOfSolve}&financed=${financed}&apr=${this.information.value['contractRate']}&frequency=${cycle.name.toLowerCase()}&periods=${parseInt(this.information.value['numberOfRegularPayments'])}&first=${this.information.value['firstScheduledPaymentDate']}&pay=${desiredPayment}`)
    this.loading = false

    if (!res || !res.data) {
      this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
      return
    }

    this.information.setValue({
      id: this.information.value['id'],
      optionIndex: this.information.value['optionIndex'],
      saleId: this.information.value['saleId'],
      contractRate: res.data.apr,
      months: this.getDiffDate(res.data.first, res.data.last, this.information.value['paymentCycle']),
      scheduledPaymentAmount: res.data.payment,
      paymentCycle: cycle.id,
      firstScheduledPaymentDate: moment(res.data.first).format('yyyy-MM-DD'),
      numberOfRegularPayments: res.data.count,
      lastPaymentAmount: res.data.final,
      totalPaymentAmount: res.data.payments,
      response: null,
      request: null
    })

    this.edit = false

    this.loanTermsCompare$.emit({ value: this.information.value })
  }

  //
  public getDiffDate = (dateStart, dateEnd, cycle) => {
    switch (cycle) {
      case 'Weekly':
        return moment(dateEnd).diff(dateStart, 'weeks')
      case 'Semimonthly':
        return moment(dateEnd).diff(dateStart, 'weeks')
      case 'Monthly':
        return moment(dateEnd).diff(dateStart, 'months')
      case 'Bi-Weekly':
        return moment(dateEnd).diff(dateStart, 'months')
    }
  }

  public chooseTerm = (isDelete) => {
    this.loanTermsCompareChoose$.emit({ value: this.information.value, isDelete: isDelete })
  }

  //
  // public checkNumberOfPayments = (isNumberOfPayments: boolean): void => {
  //   if (isNumberOfPayments) {
  //     this.information.get('scheduledPaymentAmount').setValue(0)
  //   } else {
  //     this.information.get('numberOfRegularPayments').setValue(0)
  //   }
  // }
  // **********************
  // life cycles
  // *******************
  constructor(private master: MasterService, private ms: MessageService, private store: StoreService) {
    this.navbarWatcher = this.ms.channelComponents$.subscribe(res => {
      switch (res.message) {
        case 'setPermissions':
          // this.lots = this.paymentTerm['lotsInCorp']
          break
        case 'changeLanguage':
          this.language = res.value
          break
      }
    })
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(changes.payments && (changes.payments.currentValue) && (changes.payments.currentValue.length > 0)){
      this.paymentsList=changes.payments.currentValue.filter(el=>el['amountDue']>0)
      const paymentDate=moment(this.paymentsList[this.paymentsList.length-1].due).add(1,'month').format('yyyy-MM-DD')

      this.minDate=paymentDate
      this.information.value['firstScheduledPaymentDate']=paymentDate
      this.information.get('firstScheduledPaymentDate').setValue(paymentDate)
    }

    if (changes.loanTerms) {
      let cycle = this.catalogCycles.find(el => (changes.loanTerms.currentValue['paymentCycle'] || 1) == el['id'])

      this.information.setValue({
        id: changes.loanTerms.currentValue['id'],
        optionIndex: changes.loanTerms.currentValue['optionIndex'],
        saleId: changes.loanTerms.currentValue['saleId'],
        contractRate: changes.loanTerms.currentValue.contractRate,
        months: changes.loanTerms.currentValue.months,
        scheduledPaymentAmount: changes.loanTerms.currentValue.scheduledPaymentAmount,
        paymentCycle: cycle.name,
        firstScheduledPaymentDate: moment(changes.loanTerms.currentValue.firstScheduledPaymentDate).format('yyyy-MM-DD'),
        numberOfRegularPayments: changes.loanTerms.currentValue.numberOfRegularPayments,
        lastPaymentAmount: changes.loanTerms.currentValue.lastPaymentAmount,
        totalPaymentAmount: changes.loanTerms.currentValue.totalPaymentAmount,
        response: changes.loanTerms.currentValue.response?changes.loanTerms.currentValue.response:'',
        request: changes.loanTerms.currentValue.request?changes.loanTerms.currentValue.request:''
      })
    }

    if (changes.deferredDownPayments && (changes.deferredDownPayments.currentValue) && (changes.deferredDownPayments.currentValue.length > 0)) {
      this.deferredDownPayments = changes.deferredDownPayments.currentValue.map(el => { return { "Payment": el.amountDue, "PaymentDate": el.due, "Handling": "CashDown" } })
    }

    if (changes.initialDown && (changes.initialDown.currentValue) && (changes.initialDown.currentValue.length > 0)) {
      this.initialDown = changes.initialDown.currentValue.map(el => { return { "Amount": -el.amountDue, "TitleId": "10013" } })
    }

    if (changes.fees && (changes.fees.currentValue) && (changes.fees.currentValue.length > 0)) {
      this.fees = changes.fees.currentValue.map(el => { return { "Amount": el['newAmount'] == '' ? +el['Amount'] : +el['newAmount'], "TitleId": el['TitleId'] } })
    }
  }

  ngOnDestroy() {
    if (!this.navbarWatcher) {
      return
    }

    this.navbarWatcher.unsubscribe()
  }
}
