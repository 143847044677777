import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MasterService } from '../../../../services/master.service';
import { MessageService } from '../../../../services/message.service';
import { StoreService } from '../../../../services/store.service'
import { SEARCH_DEBOUNCE_MILLIS } from 'src/app/constants/list';
//externals

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})

export class LoginFormComponent implements OnInit {
  
  // define the subdomain
  public subDomain = false
  
  // define if password input is type text or password
  public typePassword: string = 'password'
  
  // Indicates the current corp URL has been checked via an API call
  public checked: boolean = false;
  
  // display the loading state
  public loading: boolean = false
  
  // define if the corporate is valid
  public found: boolean = null;
  
  // 
  private timeOutID: any;

  // 
  @Output() image$: EventEmitter<Object> = new EventEmitter();

  public signInGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email, Validators.maxLength(150)]),
    password: new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]),
    corporateURL: new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern('[A-Za-z0-9_-]{2,150}')])
  })

  // get information of inputs
  get email() { return this.signInGroup.get('email') }
  get password() { return this.signInGroup.get('password') }
  get corporateURL() { return this.signInGroup.get('corporateURL') }



  // functions
  // change the input type, passsword or text
  public changeTypePassword(): void {
    if (this.typePassword == 'text') {
      this.typePassword = 'password'
    } else {
      this.typePassword = 'text'
    }
  }

  // init
  ngOnInit() {
  }

  constructor(
    private master: MasterService,
    private ms: MessageService,
    private router: Router,
    private store: StoreService
  ) {
    let domain = window.location.hostname
    if (domain === 'localhost') {
      domain = 'rockitdms.com'
    }

    const parts = domain.split('.')
    if (parts.length <= 2) { // no subdomain?
      return
    }

    this.subDomain = true
    this.corporateURL.setValue(parts[0])
    this.checkCorpURL()
  }

  public authenticate() {
    this.loading = true

    this.master.post('authenticate', { information: this.signInGroup.value }, res => {
      this.loading = false

      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: 'An unknown error has occurred.' })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "warning", text: res.data.error })
        return
      }
      
      this.master.reset() // reset
      localStorage.setItem('token', res.data.responseData.token)
      this.master.token = res.data.responseData.token
      this.store.userAccount = {}
      this.router.navigate([`dashboard`])
    })
  }

  public checkCorpURL() {
    this.checked = false

    if (this.timeOutID) {
      clearTimeout(this.timeOutID)
      this.timeOutID = null
    }

    if (this.corporateURL.invalid) {
      return
    }

    this.timeOutID = setTimeout(() => {
      this.read()
    }, SEARCH_DEBOUNCE_MILLIS)
  }

  private read = () => {
    this.master.get(`corporation/${this.corporateURL.value}`, res =>{
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: 'An unknown error has occurred.' })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "warning", text: res.data.error })
        return
      }

      this.checked = true

      if (!res.data.corporate) {
        this.found = false
        return
      }

      this.found = true
      this.image$.emit({message: 'setImage', image: { img: res.data.corporate.corporateLogoURL, display: res.data.corporate.displayLogoInternal }})

    })
  }

}
