import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
// services
import { MasterService } from "../../../../services/master.service";
import { MessageService } from "../../../../services/message.service";

import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as languageLibrary from '../../../../services/language'

@Component({
  selector: "table-user-options",
  templateUrl: "./table-user-options.component.html",
  styleUrls: ["./table-user-options.component.scss"],
})
export class TableUserOptionsComponent implements OnInit {
  /*
* variables
*/
  // save the language
  @Input() language: string = 'EN'
  // set all words
  public words = languageLibrary.language
  // save the users has been selecteds
  @Input() usersSelecteds: Object[] = [];
  // define if is loading
  public loading: boolean = false;
  // define if is sending
  public sending: boolean = false;
  // define if form is ready
  public formReady: boolean = false;
  public usersForm = new FormGroup({});

  // A handle to navbar event messages so we can unsubscribe later.
  private navbarWatcher: any = null
  
  //
  @Output() tableEmitter$: EventEmitter<Object> = new EventEmitter()
  /*
   * functions
   */
  // deactive user
  public deactivateUsers = (users?: Object[]): void => {
    let data = [];
    users.forEach((el) => data.push(el['email']));
    this.master.post(
      "deactivateUser",
      { emails: data, type: "delete" },
      (res) => {
        if (res) {
          if (res.status == 200) {
            this.tableEmitter$.emit({ message: "updateUserList" });
            // this.usersSelecteds = []
            // this.getUsers()
          } else {
            this.ms.sendMessage("alert", {
              type: "danger",
              text: res.data.error,
            });
          }
        }
      }
    );
  };
  // find the user in the data base
  public isEmailFree = (email, callback) => {
    // if email valid
    this.master.post("attributesExists", { email: email }, (res) => {
      if (res) {
        if (res.status == 200) {
          // status 200 when the email not exist
          callback(true);
        } else {
          this.ms.sendMessage("alert", {
            type: "danger",
            text: res.data.error,
          });
          // status 400 when the email is already use
          callback(false);
        }
      } else {
        this.ms.sendMessage("alert", {
          type: "danger",
          text: this.words[this.language]['apiNoResponse'],
        });
      }
    });
  };

  //  save change of user email (plural) pending
  public copyUpdate = (id, email, emailForm, inputLink, index, copyLink, callback?): void => {
    this.loading = true
    if (email === this.usersForm.value[emailForm]) {
      // ? only copy link
      // generate a token link
      if (copyLink) {
        this.copyLink(email, id, inputLink, res => {
          this.loading = false
        })
      }
      // return callback if success
      if (callback) {
        callback(true)
      }
    } else {
      //todo: update and copy link
      this.isEmailFree(this.usersForm.value[emailForm], free => {
        if (free) {
          // * is free
          this.updateUser(this.usersForm.value[emailForm], id, res => {
            if (res) {
              // * is correct
              this.usersSelecteds[index]['email'] = this.usersForm.value[emailForm]
              // generate copy link
              if (copyLink) {
                this.copyLink(this.usersForm.value[emailForm], id, inputLink, res => {
                  this.loading = false
                })
              }
              // return callback if success
              if (callback) {
                callback(true)
              }
            } else {
              // ! if error
              this.loading = false
              // return callback if error
              if (callback) {
                callback(false)
              }
            }
          })
        } else {
          // ! isn´t free
          this.loading = false
          // return callback if error
          if (callback) {
            callback(false)
          }
        }
      })
    }
  };

  // update email
  public updateUser = (email, id, callback): void => {
    this.master.post("updateUser", { id: id, email: email }, (res) => {
      if (res) {
        if (res.status == 200) {
          this.ms.sendMessage("alert", {
            type: "success",
            text: this.words[this.language]['success'],
          });
          callback(true);
        } else {
          this.ms.sendMessage("alert", {
            type: "danger",
            text: res.data.error,
          });
          callback(false);
        }
      } else {
        this.ms.sendMessage("alert", {
          type: "danger",
          text: this.words[this.language]['apiNoResponse'],
        });
        callback(false);
      }
    });
  };
  // set username
  public setUserName = (
    id,
    firstName,
    lastName,
    firstNameForm,
    lastNameForm,
    index,
    callback?
  ): void => {
    if (
      firstName !== this.usersForm.value[firstNameForm] ||
      lastName !== this.usersForm.value[lastNameForm]
    ) {
      this.updateUserName(
        id,
        this.usersForm.value[firstNameForm],
        this.usersForm.value[lastNameForm],
        (res) => {
          if (res) {
            // * is correct
            this.usersSelecteds[index]["firstName"] = this.usersForm.value[
              firstNameForm
            ];
            this.usersSelecteds[index]["lastName"] = this.usersForm.value[
              lastNameForm
            ];
          } else {
            // ! if error
            this.loading = false;
            // return callback if error
            if (callback) {
              callback(false);
            }
          }
        }
      );
    }
  };

  // update user first and last name
  public updateUserName = (id, firstName, lastName, callback): void => {
    this.master.post(
      "updateUser",
      { id: id, firstName: firstName, lastName: lastName },
      (res) => {
        if (res) {
          if (res.status == 200) {
            this.ms.sendMessage("alert", {
              type: "success",
              text: this.words[this.language]['success'],
            });
            callback(true);
          } else {
            this.ms.sendMessage("alert", {
              type: "danger",
              text: res.data.error,
            });
            callback(false);
          }
        } else {
          this.ms.sendMessage("alert", {
            type: "danger",
            text: this.words[this.language]['apiNoResponse'],
          });
          callback(false);
        }
      }
    );
  };

  // copy link
  public copyLink = (email, id, inputLink, callback): void => {
    this.sending = true;
    this.master.post(
      "tokenLink",
      {
        email: email,
        id: id,
      },
      (res) => {
        if (res) {
          if (res.status == 200) {
            // get token and set input token, after copy to clipboard
            let input = document.getElementById(inputLink) as HTMLInputElement;
            input.value = "";
            input.value = res.data.link;
            input.select();
            setTimeout(() => {
              document.execCommand("copy");
              this.sending = false;
              this.ms.sendMessage("alert", {
                type: "success",
                text: this.words[this.language]['success'],
              });
              callback(true);
            }, 500);
          } else {
            this.sending = false;
            this.ms.sendMessage("alert", {
              type: "danger",
              text: res.data.error,
            });
            callback(false);
          }
        } else {
          this.sending = false;
          this.ms.sendMessage("alert", {
            type: "danger",
            text: this.words[this.language]['apiNoResponse'],
          });
          callback(false);
        }
      }
    );
  };
  // sendInvitations
  public sendInvitations = (): void => {
    let success = 0;
    this.loading = true;
    for (let index = 0; index < this.usersSelecteds.length; index++) {
      this.setUserName(
        this.usersSelecteds[index]["id"],
        this.usersSelecteds[index]["firstName"],
        this.usersSelecteds[index]["lastName"],
        "firstName_" + this.usersSelecteds[index]["id"],
        "lastName_" + this.usersSelecteds[index]["id"],
        index,
        false
      );
      this.copyUpdate(
        this.usersSelecteds[index]["id"],
        this.usersSelecteds[index]["email"],
        "email_" + this.usersSelecteds[index]["id"],
        "copy_links",
        index,
        false,
        (res) => {
          if (res) {
            success = success + 1;
            if (success == this.usersSelecteds.length) {
              this.resendEmail((res) => {
                this.loading = false;
              });
            }
          } else {
            this.ms.sendMessage("alert", {
              type: "danger",
              text: "there was a problem resending invitations",
            });
            this.loading = false;
          }
        }
      );
    }
  };
  // resend email
  public resendEmail = (callback): void => {
    let emails = [];
    this.sending = true;
    this.usersSelecteds.forEach((el) => emails.push(el["email"]));
    this.master.post("resendMail", { emails: emails }, (res) => {
      if (res) {
        if (res.status == 200) {
          this.sending = false;
          this.ms.sendMessage("alert", {
            type: "success",
            text: "The invite email has been successfully sent.",
          });
          callback();
        } else {
          this.ms.sendMessage("alert", {
            type: "danger",
            text: res.data.error,
          });
          this.sending = false;
          callback();
        }
      } else {
        this.ms.sendMessage("alert", {
          type: "danger",
          text: this.words[this.language]['apiNoResponse'],
        });
        this.sending = false;
        callback();
      }
    });
  };

  // create a form
  public newForm = (arreglo: object[]): void => {
    arreglo.forEach((el) => {
      this.usersForm.addControl(
        "email_" + el["id"],
        new FormControl(el["email"], [Validators.required, Validators.email])
      );
      this.usersForm.addControl(
        "firstName_" + el["id"],
        new FormControl(el["firstName"])
      );
      this.usersForm.addControl(
        "lastName_" + el["id"],
        new FormControl(el["lastName"])
      );
    });
    this.formReady = true;
    this.loading = false;
    this.sending = false;
  };
  constructor(private master: MasterService, private ms: MessageService) { }

  ngOnInit() {

    this.navbarWatcher = this.ms.channelComponents$.subscribe(res => {
      if (res.message == "changeLanguage") {
        this.language = res.value
      }
    })
  }

  ngOnDestroy() {
    if (!this.navbarWatcher) {
      return
    }

    this.navbarWatcher.unsubscribe()
  }
}
