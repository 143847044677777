import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: "phone",
})

export class PipePhoneModule implements PipeTransform {
    transform(value: string) {
        var phone = ('' + value).replace(/\D/g, '');
        var match = phone.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return value;
    }
}

@Pipe({
    name: "safeUrl",
})

export class SafeUrlPipe implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) {}
    transform(url) {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
  
// if you want to add another pipe
// @Pipe({
//     name: "???",
// })
// export class PipeExample implements PipeTransform {
//     transform(value: string) {
//         var phone = ('' + value).replace(/\D/g, '');
//         var match = phone.match(/^(\d{3})(\d{3})(\d{4})$/);
//         if (match) {
//             return '(' + match[1] + ') ' + match[2] + '-' + match[3];
//         }
//         return value;
//     }
// }