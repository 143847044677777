import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, Pipe } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
// services
import { MasterService } from '../../../../../services/master.service'
import { MessageService } from '../../../../../services/message.service'
import * as languageLibrary from '../../../../../services/language'

import { COMMUNICATION_TYPES, COMMUNICATION_SUBTYPES } from 'src/app/constants/contact'

@Component({
    selector: 'communications',
    templateUrl: './communications.component.html',
    styleUrls: ['./communications.component.scss']
})

export class CommunicationsComponent implements OnInit, OnChanges {
    /*
  * variables
  */
    public words = languageLibrary.language
    public language: string = 'EN'
    // ontact object
    @Input() contactId: number = 0
    @Input() isFlat: boolean = false
    public communicationsList: Object[] = []
    public communicationSelected: Object = { object: {}, index: -1 }
    // save all communications type
    public types: string[] = COMMUNICATION_TYPES
    public subtypes: string[] = COMMUNICATION_SUBTYPES
    public form = null
    public expanded: boolean = true
    public modForm = null
    public communicationUpdate: boolean;
    // get information of inputs
    get value() { return this.modForm.get('value') }
    get type() { return this.modForm.get('type') }
    

    /**
     * Instantiate a new contact communication add form
     * @param master Backend API service class helper
     * @param ms Alerts and warnings display helper
     * @param fb Angular form builder helper
     */
    constructor(private master: MasterService, private ms: MessageService, private fb: FormBuilder) {
        this.form = this.fb.group({
            methods: this.fb.array([])
        })

        this.modForm = fb.group({
            contactId: new FormControl(0, [Validators.required]),
            type: new FormControl(null, [Validators.required]),
            value: new FormControl(null, [Validators.required]),
            subtype: new FormControl(null)
        }, {
            validators: Validators.compose([this.setSubTypeValidation('type')])
        })
    }

    /**
     * Helper method to consistently get the list of form methods as an Array.
     */
    get methods() {
        return this.form.controls['methods'] as FormArray;
    }

    ngOnInit() {
        this.reset()
        this.getCommunications()
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes) {
            return
        }

        const first = Object.values(changes).some(c => c.isFirstChange())
        if (first) {
            return
        }

        if (changes.contactId) {
            this.reset()
            this.getCommunications()
        }
    }

    /*
    * functions
    */

    /*
    todo: save changes
    */
    public save = (): void => {
        if (this.form.invalid) {
            return
        }

        const payload = []

        for (let group of this.methods.controls) {
            const controls = (group as FormGroup).controls
            payload.push({
                contactId: this.contactId,
                type: controls.type.value,
                value: controls.value.value,
                subtype: controls.subtype.value
            })
        }

        if (payload.length <= 0) {
            return
        }

        this.master.post(`contacts/${this.contactId}/communications`, { communications: payload }, res => {

            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.reset()
            this.reloadCommunication()
            this.getCommunications()

            // close modal
            if (!this.isFlat) {
                const btn = (document.getElementById('close-modal-communications') as HTMLButtonElement)
                if (btn) {
                    btn.click()
                }
            }
        })
    }

    /*
    todo: reload and clean the inputs
    */
    public reset = (): void => {
        this.form.reset()
        this.methods.clear()
        this.add(null)
    }

    /*
    todo: clean Fields
    */
    public resetMod = (): void => {
        this.modForm.reset()
        this.communicationUpdate = false
    }

    //todo: clean communication selected
    public reloadCommunication = () => {
        this.communicationSelected = { object: {}, index: -1 }
        this.communicationUpdate = false
    }

    /*
    todo: select communication
    */
    public selectCommunication = (communication: Object): void => {
        this.communicationSelected['object'] = communication
    }

    /*
    todo: delete communication
    */
    public delete = (): void => {
        if (!this.communicationSelected['object']['id']) {
            return
        }

        this.master.discard(`contacts/${this.contactId}/communications/${this.communicationSelected['object']['id']}`, res => {
            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.communicationUpdate = false
            if (!this.isFlat) {
                (document.getElementById('close-delete-modal-communications') as HTMLButtonElement).click();
            }
            this.getCommunications()
        })
    }

    // todo:alert quest delete
    public okToDelete = (communication: Object) => {
        if (confirm(this.words[this.language]['deleteCommunication'])) {
            this.selectCommunication(communication)
            this.delete()
        }
    }

    /*
    todo: select communication update
    */
    public selectCommunicationUpdate = (communication: Object): void => {
        this.communicationSelected['object'] = communication
        this.communicationUpdate = true
        this.clearSubTypeModForm(false, communication['type'])
        this.modForm.setValue({
            contactId: this.contactId,
            type: communication['type'],
            value: communication['value'],
            subtype: communication['subtype']
        })
    }
    /*
    todo: update some row
    */
    public modifyCommunication = (): void => {
        if (this.modForm.invalid) {
            return
        }

        if (!this.communicationSelected['object']['id']) {
            return
        }

        let payload = { ...this.modForm.value }
        this.master.put(`contacts/${this.contactId}/communications/${this.communicationSelected['object']['id']}`, payload, res => {
            if (!res) {
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] })
            // clean the fields
            this.reloadCommunication()
            this.resetMod()
            this.getCommunications()
            // close modal
            if (!this.isFlat) {
                const btn = (document.getElementById('btn-close-communications-update') as HTMLButtonElement)
                if (btn) {
                    btn.click()
                }
            }

        })
    }

    /*
   todo: define the identification default
   */
    public selectDefault = (selectedCommunication: Object): void => {

        selectedCommunication['contactId'] = this.contactId //contactId is not part of the communication object
        this.master.patch(`contacts/${this.contactId}/communications/${selectedCommunication['id']}/primary`, { type: selectedCommunication['type'] }, res => {
            if (!res) {
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.getCommunications()

        })
    }


    /**
     * Remove a communication method from the form array
     * @param index The index of the invite to remove
     */
    public discard = (index: number): void => {
        if ((index < 0) || (index > this.methods.length - 1)) {
            return
        }

        this.methods.removeAt(index)
    }

    /**
     * Add a new communincation method row to the form array
     * @param e Event that triggerd this method, if any
     */
    public add = (e): void => {
        const method = this.fb.group({
            type: [null, [Validators.required]],
            value: [null, [Validators.required]],
            subtype: [null, { disabled: true }],
        }, {
            validators: Validators.compose([this.setSubTypeValidation('type')])
        })

        this.methods.push(method)
    }
    public clearSubTypeModForm(clearValue: boolean, commType: string) {
        this.modForm.get('subtype').setValue(null)
        if (commType !== 'phone') {
            this.modForm.get('subtype').disable()
            if (clearValue) {
                this.modForm.get('subtype').setValue(null)
            }
            return
        }
        this.modForm.get('subtype').enable()
    }

    public clearSubTypeForm(i) {
        if (this.form.controls.methods.controls[i].get('type').value !== 'phone') {
            this.form.controls.methods.controls[i].get('subtype').disable()
            this.form.controls.methods.controls[i].get('subtype').setValue(null)
            return
        }
        this.form.controls.methods.controls[i].get('subtype').enable()
    }

    public setSubTypeValidation(type: string) {
        return (group: FormGroup): { [key: string]: any } => {
            let typeComms = group.controls[type]
            if (typeComms.value === 'phone') {
                group.get('subtype').setValidators([Validators.required]);
                group.get('subtype').updateValueAndValidity({ onlySelf: true, emitEvent: false });
                return
            } else {
                group.get('subtype').clearValidators();
                group.get('subtype').updateValueAndValidity({ onlySelf: true, emitEvent: false });
                return
            }
        }
    }

    getCommunications() {
        if (!this.contactId || (this.contactId <= 0)) {
            return
        }

        this.master.get(`contacts/${this.contactId}/communications`, res => {
            if (!res) {
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.communicationsList = res.data.communicationList
        })
    }

}
