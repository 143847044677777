import { Component, OnInit, Directive, HostListener, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MessageService } from '../../../services/message.service'
import { MasterService } from '../../../services/master.service'
import { StoreService } from '../../../services/store.service'
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import * as languageLibrary from '../../../services/language'

// external
import * as moment from "moment/moment";
import { isNull } from 'util';
import { SEARCH_DEBOUNCE_MILLIS, MIN_SEARCH_CHARS, SCROLL_BOTTOM_OUT } from "src/app/constants/list";

@HostListener("click", ["$event"])
@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit {

  /*
* Variables
*/

  //
  public images = []
  
  // save the language
  public language: any = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  
  // set all words
  public words = languageLibrary.language
  
  // save all data by type
  public vehicles: Object[] = []
  
  // inventorySetup
  public inventorySetup: any;
  
  // save all data by type (complete)
  public dataComplete: Object[] = []
  
  // define lot selected
  public lotSelected: any = null
  
  // the selected inventory
  public selectedInventory: [] = [];
  
  // table headers
  public headers: Object[] = [
    { name: 'Vehicle Image', view: true, disabled: true },
    { name: 'Stock #', view: true, disabled: true },
    { name: 'VIN #', view: true, disabled: true },
    { name: 'Year', view: true, disabled: true },
    { name: 'Make', view: true, disabled: true },
    { name: 'Model', view: true, disabled: true },
    { name: 'Trim Level', view: true, disabled: false },
    { name: 'Body', view: true, disabled: false },
    { name: 'Engine', view: true, disabled: false },
    { name: 'Cylinders', view: true, disabled: false },
    { name: 'Transmission', view: true, disabled: false },
    { name: 'Driveline', view: true, disabled: false },
    { name: 'Exterior Color', view: true, disabled: true },
    { name: 'Interior Color', view: true, disabled: false },
    { name: 'Exterior Color (Minor)', view: true, disabled: false },
    { name: 'Selling Price', view: true, disabled: false },
    { name: 'Type', view: true, disabled: false },
    { name: 'Miles', view: true, disabled: true },
    { name: 'Odometer', view: true, disabled: false },
    { name: 'Curb Weight', view: true, disabled: false },
    { name: 'CarryingWeight', view: true, disabled: false },
    { name: 'GVWR', view: true, disabled: false },
    { name: 'Tonnage', view: true, disabled: false },
    { name: 'Lot', view: true, disabled: true },
    { name: 'Status', view: true, disabled: true },
    { name: 'Days Old', view: true, disabled: true }
  ]
  
  // define if is loading
  public loading: boolean = false
  
  // define if something is uploading
  public uploading: boolean = false
  
  // permissions
  public permissions: Object[] = [];
  public permissionsInventorySetup: Object[] = [];
    
  // Current page the user is viewing (last loaded).
  public page = 1

  // The number contacts currently being displayed.
  public displayed = 0

  // Indicates whether or not there are no more records to load.
  public exhausted = false

  // Total number of records available
  public records: number

  // The search form group
  private form: FormGroup = null

  // The current search text to filter the list by.
  private inquiry: string = null

  // A debounce timer for search events. Used to wait for 
  // the user to stop typing before we fetch.
  private watcher: ReturnType<typeof setTimeout> = null

  // Keep track of how the user wants to sort list.
  private sortables: Object = {'received': 'DESC'}
  
  // list of status
  public statuses: string[] = []
  
  //
  public inventorySetupNotFound = false
  
  //
  public isAdmin = false
  
  // 
  public scannedVINImages = []

  //
  public userId: Number = null;

  /*
  todo: Life Cycles
  */
  constructor(private route: ActivatedRoute, private router: Router, private ms: MessageService, private master: MasterService, private store: StoreService, private fb: FormBuilder) {}

  ngOnInit() {
    this.lotSelected = this.store.lotSelected ? this.store.lotSelected : localStorage.getItem('lot')

    this.getInventoryStatuses()

    this.form = this.fb.group({
      inquiry: new FormControl('', []),
      status: new FormControl('', [])
    })

    this.form.get('inquiry').valueChanges.subscribe(inquiry => {
        if (this.watcher) {
            clearTimeout(this.watcher)
            this.watcher = null
        }

        if (this.inquiry === inquiry) {
            return
        }

        this.watcher = setTimeout(() => {
            this.inquiry = inquiry
            this.reset()            
            this.fetch()
        }, SEARCH_DEBOUNCE_MILLIS)
    })

    this.form.get('status').valueChanges.subscribe(status => {
      this.reset()            
      this.fetch()
    })

    if (!this.store.userAccount['permissions']) {
      return
    }

    this.getInventorySetup()
    this.permissions = this.store.userAccount['permissions'][4].childrens[0].permissions
    this.permissionsInventorySetup = this.store.userAccount['permissions'].find((page) => page['name'] == "Corporation Setup").childrens[16].permissions
  }

  ngOnChanges(changes: SimpleChanges): void {
    
  }

  /**
     * Reset the pagination parameters and scroll to the top
     */
  private reset() {
    this.page = 1
    this.exhausted = false
    this.displayed = 0

    const list = document.querySelector('#listing')
    if (!list) {
        return
    }

    list.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto'
    })
  }

/*
* Functions
*/

public onClick(event: any): void {
  event.stopPropagation();
}

public getInventoryStatuses() {
  this.master.get(`collections/inventory/statuses`, (res) => {
    if (!res || !res.data) {
      this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
      return
    }

    if (res.status !== 200) {
      this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
      return
    }

    this.statuses = res.data.inventoryStatuses
  })
}

  /*
todo: pagination and rows per view functions
*/
  public toggleCell(index?: number) {
    if (index != undefined) {
      this.headers[index]['view'] = !this.headers[index]['view']
    }
  }

  // save View
  public saveView = (): void => {
    let config = JSON.stringify(this.headers)
    this.master.put(`user/${this.userId}`, { users: { inventoryTable: config, id: this.userId } }, res => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }
      document.getElementById('btn-customizeColumns-close').click()
    })
  }

  // generate a inventory object
  public buildData = (data: any[]): any[] => {
    let newData: any[] = []

    if (!data || data.length <= 0) {
      return newData
    }

    for (let index = 0; index < data.length; index++) {
      newData.push({
        'id': data[index]['id'],
        'Vehicle Image': (data[index].uploads && data[index].uploads.length > 0) ? data[index].uploads[0]['url'] : '',
        'Stock #': data[index].stockNumber,
        'VIN #': data[index].vehicle.vinNumber,
        'Year': data[index].vehicle.modelYear,
        'Make': data[index].vehicle.make,
        'Model': data[index].vehicle.model,
        'Trim Level': data[index].vehicle.trimLevel,
        'Body': data[index].vehicle.bodyStyle,
        'Engine': data[index].vehicle.engine,
        'Cylinders': data[index].vehicle.cylinders,
        'Transmission': data[index].vehicle.transmission,
        'Driveline': data[index].vehicle.driveline,
        'Exterior Color': data[index].exteriorColor,
        'Interior Color': data[index].interiorColor,
        'Exterior Color (Minor)': data[index].exteriorColorMinor,
        'Selling Price': data[index].sellingPrice,
        'Type': data[index].vehicle.type,
        'Miles': data[index].miles,
        'Odometer': data[index].odometer,
        'Curb Weight': data[index].vehicle.curbWeight,
        'GVWR': data[index].vehicle.gvwr,
        'Tonnage': data[index].vehicle.tonnage,
        'Lot': data[index].lot.contact.name,
        'Status': data[index].inventoryStatus.name,
        'StatusId': data[index].statusId,
        'Days Old': this.calculateDaysOld(data[index].dateOnLot),
        stockNumber: data[index].stockNumber,
        selectable: data[index].inventoryStatus.selectable,
      })
    }
    return newData
  }

  public calculateDaysOld = (dateOnLotVar) => {
    if (isNull(dateOnLotVar)) {
      return 0
    }

    let dateOnLot = moment(dateOnLotVar);
    let now = moment(moment().format('YYYY-MM-DD'))
    return now.diff(dateOnLot, 'days') + 1
  }

  // change status
  public modifyStatus = (statusId: number, inventoryId: number): void => {
    this.master.post(`inventory/${inventoryId}/status`, { information: { statusId: statusId, inventoryId: inventoryId, startDate: new Date().toISOString().substring(0, 10) } }, res => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.ms.sendMessage("alert", { type: "success", text: this.words[this.language].saved })
    })
  }

  // move the position of columns in the table
  public drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)
      return
    }
    
    transferArrayItem(event.previousContainer.data,
      event.container.data,
      event.previousIndex,
      event.currentIndex)
  }
  
  // todo: listen to output of navbar
  public listenerNav = (e): void => {
    switch (e['message']) {
      case 'changeLanguage':
        this.language = e['value']
        break;
      case 'setPermissions':
        this.permissions = e.permissions[4].childrens[0].permissions
        this.permissionsInventorySetup = e.permissions.find((page) => page['name'] == "Corporation Setup").childrens[16].permissions
        this.getInventorySetup()
        break;
      case 'changeLot':
        this.vehicles = []
        if (this.lotSelected != e.lot) {
          this.lotSelected = e.lot
          this.getInventorySetup()
        }
        break
      default:
        break
    }
  }

  // edit inventory
  public editInventory = (id: number, stockNumber: any): void => {
    this.loading = true
    this.router.navigate([`/inventory/${id}`])
    return
  }

  public createInventory = (): void => {
    if (this.inventorySetupNotFound) {
      return
    }

    this.router.navigate([`/inventory/add`])
  }

  /**
     * Handle scroll events of the list container and load 
     * the next page when avilable and the user scrolled 
     * to the bottom.
     * @param e The scroll event that trigggered this handler.
     */
  public scrolled = (e): void => {
    if (!e || !e.target) {
        return
    }

    if (this.exhausted) {
        return
    }

    if (this.loading) {
        return
    }

    const location = Math.abs(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop)

    if (location >= SCROLL_BOTTOM_OUT) {
        return
    }

    this.page = this.page + 1
    this.fetch()
  }

  /**
   * 
   * @param field The field that user wants to sort by. 
   */
  public sort = (field: string): void => {
      if (!field || (field.length <= 0)) {
          return
      }

      if (this.sortables[field]) { // need to toggle the direction?
          switch (this.sortables[field]) {
              case 'ASC':
                  this.sortables[field] = 'DESC'
                  break
              default:
                  this.sortables[field] = 'ASC'
                  break
          }

          this.reset()
          this.fetch()
          return
      }

      this.sortables = {}
      this.sortables[field] = 'ASC'
      this.reset()
      this.fetch()
  }

  public sortedAsc = (field: string): Boolean => {
      if (!field || (field.length <= 0) || !this.sortables) {
          return false
      }

      if (!this.sortables[field]) {
          return false
      }

      return (this.sortables[field].toUpperCase() === 'ASC')
  }

  public sortedDesc = (field: string): Boolean => {
      if (!field || (field.length <= 0) || !this.sortables) {
          return false
      }

      if (!this.sortables[field]) {
          return false
      }

      return (this.sortables[field].toUpperCase() === 'DESC')
  }

  /**
   * Acquire contact list by given type
   */
  public fetch = (): void => {
      if (this.loading) { // already getting next page
          return
      }

      if (this.exhausted) { // currently showing all available records?
          return
      }

      this.loading = true

      let lotParam = ''
      if (this.lotSelected && (this.lotSelected > 0)) {
          lotParam = `&lotId=${parseInt(this.lotSelected)}`
      }

      let inquiryParam = ''
      if (this.inquiry && (this.inquiry.length > 0)) {
          inquiryParam = `&q=${encodeURIComponent(this.inquiry)}`
      }

      let statusParam = ''
      const status = parseInt(this.form.get('status').value)
      if (status && (!isNaN(status)) && (status > 0)) {
        statusParam = `&statuses=${status}`
      }

      let sortParam = ''
      if (this.sortables && (Object.keys(this.sortables).length > 0)) {
          const sorts = []
          for (const key of Object.keys(this.sortables)) {
              let direction = ''
              if (this.sortables[key] && this.sortables[key].length > 0) {
                  direction = `|${this.sortables[key]}`
              }

              sorts.push(`${key}${direction}`)
          }

          sortParam = `&sort=${sorts.join(',')}`
      }

      this.master.get(`inventory?page=${this.page}${inquiryParam}${sortParam}${lotParam}${statusParam}`, (res) => {
          this.loading = false

          if (!res || !res.data) {
              this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
              return
          }

          if (res.status !== 200) {
              this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
              return
          }

          this.records = res.data.records
          this.displayed = Math.min(this.records, (res.data.page * res.data.ipp))
          this.exhausted = (res.data.next === null)

          if (this.page > 1) {
              this.vehicles = this.vehicles.concat(res.data.inventory)
              return
          }

          this.vehicles = res.data.inventory
      })
  }

  public getInventorySetup() {
    this.inventorySetupNotFound = false
    this.master.get(`lots/${this.lotSelected}/inventorySetup`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.isAdmin = this.permissionsInventorySetup.includes('edit') || false
      if (!this.isAdmin){
        document.getElementById('btn-alertModalSetup').click();
        this.inventorySetupNotFound = true
        return
      }
      
      if (!res.data.inventorySetup || (!res.data.inventorySetup.defaultFormat)) {
        document.getElementById('btn-showInventorySetup').click();
        this.inventorySetupNotFound = true
        return
      }
      
      this.inventorySetup = res.data.inventorySetup
      this.reset()
      this.fetch()
    })
  }


  // todo: listener to childrens
  public listenerChildrens = (e): void => {
    if (e.message === 'closeModal') {
      (document.getElementById('btn-showInventorySetup-close') as HTMLButtonElement).click();
      this.inventorySetupNotFound = false
    }

    if (e.message === 'refreshInventoryList') {
      this.reset()
      this.fetch()
    }
  }

  public selectInventory(inventory: []) {
    this.selectedInventory = inventory
  }


  public deleteInventory() {
    if (this.selectedInventory['status'] === 'Sold') {
      return
    }

    this.master.discard(`inventory/${this.selectedInventory['id']}`, (res) => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      (document.getElementById('btn-close-modal-delete') as HTMLButtonElement).click();
      this.reset()
      this.fetch()
    })
  }

}
