import { Component, Injector, Input, OnInit } from '@angular/core';
import { DynamicService } from 'src/app/services/dynamic.service';
import { token } from '../roles.component';
import { MessageService } from 'src/app/services/message.service';
import { MasterService } from 'src/app/services/master.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { COLLECTION_METHODS } from 'src/app/constants/payments';
import { FINANCING_OPTIONS } from 'src/app/constants/contact';
import * as languageLibrary from '../../../../../../services/language'

@Component({
  selector: '[role-lienholder]',
  templateUrl: './role-lienholder.component.html',
  styleUrls: ['./role-lienholder.component.scss']
})
export class RoleLienholderComponent implements OnInit {
  public contactId: number = 0
  public contactRoleId: number = 0
  public dataIn: string;
  public id: number = 0;
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  public words = languageLibrary.language
  public collectionMethods = COLLECTION_METHODS
  public financingOptions = FINANCING_OPTIONS
  public lienholderInformation = new FormGroup({
    id: new FormControl(null),
    contactId: new FormControl(null, [Validators.required]),
    contractUsed: new FormControl(null, [Validators.required]),
    defaultCollectionMethod: new FormControl(0, [Validators.required]),
    financesSaleType: new FormControl(0, [Validators.required]),
  })
  public calledFrom: string;

  constructor(private master: MasterService, private ms: MessageService, private inject: Injector, private serv: DynamicService) {
  }

  ngOnInit() {
    this.dataIn = this.inject.get(token);
    this.contactId = this.dataIn['contactId']
    this.contactRoleId = this.dataIn['contactRoleId']
    this.calledFrom = this.dataIn['calledFrom']

    if (!this.contactId) {
      return
    }

    this.lienholderInformation.get("contactId").setValue(this.contactId)

    this.getRoleLienholder()
  }

  getRoleLienholder() {
    this.master.get(`contacts/${this.contactId}/lienholder`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      if (res.data.contactLienholder.length == 0){
        return
      }

      this.setInformation(res.data.contactLienholder[0])

      if (res.data.contactLienholder.length == 0) {
        if (this.calledFrom == "contactAdvancedSearch")
          this.serv.outputFromDynamicComponent({ action: "setupNeeded", setupNeeded: true, type:"lienholder" });
        return
      }

      if (this.calledFrom == "contactAdvancedSearch") {
        this.serv.outputFromDynamicComponent({ action: "setupNeeded", setupNeeded: false, type:"lienholder" });
        return
      }
    })
  }

  setInformation(lienholder: any) {
    this.id = lienholder['id']
    this.lienholderInformation.setValue({
      id: lienholder['id'],
      contactId: this.contactId,
      contractUsed: lienholder['contractUsed'],
      defaultCollectionMethod: lienholder['defaultCollectionMethod'],
      financesSaleType: lienholder['financesSaleType'],
    })
  }


  public save() {
    if (!this.lienholderInformation.valid) {
      return
    }

    if (this.id <= 0) {
      this.create()
      return
    }

    if (this.id >= 1) this.modify()
  }

  create() {
    this.master.post(`contacts/${this.contactId}/lienholder`, { information: this.lienholderInformation.value }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
      this.setInformation(res.data.contactLienholder)

      if (this.calledFrom == "contactAdvancedSearch")
        this.serv.outputFromDynamicComponent({ action: "setupSaved", setupSaved: true, type:"lienholder" });

    })
  }

  modify() {
    this.master.put(`contacts/${this.contactId}/lienholder/${this.id}`, { information: this.lienholderInformation.value }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      if (this.calledFrom == "contactAdvancedSearch")
        this.serv.outputFromDynamicComponent({ action: "setupSaved", setupSaved: true, type:"lienholder" });
    })
  }

  deactivateRole() {
    this.serv.outputFromDynamicComponent({ action: "deactivate", contactRoleId: this.contactRoleId });
  }

}
