import { Component, Injector, OnInit } from '@angular/core';
import * as languageLibrary from '../../../../../../services/language'
import { DynamicService } from 'src/app/services/dynamic.service';
import { token } from '../roles.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MasterService } from 'src/app/services/master.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: '[role-lifeinsurance]',
  templateUrl: './role-lifeinsurance.component.html',
  styleUrls: ['./role-lifeinsurance.component.scss']
})
export class RoleLifeInsuranceComponent implements OnInit {
  public contactId: number
  public contactRoleId: number = 0
  public words = languageLibrary.language
  public language: string = 'EN'
  public lifeInsuranceInformation = new FormGroup({
    id: new FormControl(null),
    contactId: new FormControl(null, [Validators.required]),
    accountNumber: new FormControl(null),
    creditLifeCommissionRate: new FormControl(null),
    accidentHealthCommissionRate: new FormControl(null),
    otherFees: new FormControl(1),
  })
  public dataIn: string;
  public id: number = 0;
  public calledFrom: string;
  constructor(private master: MasterService, private ms: MessageService, private inject: Injector, private serv: DynamicService) {
  }

  deactivateRole() {
    this.serv.outputFromDynamicComponent({ action: "deactivate", contactRoleId: this.contactRoleId });
  }
  ngOnInit(): void {

    this.dataIn = this.inject.get(token);
    this.contactId = this.dataIn['contactId']
    this.contactRoleId = this.dataIn['contactRoleId']
    this.calledFrom = this.dataIn['calledFrom']

    if (!this.contactId || this.calledFrom == 'salesScreen') {
      return
    }

    this.lifeInsuranceInformation.get("contactId").setValue(this.contactId)

    this.getLifeInsurance()
  }

  public getLifeInsurance() {
    this.master.get(`contacts/${this.contactId}/lifeInsurance`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      if (res.data.contactLifeInsurance.length == 0) {
        if (this.calledFrom == "contactAdvancedSearch")
          this.serv.outputFromDynamicComponent({ action: "setupNeeded", setupNeeded: true, type:"lifeInsurance" });
        return
      }
      
      this.setInformation(res.data.contactLifeInsurance[0])

      if (this.calledFrom == "contactAdvancedSearch") {
        this.serv.outputFromDynamicComponent({ action: "setupNeeded", setupNeeded: false, type:"lifeInsurance" });
        return
      }
    })
  }

  public saveLifeInsurance() {
    if (!this.lifeInsuranceInformation.valid) {
      return
    }

    if (this.id <= 0) {
      this.createLifeInsurance()
      return
    }

    if (this.id >= 1) this.modifyLifeInsurance()
  }

  createLifeInsurance() {
    this.master.post(`contacts/${this.contactId}/lifeInsurance`, { information: this.lifeInsuranceInformation.value, }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
      this.setInformation(res.data.contactLifeInsurance)

      if (this.calledFrom == "contactAdvancedSearch")
        this.serv.outputFromDynamicComponent({ action: "setupSaved", setupSaved: true, type:"lifeInsurance" });
    })
  }

  modifyLifeInsurance() {
    this.master.put(`contacts/${this.contactId}/lifeInsurance/${this.id}`, { information: this.lifeInsuranceInformation.value, }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
      if (this.calledFrom == "contactAdvancedSearch")
        this.serv.outputFromDynamicComponent({ action: "setupSaved", setupSaved: true, type:"lifeInsurance" });

    })
  }

  deleteLifeInsurance() {
    this.master.discard(`contacts/${this.contactId}/lifeInsurance/${this.id}`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.resetInformation()
    })
  }

  resetInformation() {
    this.lifeInsuranceInformation.reset({
      contactId: this.contactId
    })
    this.id = null
  }

  setInformation(info) {
    this.id = info["id"]
    this.lifeInsuranceInformation.setValue({
      id: info['id'],
      contactId: info['contactId'],
      accountNumber: info['accountNumber'],
      creditLifeCommissionRate: info['creditLifeCommissionRate'],
      accidentHealthCommissionRate: info['accidentHealthCommissionRate'],
      otherFees: info['otherFees'],
    })
  }

}
