import { Component, OnInit, Input, EventEmitter, OnChanges, SimpleChanges, Output } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
// services
import { MasterService } from '../../../../services/master.service'
import { MessageService } from '../../../../services/message.service'
import { Router, ActivatedRoute } from '@angular/router';
import * as languageLibrary from '../../../../services/language'

import { COMMUNICATION_TYPES, COMMUNICATION_SUBTYPES } from 'src/app/constants/contact';

@Component({
    selector: 'communications-corporate',
    templateUrl: './communications.component.html',
    styleUrls: ['./communications.component.scss']
})
export class CommunicationsCorporateComponent implements OnChanges {
    /*
   * variables
   */
    @Output() childEmitter$: EventEmitter<Object> = new EventEmitter()
    //  define if some changes not saved
    public savePending: boolean = false
    // save the language
    @Input() language: string = 'EN'
    // set all words
    public words = languageLibrary.language
    // Unique internal identity of the associated contact
    @Input() contactId: number = 0
    //  define if loading state
    public loading: boolean = false
    // define the selected communication
    public communicationSelected: Object = { object: {}, index: 0 }
    // ontact object
    @Input() corporate: Object = {}
    // define the corporate type
    public corporatetype: string = ''
    // define the corporate type
    public id: number = 0
    // permissions
    @Input() permissions: Object[] = []
    // save all communications information
    public communicationsList: Object[] = []
    public departmentsList: Object[] = []
    // Available corporate departments
    public departments: string[] = []
    // save all communications type
    public types: string[] = COMMUNICATION_TYPES
    public subtypes: string[] = COMMUNICATION_SUBTYPES
    // Group of inputs
    public form = null
    // update
    public modFormComms = null
    // collapse/expand button
    public expanded: boolean = false
    /*
    * functions
    */
    public getCommunications() {
        if (isNaN(this.contactId) || (this.contactId <= 0)) {
            this.contactId = 0
            return
        }

        this.master.get(`contacts/${this.contactId}/communications`, res => {
            this.loading = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.communicationsList = res.data.communicationList
            this.getDepartments();
        })
    }
    getDepartments() {
        this.master.get(`collections/corporation/departments`, res => {
            this.loading = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.departmentsList = res.data.Departments
        })
    }

    public getCommunication = (communicationId: string): void => {
        if (isNaN(this.contactId) || (this.contactId <= 0)) {
            this.contactId = 0
            return
        }

        this.master.get(`contacts/${this.contactId}/communications/${communicationId}`, res => {
            this.loading = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.setFormInfo(res.data.communication)

        })
    }

    setFormInfo(communication: any) {
        this.modFormComms.setValue({
            type: communication['type'],
            value: communication['value'],
            subtype: communication['subtype'],
            contactId: communication['contactId'],
            id: communication['id'],
            departmentId: communication['departmentId'],
        })
    }

    public cleanFields = (): void => {
        this.modFormComms.reset()
        this.modFormComms.get('contactId').setValue(this.contactId)
    }

    filterForDepartment(departmentId): string {
        const returnedValue = this.departmentsList.find(department => department['id'] === departmentId);
        if (typeof returnedValue !== 'undefined') {
            return returnedValue['name']
        }
    }

    public delete = (): void => {
        this.loading = true

        this.master.discard(`contacts/${this.contactId}/communications/${(this.communicationSelected)}`, res => {
            this.loading = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            const btn = (document.getElementById('close-delete-modal-communications') as HTMLButtonElement)
            if (btn) {
                btn.click()
            }

            this.cleanFields;
            this.getCommunications()

        })
    }

    // save changes
    public save = (): void => {
        const payload = []
        if (this.form.invalid) {
            return
        }

        for (let group of this.methods.controls) {
            const controls = (group as FormGroup).controls
            payload.push({
                contactId: this.contactId,
                type: controls.type.value,
                value: controls.value.value,
                subtype: controls.subtype.value,
                departmentId: controls.departmentId.value,
            })
        }

        this.master.post(`contacts/${this.contactId}/communications`, { communications: payload }, res => {
            this.loading = false

            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }


            this.cleanFields
            this.getCommunications()
            this.reset()

            const btn = (document.getElementById('close-modal-communications') as HTMLButtonElement)
            if (btn) {
                btn.click()
            }

        })
    }

    /*
    todo: reload and clean the inputs
    */
    public reset = (): void => {
        this.form.reset()
        this.methods.clear()
        this.add(null)
    }

    // select communication
    public selectCommunication = (communicationId): void => {
        this.communicationSelected = communicationId
    }

    /*
    todo: update
    */

    public modifyCommunication = (communication: Object): void => {
        if (this.modFormComms.invalid) {
            return
        }

        this.loading = true
        this.master.put(`contacts/${this.contactId}/communications/${communication['id']}`, this.modFormComms.value, res => {
            this.loading = false

            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            const btn = (document.getElementById('btn-close-communications-update') as HTMLButtonElement)
            if (btn) {
                btn.click()
            }
            // clean the fields
            this.cleanFields
            this.getCommunications()
        })
    }


    /*
   * select default
   */
    // define the identification default
    public selectDefault = (selectedCommunication: Object, type: string): void => {
        this.master.patch(`contacts/${this.contactId}/communications/${selectedCommunication['id']}/primary`, { type: selectedCommunication['type'] }, res => {
            if (!res) {
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }
            this.getCommunications()
        })
    }

    // send post toggle
    public togglePrimary = (objectCommunication: Object, sendMessage: boolean): void => {
        objectCommunication['departmentId'] = objectCommunication['departmentId']
        this.master.put('modifyContactCommunication', objectCommunication, res => {
            this.loading = false

            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status == 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            if (!sendMessage) {
                return
            }

            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] })
        })
    }

    /**
     * Remove a communication method from the form array
     * @param index The index of the invite to remove
     */
    public discard = (index: number): void => {
        if ((index < 0) || (index > this.methods.length - 1)) {
            return
        }

        this.methods.removeAt(index)
    }

    public setSubTypeValidation(type: string) {
        return (group: FormGroup): { [key: string]: any } => {
            let typeComms = group.controls[type]
            if (typeComms.value === 'phone') {
                group.get('subtype').setValidators([Validators.required]);
                group.get('subtype').updateValueAndValidity({ onlySelf: true, emitEvent: false });
                return
            } else {
                group.get('subtype').clearValidators();
                group.get('subtype').updateValueAndValidity({ onlySelf: true, emitEvent: false });
                return
            }
        }
    }

    public clearSubTypeModFormComms(clearValue: boolean, commType: string) {
        this.modFormComms.get('subtype').setValue(null)
        if (commType !== 'phone') {
            this.modFormComms.get('subtype').disable()
            if (clearValue) {
                this.modFormComms.get('subtype').setValue(null)
            }
            return
        }
        this.modFormComms.get('subtype').enable()
    }
    public clearSubTypeForm(i) {
        if (this.form.controls.methods.controls[i].get('type').value !== 'phone') {
            this.form.controls.methods.controls[i].get('subtype').disable()
            this.form.controls.methods.controls[i].get('subtype').setValue(null)
            return
        }
        this.form.controls.methods.controls[i].get('subtype').enable()
    }
    /**
     * Add a new communincation method row to the form array
     * @param e Event that triggerd this method, if any
     */
    public add = (e): void => {
        const method = this.fb.group({
            type: ['', [Validators.required]],
            value: ['', [Validators.required]],
            subtype: ['', [Validators.required]],
            departmentId: new FormControl(1, [Validators.required]),
            contactId: new FormControl(this.contactId, [Validators.required]),
        }, {
            validators: Validators.compose([this.setSubTypeValidation('type')])
        })

        this.methods.push(method)
    }

    constructor(private master: MasterService, private ms: MessageService, private route: ActivatedRoute, private fb: FormBuilder) {
        this.corporatetype = this.route.snapshot.params['type']
        this.id = parseInt(this.route.snapshot.params['id'])

        this.form = this.fb.group({
            methods: this.fb.array([])
        })

        this.modFormComms = fb.group({
            type: new FormControl('', [Validators.required]),
            value: new FormControl('', [Validators.required]),
            subtype: new FormControl('', [Validators.required]),
            departmentId: new FormControl('', [Validators.required]),
            contactId: new FormControl(this.contactId, [Validators.required]),
            id: new FormControl(null, [Validators.required]),
        }, {
            validators: Validators.compose([this.setSubTypeValidation('type')])
        })
    }

    /**
     * Helper method to consistently get the list of form methods as an Array.
     */
    get methods() {
        return this.form.controls['methods'] as FormArray;
    }

    // get information of inputs
    get value() { return this.modFormComms.get('value') }
    get type() { return this.modFormComms.get('type') }


    ngOnChanges(changes: SimpleChanges) {
        this.getCommunications()
    }



}
