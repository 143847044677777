import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import * as languageLibrary from '../../../../services/language'
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { MessageService } from "../../../../services/message.service";
import { MasterService } from "../../../../services/master.service";
import { StoreService } from "../../../../services/store.service";
import { SecurityService } from '../../../../services/security.service'


@Component({
    selector: "buyer",
    templateUrl: "./buyer.component.html",
    styleUrls: ["./buyer.component.scss"],
})
export class DealBuyerComponent implements OnInit, OnChanges {
    
    @Input() language
    @Input() isUnLock: boolean = true
    @Input() saleId: number = 0

    // permissions
    @Input() permissions: Object[] = []

    @Input('createSaleEvent') createSaleEvent: Subject<number>

    @Output() buyerChangeEvent = new EventEmitter<Object>()
    
    public words = languageLibrary.language
    public loading: boolean = false

    public saleBuyer = null
    public saleCoBuyers: Object[] = []
    public saleCoSigners: Object[] = []
    
    public lotId: number
    public buyerRole: Object

    /** The contact chosen or created in the contact add modal. */
    private selectedContact: Object = null
    
    public editBuyerId: number = 0
    public deleteBuyerId: number = 0

    private BUYER_ROLE: {}
    private COBUYER_ROLE: {}
    private COSIGNER_ROLE: {}

    private displayContactSelector: boolean = false

    constructor(private route: ActivatedRoute, private router: Router, private ms: MessageService, private master: MasterService, private store: StoreService, private security: SecurityService, private fb: FormBuilder,) {}

    ngOnInit(): void {
        this.saleId = 0
        const id = parseInt(this.route.snapshot.params['id'])
        if (id && !isNaN(id) && (id > 0)) {
            this.saleId = id
        }
        
        this.lotId = parseInt(localStorage.getItem('lot'))

        this.createSaleEvent.subscribe(e => {
            this.saleCreated(e)
        })

        this.fetchRoles()
    }

    ngOnDestroy() {
        this.createSaleEvent.unsubscribe()
    }

    ngOnChanges(changes: SimpleChanges): void {
        
    }

    // todo: listener to childrens
    public listenEndpoint = (e) => {
        switch (e.message) {
            case 'reloadContactDetail':
                break
            case 'buyerAddressSelected':
                const btn = (document.getElementById('btn-select-address-buyer-modal') as HTMLButtonElement)
                if (btn) {
                    btn.click()
                }
                
                const addressId: number = parseInt(e.id)
                if (!e.id || isNaN(addressId) || (addressId <= 0)) {
                    return
                }

                this.saveSaleBuyerAddress(addressId)

                break
        }
    }

    /**
     * Acquire the buyer role objects from the db
     */
    private fetchRoles = async () => {
        this.loading = true

        return this.master.getAsync(`sales/buyerroles`).then(res => {
            this.loading = false
            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (!res.data.roles) return

            this.BUYER_ROLE = res.data.roles.find(el => el['name'].toLowerCase() === 'buyer')
            this.COBUYER_ROLE = res.data.roles.find(el => el['name'].toLowerCase() === 'co-buyer')
            this.COSIGNER_ROLE = res.data.roles.find(el => el['name'].toLowerCase() === 'cosigner')
            this.fetchBuyers()
        })
    }

    /**
     * Acquire the contacts assigned to the sale from the db
     */
    public fetchBuyers = async () => {
        if (this.saleId <= 0) {
            return
        }

        this.loading = true
        
        const buyers: Array<Object> = await this.master.getAsync(`sales/${this.saleId}/buyers`).then(res => {
            this.loading = false
            
            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return null
            }

            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return null
            }

            if (!res.data) {
                return null
            }

            return res.data
        })

        if (!buyers || (buyers.length <= 0)) {
            return
        }

        const buyer = buyers.find(el => el['saleBuyerRoleId'] == this.BUYER_ROLE['id'])
        if (buyer) {
            this.saleBuyer = this.flattenBuyer(buyer)
            
            this.buyerChangeEvent.emit({
                id: parseInt(this.saleBuyer['id']), 
                name: `${this.saleBuyer['lastName']}, ${this.saleBuyer['firstName']}`, 
                zip: this.saleBuyer['zipCode'],
                init: true
            })
        }

        const coBuyers = buyers.filter((item) => item['saleBuyerRoleId'] == this.COBUYER_ROLE['id']) || []
        for (let coBuyer of coBuyers) {
            this.saleCoBuyers.push(this.flattenBuyer(coBuyer))
        }

        const coSigners = buyers.filter((item) => item['saleBuyerRoleId'] == this.COSIGNER_ROLE['id']) || []
        for (let coSigner of coSigners) {
            this.saleCoSigners.push(this.flattenBuyer(coSigner))
        }
    }

    /**
     * 
     * @param buyer 
     * @returns 
     */
    private flattenBuyer = (buyer: Object): Object => {
        if (!buyer || !buyer['contact']) {
            return null
        }

        const contact = JSON.parse(JSON.stringify(buyer['contact']))
        delete(buyer['contact'])

        const chosenAddress = buyer['contactAddress']
        const primaryEmail = contact['contactCommunications'].find(el => el.type === 'email' && el.primary === 1)
        const primaryPhone = contact['contactCommunications'].find(el => el.type === 'phone' && el.primary === 1 && el.subtype === 'cell') || 
            contact['contactCommunications'].find(el => el.type === 'phone' && el.primary === 1 && el.subtype === 'home') || 
            contact['contactCommunications'].find(el => el.type === 'phone' && el.primary === 1 && el.subtype === 'work')
        const primaryIdentification = contact['contactIdentifications'].find(el => el.primary === 1)
        
        const res = {
            id: parseInt(buyer['id']),
            saleId: this.saleId,
            lotId: this.lotId,
            contactId: parseInt(contact['id']),
            addressId: parseInt(chosenAddress['id']),
            isIndividual: contact['isIndividual'] ? (contact['isIndividual'] == true) : false, 
            saleBuyerRole: this.buyerRole,
            saleBuyerRoleId: buyer['saleBuyerRoleId'],
            companyName: contact['companyName'],
            firstName: contact['firstName'],
            lastName: contact['lastName'],
            address: chosenAddress.address,
            address2: chosenAddress.address2,
            city: chosenAddress.city,
            state: chosenAddress.state,
            zipCode: chosenAddress.zipCode,
            county: chosenAddress.county,
            country: chosenAddress.country,
            email: primaryEmail ? primaryEmail.value : '',
            phoneNumber: primaryPhone ? primaryPhone.value : '',
            phoneType: primaryPhone ? primaryPhone.subtype : '',
            identificationNumber: primaryIdentification ? primaryIdentification.number: '',
            identificationIssuer: primaryIdentification ? primaryIdentification.issuer: '',
            identificationType: primaryIdentification ? primaryIdentification.type: '',
            identificationExpiration: primaryIdentification ? primaryIdentification.expirationDate: ''
        }

        return res
    }

    /**
     * Assign the selected contact role based on which add button was clicked
     * @param { Object } paramBuyerRole The role object of the selected contact.
     */
    public selectContact = (e, paramBuyerRole: Object) => {
        e.preventDefault()
        e.stopPropagation()
        this.buyerRole = paramBuyerRole

        this.displayContactSelector = true
        const btn = (document.getElementById('btn-select-contact') as HTMLButtonElement)
        if (!btn) {
            return
        }

        btn.click()
    }

    /**
     * Assign the selected contact and map the fields 
     * @param { Object } paramBuyerRole The role object of the selected contact.
     */
    public contactSelected = async (contactId) => {
        const id = parseInt(contactId)
        if (!id || isNaN(id) || (id <= 0)) {
            return
        }

        await this.buildContact(contactId).then(res => {
            this.selectedContact = res
        })

        this.saveSaleBuyer()

        this.displayContactSelector = false
        const btn = (document.getElementById('btn-close-modal-contact-list') as HTMLButtonElement)
        if (!btn) {
            return
        }

        btn.click()
    }

    /** Handle close events of the contact selector modal. */
    public contactSelectorClosed = (e) => {
        this.displayContactSelector = false
    }

    /**
     * Build the contact data from the contact db with default primary data
     * @param { Number } id The id of the contact to build.
     * @returns { Promise<Object> } The contact object
     */
    private buildContact = async (id: number): Promise<Object> => {
        const contact = await this.lookupContact(id)
        return this.flattenContact(contact)
    }

    /**
     * 
     * @param contact 
     * @returns 
     */
    private flattenContact = (contact: Object): Object => {
        if (!contact || !contact['contactAddresses'] || !contact['contactCommunications']) {
            return null
        }

        const primaryAddress = contact['contactAddresses'].find(el => el.primaryAddress == 1)
        const primaryEmail = contact['contactCommunications'].find(el => el.type === 'email' && el.primary == 1)
        const primaryPhone = contact['contactCommunications'].find(el => el.type === 'phone' && el.primary == 1 && el.subtype === 'cell') || 
            contact['contactCommunications'].find(el => el.type === 'phone' && el.primary == 1 && el.subtype === 'home') || 
            contact['contactCommunications'].find(el => el.type === 'phone' && el.primary == 1 && el.subtype === 'work')
        const primaryIdentification = contact['contactIdentifications'] ? contact['contactIdentifications'].find(el => el.primary == 1) : null
        
        const res = {
            saleId: this.saleId,
            lotId: this.lotId,
            saleBuyerRole: this.buyerRole,
            saleBuyerRoleId: parseInt(this.buyerRole['id']),
            contactId: parseInt(contact['id']),
            addressId: parseInt(primaryAddress.id),
            companyName: contact['companyName'],
            firstName: contact['firstName'],
            lastName: contact['lastName'],
            address: primaryAddress.address,
            address2: primaryAddress.address2,
            city: primaryAddress.city,
            state: primaryAddress.state,
            zipCode: primaryAddress.zipCode,
            county: primaryAddress.county,
            country: primaryAddress.country,
            email: primaryEmail ? primaryEmail.value : '',
            phoneNumber: primaryPhone ? primaryPhone.value : '',
            phoneType: primaryPhone ? primaryPhone.subtype : '',
            identificationNumber: primaryIdentification ? primaryIdentification.number: '',
            identificationIssuer: primaryIdentification ? primaryIdentification.issuer: '',
            identificationType: primaryIdentification ? primaryIdentification.type: '',
            identificationExpiration: primaryIdentification ? primaryIdentification.expirationDate: ''
        }

        return res
    }

    /**
     * Acquire the contact details from the db
     * @param { Number } id The id of the contact to lookup.
     * @returns { Promise<Object> } The contact object
     */
    private lookupContact = async (id: number): Promise<Object> => {
        if (id <= 0) {
            return null
        }

        return await this.master.getAsync(`contacts/${id}`).then((res) => {
            this.loading = false

            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
            }

            return res.data.contact
        })
    }
    
    /**
     * A saleCreated event from parent, assign saleId and
     * save the buyer, if one is selected
     * @returns { Object } The contact object
     */
    public saleCreated = (saleId: number): void => {
        if (saleId <= 0) {
            return
        }
        
        if (this.saleId != saleId) {
            this.saleId = saleId
            if (this.selectedContact && (Object.keys(this.selectedContact).length > 0)) {
                this.saveSaleBuyer()
            }
        }
    }

    /**
     * Assigns the contact to bound variable for UI
     */
    private saveSaleBuyer = (): void => {
        if (this.saleId <= 0) {
            this.buyerChangeEvent.emit({id: 0, name: null, zip: null, init: false}) // tell the sale to save itself
            return
        }

        if (!this.selectedContact) {
            return
        }

        const buyer = {
            saleId: this.saleId,
            lotId: this.lotId,
            contactId: this.selectedContact['contactId'],
            addressId: this.selectedContact['addressId'],
            saleBuyerRoleId: this.selectedContact['saleBuyerRole']['id']
        }

        return this.master.post(`sales/${this.saleId}/buyers`, { buyer: buyer }, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            if (!res.data || !res.data.id) {
                return
            }

            this.selectedContact['id'] = res.data.id

            switch (this.buyerRole['id']) {
                case this.BUYER_ROLE['id']:
                    this.saleBuyer = this.selectedContact
                    this.buyerChangeEvent.emit({
                        id: parseInt(this.saleBuyer['id']), 
                        name: `${this.saleBuyer['lastName']}, ${this.saleBuyer['firstName']}`, 
                        zip: this.saleBuyer['zipCode'],
                        init: false
                    })
                    break
                case this.COBUYER_ROLE['id']:
                    this.saleCoBuyers.push(this.selectedContact)
                    break
                case this.COSIGNER_ROLE['id']:
                    this.saleCoSigners.push(this.selectedContact)
                    break
            }
        })
    }

    /**
     * Assigns the selected contactId to variable 
     * to be used in modals
     * @param { Number } contactId The id of the sale buyer
     */
    public setSelectedBuyer = (contactId) => {
        const identity = parseInt(contactId)
        if (!identity || isNaN(identity) || (identity <= 0) || !this.isUnLock) {
            this.editBuyerId = 0
            return
        }

        this.editBuyerId = identity
    }

    /**
     * Links a newly selected sale buyer address to the existing sale.
     */
    private saveSaleBuyerAddress = (id: number): void => {
        if (this.saleId <= 0) {
            return
        }

        if (!id || (id <= 0)) {
            return
        }

        if (!this.saleBuyer || (!this.saleBuyer['id'])) {
            return
        }

        const payload = {
            buyer: {
                addressId: id
            }
        }

        return this.master.patch(`sales/${this.saleId}/buyers/${this.saleBuyer['id']}`, payload, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.saleBuyer = null
            this.saleCoBuyers = []
            this.saleCoSigners = []

            this.fetchBuyers()
        })
    }

    /**
     * Handle delete buyer dialog accept event.
     * @returns 
     */
    public proceedWithDiscard = async () => {
        if (!this.deleteBuyerId || (this.deleteBuyerId <= 0)) {
            return
        }

        await this.discard(this.deleteBuyerId)
    }

    /**
     * Performs the delete request to the api,
     * updates UI buyer data, and removes the id
     * from the contactList filter.
     */
    private discard = async (buyerId: number) => {
        if (buyerId <= 0) {
            return
        }

        if (this.saleBuyer && buyerId == this.saleBuyer['id']) {
            this.saleBuyer = null
            this.buyerChangeEvent.emit({id: 0, name: null, zip: null})
        }

        if (this.saleCoBuyers && this.saleCoBuyers.length > 0) {
            this.saleCoBuyers =  this.saleCoBuyers.filter(item => { return item['id'] != buyerId })
        }

        if (this.saleCoSigners && this.saleCoSigners.length > 0) {
            this.saleCoSigners = this.saleCoSigners.filter(item => { return item['id'] != buyerId })
        }

        if (this.saleId <= 0) {
            return
        }

        this.loading = true
            
        await this.master.discard(`sales/${this.saleId}/buyers/${buyerId}`, (res) => {
            this.deleteBuyerId = 0
            this.loading = false

            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }    
        })
    }
}
