import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
// services
import { MessageService } from '../../../../services/message.service'
import { MasterService } from '../../../../services/master.service'
import { StoreService } from '../../../../services/store.service'
import * as languageLibrary from '../../../../services/language'

import { INVENTORY } from '../../../../constants/inventory'
import { Subject } from 'rxjs';

import { formatCurrency, formatDecimal } from 'src/app/utils/format';
import { clean } from 'src/app/utils/numeric';

@Component({
    selector: 'inventory-quick-add',
    templateUrl: './inventory-quick-add.component.html',
    styleUrls: ['./inventory-quick-add.component.scss']
})
export class InventoryQuickAddComponent implements OnInit {

    /*
    * Variables
    */

    // save the language
    @Input() language: string = 'EN'

    @Input('saved') saved: Subject<any>

    @Output() validity = new EventEmitter<boolean>()

    @Output() created = new EventEmitter<Number>()

    // set all words
    public words = languageLibrary.language

    //
    private features: Object = null
    
    // list of odometers
    public odometers: {} = INVENTORY.ODOMETER_TYPES
    
    // list of colors
    public colors: {} = INVENTORY.COLORS
    
    // list of Vehicle Types
    public vehicleTypes: {} = INVENTORY.VEHICLE_TYPES

    // Indicates a duplicate VIN was found.
    public dupe: boolean = false
    
    // Indicates the vehicle is currently being saved.
    public saving: boolean = false

    public information = new FormGroup({
        id: new FormControl(0),
        vehicleId: new FormControl(0),
        lotId: new FormControl(0),
        statusId: new FormControl(3), // 3 = Available
        dateOnLot: new FormControl(new Date().toISOString().substring(0, 10)),
        vinNumber: new FormControl(null, [Validators.required]),
        stockNumber: new FormControl(null, [Validators.required]),
        cleanStockNumber: new FormControl(null, []),        
        sellingPrice: new FormControl(formatCurrency(0), Validators.min(0)),
        modelYear: new FormControl(null),
        make: new FormControl(null),
        model: new FormControl(null),
        miles: new FormControl(null, [Validators.pattern('[0-9,]{1,20}'), Validators.min(0)]),
        odometer: new FormControl('ACTUAL', [Validators.required]),
        exteriorColor: new FormControl(null),
        interiorColor: new FormControl(null),
        type: new FormControl('Car'),
        cylinders: new FormControl(null),
        transmission: new FormControl(null),
        driveline: new FormControl(null),
        bodyStyle: new FormControl(null),
        trimLevel: new FormControl(null),
        engine: new FormControl(null),
        curbWeight: new FormControl(null),
        carryingWeight: new FormControl(null),
        gvwr: new FormControl(null),
        tonnage: new FormControl(null)
    })

    get vinNumber() { return this.information.get('vinNumber') }
    get stockNumber() { return this.information.get('stockNumber') }

    /*
    * life cycles
    */
    constructor(private router: Router, private route: ActivatedRoute, private ms: MessageService, private master: MasterService, private store: StoreService) {}

    ngOnInit() {
        const lotId = parseInt(localStorage.getItem('lot') || this.store.lotSelected)
        this.information.get("lotId").setValue(lotId)

        this.validity.next(this.information.valid)
        this.information.valueChanges.subscribe(() => {
            this.validity.next(this.information.valid)
        })

        this.saved.subscribe((e) => {
            if (this.information.invalid) {
                return
            }
            
            this.createInventory()
        })
    }

    ngOnChanges(changes: SimpleChanges): void {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        //Add '${implements OnChanges}' to the class.
    }

    /*
    * functions
    */

    public saveInventory() {
        this.createInventory()
    }

    public createInventory() {
        if (this.information.invalid) {
            return
        }

        const payload = JSON.parse(JSON.stringify(this.information.value))
        payload['sellingPrice'] = clean(payload['sellingPrice'])
        payload['miles'] = clean(payload['miles'])

        this.master.post(`inventory/`, { inventoryData: payload, featuresList: JSON.stringify(this.features) }, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.data.error == 'Error: Stock Number is taken.') {
                this.information.controls['stockNumber'].setErrors({ 'duplicateFound': "Error! Stock Number is taken." });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.created.emit(res.data.inventory.id)
        })
    }

    public decodeVIN(stockNumber: string, vinNumber: string) {
        if (!vinNumber) {
            return
        }

        // set stock Number and check duplicate VIN
        this.setStockNumber(stockNumber, vinNumber)

        //decode VIN here
        this.decode(vinNumber)
    }

    public decode(vinNumber: string) {
        const lotId = parseInt(this.information.get('lotId').value)
        if (!lotId || isNaN(lotId) || (lotId <= 0)) {
            return
        }

        this.master.get(`vendors/vin/decode?vinNumber=${vinNumber}&lotId=${lotId}`, res => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (!res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            const decoded = res.data
            this.information.get("modelYear").setValue(decoded.modelYear)
            this.information.get("make").setValue(decoded.make)
            this.information.get("model").setValue(decoded.model)
            this.information.get("cylinders").setValue(decoded.cylinders || null)
            this.information.get("transmission").setValue(decoded.transmission || null)
            this.information.get("driveline").setValue(decoded.driveline || null)
            this.information.get("trimLevel").setValue(decoded.trimLevel || null)
            this.information.get("bodyStyle").setValue(decoded.bodyStyle || null)
            this.information.get("engine").setValue(decoded.engine || null)
            this.information.get("curbWeight").setValue(decoded.curbWeight || null)
            this.features = decoded.features

        })
    }

    public setStockNumber(stockNumber: string, vinNumber: string): any {
        if (stockNumber !== "" && !this.information.controls['stockNumber'].hasError) {
            return
        }

        const lotId = parseInt(this.information.get('lotId').value)
        if (!lotId || isNaN(lotId) || (lotId <= 0)) {
            return
        }

        this.master.get(`inventory/stock/next?lotId=${lotId}&vinNumber=${vinNumber}`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            const info = res.data.inventoryStockData
            if (!info) {
                return
            }

            const stockNumber = info.preStockNumber || ''
            this.information.get("stockNumber").setValue(stockNumber)
            this.information.get("cleanStockNumber").setValue(stockNumber)

            this.checkDuplicateVIN(stockNumber, vinNumber)
        })
    }

    public checkDuplicateVIN(stockNumber: string, vinNumber: string) {
        if (!vinNumber || (vinNumber.length <= 0)) {
            return
        }

        this.dupe = false
        this.master.get(`inventory/vin/exists?vinNumber=${vinNumber}&stockNumber=${stockNumber}`, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status === 200) {
                return
            }

            this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
            this.dupe = (res.status === 409)
        })
    }
}


