import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MasterService } from 'src/app/services/master.service';
import * as words from '../../services/language'
import { MessageService } from 'src/app/services/message.service';
import { GpsInventoryAssignmentComponent } from "./gps-inventory-assignment/gps-inventory-assignment.component";

@Component({
  selector: 'app-gps',
  templateUrl: './gps.component.html',
  styleUrls: ['./gps.component.scss']
})
export class GpsComponent implements OnInit {
  @ViewChild(GpsInventoryAssignmentComponent, { static: false }) childC: GpsInventoryAssignmentComponent;
  // set all words
  public words = words.language
  // save all data by type
  public vehicles: Object[] = [];
  public permissions: Object[] = []
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  public gpsDevices = [] = []
  public unassigned: {};
  public installed: {};
  public activated: {};
  public inactive: {};
  public assignedData: {};
  public gpsInventoryAssignmentInformation = new FormGroup({
    id: new FormControl(0),
    GPSDeviceId: new FormControl(0, [Validators.required]),
    vehicleId: new FormControl(0, [Validators.required]),
    deviceLocation: new FormControl(null),
    installedBy: new FormControl(null)
  })
  public gpsDeviceInformation = new FormGroup({
    id: new FormControl(0),
    productName: new FormControl(null),
    serialNumber: new FormControl(0),
    status: new FormControl("Unassigned"),
    integrationCompanyId: new FormControl(null, [Validators.required])
  })
  public gpsProviders: Object[] = [];

  constructor(private master: MasterService, private ms: MessageService, private fb: FormBuilder) { }

  onUpdateChild() {
    this.childC.update();
 }

  ngOnInit() {
    this.listGPSDevices()
    this.listGPSProviders()
  }

  public listGPSProviders = () => {
    this.master.get('gpsProviders?category=GPS&enabled=1', res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.gpsProviders = res.data.integrations
    })
  }

  // todo: listen to output of navbar
  public listenerNav = (e): void => {
    switch (e['message']) {
      case 'changeLanguage':
        this.language = e['value']
        break
    }
  }

  public listGPSDevices = () => {
    this.master.get('gpsDevices', res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.gpsDevices = res.data.gpsDevices
      this.unassigned = this.gpsDevices.length > 0 ? this.gpsDevices.filter(gpsDevice => gpsDevice['status'] == 'Unassigned') : null
      this.installed = this.gpsDevices.length > 0 ? this.gpsDevices.filter(gpsDevice => gpsDevice['status'] == 'Installed') : null
      this.activated = this.gpsDevices.length > 0 ? this.gpsDevices.filter(gpsDevice => gpsDevice['status'] == 'Activated') : null
      this.inactive = this.gpsDevices.length > 0 ? this.gpsDevices.filter(gpsDevice => gpsDevice['status'] == 'Inactive') : null
    })
  }

  public listenerChildrens = (e) => {
    switch (e.message) {
      case "choseVehicle":
        this.gpsInventoryAssignmentInformation.patchValue({
          vehicleId: e.vehicleId,
        })
        this.assignedData = e.inventoryDetails
        break;
      case "refreshList":
        this.listGPSDevices()
        break;

      default:
        break;
    }
  }

  public assignSetup = (data) => {
    this.gpsInventoryAssignmentInformation.patchValue({
      GPSDeviceId: data.id,
    })
    document.getElementById('btn-choose-retail').click()
  }

  public editSetup = (data) => {
    this.gpsDeviceInformation.patchValue(data)
    document.getElementById('update-GPS-device').click()
  }

  public resetAllForms = () => {
    this.resetGPSInventoryAssignmentInformation()
    this.resetGPSDeviceInformation()
  }

  public resetGPSInventoryAssignmentInformation = () => {
    this.gpsInventoryAssignmentInformation.reset()
    this.assignedData = null
  }
  public resetGPSDeviceInformation = () => {
    this.gpsDeviceInformation.reset()
    this.assignedData = null
  }

  public addGPSInventoryAssignment = () => {
    let payload = this.gpsInventoryAssignmentInformation.value
    payload['status'] = this.gpsDeviceInformation.get('status').value
    this.master.post('gpsInventoryAssignment', { information: payload }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      document.getElementById('btn-close-modal-inventory-list').click()
      this.resetAllForms()
      this.listGPSDevices()
      this.onUpdateChild()

    })
  }

  public addGPSDevice = () => {
    let payload = this.gpsDeviceInformation.value
    this.master.post('gpsDevice', { information: payload }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      document.getElementById('btn-close-modal-add-gps-device').click()
      this.listGPSDevices()
    })
  }

  public updateGPSDevice = () => {
    let payload = this.gpsDeviceInformation.value
    this.master.put(`gpsDevice/${payload.id}`, { information: payload }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      document.getElementById('btn-close-modal-update-gps-device').click()
      this.resetAllForms()
      this.listGPSDevices()
      this.onUpdateChild()
    })
  }
}
