import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, Injector } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
// services
import { MessageService } from '../../../../services/message.service';
import { MasterService } from '../../../../services/master.service';
import { StoreService } from '../../../../services/store.service';
import * as words from '../../../../services/language'
import { formatCurrency } from 'src/app/utils/format';
import { clean } from 'src/app/utils/numeric';
import { ago } from 'src/app/utils/date';
import * as moment from 'moment';

@Component({
    selector: 'promise-edit',
    templateUrl: './promise-edit.component.html',
    styleUrls: ['./promise-edit.component.scss']
})
export class PayPromiseEditComponent implements OnInit {
    /*
    * variables
    */
    
    // Unique internal identity of the expected payment whose promies are being edited.
    @Input() expectedId: number = 0
    
    // Currently selected language
    @Input() language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
    
    // Word replacements for each language.
    @Input() words: Object = words.language

    // Communicate the completion of the editing process. Let the listner know if anything changed via the boolean.
    @Output() edited: EventEmitter<Boolean> = new EventEmitter()
    
    // Indicates whether the data is currently loading.
    public loading: boolean = false

    // The expected payment whose promises are being modified.
    public expected: Object = null
    
    public form = new FormGroup({
        promises: this.fb.array([])
    })

    get promises() {
        return this.form.controls['promises'] as FormArray;
    }

    constructor(private master: MasterService, private ms: MessageService, private store: StoreService, private fb: FormBuilder) { }

    ngOnInit() {
        this.reset()

        if (!this.expectedId) {
            return
        }

        this.fetch()
        this.read()
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes) {
            return
        }

        const first = Object.values(changes).some(c => c.isFirstChange())
        if (first) {
            return
        }

        if (changes.expectedId) {
            this.reset()
            this.fetch()
            this.read()
        }
    }
    
    /*
    * functions
    */

    /**
     * 
     * @param e 
     */
    public cancel = (e: Event): void => {
        e.stopPropagation()
        e.preventDefault()

        this.edited.next(false)
        this.reset()
    }
   
    /**
     * 
     * @param e 
     */
    public save = (e: Event): void => {
        e.stopPropagation()
        e.preventDefault()

        if (this.form.invalid) {
            return
        }

        const adds = []
        for (let promise of this.promises.controls) {
            const controls = (promise as FormGroup).controls
            
            const id = parseInt(controls.id.value)
            if (id && isNaN(id) && (id > 0)) {
                this.modify(id, {
                    due: controls.due.value,
                    amount: controls.amount.value,
                    note: controls.note.value
                })

                continue
            }

            adds.push({
                due: controls.due.value,
                amount: clean(controls.amount.value),
                note: controls.note.value
            })
        }

        if (adds.length <= 0) {
            this.edited.next(true)
            this.reset()
            return
        }
        
        this.master.post(`payments/expected/${this.expectedId}/promises`, {promises: adds}, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }
            
            this.edited.next(true)
            this.reset()
        })
    }

    /**
     * 
     */
    public modify = (promiseId: number, payload: Object): void => {
        if (!promiseId || (promiseId <= 0) || !payload) {
            return
        }
        
        this.master.put(`payments/promises/${promiseId}`, payload, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }
        })
    }

    /**
     * 
     */
    public close = (promiseId: number, index: number): void => {
        if (!promiseId || (promiseId <= 0)) {
            return
        }
        
        this.master.patch(`payments/promises/${promiseId}/close`, null, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            if ((index < 0) || (index > this.promises.length - 1)) {
                return
            }
    
            this.promises.removeAt(index)
        })
    }

    /**
     * 
     */
    public delete = (promiseId: number, index: number): void => {
        if (!promiseId || (promiseId <= 0)) {
            this.promises.removeAt(index)
            return
        }

        this.master.discard(`payments/promises/${promiseId}`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            if ((index < 0) || (index > this.promises.length - 1)) {
                return
            }
    
            this.promises.removeAt(index)
        })
    }

    /**
     * Add a new expected payment promise row to the form array
     * @param e Event that triggerd this method, if any
     */
    public add = (e): void => {
        const promise = this.fb.group({
            id: new FormControl(0, []),
            due: new FormControl(null, [Validators.required]),
            amount: new FormControl(formatCurrency(0), [Validators.required, Validators.min(1), Validators.pattern('^[0-9]{1,16}(\.[0-9]{0,2})?$')]),
            note: new FormControl(null, [])
        })

        this.promises.push(promise)
    }

    /*
    * Reset the promises input array
    */
    private reset = (): void => {
        this.expected = null
        this.loading = false
        this.form.reset()
        this.promises.clear()
    }

    /**
     * 
     */
    private read() {
        if (!this.expectedId) {
            return
        }

        this.master.get(`payments/expected/${this.expectedId}`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }
            
            this.expected = res.data
            this.preset()
        })
    }

    /**
     * 
     */
    private fetch() {
        if (this.loading) {
            return
        }

        if (!this.expectedId) {
            return
        }

        this.loading = true
        this.promises.clear()
        this.master.get(`payments/expected/${this.expectedId}/promises`, res => {
            this.loading = false

            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }
            
            const results = res.data.promises
            if (!results || (results.length <= 0)) {
                this.add(null)
                this.preset()
                return
            }

            for (const item of results) {
                const promise = this.fb.group({
                    id: new FormControl(item['id'], []),
                    due: new FormControl(item.due, [Validators.required]),
                    amount: new FormControl(formatCurrency(item.amount), [Validators.required, Validators.min(1), Validators.pattern('^[0-9]{1,16}(\.[0-9]{0,2})?$')]),
                    note: new FormControl(item.note || null, [])
                })
        
                this.promises.push(promise)
            }

            this.preset()
        })
    }

    /**
     * Preset promise data if conditions are right
     */
    private preset() {
        if (!this.expected || !this.promises || (this.promises.length !== 1)) {
            return
        }

        const promise = this.promises.at(0)
        if (!promise) {
            return
        }

        const promiseId = parseInt(promise.get('id').value)
        if (promiseId && !isNaN(promiseId) && (promiseId > 0)) { // not a new promise
            return
        }

        const due = new Date(this.expected['due'])
        due.setDate(due.getDate() + (5-due.getDay() + 7)) // next Friday

        promise.patchValue({
            amount: formatCurrency(parseFloat(this.expected['amount'])),
            due: moment(due).format('YYYY-MM-DD')
        }, {emitEvent: false})
    }

    /**
     * Generate a color class (past, today, etc..) for the given due date.
     * @param due A date to generate a color class by.
     */
    public dueToClass = (due: string): string => {
        return ago(due)
    }
}
