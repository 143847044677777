import { Component, Input, OnInit, InjectionToken, Injector, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { RoleService, RoleItem } from 'src/app/services/role.service';
import { DynamicService } from 'src/app/services/dynamic.service';
import { MasterService } from 'src/app/services/master.service';
import { MessageService } from 'src/app/services/message.service';
import * as languageLibrary from '../../../../../services/language'
export const token = new InjectionToken<string>('');

@Component({
  selector: 'contact-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class ContactRolesComponent implements OnInit {
  @Output() setupNeededEvent = new EventEmitter<Object>()
  @Output() setupSavedEvent = new EventEmitter<Object>()
  @Input() contactId: number = 0
  @Input() roleArray: []
  @Input() calledFrom: string = null
  @Input() type: string
  public words = languageLibrary.language
  public language: string = 'EN'
  public roles: RoleItem[] = [];
  public activeComponent: any;
  public activeComponentData: string = '';
  public receivedData: string;
  public myInjector: Injector;
  public contactRolesCollection: [] = [];
  public selectableContactRolesCollection: string[];
  public newRoleArray: any;
  public actionRoleName: string = null
  public activeTabIndex: number;

  ngOnInit(): void {
    this.getContactRolesCollection()
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.type) {
      this.type = changes.type.currentValue
    }
    if (changes.contactId) {
      this.contactId = changes.contactId.currentValue
      if (isNaN(this.contactId)) {
        return
      }
      this.getContactRoles()
    }
  }

  public getContactRolesCollection() {
    this.master.get(`collections/contacts/roles?display=true`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.contactRolesCollection = res.data.contactRoleTypes
      this.selectableContactRolesCollection = this.contactRolesCollection
      this.getContactRoles()
    })
  }

  public getContactRoles() {
    if (!this.contactId) {
      return
    }

    this.reset()
    this.master.get(`contacts/${this.contactId}/roles`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      if (res.data.contactRoles.length == 0) {
        return
      }

      this.roleArray = res.data.contactRoles
      if (!this.roleArray || !this.contactId) {
        return
      }
      
      this.loadRoles()
    })
  }

  public reset() {
    this.roles = []
    this.receivedData = '';
    this.newRoleArray = []
    this.actionRoleName = null
    this.activeComponent = null;
    this.activeComponentData = null;
    this.activeTabIndex = null
    this.selectableContactRolesCollection = this.contactRolesCollection
  }

  public loadRoles() {
    if (this.roleArray.length == 0 || this.contactRolesCollection.length == 0) {
      this.reset()
      return
    }
    if (this.calledFrom !== "contactAdvancedSearch") {
      this.selectableContactRolesCollection = this.contactRolesCollection.filter((contactRoleType) => !this.roleArray.find(item => item['contactType']['key'] === contactRoleType['key']));
      this.newRoleArray = this.roleArray
    } else {
      this.newRoleArray = this.roleArray.filter(role => role['contactType']['key'] == this.type)
    }
    this.roles = this.roleService.getRoles(this.newRoleArray, this.contactId, this.calledFrom);
    this.serv.getObservable().subscribe((data) => {
      this.receivedData = data;
      this.actionRoleName = JSON.parse(data)
      if (this.actionRoleName['action'] == 'deactivate') {
        document.getElementById("deactivateRoleBtn").click()
      }
      if (this.actionRoleName['action'] == 'setupNeeded') {
        this.setupNeededEvent.emit({ setupNeeded: this.actionRoleName['setupNeeded'], type: this.actionRoleName['type'] })
      }
      if (this.actionRoleName['action'] == 'setupSaved') {
        this.setupSavedEvent.emit({ setupSaved: this.actionRoleName['setupSaved'], type: this.actionRoleName['type'] })
      }
    });
    if (!this.roles || this.roles.length == 0) {
      this.reset()
      return
    }
    this.ldTab(this.roles[0].component, this.roles[0].data, 0);
  }

  public setActiveTab(roleInd: number) {
    this.roles.forEach((role, roleIndex) => {
      role.isActive = roleIndex === roleInd;
    });
  }

  public ldTab(tabComponent: any, tabData: string, tabIndex: number) {
    setTimeout(() => {
      if (this.activeTabIndex == tabIndex) {
        return
      }
      this.setActiveTab(tabIndex);
      this.receivedData = '';
      this.actionRoleName = null
      this.activeComponent = tabComponent;
      this.activeComponentData = tabData;
      this.activeTabIndex = tabIndex;
      this.createInjector();
    }, 0);
  }

  public createInjector() {
    this.myInjector = Injector.create({
      providers: [{ provide: token, useValue: this.activeComponentData }],
      parent: this.injector,
    });
  }

  /**
   * 
   * @param {String} contactRoleType The contact role (type) key name to add to this contact.
   */
  public addRole(contactRoleType: string) {
    this.master.post(`contacts/${this.contactId}/roles`, { information: contactRoleType }, res => {
      if (!res) {
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      document.getElementById("close-modal-roleOption").click()
      this.getContactRoles()
    })
  }

  public deactivateRole() {
    this.master.discard(`contacts/${this.contactId}/roles/${this.actionRoleName['contactRoleId']}`, res => {
      if (!res) {
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      document.getElementById("deactivateRoleBtn").click()
      this.getContactRoles()
    })
  }

  constructor(private roleService: RoleService, private injector: Injector, private serv: DynamicService, private master: MasterService, private ms: MessageService) { }

}
