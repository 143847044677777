import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
// services
import { MessageService } from "../../../../../../services/message.service";
import { MasterService } from "../../../../../../services/master.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as languageLibrary from '../../../../../../services/language'
import { StoreService } from "src/app/services/store.service";
import { PAYMENT_CYCLES } from '../../../../../../constants/payments'
import { formatDecimal } from '../../../../../../utils/format'
import * as moment from "moment";


@Component({
    selector: "outside-insurance",
    templateUrl: "./outside-insurance.component.html",
    styleUrls: ["./outside-insurance.component.scss"],
})
export class OutsideInsuranceComponent implements OnInit {
    @Input() saleId: number;
    @Input() saleInfo: Object = Array;
    @Input() outsideInsurancePolicyInfo: Object = Array;
    @Output() emitterInformation$: EventEmitter<Object> = new EventEmitter()
    public id: number = null
    public status: string = null;
    public paymentCycles = PAYMENT_CYCLES
    public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
    public insuranceInformation = new FormGroup({
        id: new FormControl(null),
        cpi: new FormControl(0, [Validators.required]),
        contactId: new FormControl(null, [Validators.required]),
        saleId: new FormControl(null, [Validators.required]),
        status: new FormControl("Active", [Validators.required])
    })
    public personalAutoInsurances = []

    public expectedPayments: Object[] = [];
    public sales: Object[] = [];
    public companiesInsurance: Object[] = [];
    public words = languageLibrary.language
    public inactivePolicies: Object[] = [];
    public idForAction: Number = 0;
    public insuranceDetailToCopy: [] = [];
    // todo: listen to output of navbar
    public listenerNav = (e): void => {
        switch (e['message']) {
            case 'changeLanguage':
                this.language = e['value']
                break;
            default:
                break;
        }
    }
    public effectiveFrom: Date;
    public expiryDate: Date;

    constructor(
        private route: ActivatedRoute,
        private ms: MessageService,
        private master: MasterService,
        private store: StoreService,
        private location: Location
    ) { }

    ngOnInit() {
        // this.getVendors()
        this.getPersonalAutoInsurance()
    }

    ngOnChanges(changes: SimpleChanges): void {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        //Add '${implements OnChanges}' to the class.
        if (changes.saleInfo) {
            if (!this.saleInfo) {
                return
            }
            this.insuranceInformation.get('saleId').setValue(this.saleId)
        }

        if (changes.outsideInsurancePolicyInfo) {
            if (changes.outsideInsurancePolicyInfo.currentValue !== null) {
                this.setInformation(changes.outsideInsurancePolicyInfo.currentValue)
            }
        }
    }
      
    public getPersonalAutoInsurance() {
        this.resetInformation()
        this.master.get(`contacts/${this.saleInfo['contactId']}/personalAutoInsurance`, res => {
          if (!res) {
            this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
            return
          }
    
          if (res.status !== 200) {
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
            return
          }
    
          if (res.data.contactPersonalAutoInsurance.length == 0) {
            return
          }
    
          this.personalAutoInsurances = res.data.contactPersonalAutoInsurance
        })
      }

    public saveInsurance() {
        if (!this.insuranceInformation.valid) {
            return
        }

        this.insuranceInformation.updateValueAndValidity({ onlySelf: true })
        if (this.id <= 0) this.createInsurance()
        if (this.id >= 1) this.modifyInsurance()
    }

    public modifyInsurance() {
        this.master.put(`sales/${this.saleId}/insurance/${this.id}`, { information: this.insuranceInformation.value }, res => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.emitterInformation$.emit({ message: 'getAutoInsurance' })

        })
    }

    public createInsurance() {
        this.master.post(`sales/${this.saleId}/insurance/`, { information: this.insuranceInformation.value }, res => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.emitterInformation$.emit({ message: 'getAutoInsurance' })
            this.location.replaceState(`sales/${this.saleId}/insurance/${this.id}`);
        })
    }

    public setDetails(insuranceDetail) {
        this.insuranceDetailToCopy = insuranceDetail
    }
    
    public deactivateInsurancePolicy() {
        this.master.patch(`sales/${this.saleId}/insurance/${this.id}/deactivate`, { saleId: this.saleId }, res => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.resetInformation()

            document.getElementById("btn-close-modal-cancel-insurance").click();
            this.emitterInformation$.emit({ message: 'getAutoInsurance' })
        })
    }

    public setId(id) {
        this.idForAction = id
    }

    public getVendors() {
        this.master.get(`contacts?type=vendor`, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.companiesInsurance = res.data.contacts
        })
    }

    public resetInformation() {
        this.id = null
        this.status = null
        this.idForAction = null
        this.expectedPayments = null
        this.insuranceInformation.reset()
        this.insuranceInformation.get('saleId').setValue(this.saleId)
        this.insuranceInformation.get('status').setValue("Active")
        this.insuranceInformation.get('cpi').setValue(0)
    }

    // public setDuplicateInformation(insurancePolicy) {
    //     this.id = null
    //     this.insuranceInformation.setValue({
    //         id: null,
    //         saleId: insurancePolicy['saleId'],
    //         status: "Active",
    //         contactId: insurancePolicy['contactId'],
    //         cpi: 0
    //     })
    // }

    public setInformation(insurancePolicies) {
        this.id = insurancePolicies['id']
        this.status = insurancePolicies['status']
        this.insuranceInformation.setValue({
            id: insurancePolicies['id'],
            cpi: insurancePolicies['cpi'],
            saleId: insurancePolicies['saleId'],
            status: insurancePolicies['status'],
            contactId: insurancePolicies['contactId']
        })
        this.expectedPayments = insurancePolicies.expectedPayments
    }
}
