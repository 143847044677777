import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// services
import { MasterService } from '../../../../services/master.service'
import { MessageService } from '../../../../services/message.service';
import * as languageLibrary from '../../../../services/language'
@Component({
  selector: 'register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit {
  public language: any = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  // Variables
  // define if password input is type text or password
  public typePassword: string = 'password'
  // define if the email is already user
  public emailExist: boolean = false
  // display de loading state
  public loading: boolean = false
  // check accept Terms and Conditions
  public acceptTerms = false
  // corporates user list
  public userCorporateList = []
  // list of unacceptable URLs
  public unacceptableURLList = ["www"]
  // have Password
  public passwordSet: boolean = false
  // 
  public userId = ''
  // 
  public isWww: boolean = false
  // create new corporate variable
  public newCorporate: boolean = false
  // Group of inputs
  public information = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email, Validators.maxLength(150), Validators.minLength(5), Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/)]),
    firstName: new FormControl(null, [Validators.required, Validators.maxLength(50), Validators.minLength(1), Validators.pattern('[A-Za-zÑñÁáÉéÍíÓóÚú ]{1,50}')]),
    lastName: new FormControl(null, [Validators.required, Validators.maxLength(50), Validators.minLength(1), Validators.pattern('[A-Za-zÑñÁáÉéÍíÓóÚú _-]{1,50}')]),
    password: new FormControl(null, [Validators.required, Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&?¿¡*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&?¿¡*()_+]{7,39}$/)]),
    companyName: new FormControl(null, [Validators.required, Validators.maxLength(150), Validators.minLength(3)]),
    corporateURL: new FormControl(null, [Validators.required, Validators.maxLength(150), Validators.minLength(3), Validators.pattern('[A-Za-z0-9_-]{3,150}')]),
    phone: new FormControl(null, [Validators.required, Validators.pattern('[A-Za-z0-9_-]{3,150}')]),
    dealerNumber: new FormControl(null, [Validators.required, Validators.maxLength(150), Validators.minLength(3)]),
    position: new FormControl(null, [Validators.required]),
    acceptTerms: new FormControl(false, [Validators.required, Validators.pattern("true")]),
  })
  corporateExists: boolean;
  // get information of inputs
  get email() { return this.information.get('email') }
  get firstName() { return this.information.get('firstName') }
  get lastName() { return this.information.get('lastName') }
  get password() { return this.information.get('password') }
  get corporateURL() { return this.information.get('corporateURL') }
  get companyName() { return this.information.get('companyName') }
  get phone() { return this.information.get('phone') }
  get dealerNumber() { return this.information.get('dealerNumber') }
  // functions

  // change the input type, passsword or text
  public changeTypePassword = (): void => {
    if (this.typePassword == 'text') {
      this.typePassword = 'password'
    } else {
      this.typePassword = 'text'
    }
  }

  public preValidateCorporateURL = () => {
    this.information.controls['corporateURL'].setErrors(null);
    let corpURL = this.information.value['corporateURL'] ? this.information.value['corporateURL'].toLowerCase().toLowerCase() : null
    if (!corpURL) {
      return
    }
    if (this.userCorporateList.find(res => res.corporateURL.toLowerCase() === corpURL + '.rockitdms.com')) {
      this.information.controls['corporateURL'].setErrors({ 'duplicateCorp': 'This is a duplicate corporation.' });
      return
    }
    if (this.unacceptableURLList.find(res => res === corpURL)) {
      this.information.controls['corporateURL'].setErrors({ 'unacceptableURL': 'Please enter a valid Corporate URL.' });
    }
    this.information.get('corporateURL').setValue(corpURL)
  }

  // implements the master services and message services
  constructor(private master: MasterService, private ms: MessageService, private router: Router) { }

  ngOnInit() {

  }

  public setCorporateURL() {
    this.information.get('corporateURL').setValue(this.information.get('companyName').value.replaceAll(' ', '').replaceAll(/[`~!@#$%^&*()|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ''))
    this.preValidateCorporateURL()
  }

  public registerCorporation() {
    this.preValidateCorporateURL()
    if (this.information.invalid) {
      return
    }
    this.master.post(`corporations`, { information: this.information.value }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        this.corporateExists = true
        this.information.controls['corporateURL'].setErrors({ 'duplicateCorp': res.error });
        return
      }

      this.router.navigate([`email/${this.information.get('email').value}`])
    })
  }
  public findUser() {
    this.passwordSet = false
    this.master.get(`user?email=${this.information.get('email').value}`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      if (!res.data.userAndCorp || !res.data) {
        return
      }
      this.userCorporateList = res.data.userAndCorp.corporates
      this.userId = res.data.userAndCorp.userId
      this.passwordSet = res.data.userAndCorp.passwordSet
      if (this.passwordSet) {
        this.information.get('password').setValidators(null)
        this.information.get('password').updateValueAndValidity()
      } else {
        this.information.get('password').setValidators([Validators.required, Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&?¿¡*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&?¿¡*()_+]{7,39}$/)])
        this.information.get('password').updateValueAndValidity()
      }
      this.preValidateCorporateURL()
      this.showNewUserForm(false)
    })
  }

  public showNewUserForm(show) {
    this.newCorporate = show
    this.preValidateCorporateURL()
  }
}
