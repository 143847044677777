import { Component, OnInit, OnChanges, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";

// services
import { MasterService } from '../../../../../../services/master.service'
import { StoreService } from '../../../../../../services/store.service'
import { MessageService } from '../../../../../../services/message.service'
import * as languageLibrary from '../../../../../../services/language'
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as moment from 'moment'

import { TAX_METHODS, SALE_TYPES, PAYMENT_CYCLE_OPTIONS, SAVE_DEBOUNCE_MILLIS } from 'src/app/constants/sale';
import { formatCurrency, formatPercent, formatCount } from 'src/app/utils/format';
import { clean } from 'src/app/utils/numeric';
import { COLLECTION_METHODS } from 'src/app/constants/payments';


@Component({
  selector: 'terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit, OnChanges {

  // ======================
  // * variables
  // ======================
  
  /**  */
  @Input() isUnLock = true
  
  /**  */
  @Input() saleId = null

  /**  */
  @Input() saleType: number = 0
  
  /**  */
  @Input() totalSalePrice: number = 0
  
  /**  */
  @Input() baseSalePrice: number = 0
  
  /**  */
  @Input() driveoutPrice: number = 0

  /**  */
  @Input() tradeAllowance: number = 0

  /**  */
  @Input() tradePayoff: number = 0

  /**  */
  @Input() salesTax: number = 0

  /**  */
  @Input() amountToFinance: number = 0

  /** The primary buyer or lot zip code. */
  @Input() saleZip: number = 0

  /** The purchased vehicle year of manufacture. */
  @Input() vehicleYear: number = 0

  /** Lot presets from the corporate seetup screen.  */
  @Input() presets = null

  /** The sale date or the last down/deferred payment date, if any */
  @Input() lastDeferredPayment = null

  /** Contract/Interest state date */
  @Input() sold: string = null

  /** Let listeners know the terms have changed. */
  @Output() termsEmitter$: EventEmitter<Object> = new EventEmitter()

  // 
  public lienholders = []
  
  //
  public paymentsList = []

  //  An associative array of sale tax methods with the id as the key
  public taxMethods: Object = TAX_METHODS

  // The collection setup for the lot associated with this sale.
  private setup: Object = null

  // A string array of collection methods
  public collectionMethods: string[] = COLLECTION_METHODS

  //  An associative array of sale types with the id as the key
  public saleTypes: Object = SALE_TYPES
  
  //
  public totalPayments = 0

  //
  public maxApr: number = -1 // 0 is a valid value, -1 indicates not set

  //
  public stateMaxApr: number = -1 // 0 is a valid value, -1 indicates not set

  //
  public presetMaxApr: number = -1 // 0 is a valid value, -1 indicates not set
  
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  
  // set all words
  public words = languageLibrary.language
  
  // define loading status
  public loading: boolean = false

  //
  public showMore: boolean = false
  
  //
  public lotId: number = 0

  public frequencies: any[] = []

  private calculating: boolean = false

  private solveFor: string = 'payment'

  /** A setTimeout watcher to delay computations during times of rapid changes (first load) */
  private watcher: ReturnType<typeof setTimeout> = null

  /**  */
  private navbarWatcher: any = null
  
  //
  public terms = new FormGroup({
    id: new FormControl(0, [Validators.required]),
    taxMethod: new FormControl(null, [Validators.required]),
    collectionMethod: new FormControl(null, [Validators.required]),
    financed: new FormControl(0, [Validators.required]),
    interest: new FormControl(0, []),
    apr: new FormControl(0, [Validators.required]),
    maxApr: new FormControl(0, []),
    paymentCycleId: new FormControl(0, [Validators.required]),
    term: new FormControl(1, [Validators.required]),
    payment: new FormControl(0, []),
    final: new FormControl(0, []),
    first: new FormControl(moment().format('yyyy-MM-DD'), []),
    maturity: new FormControl(null, []),
    payments: new FormControl(0, []),
    months: new FormControl(0, []),
    dealerAPRLimit: new FormControl(0, []),
    contractRate: new FormControl(0, []),
  })

  public dealerAPRLimit: number = 0;
  
  public availableToSelect: boolean = true;

  get outside() { return ((this.saleType) && (this.saleTypes[this.saleType] === 'Outside')) }
  get bhph() { return ((this.saleType) && (this.saleTypes[this.saleType] === 'BHPH')) }

  /** Indicates if saleType supports/needs terms */
  get termable (): boolean { return ((this.saleType) && (['Outside', 'BHPH'].includes(this.saleTypes[this.saleType]))) }
  get taxMethodKeys() { return Object.keys(this.taxMethods) }

  // ======================
  // * life cycle
  // ======================
  constructor(private route: ActivatedRoute, private master: MasterService, private ms: MessageService, private store: StoreService) {
    this.navbarWatcher = this.ms.channelComponents$.subscribe(res => {
      switch (res.message) {
        case 'setPermissions':
          break
        case 'changeLot':
          this.lotId = parseInt(this.store.lotSelected)
          this.setup = null
          this.readCollectionSetup()
          break
      }
    })
  }

  ngOnInit() {
    this.readFrequencies()
    this.fetchLienholders()
    this.readTerms()
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (!changes) {
      return
    }

    const first = Object.values(changes).some(c => c.isFirstChange())
    if (first) {
      return
    }

    if (changes.isUnLock) {
      this.terms.disable({emitEvent: false})
      if (this.isUnLock) {
        this.terms.enable({emitEvent: false})
      }
    }

    if (changes.saleZip || changes.vehicleYear) {
      this.fetchMaxApr()
    }

    if (changes.saleType) {
      this.lienholders = []
      this.terms.get('financed').markAsDirty()

      if (this.outside) {
        this.terms.get('taxMethod').setValue('PAID')
        this.calculate()       
      }

      this.fetchLienholders()
    }

    if (changes.presets && this.presets) {
      if (!this.terms.get('taxMethod').value) {
          this.terms.get('taxMethod').setValue(this.presets['defaultBHPHTaxMethod'])
      }

      if (!this.terms.get('paymentCycleId').value) {
        this.terms.get('paymentCycleId').setValue(this.toCycleId(this.presets['defaultBHPHPaymentCycle']))
      }
    }

    if (changes.lastDeferredPayment) {
      this.computeFirstPayDate()
    }

    if (changes.saleId) {
      const identity = parseInt(this.saleId)
      if (isNaN(identity) || (identity <= 0)) {
        return
      }

      this.fetchLienholders()
      this.readTerms()
    }

    if (changes.amountToFinance) {
      if (this.watcher) {
        clearTimeout(this.watcher)
      }

      const financed = this.terms.get('financed').value
      if (this.loading || (financed == changes.amountToFinance.currentValue)) { // no real change?
        return
      }
      
      this.watcher = setTimeout(() => {
          this.terms.get('financed').markAsDirty()
          this.calculate()
      }, SAVE_DEBOUNCE_MILLIS)
    }
  }

  ngOnDestroy() {
    this.navbarWatcher.unsubscribe()
  }

  /**
     * Helper method to consistently get the list of form fees as an Array.
     */
  get taxDeferred() {
    return (this.terms.get('taxMethod').value === 'DEFERRED');
  }

  /**
   * Get a list of lienholders attched to this sale.
   */
  private fetchLienholders = () => {
    this.lienholders = []
    const identity = parseInt(this.saleId)
    if (isNaN(identity) || (identity <= 0)) {
      return
    }

    this.master.get(`sales/${identity}/lienholders`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.lienholders = res.data.saleLienholders || []
    })
  }

  private readFrequencies = () => {
    this.master.get(`collections/paymentcycles`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.frequencies = res.data.paymentCycles

      if (this.saleId) { // will get frequency from readTerms?
        return
      }

      if (!this.presets || !this.presets['defaultBHPHPaymentCycle']) {
        return
      }

      const defaultCycleId = this.toCycleId(this.presets['defaultBHPHPaymentCycle'])
      if (!defaultCycleId) {
        return
      }

      this.terms.get('paymentCycleId').setValue(defaultCycleId, {emitEvent: false})
    })
  }

  // ======================
  // ? read all terms
  // ======================
  private readTerms = () => {
    const identity = parseInt(this.saleId)
    if (isNaN(identity) || (identity <= 0)) {
      return
    }

    this.loading = true
    this.master.get(`sales/${identity}/terms`, res => {
      this.loading = false

      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
      
      const meta = res.data
      if (!meta || !meta['saleTerms']) {
        return
      }

      let defaultCycleId = 0
      if (this.frequencies && (this.frequencies.length > 0)) {
        defaultCycleId = this.frequencies[0].id
      }     

      // loan terms
      this.terms.setValue({
        id: parseInt(meta['saleTerms'].id),
        taxMethod: this.saleType == 4 ? (meta['saleTerms']['sale'].taxMethod || this.presets['defaultBHPHTaxMethod']) : 'DEFERRED',
        collectionMethod: meta['saleTerms'].collectionMethod || null,
        paymentCycleId: parseInt(meta['saleTerms'].paymentCycleId || defaultCycleId),
        payment: formatCurrency(meta['saleTerms'].schedulePmtAmt),
        apr: formatPercent(meta['saleTerms'].APR || 0),
        maxApr: formatPercent(meta['saleTerms'].maxApr || 0),
        financed: meta['saleTerms'].amountToFinance || 0,
        interest: meta['saleTerms'].financeCharges || 0,
        final: meta['saleTerms'].lastPmtAmt || 0,
        term: formatCount(meta['saleTerms'].numberOfPmt || 1),
        months: formatCount(meta['saleTerms'].months || 0),
        first: meta['saleTerms']['firstScheduledPmtDue'] ? moment(meta['saleTerms']['firstScheduledPmtDue']).format('yyyy-MM-DD') : moment().format('yyyy-MM-DD'),
        maturity: meta['saleTerms']['maturity'] ? moment(meta['saleTerms']['maturity']).toDate() : moment().toDate(),
        payments: meta['saleTerms'].totalPaymentAmount || 0,
        dealerAPRLimit: formatPercent(meta['saleTerms'].dealerAPRLimit || 0),
        contractRate: formatPercent(meta['saleTerms'].contractRate || 0),
      }, { emitEvent: false })

      this.maxApr = this.terms.get('maxApr').value
      this.dealerAPRLimit = this.terms.get('dealerAPRLimit').value
      this.lotId = parseInt(meta['saleTerms']['lotId'])
      this.readCollectionSetup()
      // this.fetchMaxApr()
    })
  }

  /**
   * 
   */
  private readCollectionSetup = async () => {
      if (this.setup) {
          console.log('readCollectionSetup: already have setup')
          return
      }

      if (!this.lotId || (this.lotId <= 0)) {
          console.log('readCollectionSetup: no lotId')
          return
      }

      await this.master.getAsync(`lots/${this.lotId}/collectionSetup`).then( async (res) => {
          this.setup = res.data.collectionSetup

          const method = this.terms.get('collectionMethod').value
          if (((method) && (method.length > 0)) || !this.setup || !this.setup['defaultCollectionMethod']) {
            return
          }

          this.terms.get('collectionMethod').setValue(this.setup['defaultCollectionMethod'])
      })
  }

  /**
   * Obtain the maximum allowed APR that can be used 
   * for in-house (bhph) financing based on the term 
   * and other sale details.
   */
  private fetchMaxApr = () => {
    this.calculate(this.solveFor)
    return
    this.stateMaxApr = -1
    if (!this.saleZip || (this.saleZip <= 0) || 
        !this.vehicleYear || (this.vehicleYear <= 0)) {
      return
    }

    const term = parseInt(this.terms.get('term').value)
    if (isNaN(term) || (term <= 0)) {
      return
    }  
    
    const months = this.toMonths(term)

    this.master.get(`vendors/apr/limits?zip=${this.saleZip}&year=${this.vehicleYear}&term=${months}`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      if (!res.data || !res.data.limit) {
        this.maxApr = -1
        return
      }

      this.maxApr = parseFloat(res.data.limit)
      if ((this.maxApr === undefined) || isNaN(this.maxApr) || (this.maxApr < 0)) {
        this.maxApr = -1
      }

      this.stateMaxApr = this.maxApr

      const preset = this.maxAprPreset()
      if ((preset >= 0) && (this.maxApr >= 0) && (preset < this.maxApr)) {
        this.maxApr = preset
      }

      this.terms.get('maxApr').setValue(formatPercent(this.maxApr))

      if (this.isUnLock && (this.maxApr >= 0) && ((this.terms.get('term').dirty) || (this.terms.get('paymentCycleId').dirty) || (this.terms.get('apr').value == 0))) {
        this.terms.get('apr').setValue(formatPercent(this.maxApr))
        this.calculate(this.solveFor)
      }
    })
  }
  
  /**
   * Check for an APR lot sales setup 
   * preset and use it if found.
   * @returns The max APR preset from lot setup or -1 if a preset could not be found.
   */
  private maxAprPreset = (): number => {
    return
    this.presetMaxApr = -1
    if (!this.presets || !this.vehicleYear) {
      return -1
    }

    const useStateMaximums = (this.presets['useStateMaximums'] && (this.presets['useStateMaximums'] == true)) 
    if (useStateMaximums) {
      return -1
    }

    const age = (new Date()).getFullYear() - this.vehicleYear

    let preset = -1
    if (age <= 2) {
      preset = parseFloat(this.presets['aprBelow2'])
    }
    else if ((age >= 3) && (age <= 4)) {
      preset = parseFloat(this.presets['apr3To4'])
    }
    else if (age >= 5) {
      preset = parseFloat(this.presets['apr5Over'])
    }

    if ((preset === undefined) || isNaN(preset) || (preset < 0)) { // 0 is a valid value
      return -1
    }

    this.presetMaxApr = preset

    return preset
  }

  // ======================
  // Save the terms to the database.
  // ======================
  public save = () => {
    const identity = parseInt(this.terms.get('id').value)
    if (!identity || isNaN(identity) || (identity <= 0)) {
      return
    }

    const payload = {
      taxMethod: this.terms.get('taxMethod').value,
      collectionMethod: this.terms.get('collectionMethod').value,
      paymentCycleId: parseInt(this.terms.get('paymentCycleId').value),
      schedulePmtAmt: clean(this.terms.get('payment').value),
      APR: clean(this.terms.get('apr').value),
      maxApr: clean(this.terms.get('maxApr').value),
      amountToFinance: clean(this.terms.get('financed').value),
      financeCharges: clean(this.terms.get('interest').value),
      lastPmtAmt: clean(this.terms.get('final').value),
      totalPaymentAmount: clean(this.terms.get('payments').value),
      numberOfPmt: parseInt(this.terms.get('term').value),
      months: this.toMonths(parseInt(this.terms.get('term').value)),
      firstScheduledPmtDue: this.terms.get('first').value,
      maturity: this.terms.get('maturity').value,
      dealerAPRLimit: clean(this.terms.get('dealerAPRLimit').value),
      contractRate: clean(this.terms.get('contractRate').value),
    }

    this.master.put(`sales/${this.saleId}/terms/${identity}`, payload, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }
        
      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
    })
  }

  // ======================
  // Save the sale tax method after a change so 
  // the compute picks up the new setting
  // ======================
  public patchSale = () => {
    const identity = parseInt(this.terms.get('id').value)
    if (!identity || isNaN(identity) || (identity <= 0)) {
      return
    }

    const payload = {
      taxMethod: this.terms.get('taxMethod').value
    }

    this.master.patch(`sales/${this.saleId}`, payload, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }
        
      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.calculate(this.solveFor)
    })
  }

  /**
   * 
   * @param {String} [solve = 'payment'] One of payment, term or apr to tell the compute engine what to solve for.
   */
  private calculate = async (solve = 'payment') => {
    if (!this.isUnLock) {
      return
    }
    
    if (this.calculating) {
      return
    }

    if (!this.saleId || (this.saleId <= 0)) {
      return
    }

    if (this.baseSalePrice <= 0) {
      return
    }

    if (!this.sold || (this.sold.toString().toLowerCase() == 'invalid date')) {
      return
    }

    if (!this.termable) {
      return
    }

    this.calculating = true

    let changed = ''
    for (const key in this.terms.controls) {      
      const control = this.terms.get(key)
      if (control && control.pristine) {
        continue
      }

      changed = key
      break
    }

    if (changed == ''){
      this.calculating = false
      return
    }

    let desiredPayment = clean(this.terms.get('payment').value)
    if (!desiredPayment || isNaN(desiredPayment) || (desiredPayment <= 0)) {
      desiredPayment = 0
    }

    let financed = this.amountToFinance
    if (this.terms.get('taxMethod').value === 'DEFERRED') {
      financed -= this.salesTax
    }

    if (!financed || (financed <= 0)) {
      this.calculating = false
      return
    }

    this.terms.get('financed').setValue(financed)
    
    const apr = clean(this.terms.get('apr').value)
    if ((apr === undefined) || (apr === null) || isNaN(apr) || (apr < 0)) {
      this.calculating = false
      return
    }

    const term = clean(this.terms.get('term').value)
    if (!term || isNaN(term) || (term <= 0)) {
      this.calculating = false
      return
    }

    let first = this.terms.get('first').value
    if (!first || (first.length <= 0)) {
      this.calculating = false
      return
    }

    if (new Date(first) <= new Date(this.sold)){
      first = this.computeFirstPayDate()
      this.calculating = false
      return
    }

    first = encodeURIComponent(first.trim())

    const cycleId = parseInt(this.terms.get('paymentCycleId').value)
    const frequency = this.toFrequency(cycleId)
    if (!frequency) {
      this.calculating = false
      return
    }

    let payment = clean(this.terms.get('payment').value)
    if (!payment || isNaN(payment) || (payment <= 0)) {
      payment = 0
    }

    if ((payment <= 0) && (solve.toLowerCase() === 'term')) {
      this.calculating = false
      return
    }

    const res = await this.master.getAsync(`vendors/terms/compute?salePrice=${this.baseSalePrice}&saleId=${this.saleId}&financed=${financed}&apr=${apr}&frequency=${frequency}&periods=${term}&sold=${this.sold}&first=${first}&solve=${solve}&changed=${changed}&pay=${payment}`)
    .catch(error => {
      this.ms.sendMessage("alert", { type: "danger", text:  error})
      this.calculating = false
      return
    })

    if (!res || !res.data) {
      this.calculating = false

      let error = this.words[this.language]['apiNoResponse']
      if (res && res['error']) {
        error = res['error']
      }

      this.ms.sendMessage("alert", { type: "danger", text:  error})
      return
    }

    this.terms.patchValue({
      financed: parseFloat(res.data.financed),
      interest: parseFloat(res.data.interest),
      apr: formatPercent(res.data.apr),
      paymentCycleId: this.toCycleId(res.data.frequency),
      term: formatCount(res.data.count),
      payment: formatCurrency(res.data.payment),
      final: parseFloat(res.data.final),
      first: moment(res.data.first).format('yyyy-MM-DD'),
      maturity: res.data.last,
      payments: parseFloat(res.data.payments),
      months: this.toMonths(res.data.count),
      maxApr: res.data.maxLegalAPR,
      dealerAPRLimit: res.data.dealerAPRLimit,
      contractRate: res.data.contractRate,
    }, {emitEvent: false})

    this.maxApr = res.data.maxLegalAPR
    this.dealerAPRLimit = res.data.dealerAPRLimit

    this.terms.markAsPristine()
    this.calculating = false
    this.save()
  }

  /**
   * Handle input blur or change events 
   * and recalculate when needed.
   * @param e 
   * @returns 
   */
  public changed(e): void {
    if (this.terms.pristine) {
      return
    }

    if (this.calculating) {
      return
    }

    if (e && e.target && (['months', 'maxApr'].includes(e.target.id))) { // dont need to recalc for these
      return
    }

    const input = this.terms.get(e.target.id)
    if (input) {
      input.markAsDirty()
    }

    this.solveFor = 'payment'
    if (e && e.target && (e.target.id === 'payment')) {
      this.solveFor = 'term'
    }

    if (e && e.target && (e.target.id === 'apr') && this.maxApr && (this.maxApr >= 0)) {
      const apr = parseFloat(e.target.value)
      if (apr && apr > this.maxApr) {
        this.terms.get('apr').setValue(formatPercent(this.maxApr))
      }
    }

    if (e && e.target && (e.target.id === 'term')) {
      this.terms.get('months').setValue(this.toMonths(parseInt(this.terms.get('term').value)))
      this.fetchMaxApr()      
      return
    }

    if (e && e.target && (e.target.id === 'taxMethod')) {
      this.patchSale()
      return
    }

    if (e && e.target && (e.target.id === 'paymentCycleId')) {
      this.computeFirstPayDate()
      this.fetchMaxApr()
      return
    }

    this.calculate(this.solveFor)
  }

  /**
   * Handle lienholder chose events by the user.
   * @param lienholderId Unique internal identity of the lienholder contact chosen.
   */
  public lienholderChosen(lienholderId: number, primary: boolean, index: number) {
    if (!lienholderId) {
        this.availableToSelect = primary ? false : true
      return
    }
    this.availableToSelect = true

    let id = 0
    if (this.lienholders && this.lienholders.length > 0) {
      if (this.lienholders[index]) {
        id = parseInt(this.lienholders[index].id)
      }
    }

    let lienholderIdExists = this.lienholders.find(lienholder => lienholder['lienholderContactId'] == lienholderId)
    if (lienholderIdExists){
      alert(`${lienholderIdExists['contact']['companyName']} has already been selected as a lienholder. Please choose another lienholder.`)
      return
    }

    if (id && (id == lienholderId)) { // didn't change
      return
    }

    if (id && (id > 0)) {
      this.modifySaleLienholder(id, lienholderId, primary)
      return
    }

    this.addSaleLienholder(lienholderId, primary)
  }

  /**contactId
   * Modfy the existing sale lienholder based on the recent choice by the user.
   * @param lienholder Unique internal identity of the current sale lienholder
   * @param lienholderId Unique internal identity of the new sale lienholder as chosen by the user
   */
  private modifySaleLienholder(id: number, lienholderId: number, primary: boolean) {
    if (!this.saleId) {
      return
    }

    if (!id || (id <= 0) || !lienholderId || (lienholderId <= 0)) {
      return
    }

    const payload = {
      lienholderContactId: lienholderId,
      lienholderLotId: null,
      address: null,
      bankFee: 0.0,
      buyRate: 0.0,
      splitRate: 0.0,
      primary
    }

    this.master.put(`sales/${this.saleId}/lienholders/${id}`, { payload: payload }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }
        
      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.fetchLienholders()
    })
  }

  /**
   * Create a new sale lienholder based on the recent choice by the user.
   * @param lienholderId 
   */
  private addSaleLienholder(lienholderId: number, primary: boolean) {
    if (!this.saleId) {
      return
    }

    if (!lienholderId || (lienholderId <= 0)) {
      return
    }

    const payload = {
      contactId: lienholderId,
      primary
    }

    this.master.post(`sales/${this.saleId}/lienholders`, payload, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }
        
      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.fetchLienholders()
    })
  }

  /**
   * 
   * @param paymentCycleId 
   * @returns 
   */
  private toFrequency(paymentCycleId: number): string {
    const found = this.frequencies.find((item) => item.id == paymentCycleId)
    if (!found || (!found.name && !found.carletonApiName)) {
      return null
    }

    return found.carletonApiName || found.name
  }

  /**
   * 
   * @param frequency 
   * @returns 
   */
  private toCycleId(frequency: string): number {
    if (!frequency || (frequency.length <= 0)) {
      return 0
    }

    const found = this.frequencies.find((item) => ((item.name.toLowerCase() == frequency.toLowerCase()) || (item.carletonApiName.toLowerCase() == frequency.toLowerCase())))
    if (!found || !found.id) {
      return 0
    }

    const id = parseInt(found.id)
    if (!id || isNaN(id) || (id <= 0)) {
      return 0
    }

    return id
  }

  /**
   * Calculate the given term in months based on 
   * the current payment frequency.
   * @param term The current number of payments to determine months with.
   * @returns The number of months in the given term and known frequency (cycle).
   */
  private toMonths(term: number): number {
    const frequency = this.toFrequency(this.terms.get('paymentCycleId').value)
    if (!frequency || frequency.length <= 0) {
      return term
    }

    switch (frequency.toLowerCase()) {
      case 'weekly': // once a week
        return Math.round((term / 52) * 12)
      case 'bi-weekly': // every two weeks
      case 'biweekly':
        return Math.round((term / 26) * 12)
      case 'semi-monthly': // twice a month
      case 'semimonthly':
          return Math.round(term / 2)
      case 'bi-monthly': // every two months
          return Math.round(term * 2)
      case 'quaterly': // every three months
        return Math.round(term * 3)
      case 'annually':
        return Math.round(term * 12)
      default: // once a month (i.e. monthly)
        return term
    }
  }

  /**
   * When the sale date or a down/deferred payment 
   * changes lets compute the new first payment 
   * date based on the currently selected 
   * frequency (cycle).
   */
  private computeFirstPayDate(): void {
    if (!this.isUnLock) {
      return
    }
    
    if (!this.lastDeferredPayment) {
      return
    }

    const cycleId = parseInt(this.terms.get('paymentCycleId').value)
    if (!cycleId || isNaN(cycleId) || (cycleId <= 0)) {
      return
    }

    const frequency = this.toFrequency(cycleId)
    if (!frequency || frequency.length <= 0) {
      return
    }

    const firstPayDate = this.terms.get('first').value
    if (!firstPayDate || (firstPayDate.length <= 0)) {
      return
    }

    let computed = null
    
    switch (frequency.toLowerCase()) {
      case 'weekly': // once a week
        computed = moment(this.lastDeferredPayment).add(1, 'week').format('yyyy-MM-DD')
        break
      case 'bi-weekly': // every two weeks
      case 'biweekly':
        computed = moment(this.lastDeferredPayment).add(2, 'weeks').format('yyyy-MM-DD')
        break
      case 'semi-monthly': // twice a month
      case 'semimonthly':
        computed = moment(this.lastDeferredPayment).add(15, 'days').format('yyyy-MM-DD')
        break
      case 'bi-monthly': // every two months
        computed = moment(this.lastDeferredPayment).add(2, 'months').format('yyyy-MM-DD')
        break
      case 'quaterly': // every three months
        computed = moment(this.lastDeferredPayment).add(3, 'months').format('yyyy-MM-DD')
        break
      case 'annually':
        computed = moment(this.lastDeferredPayment).add(1, 'year').format('yyyy-MM-DD')
        break
      default: // once a month (i.e. monthly)
        computed = moment(this.lastDeferredPayment).add(1, 'month').format('yyyy-MM-DD')
        break
    }

    if (!computed || (computed == firstPayDate)) { // no change?
      return
    }
    
    this.terms.get('first').setValue(computed)
    this.terms.get('first').markAsDirty()
    this.calculate()
  }

  public discardLienholder = (saleLienholderId) => {

    this.master.discard(`sales/${this.saleId}/lienholders/${saleLienholderId}`, (res) => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.fetchLienholders()
    })
  }

}
