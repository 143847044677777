import { Injectable } from '@angular/core';
import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

    private capabilities: Object = null

    constructor (private store: StoreService) {}

    /**
     * Check to see if the user has permission to perform 
     * the given action on the resource within the given 
     * module.
     * @param {string} module The name of the system module where the given resource is located.
     * @param {string} resource The name of the resource the user is trying to perform an action on.
     * @param {string} action The action the user is trying to perform. Typically view, add, update or delete.
     * @returns A boolean that indicates whether or not the user has the requested permission.
    */
    public permitted = (module, resource, action) => {
        this.capabilities = this.store.userAccount['capabilities']
        if (!this.capabilities || (Object.keys(this.capabilities).length <= 0)) {
            return false
        }

        if (!module || (module.length <= 0)) {
            return false
        }

        if (!resource || (resource.length <= 0)) {
            return false
        }

        if (!action || (action.length <= 0)) {
            return false
        }

        module = module.trim().toUpperCase().replace(/\s+/g, '_')
        resource = resource.trim().toUpperCase().replace(/\s+/g, '_')
        action = action.trim().toUpperCase().replace(/\s+/g, '_')

        return this.capabilities[`${module}:${resource}_${action}`] || false
    }

    /**
     * Check to see if the user does not have permission to perform 
     * the given action on the resource within the given 
     * module.
     * @param {string} module The name of the system module where the given resource is located.
     * @param {string} resource The name of the resource the user is trying to perform an action on.
     * @param {string} action The action the user is trying to perform. Typically view, add, update or delete.
     * @returns A boolean that indicates whether or not the user has the requested permission
    */
    public notPermitted = (module, resource, action) => {
        return !this.permitted(module, resource, action)
    }

}

/**
 * Capabilities as of 2023.11.01 in the format {MODULE}:{RESOURCE}_{ACTION}
{
    "USERS:USER_LIST_VIEW": true,
    "USERS:USER_LIST_ADD": true,
    "USERS:USER_LIST_UPDATE": true,
    "USERS:USER_LIST_DELETE": true,
    "CONTACTS:CONTACT_LIST_VIEW": true,
    "CONTACTS:CONTACT_LIST_ADD": true,
    "CONTACTS:CONTACT_LIST_UPDATE": true,
    "CONTACTS:CONTACT_LIST_DELETE": true,
    "CONTACTS:CONTACT_DETAIL_VIEW": true,
    "CONTACTS:CONTACT_DETAIL_ADD": true,
    "CONTACTS:CONTACT_DETAIL_UPDATE": true,
    "CONTACTS:CONTACT_DETAIL_DELETE": true,
    "CONTACTS:GENERAL_INFORMATION_VIEW": true,
    "CONTACTS:GENERAL_INFORMATION_ADD": true,
    "CONTACTS:GENERAL_INFORMATION_UPDATE": true,
    "CONTACTS:GENERAL_INFORMATION_DELETE": true,
    "CONTACTS:ADDRESSES_VIEW": true,
    "CONTACTS:ADDRESSES_ADD": true,
    "CONTACTS:ADDRESSES_UPDATE": true,
    "CONTACTS:ADDRESSES_DELETE": true,
    "CONTACTS:COMMUNICATIONS_VIEW": true,
    "CONTACTS:COMMUNICATIONS_ADD": true,
    "CONTACTS:COMMUNICATIONS_UPDATE": true,
    "CONTACTS:COMMUNICATIONS_DELETE": true,
    "CONTACTS:IDENTIFICATIONS_VIEW": true,
    "CONTACTS:IDENTIFICATIONS_ADD": true,
    "CONTACTS:IDENTIFICATIONS_UPDATE": true,
    "CONTACTS:IDENTIFICATIONS_DELETE": true,
    "CONTACTS:NUMBER_VIEW": true,
    "CONTACTS:NUMBER_ADD": true,
    "CONTACTS:NUMBER_UPDATE": true,
    "CONTACTS:NUMBER_DELETE": true,
    "CONTACTS:INCOME_SOURCES_VIEW": true,
    "CONTACTS:INCOME_SOURCES_ADD": true,
    "CONTACTS:INCOME_SOURCES_UPDATE": true,
    "CONTACTS:INCOME_SOURCES_DELETE": true,
    "CONTACTS:MONTHLY_AMOUNT_VIEW": true,
    "CONTACTS:MONTHLY_AMOUNT_ADD": true,
    "CONTACTS:MONTHLY_AMOUNT_UPDATE": true,
    "CONTACTS:MONTHLY_AMOUNT_DELETE": true,
    "CONTACTS:REFERENCES_VIEW": true,
    "CONTACTS:REFERENCES_ADD": true,
    "CONTACTS:REFERENCES_UPDATE": true,
    "CONTACTS:REFERENCES_DELETE": true,
    "CONTACTS:NOTES_ALERTS_VIEW": true,
    "CONTACTS:NOTES_ALERTS_ADD": true,
    "CONTACTS:NOTES_ALERTS_UPDATE": true,
    "CONTACTS:NOTES_ALERTS_DELETE": true,
    "CONTACTS:SETUP_DEFAULTS_VIEW": true,
    "CONTACTS:SETUP_DEFAULTS_ADD": true,
    "CONTACTS:SETUP_DEFAULTS_UPDATE": true,
    "CONTACTS:SETUP_DEFAULTS_DELETE": true,
    "CORPORATION_SETUP:CORPORATE_LIST_VIEW": true,
    "CORPORATION_SETUP:CORPORATE_LIST_ADD": true,
    "CORPORATION_SETUP:CORPORATE_LIST_UPDATE": true,
    "CORPORATION_SETUP:CORPORATE_LIST_DELETE": true,
    "CORPORATION_SETUP:CORPORATE_DETAIL_VIEW": true,
    "CORPORATION_SETUP:CORPORATE_DETAIL_ADD": true,
    "CORPORATION_SETUP:CORPORATE_DETAIL_UPDATE": true,
    "CORPORATION_SETUP:CORPORATE_DETAIL_DELETE": true,
    "CORPORATION_SETUP:GENERAL_INFORMATION_VIEW": true,
    "CORPORATION_SETUP:GENERAL_INFORMATION_ADD": true,
    "CORPORATION_SETUP:GENERAL_INFORMATION_UPDATE": true,
    "CORPORATION_SETUP:GENERAL_INFORMATION_DELETE": true,
    "CORPORATION_SETUP:ADDRESSES_VIEW": true,
    "CORPORATION_SETUP:ADDRESSES_ADD": true,
    "CORPORATION_SETUP:ADDRESSES_UPDATE": true,
    "CORPORATION_SETUP:ADDRESSES_DELETE": true,
    "CORPORATION_SETUP:COMMUNICATIONS_VIEW": true,
    "CORPORATION_SETUP:COMMUNICATIONS_ADD": true,
    "CORPORATION_SETUP:COMMUNICATIONS_UPDATE": true,
    "CORPORATION_SETUP:COMMUNICATIONS_DELETE": true,
    "CORPORATION_SETUP:DEALERSHIP_DETAIL_VIEW": true,
    "CORPORATION_SETUP:DEALERSHIP_DETAIL_ADD": true,
    "CORPORATION_SETUP:DEALERSHIP_DETAIL_UPDATE": true,
    "CORPORATION_SETUP:DEALERSHIP_DETAIL_DELETE": true,
    "CORPORATION_SETUP:LOT_DETAIL_VIEW": true,
    "CORPORATION_SETUP:LOT_DETAIL_ADD": true,
    "CORPORATION_SETUP:LOT_DETAIL_UPDATE": true,
    "CORPORATION_SETUP:LOT_DETAIL_DELETE": true,
    "CORPORATION_SETUP:BUYERS_GUIDE_VIEW": true,
    "CORPORATION_SETUP:BUYERS_GUIDE_ADD": true,
    "CORPORATION_SETUP:BUYERS_GUIDE_UPDATE": true,
    "CORPORATION_SETUP:BUYERS_GUIDE_DELETE": true,
    "CORPORATION_SETUP:INVENTORY_SETUP_VIEW": true,
    "CORPORATION_SETUP:INVENTORY_SETUP_ADD": true,
    "CORPORATION_SETUP:INVENTORY_SETUP_UPDATE": true,
    "CORPORATION_SETUP:INVENTORY_SETUP_DELETE": true,
    "SECURITY:ROLES_VIEW": true,
    "SECURITY:ROLES_ADD": true,
    "SECURITY:ROLES_UPDATE": true,
    "SECURITY:ROLES_DELETE": true,
    "SECURITY:ROLES_USERS_VIEW": true,
    "SECURITY:ROLES_USERS_ADD": true,
    "SECURITY:ROLES_USERS_UPDATE": true,
    "SECURITY:ROLES_USERS_DELETE": true,
    "SECURITY:ROLES_PERMISSIONS_VIEW": true,
    "SECURITY:ROLES_PERMISSIONS_ADD": true,
    "SECURITY:ROLES_PERMISSIONS_UPDATE": true,
    "SECURITY:ROLES_PERMISSIONS_DELETE": true,
    "INVENTORY:INVENTORY_LIST_VIEW": true,
    "INVENTORY:INVENTORY_LIST_ADD": true,
    "INVENTORY:INVENTORY_LIST_UPDATE": true,
    "INVENTORY:INVENTORY_LIST_DELETE": true,
    "INVENTORY:INVENTORY_DETAIL_VIEW": true,
    "INVENTORY:INVENTORY_DETAIL_ADD": true,
    "INVENTORY:INVENTORY_DETAIL_UPDATE": true,
    "INVENTORY:INVENTORY_DETAIL_DELETE": true,
    "INVENTORY:GENERAL_INFORMATION_VIEW": true,
    "INVENTORY:GENERAL_INFORMATION_ADD": true,
    "INVENTORY:GENERAL_INFORMATION_UPDATE": true,
    "INVENTORY:GENERAL_INFORMATION_DELETE": true,
    "INVENTORY:FINANCIALS_VIEW": true,
    "INVENTORY:FINANCIALS_ADD": true,
    "INVENTORY:FINANCIALS_UPDATE": true,
    "INVENTORY:FINANCIALS_DELETE": true,
    "INVENTORY:ADMINISTRATIVE_VIEW": true,
    "INVENTORY:ADMINISTRATIVE_ADD": true,
    "INVENTORY:ADMINISTRATIVE_UPDATE": true,
    "INVENTORY:ADMINISTRATIVE_DELETE": true,
    "INVENTORY:VEHICLE_OPTIONS_VIEW": true,
    "INVENTORY:VEHICLE_OPTIONS_ADD": true,
    "INVENTORY:VEHICLE_OPTIONS_UPDATE": true,
    "INVENTORY:VEHICLE_OPTIONS_DELETE": true,
    "INVENTORY:RECONDITIONING_COST_VIEW": true,
    "INVENTORY:RECONDITIONING_COST_ADD": true,
    "INVENTORY:RECONDITIONING_COST_UPDATE": true,
    "INVENTORY:RECONDITIONING_COST_DELETE": true,
    "INVENTORY:FLOOR_PLANS_VIEW": true,
    "INVENTORY:FLOOR_PLANS_ADD": true,
    "INVENTORY:FLOOR_PLANS_UPDATE": true,
    "INVENTORY:FLOOR_PLANS_DELETE": true,
    "INVENTORY:NOTES_ALERTS_VIEW": true,
    "INVENTORY:NOTES_ALERTS_ADD": true,
    "INVENTORY:NOTES_ALERTS_UPDATE": true,
    "INVENTORY:NOTES_ALERTS_DELETE": true,
    "INVENTORY:RECONDITIONING_TYPES_VIEW": true,
    "INVENTORY:RECONDITIONING_TYPES_ADD": true,
    "INVENTORY:RECONDITIONING_TYPES_UPDATE": true,
    "INVENTORY:RECONDITIONING_TYPES_DELETE": true,
    "INVENTORY:IMAGES_VIEW": true,
    "INVENTORY:IMAGES_ADD": true,
    "INVENTORY:IMAGES_UPDATE": true,
    "INVENTORY:IMAGES_DELETE": true,
    "REPORTS:BUYERS_VIEW": true,
    "REPORTS:BUYERS_ADD": true,
    "REPORTS:BUYERS_UPDATE": true,
    "REPORTS:BUYERS_DELETE": true,
    "BASIC:DASHBOARD_VIEW": true,
    "BASIC:DASHBOARD_ADD": true,
    "BASIC:DASHBOARD_UPDATE": true,
    "BASIC:DASHBOARD_DELETE": true,
    "SALES:SALE_LIST_VIEW": true,
    "SALES:SALE_LIST_ADD": true,
    "SALES:SALE_LIST_UPDATE": true,
    "SALES:SALE_LIST_DELETE": true,
    "SALES:SALE_DETAIL_VIEW": true,
    "SALES:SALE_DETAIL_ADD": true,
    "SALES:SALE_DETAIL_UPDATE": true,
    "SALES:SALE_DETAIL_DELETE": true,
    "SALES:BUYERS_VIEW": true,
    "SALES:BUYERS_ADD": true,
    "SALES:BUYERS_UPDATE": true,
    "SALES:BUYERS_DELETE": true,
    "SALES:CO-BUYERS_VIEW": true,
    "SALES:CO-BUYERS_ADD": true,
    "SALES:CO-BUYERS_UPDATE": true,
    "SALES:CO-BUYERS_DELETE": true,
    "SALES:COSIGNERS_VIEW": true,
    "SALES:COSIGNERS_ADD": true,
    "SALES:COSIGNERS_UPDATE": true,
    "SALES:COSIGNERS_DELETE": true,
    "SALES:SSN_VIEW": true,
    "SALES:SSN_ADD": true,
    "SALES:SSN_UPDATE": true,
    "SALES:SSN_DELETE": true,
    "SALES:NOTES_ALERTS_VIEW": true,
    "SALES:NOTES_ALERTS_ADD": true,
    "SALES:NOTES_ALERTS_UPDATE": true,
    "SALES:NOTES_ALERTS_DELETE": true,
    "SALES:VEHICLES_VIEW": true,
    "SALES:VEHICLES_ADD": true,
    "SALES:VEHICLES_UPDATE": true,
    "SALES:VEHICLES_DELETE": true
}
 */