import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as words from '../../../services/language'
import { MessageService } from '../../../services/message.service';
import { MasterService } from '../../../services/master.service';
import { StoreService } from '../../../services/store.service';
import * as moment from 'moment';
import { Subject } from "rxjs";

@Component({
    selector: 'app-collect',
    templateUrl: './collect.component.html',
    styleUrls: ['./collect.component.scss']
})
export class CollectComponent implements OnInit {

    // **************************
    // ****** variables *********
    // **************************

    /** Handle payment taken notifications. */
    public paymentTakenEvent: Subject<Object> = new Subject();

    /** Notify take payment that we want to collect a certain amount. */
    public payThisAmountEvent: Subject<number> = new Subject();

    /** Save the language */
    public language: string= 'EN'

    /** Set all words */
    public words = words.language

    /** Unique internal identity of the contact/customer whose collections will be shown. */
    public contactId: number = 0

    // The full name of the contact whose payments need to be collected.
    public contact: string = null

    // The primary address of the contact whose payments need to be collected.
    public address: string = null

    // The primary email address of the contact whose payments need to be collected.
    public email: string = null

    // The primary phone/cell number of the contact whose payments need to be collected.
    public phone: string = null

    /** Indicates the component is loading content. */
    public loading: boolean = false

    /**  */
    private pays: Object = null

    get pastDue() { 
        if (!this.pays || !this.pays['past'] || !this.pays['past']['total']) {
            return 0
        }

        return parseFloat(this.pays['past']['total'])
    }

    get pastDueSince() {
        if (!this.pays || !this.pays['past'] || !this.pays['past']['items'] || (this.pays['past']['items'].length <= 0)) {
            return 'as of today'
        }

        return `since ${moment(this.pays['past']['items'][0].due).format('ddd MMM Do, YYYY')}`
    }

    get dueToday() { 
        if (!this.pays || !this.pays['today'] || !this.pays['today']['total']) {
            return 0
        }

        return parseFloat(this.pays['today']['total'])
    }

    // **************************
    // ****** life cycles *******
    // **************************

    constructor(private route: ActivatedRoute, private ms: MessageService, private master: MasterService, private store: StoreService, private router: Router, private fb: FormBuilder) { }

    ngOnInit() {
        this.contactId = 0
        const id = parseInt(this.route.snapshot.params['id'])
        if (id && !isNaN(id) && (id > 0)) {
            this.contactId = id
        }

        this.readContact()
    }

    // Listen to output of navbar
    public listenerNav = (e): void => {
        switch (e['message']) {
            case 'changeLanguage':
                this.language = e['value']
                break
        }
    }

    private readContact = (): void => {
        if (!this.contactId || (this.contactId <= 0)) {
            return
        }
        
        this.master.get(`contacts/${this.contactId}`, (res) => {
            this.loading = false
  
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }
  
            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            if (!res.data.contact) {
                return
            }
            
            this.contact = res.data.contact.name
            this.determineAddress(res.data.contact.contactAddresses)
            this.determineEmail(res.data.contact.contactCommunications)
            this.determinePhone(res.data.contact.contactCommunications)
        })
    }

    private determineAddress = (addresses) => {
        if (!addresses || (addresses.length <= 0)) {
            return
        }

        const primary = addresses.find((addy) => addy.primaryAddress)
        if (!primary) {
            return
        }

        this.address = `${primary.address} ${primary.city}, ${primary.state} ${primary.zipCode}`
    }

    private determineEmail = (communications) => {
        if (!communications || (communications.length <= 0)) {
            return
        }

        const primary = communications.find((comm) => ((comm.type === 'email') && comm.primary))
        if (!primary) {
            return
        }

        this.email = `${primary.value}`
    }

    private determinePhone = (communications) => {
        if (!communications || (communications.length <= 0)) {
            return
        }

        const primary = communications.find((comm) => ((comm.type === 'phone') && comm.primary))
        if (!primary) {
            return
        }

        this.phone = `${primary.value}`
    }

    /**
     * Trigger event to the take payment component with 
     * the current past due amount.
     * @param e Event meta data that triggered this function.
     */
    public payPastDue = (e: Event) => {
        const toPay = this.pastDue
        if (toPay <= 0) {
            return
        }

        this.payThisAmountEvent.next(toPay)
    }

    /**
     * Trigger event to the take payment component with 
     * the current due today amount.
     * @param e Event meta data that triggered this function.
     */
    public payDueToday = (e: Event) => {
        const toPay = this.dueToday
        if (toPay <= 0) {
            return
        }

        this.payThisAmountEvent.next(toPay)
    }

    /**
     * Listen for payment changes from the pays component then update 
     * the display and pass the changes to other components.
     * @param pays The payment data showing past, today and next due items.
     */
    public paymentsChanged = (pays: Object) => {
        this.pays = pays
    }

    /**
     * Listen for payment processing events from the take payment component then update 
     * the display and pass the changes to other components.
     * @param payment The payment details after processing.
     */
    public paymentTaken = (payment: Object) => {
        this.paymentTakenEvent.next(payment)
    }
}
