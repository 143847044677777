import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MasterService } from 'src/app/services/master.service';
import { MessageService } from 'src/app/services/message.service';
import { SecurityService } from 'src/app/services/security.service'
import { ActivatedRoute } from '@angular/router';
import { SEARCH_DEBOUNCE_MILLIS, MIN_SEARCH_CHARS } from 'src/app/constants/list';
import { ADDRESS_OWNERSHIP, ADDRESS_TYPES } from 'src/app/constants/contact'


@Component({
    selector: 'address-chooser',
    templateUrl: './address-chooser.component.html',
    styleUrls: ['./address-chooser.component.scss']
})
export class AddressChooserComponent implements OnInit, OnChanges {
    
    //
    @Input() isFlat: boolean = false
    
    //  define if is individual
    @Input() isIndividual: boolean = false
   
    // save the language
    @Input() language: 'EN'
    
    // set all words
    @Input() words = {}
    
    // Unique internal identity of the associated contact
    @Input() contactId: number = 0

    // Unique internal identity of the current address in use, if any.
    @Input() addressIdInUse: number = 0
    
    // the type of contact
    @Input() type = {}
    
    //
    @Output() addressSelected$: EventEmitter<Object> = new EventEmitter()
    
    //
    public expanded: boolean = true

    // save the types
    public types: Object[] = ADDRESS_TYPES

    // save the rent or own
    public rentOwn: string[] = ADDRESS_OWNERSHIP

    // define if loading state
    public loading: boolean = false

    // zip code list
    public zipCodeList: Object[] = []

    // latest search inquiry
    public zipInquiry = null

    // Keep track of which address index the user is querying for a zip code within.
    private zipIndex = -1

    // set time out
    private timeOutID: any;

    public form: FormGroup = this.fb.group({
        addresses: this.fb.array([])
    })

    /*
    * Life cycle events
    */

    constructor(private master: MasterService, private ms: MessageService, private security: SecurityService, private route: ActivatedRoute, private fb: FormBuilder) {}

    ngOnInit(): void {
        this.list()
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes) {
            return
        }

        const first = Object.values(changes).some(c => c.isFirstChange())
        if (first) {
            return
        }
        
        if(changes.contactId) {
            this.list()
        }
    }

    ngOnDestroy(): void {
        this.addresses.clear()
    }

    /**
     * Helper method to consistently get the list of form addresses as an Array.
     */
    get addresses() {
        return this.form.controls['addresses'] as FormArray;
    }

    public list = () => {
        if (!this.contactId || this.contactId <= 0) {
            return
        }

        this.loading = true
        this.addresses.clear()

        this.master.getAsync(`contacts/${this.contactId}/addresses`).then(res => {
            this.loading = false

            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            for (let address of res.data.addresses) {
                this.append(address)
            }
        })
    }

    /**
     * 
     * @param address 
     */
    public append = (address) => {
        let edit = false
        if (!address || !address.id) {
            edit = true
            address = {}
        }

        const addy = this.fb.group({
            id: [address['id'] || 0],
            contactId: [this.contactId, [Validators.required]],
            addressTypeId: [address['addressTypeId'] || 2, [Validators.required]], // 2 = Home
            status: [address['status'] || 'Own', [Validators.required]],
            address: [address['address'] || '', [Validators.required]],
            address2: [address['address2'] || ''],
            zipCode: [address['zipCode'] || '', [Validators.required, Validators.pattern('[0-9]{3,10}')]],
            city: [address['city'] || '', [Validators.required]],
            state: [address['state'] || '', [Validators.required]],
            county: [address['county'] || '', [Validators.required]],
            country: [address['country'] || 'United States', [Validators.required]],
            startDate: [address['startDate'] || null],
            endDate: [address['endDate'] || null],
            primary: [address['primaryAddress'] || (this.addresses.length <= 0)],
            editing: [edit]
        })

        this.addresses.push(addy)
    }

    /**
     * 
     * @param {Event} e
     * @param {number} index 
     * @returns 
     */
    public discard = (e, index) => {
        if ((index === null) || (index === undefined) || index < 0 || (index > this.addresses.length-1)) {
            return
        }

        // this method is only to remove those not yet saved (id = 0).
        if (this.addresses.controls[index].get('id').value > 0) {
            return
        }

        this.addresses.removeAt(index)
    }

    /**
     * 
     * @param {Event} e
     * @param {number} index 
     * @returns 
     */
    public save = (e, index) => {
        e.preventDefault()
        e.stopPropagation()

        if ((index === null) || (index === undefined) || index < 0 || (index > this.addresses.length-1)) {
            return
        }

        if (this.addresses.controls[index].get('id').value > 0) {
            return
        }

        const payload = {addresses: [this.addresses.controls[index].value]}
        this.master.post(`contacts/${this.contactId}/addresses`, payload, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            if (!res.data || !res.data.identities || (res.data.identities.length <= 0)) {
                return
            }

            const addressId = parseInt(res.data.identities[0])

            this.addresses.controls[index].get('id').setValue(addressId)
            this.addresses.controls[index].get('editing').setValue(false)

            this.addressSelected$.emit({message: 'buyerAddressSelected', id: addressId})
        })        
    }

    /**
     * 
     * @param {Event} e
     * @param {number} index 
     * @returns 
     */
    public use = (e, index) => {
        e.preventDefault()
        e.stopPropagation()

        if ((index === null) || (index === undefined) || index < 0 || (index > this.addresses.length-1)) {
            return
        }

        if (this.addresses.controls[index].get('id').value <= 0) {
            return
        }

        this.addressSelected$.emit({message: 'buyerAddressSelected', id: this.addresses.controls[index].get('id').value})
    }
    
    /**
     * 
     * @returns 
     */
    public inquired = (index): void => {
        if ((index === null) || (index === undefined) || index < 0 || (index > this.addresses.length-1)) {
            return
        }

        this.zipIndex = index

        const code: string = this.addresses.controls[index].get('zipCode').value
        if (!code || code.length < MIN_SEARCH_CHARS) {
            this.zipCodeList = []
            return
        }

        if (this.zipInquiry && (code === this.zipInquiry)) { // hasn't changed?
            return
        }

        clearTimeout(this.timeOutID)

        this.timeOutID = setTimeout(() => {
            this.zipCodeList = []
            this.zipInquiry = code

            this.master.get(`readLocationByZipcode?zipcode=${code}`, res => {
                if (!res) {
                    this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                    return
                }

                if (res.status !== 200) {
                    return
                }

                this.zipCodeList = res.data.locations

                // autocomplete
                if ((code.length >= 5) && (this.zipCodeList.length === 1)) {
                    this.zipSelected(this.zipCodeList[0])
                }
            })
        }, SEARCH_DEBOUNCE_MILLIS)
    }

    public zipSelected = (address: Object): void => {
        if (!address || !this.addresses || (this.zipIndex < 0)) {
            return
        }

        this.addresses.controls[this.zipIndex].get('city').setValue(address['city'])
        this.addresses.controls[this.zipIndex].get('state').setValue(address['state'])
        this.addresses.controls[this.zipIndex].get('county').setValue(address['county'])
        this.addresses.controls[this.zipIndex].get('zipCode').setValue(address['zipcode'])
        this.addresses.controls[this.zipIndex].get('country').setValue('United States')

        this.zipCodeList = []
        this.zipIndex = -1
    }

}
