import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { StoreService } from '../../../../services/store.service'
import { MessageService } from "../../../../services/message.service";
import { MasterService } from "../../../../services/master.service";
import { SecurityService } from '../../../../services/security.service'
import { ActivatedRoute } from "@angular/router";
import * as languageLibrary from '../../../../services/language'
import { Subject } from 'rxjs';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { INVENTORY } from '../../../../constants/inventory'
import { SAVE_DEBOUNCE_MILLIS, SALE_TYPES } from "src/app/constants/sale";
import { formatCount } from "src/app/utils/format";
import { clean } from "src/app/utils/numeric";

@Component({
    selector: "stock",
    templateUrl: "./stock.component.html",
    styleUrls: ["./stock.component.scss"],
})

export class DealStockComponent implements OnInit, OnChanges {
    @Input() lienholders: Object[] = []
    
    @Input() salePrice = 0
    
    @Input() isLocked: boolean = false
    
    @Input() saleId: number = 0
    
    @Input() isQuote: boolean = true

    //
    @Input() saleType = null
    
    // permissions
    @Input() permissions: Object[] = []

    @Input('createSaleEvent') createSaleEvent: Subject<number>
    
    @Input('buyerChangeEvent') buyerChangeEvent: Subject<Object>
    
    @Output() vehicleChangeEvent = new EventEmitter<Object>()
    
    @Output() tradeChangeEvent = new EventEmitter<Object>()

    public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
    public words = languageLibrary.language
    public auxVehicle = {}
    public saleStages: [] = []
    public vinLookup = ''
    public saleVehicle = null
    public saleVehicles = []
    public saleVehicleId = 0
    public isEdit = false
    public isAdmin = false

    // Notify listeners that the user wants to add a new trade to inventory
    private addTrade: Subject<number> = new Subject();

    // Notify listeners that the user wants to add a past buyer purchase as a trade-in
    private tradedIn: Subject<number> = new Subject();

    public odometers: object[] = INVENTORY.ODOMETER_TYPES
    readonly RETAIL_TRANS_TYPE = 'Retail'
    private saleVehicleTransactionTypes = []
    public formRetailVehicle: FormGroup;
    public inventoryListFilters = '&statuses=Available'
    public displayList: boolean = false
    public inventoryId = 0
    public buyerId: number = 0

    // A list of past purchases for the selected buyer. 
    // Allows the selection of one of these past purchases as a trade-in
    public purchases: Array<Object> = null

    //  An associative array of sale types with the id as the key
    public saleTypes: Object = SALE_TYPES

    public loading: boolean = false
    public saving: boolean = false

    // A debounce timer for change events. Used to wait for 
    // the user to stop typing before we save.
    private watcher: ReturnType<typeof setTimeout> = null

    vinNumber = null
    stockNumber = null
    viewDetail = false
    lotId = 0
    allData = {
        generalInformation: {},
        financials: {},
        administrative: {}
    }

    get wholesale(): boolean { return ((this.saleType) && (this.saleTypes[this.saleType] === 'Wholesale')) }

    constructor(private ms: MessageService, private master: MasterService, private route: ActivatedRoute, private store: StoreService, private security: SecurityService, private fb: FormBuilder) {}

    ngOnInit() {
        this.saleId = 0
        const id = parseInt(this.route.snapshot.params['id'])
        if (id && !isNaN(id) && (id > 0)) {
            this.saleId = id
        }
        
        this.lotId = parseInt(localStorage.getItem('lot'))

        this.formRetailVehicle = this.fb.group({
            mileage: [0, [Validators.required, Validators.min(0)]],
            odometerStatus: [{ value: 'ACTUAL', disabled: this.isLocked }, Validators.required],
        })

        this.formRetailVehicle.valueChanges.subscribe(() => {
            if (this.watcher) {
                clearTimeout(this.watcher)
            }
            
            this.watcher = setTimeout(() => {
                this.save()
            }, SAVE_DEBOUNCE_MILLIS)
        })

        this.createSaleEvent.subscribe(e => {
            this.saleCreated(e)
        });

        this.buyerChangeEvent.subscribe(buyer => {
            this.buyerChange(buyer['id'])
        })

        this.populate()
    }

    ngOnDestroy() {
        this.createSaleEvent.unsubscribe()
        this.buyerChangeEvent.unsubscribe()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes) {
            return
        }

        const first = Object.values(changes).some(c => c.isFirstChange())
        if (first) {
            return
        }

        if (changes.isLocked && this.formRetailVehicle) {
            this.formRetailVehicle.enable({emitEvent: false})
            if (this.isLocked) {
                this.formRetailVehicle.disable({emitEvent: false})
            }
            return
        }
    }

    get formControlRetailVehicle() {
        return this.formRetailVehicle.controls;
    }

    // listener childrens
    public listenerChildrens = (e): void => {
        switch (e.message) {
            // Retail vehicle selected from inventory
            case 'choseVehicle':
                this.closeChooser()
                this.vehicleSelected(e.inventoryId)
                break
        }
    }

    /**
     * Acquire the data needed for the view
     */
    private populate = async () => {
        await this.master.getAsync(`sales/vehicletransactiontypes`).then(res => {
            this.saleVehicleTransactionTypes = res.data.VehicleTransactionTypes ? res.data.VehicleTransactionTypes.slice() : []
        })

        await this.master.getAsync(`collections/sales/stages`).then(res => {
            this.saleStages = res.data.Stages ? res.data.Stages.slice() : []
        })

        if (this.saleId <= 0) {
            return
        }

        this.fetchBuyerPastPurchases()

        this.saleVehicles = await this.getSaleVehicles()
        if (!this.saleVehicles || (this.saleVehicles.length <= 0)) {
            return
        }

        this.saleVehicle = this.saleVehicles[0]
        if (!this.saleVehicle || !this.saleVehicle['inventory']) {
            return
        }

        this.saleVehicleId = this.saleVehicle['inventory']['id'] ? parseInt(this.saleVehicle['inventory']['id']) : 0
        this.vinLookup = this.saleVehicle['inventory']['stockNumber']
        this.formRetailVehicle.controls['mileage'].setValue(formatCount(this.saleVehicle['mileage']));
        this.formRetailVehicle.controls['odometerStatus'].setValue(this.saleVehicle['odometerStatus']);
        
        // need this here since other components depend on it (terms)
        this.vehicleChangeEvent.emit({
            id: parseInt(this.saleVehicle['id']),
            year: parseInt(this.saleVehicle['inventory']['vehicle']['modelYear']),
            make: this.saleVehicle['inventory']['vehicle']['make'],
            model: this.saleVehicle['inventory']['vehicle']['model'],
            miles: parseInt(this.saleVehicle['mileage']),
            price: null // don't send for a read because price is already established in contract.
        })
    }
    
    /**
     * Acquire the vehicles selected on the sale
     */
    private getSaleVehicles = async () => {
        if (!this.saleId || (this.saleId <= 0)) {
            return
        }

        this.loading = true
        return await this.master.getAsync(`sales/${this.saleId}/vehicles?type=retail`).then((res) => {
            this.loading = false
            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            return res.data.vehicles;
        })
    }

    public tradeNew = (e: Event): void => {
        if (!e) {
            return
        }

        e.stopPropagation()
        
        this.addTrade.next(this.saleId)
    }

    /**
     * A past vehicle purchase of the buyer was selected as a trade-in on this sale.
     * @param e The event that trigger the purchase selection
     * @param inventoryId UUnique internal identity of the invenotry item to be traded-in on this sale.
     */
    public tradeSelected = (e: Event, inventoryId: number): void => {
        if (!e) {
            return
        }

        e.stopPropagation()

        if (!inventoryId || (inventoryId <= 0)) {
            return
        }

        this.tradedIn.next(inventoryId)
    }

    public buyerChange = (buyerId) => {
        if (this.buyerId == buyerId) {
            return
        }

        this.buyerId = parseInt(buyerId)
        this.fetchBuyerPastPurchases()
    }

    /**
     * Assign the selected vehicle
     * @param { Number } inventoryId The id of the selected inventory item.
     */
    public vehicleSelected = async (inventoryId) => {
        if (inventoryId <= 0) {
            return
        }

        const inventoryItem = await this.fetchInventoryItem(inventoryId)
        if (!inventoryItem || !inventoryItem['stockNumber']) {
            return
        }

        const vehicleTransType = this.saleVehicleTransactionTypes.find(el => el['name'] === this.RETAIL_TRANS_TYPE)
        this.vinLookup = inventoryItem['stockNumber']

        this.saleVehicle = {
            inventoryId: parseInt(inventoryItem['id']),
            inventory: inventoryItem,
            saleVehicleTransactionTypeId: vehicleTransType && vehicleTransType.id > 0 ? vehicleTransType.id : 0,
            mileage: parseInt(inventoryItem['miles']),
            odometerStatus: 'ACTUAL'
        }

        this.formRetailVehicle.get('mileage').setValue(formatCount(this.saleVehicle['mileage']))
        
        this.save()
    }

    /**
     * Assign the inventory id for us in the modal
     */
    public edit = (id: number) => {
        this.inventoryId = id
    }

    public getGeneralInformation = (res) => {
        // this.allData.generalInformation = res.value
        // this.auxVehicle['mileage'] = res.value['miles']
        this.auxVehicle['vinNumber'] = res.value['vinNumber']
        this.auxVehicle['stockNumber'] = res.value['stockNumber']
        // this.auxVehicle['odometerStatus'] = res.value['odometer']
        this.auxVehicle['saleVehicleTransactionTypeId'] = 1
        // this.vinLookup = res.value['stockNumber']
        this.auxVehicle['data'] = {
            "vinNumber": res.value['vinNumber'],
            "modelYear": res.value['modelYear'],
            "make": res.value['make'],
            "model": res.value['model'],
            "exteriorColor": res.value['exteriorColor']
        }
    }

    public proceedWithDiscard = () => {
        if (!this.saleVehicle || !this.saleVehicle.id || (this.saleVehicle.id <= 0)) {
            return
        }

        this.discardSaleVehicle()
    }

    /**
     * Remove an item from saleVehicles
     */
    private discardSaleVehicle = async () => {
        if (!this.saleVehicle || !this.saleVehicle.id || (this.saleVehicle.id <= 0)) {
            return
        }

        return await this.master.discard(`sales/${this.saleId}/vehicles/${this.saleVehicle.id}`, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] })

            this.vehicleChangeEvent.emit({id: 0, year: null, make: null, model: null, miles: 0, price: 0}) // notify other components sale vehicle is gone
            this.saleVehicle = null
            this.vinLookup = ''            
        })
    }

    /**
     * Acquire the inventory data from the store
     * @param { Number } inventoryId The id of the selected inventory item.
     */
    private fetchInventoryItem = async (inventoryId: number): Promise<Object> => {
        if (!inventoryId || inventoryId <= 0) return

        this.loading = true
        return await this.master.getAsync(`inventory/${inventoryId}`).then((res) => {
            this.loading = false
            if (!res || !res.data) {
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            return res.data.inventory
        })
    }

    /**
     * Acquire a list of past purchases for the selected buyer
     */
    private fetchBuyerPastPurchases = async () => {
        if (!this.saleId || (this.saleId <= 0)) {
            return
        }

        if (!this.buyerId || (this.buyerId <= 0)) {
            return
        }

        if (this.purchases) {
            this.purchases.length = 0
        }
        
        this.purchases = []

        await this.master.getAsync(`sales/${this.saleId}/buyers/${this.buyerId}/purchases`).then(res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            let pastPurchases = res.data.tradeins
            if (!pastPurchases || pastPurchases.length <= 0) {
                return
            }

            for (let purchase of pastPurchases) {
                const sale = purchase['sale']
                if (!sale) {
                    continue
                }

                const vehicles = sale['saleVehicles']
                if (!vehicles || (vehicles.length <= 0)) {
                    continue
                }

                const vehicle = vehicles[0]
                if (!vehicle || !vehicle.inventory || !vehicle.inventory.id) {
                    continue
                }

                this.purchases.push({
                    id: parseInt(vehicle.inventory.id),
                    stock: vehicle.inventory.stockNumber,
                    vin: vehicle.inventory.vehicle.vinNumber,
                    year: parseInt(vehicle.inventory.vehicle.modelYear),
                    make: vehicle.inventory.vehicle.make,
                    model: vehicle.inventory.vehicle.model
                })
            }
        })
    }
    
    /**
     * A saleCreated event from parent, assign saleId and
     * save the vehicle, if one is selected
     * @param { number } saleId The id of the sale from the parent.
     */
    public saleCreated = (saleId: number): void => {
        if (saleId <= 0) {
            return
        }

        if (this.saleId != saleId) {
            this.saleId = saleId
            if (this.saleVehicle && Object.keys(this.saleVehicle).length > 0) {
                this.save()
            }
        }
    }
    
    /**
     * If header Quote Status changed, update vehicle inventory status.
     * @param { number } stageId The id of the stage from the parent.
     */
    public saleStageChanged = (stageId): void => {
        if (!this.saleVehicle) {
            return
        }
        
        
    }

    /**
     * Map db compatible object to post.
     * @param { number } vehicleId The id of the vehicle.
     * @param { number } transactionTypeId The id of the transactionType (Retail or Trade-In).
     */
    private mapVehicle = async (vehicleId, transactionTypeId) => {
        return {
            lotId: this.lotId,
            saleVehicleTransactionTypeId: transactionTypeId,
            inventoryId: this.saleVehicle['inventoryId'] || 0,
            vehicleId: vehicleId,
            mileage: clean(this.formControlRetailVehicle.mileage.value),
            odometerStatus: this.formControlRetailVehicle.odometerStatus.value,
            sellingPrice: this.saleVehicle['sellingPrice'] || this.saleVehicle['inventory']['sellingPrice'] || 0,
            lienholderContactId: null,
            saleStageId: this.saleVehicle['saleStageId'],

            //trade-in data
            saleIdTradedOn: null,
            saleIdTradedFrom: null,
            tradeInAllowance: 0,
            tradeInPayoff: 0
        }
    }

    /**
     * Save the selected vehicle to the store
     */
    public save = async () => {
        if (this.saving) {
            return
        }

        if (!this.saleId || (this.saleId <= 0)) {
            this.vehicleChangeEvent.emit({id: 0, year: null, make: null, model: null, miles: 0, price: 0}) // tell sale to save itself
            return
        }

        if (!this.saleVehicle) {
            return
        }

        let saleVehicleTransactionTypeId = this.saleVehicle['saleVehicleTransactionTypeId'] ? parseInt(this.saleVehicle['saleVehicleTransactionTypeId']) : 0
        if (isNaN(saleVehicleTransactionTypeId) || (saleVehicleTransactionTypeId <= 0)) {
            return
        }

        let payload = await this.mapVehicle(this.saleVehicle['inventoryId'], saleVehicleTransactionTypeId)

        const identity = parseInt(this.saleVehicle['id'])
        if (!identity || isNaN(identity) || (identity <= 0)) {
            this.add(payload)
            return
        }

        this.modify(identity, payload)
    }

    /**
     * Create the new retail sale vehicle
     * @param vehicle 
     */
    private add = (vehicle) => {
        if (!this.saleId || this.saleId <= 0) {
            return
        }

        if (!vehicle) {
            return
        }

        this.saving = true
        this.master.post(`sales/${this.saleId}/vehicles`, { payload: vehicle }, (res) => {
            this.saving = false

            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
            }

            this.saleVehicle['id'] = res.data.id

            this.vehicleChangeEvent.next({
                id: parseInt(this.saleVehicle['id']),
                year: parseInt(this.saleVehicle['inventory']['vehicle']['modelYear']),
                make: this.saleVehicle['inventory']['vehicle']['make'],
                model: this.saleVehicle['inventory']['vehicle']['model'],
                miles: parseInt(this.saleVehicle['mileage']),
                price: parseFloat(this.saleVehicle['inventory']['sellingPrice'])
            })
        })
    }

    /**
     * Modify the existing retail sale vehicle.
     * @param id 
     * @param vehicle 
     * @returns 
     */
    private modify = (id:number, vehicle: Object) => {
        if (!this.saleId || this.saleId <= 0) {
            return
        }

        if (!id || (id <= 0)) {
            return
        }

        if (!vehicle) {
            return
        }

        this.saving = true
        this.master.put(`sales/${this.saleId}/vehicles/${id}`, { payload: vehicle }, (res) => {
            this.saving = false

            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
            }
        })
    }

    /**
     * Notify parent the trade changed
     */
    public tradeChange = (trades) => {
        this.tradeChangeEvent.emit(trades)
        this.fetchBuyerPastPurchases()
    }

    /**
     * Handle close button clicks in the inventory list modal.
     * @param e 
     */
    public listOpened = (e): void => {
        e.stopPropagation()
        this.displayList = true

        const btn = (document.getElementById('btn-choose-retail') as HTMLButtonElement)
        if (!btn) {
            return
        }

        btn.click()
    }

    /**
     * Handle close button clicks in the inventory list modal.
     * @param e 
     */
    public listClosed = (e): void => {
        this.displayList = false
    }

    /**
     * Close the inventory list modal after tbe user selects a vehicle.
     * @returns 
     */
    private closeChooser = (): void => {
        this.displayList = false
        const btn: HTMLButtonElement = (document.getElementById('btn-choose-retail') as HTMLButtonElement)
        if (!btn) {
            return
        }

        btn.click()
    }

}
