import { Component, OnInit } from "@angular/core";
// services
import { MasterService } from "../../services/master.service";
import { StoreService } from "../../services/store.service";
import { MessageService } from "../../services/message.service";
import * as languageLibrary from '../../services/language'
import { FormControl, FormGroup, Validators } from "@angular/forms";
@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
  /*
   * Variables
   */
  public information = new FormGroup({
    id: new FormControl(null, [Validators.required]),
    contactId: new FormControl(null, [Validators.required]),
    firstName: new FormControl(null, [Validators.required]),
    lastName: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.email, Validators.required])
  })

  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  //  save all users  filtered
  public users: Object[] = [];
  //  save all users completed
  public usersComplete: Object[] = [];
  // save the users has been selecteds
  public usersSelecteds: Object[] = [];
  // user selected (single)
  public userSelected: Object = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    status: "",
    invited: "",
    active: "",
    disabledEditing: true,
  };
  // New Users inviteds
  public usersInviteds: Object[] = [{ firstName: "", lastName: "", email: "" }];
  // table headers
  public headers: Object[] = [
    { name: "First Name", param: "firstName" },
    { name: "Last Name", param: "lastName" },
    { name: "Email Address", param: "email" },
    { name: "Status", param: "status" },
    { name: "Invited Date", param: "invitedAt" },
    { name: "Active Date", param: "validateAt" },
  ];
  // define the rows ordered
  public order = { isAscend: true, type: "" };
  // save the pagination settings
  public pagination = {
    index: 0,
    rowsByPage: 10,
    pages: [{ numPage: 0, start: 0, end: 8 }],
  };
  // define if is loading
  public loading: boolean = false;
  // define if is sending
  public sending: boolean = false;
  // save the invities success and failed
  public invitations = { success: [], failed: [] };
  // permissions
  public permissions = [];
  /*
   * Functions
   */
  /*
  todo: desactivate user
  */
  //  deacitive masive users
  // public deactivateUser = (): void => {
  //   let action = "delete";
  //   if (this.userSelected["status"] == "Disabled") {
  //     action = "active";
  //   }
  //   this.master.post(
  //     "deactivateUser",
  //     { emails: [this.userSelected["email"]], type: action },
  //     (res) => {
  //       if (res) {
  //         if (res.status == 200) {
  //           this.usersSelecteds = [];
  //           this.getUsers();
  //         } else {
  //           this.ms.sendMessage("alert", {
  //             type: "danger",
  //             text: res.data.error,
  //           });
  //         }
  //       }
  //     }
  //   );
  // };

  //  save change of user email (single)
  public updateEmail = (emailInputName, id): void => {
    // test email valid
    this.loading = true;
    let email = document.getElementById(emailInputName) as HTMLInputElement;
    if (this.userSelected["email"] != email.value) {
      // if email isn´t equal
      if (email.validity.valid) {
        // verufy if email exist
        this.emailAlreadyUsed(email.value, (used) => {
          // if email correct
          if (!used) {
            // change email
            this.master.post(
              "updateUser",
              { id: id, email: email.value },
              (res) => {
                if (res) {
                  if (res.status == 200) {
                    this.userSelected["email"] = email.value;
                    // this.ms.sendMessage("alert", { type: "success", text: 'Update Success' });
                    this.copyLink(email.value, id, "copy_link");
                    this.getUsers();
                    (document.getElementById(
                      "emailHelp"
                    ) as HTMLInputElement).innerText = "";
                  } else {
                    this.ms.sendMessage("alert", {
                      type: "danger",
                      text: res.data.error,
                    });
                    this.loading = false;
                  }
                } else {
                  this.ms.sendMessage("alert", {
                    type: "danger",
                    text: this.words[this.language]['apiNoResponse'],
                  });
                  this.loading = false;
                }
              }
            );
          } else {
            // email already used
            this.ms.sendMessage("alert", { type: "danger", text: used.error });
            this.loading = false;
          }
        });
      } else {
        (document.getElementById("emailHelp") as HTMLInputElement).innerText =
          "Invalid Email Address";
        this.loading = false;
      }
    } else {
      this.copyLink(this.userSelected["email"], id, "copy_link");
      this.getUsers();
      (document.getElementById("emailHelp") as HTMLInputElement).innerText = "";
    }
  };

  // return true if users selecteds
  public sendInvitation = (emailInputName, firstNameInputName, lastNameInputName, id): void => {
    //
    // test email valid
    this.sending = true;
    let email = document.getElementById(emailInputName) as HTMLInputElement;
    let firstName = document.getElementById(
      firstNameInputName
    ) as HTMLInputElement;
    let lastName = document.getElementById(
      lastNameInputName
    ) as HTMLInputElement;

    if (
      this.userSelected["firstName"] != firstName.value ||
      this.userSelected["lastName"] != lastName.value
    ) {
      this.master.post(
        "updateUser",
        { id: id, firstName: firstName.value, lastName: lastName.value },
        (res) => {
          if (res) {
            if (res.status == 200) {
              this.userSelected["firstName"] = firstName.value;
              this.userSelected["lastName"] = lastName.value;
              this.ms.sendMessage("alert", {
                type: "success",
                text: this.words[this.language]['success'],
              });
              this.getUsers();
            } else {
              this.ms.sendMessage("alert", {
                type: "danger",
                text: res.data.error,
              });
              this.sending = false;
            }
          }
        }
      );
    }
    if (this.userSelected["email"] !== email.value) {
      // if email isn´t equal
      if (email.validity.valid) {
        // verufy if email exist
        this.emailAlreadyUsed(email.value, (used) => {
          // if email correct
          if (!used) {
            // change email
            this.master.post(
              "updateUser",
              { id: id, email: email.value },
              (res) => {
                if (res) {
                  if (res.status == 200) {
                    this.userSelected["email"] = email.value;
                    this.ms.sendMessage("alert", {
                      type: "success",
                      text: this.words[this.language]['success'],
                    });
                    // this.copyLink(email.value, id,'copy_link')

                    this.sendEmail(email.value);
                    this.getUsers();
                    (document.getElementById(
                      "emailHelp"
                    ) as HTMLInputElement).innerText = "";
                  } else {
                    this.ms.sendMessage("alert", {
                      type: "danger",
                      text: res.data.error,
                    });
                    this.sending = false;
                  }
                } else {
                  this.ms.sendMessage("alert", {
                    type: "danger",
                    text: this.words[this.language]['apiNoResponse'],
                  });
                  this.sending = false;
                }
              }
            );
          } else {
            // email already used
            this.ms.sendMessage("alert", { type: "danger", text: used.error });
            this.sending = false;
          }
        });
      } else {
        (document.getElementById("emailHelp") as HTMLInputElement).innerText =
          "Invalid Email Address";
        this.sending = false;
      }
    } else {
      // this.copyLink(this.userSelected['email'], id,'copy_link')
      this.sendEmail(email.value);
      this.getUsers();
      (document.getElementById("emailHelp") as HTMLInputElement).innerText = "";
    }
    //
  };
  // send the email
  public sendEmail = (email): void => {
    this.sending = true;
    this.master.post("resendMail", { emails: [email] }, (res) => {
      if (res) {
        if (res.status == 200) {
          this.sending = false;
          this.ms.sendMessage("alert", {
            type: "success",
            text: this.words[this.language]['invitationsWasSend'],
          });
        } else {
          this.ms.sendMessage("alert", {
            type: "danger",
            text: res.data.error,
          });
          this.sending = false;
        }
      } else {
        this.ms.sendMessage("alert", {
          type: "danger",
          text: this.words[this.language]['apiNoResponse'],
        });
        this.sending = false;
      }
    });
  };
  // copy link
  public copyLink = (email, id, inputName): void => {
    this.loading = true;
    this.master.post(
      "tokenLink",
      {
        email: email,
        id: id,
      },
      (res) => {
        if (res) {
          if (res.status == 200) {
            let input = document.getElementById(inputName) as HTMLInputElement;
            input.value = "";
            input.value = res.data.link;
            input.select();
            setTimeout(() => {
              document.execCommand("copy");
              this.loading = false;
              this.ms.sendMessage("alert", {
                type: "success",
                text: this.words[this.language]['copySuccess'],
              });
            }, 500);
          } else {
            this.loading = false;
            this.ms.sendMessage("alert", {
              type: "danger",
              text: res.data.error,
            });
          }
        } else {
          this.loading = false;
          this.ms.sendMessage("alert", {
            type: "danger",
            text: this.words[this.language]['apiNoResponse'],
          });
        }
      }
    );
  };
  // find the user in the data base
  public emailAlreadyUsed = (email, callback) => {
    // if email valid
    this.master.post("attributesExists", { email: email }, (res) => {
      if (res.status == 200) {
        // status 200 when the email not exist
        callback(false);
      } else {
        // status 400 when the email is already use
        callback(true);
      }
    });
  };
  /*
  todo: pagination and rows per view functions
  */
  // sort the users registers
  // public sort = (type: string): void => {
  //   if (type == this.order.type) {
  //     this.order.isAscend = !this.order.isAscend;
  //   } else {
  //     this.order.type = type;
  //     this.order.isAscend = true;
  //   }
  //   if (this.order.isAscend) {
  //     // ascend
  //     this.users = this.users.sort((a, b) => (a[type] > b[type] ? 1 : -1));
  //   } else {
  //     // descend
  //     this.users = this.users.sort((a, b) => (a[type] > b[type] ? -1 : 1));
  //   }
  // };
  // change num page view
  public changePage = (page: number): void => {
    if (page == -1) {
      // back
      if (this.pagination.index >= 1) {
        this.pagination.index = this.pagination.index - 1;
      }
    } else {
      // next
      if (this.pagination.index < this.pagination.pages.length - 1) {
        this.pagination.index = this.pagination.index + 1;
      }
    }
  };
  // create a pagination
  public newPagination = (registers: Object[]): void => {
    this.pagination.pages = [];
    this.pagination.index = 0;
    // let registrosPerPage=2
    for (
      let index = 0;
      index < registers.length / this.pagination.rowsByPage;
      index++
    ) {
      this.pagination.pages.push({
        numPage: index,
        start: this.pagination.rowsByPage * index,
        end: this.pagination.rowsByPage * (index + 1),
      });
    }
  };
  // change the rows per page
  public changeRows = (): void => {
    this.pagination.index = 0;
    this.newPagination(this.users);
  };
  // filter by word
  public search = (e): void => {
    // if (e.key == "Enter") {
    let word = (document.getElementById("search") as HTMLInputElement).value;
    this.users = this.usersComplete.filter(
      (el) =>
        el["firstName"].includes(word) ||
        el["lastName"].includes(word) ||
        el["email"].includes(word) ||
        el["status"].includes(word) ||
        el["id"].toString().includes(word)
    );
    if (this.users.length >= 1) {
      this.pagination.rowsByPage = this.users.length;
      // exist rows
      this.newPagination(this.users);
    } else {
      this.pagination.rowsByPage = 10;
      this.ms.sendMessage("alert", {
        type: "info",
        text: this.words[this.language]['noFound'],
      });
    }
    // }
  };

  // todo: listen to output of navbar
  public listenerNav = (e): void => {

    switch (e.message) {

      case 'changeLanguage':
        this.language = e.value
        break;
      case 'setPermissions':
        // permissions on user list
        this.permissions = e.permissions[0].childrens[0].permissions;

        // if not have permissions, return to account settings
        if (this.permissions.includes('view')) {
          // this.ms.sendMessage("alert", { type: "danger", text: 'You don´t have permissions' });
          this.getUsers()
        }
        break;

      default:
        break;
    }
  }
  // listener table emitter
  public listenerTable = (e) => {
    // reload user list
    this.getUsers();
    // descart user selecteds
    this.usersSelecteds = [];
  }
  public urlLink: string = null;

  /*
   * Life status
   */
  constructor(
    private master: MasterService,
    private ms: MessageService,
    public store: StoreService
  ) {
    // normal

    if (store.userAccount["permissions"]) {
      // permissions on user list
      this.permissions =
        store.userAccount['permissions'][0].childrens[0].permissions;

      // if not have permissions, return to account settings
      if (this.permissions.includes('view')) {
        this.getUsers()
      }
    }

  }

  ngOnInit() {

  }

  // get users data
  public getUsers() {
    this.master.get("users", (res) => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: 'An unknown error has occurred.' })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "warning", text: res.data.error })
        return
      }

      this.users = res.data.users;
      this.usersComplete = res.data.users;
      this.newPagination(this.users);

    });
  };

  public setInformation(user) {
    this.urlLink = null
    this.information.setValue({
      id: user.id,
      contactId: user['corporatesUsers'][0]['contact'].id,
      firstName: user['corporatesUsers'][0]['contact'].firstName,
      lastName: user['corporatesUsers'][0]['contact'].lastName,
      email: user.email
    })
  };

  public modifyUser() {
    this.urlLink = null
    if (this.information.invalid) {
      return
    }

    const information = this.information.value
    if (!this.information.get('email').dirty) {
      delete (information.email)
    }
    this.master.put(`user/${this.information.get('id').value}`, { users: this.information.value }, res => {
      this.loading = false
      
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: 'An unknown error has occurred.' })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "warning", text: res.data.error })
        return
      }
      
      this.getUsers()
      
      if (!res.data.link){
        return
      }

      this.urlLink = res.data.link
    })
  }

  public copyInviteURL() {
    navigator.clipboard.writeText(this.urlLink).then(function () {
      console.log('Async: Copying to clipboard was successful!');
    }, function (err) {
      console.error('Async: Could not copy text: ', err);
    });
  }

  public deactivateUser(){
    if (this.information.invalid){
      return
    }

    this.master.discard(`user/${this.information.get('id').value}`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: 'An unknown error has occurred.' })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "warning", text: res.data.error })
        return
      }
      this.information.reset()
      this.users = res.data.users;
      this.usersComplete = res.data.users;
      this.newPagination(this.users);
    })
  }

  
  public reactivateUser(){
    if (this.information.invalid){
      return
    }

    this.master.patch(`user/${this.information.get('id').value}`, null, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: 'An unknown error has occurred.' })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "warning", text: res.data.error })
        return
      }
      this.information.reset()
      this.users = res.data.users;
      this.usersComplete = res.data.users;
      this.newPagination(this.users);
    })
  }
}
