import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { INVENTORY } from '../../../../constants/inventory'
// services
import { MessageService } from '../../../../services/message.service'
import { MasterService } from '../../../../services/master.service'
import * as languageLibrary from '../../../../services/language'
import { StoreService } from 'src/app/services/store.service';
import * as moment from 'moment';
@Component({
  selector: 'inventory-reconditioning-cost',
  templateUrl: './inventory-reconditioning-cost.component.html',
  styleUrls: ['./inventory-reconditioning-cost.component.scss']
})
export class InventoryReconditioningCostComponent implements OnInit {
  /*
    * Variables
    */
  @Output() emitterInformation$: EventEmitter<Object> = new EventEmitter()
  //  define if some changes not saved
  public savePending: boolean = false
  // save the language
  @Input() language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  // define expanded
  public expanded: boolean = true
  // define the inventoryId
  @Input() inventoryId: number = 0
  // define if is loading
  public loading: boolean = false

  // form
  public information = new FormGroup({
    id: new FormControl(null),
    phoneNumber: new FormControl(null),
    costIncurred: new FormControl(0, [Validators.required, Validators.min(1)]),
    dateIncurred: new FormControl(new Date().toISOString().substring(0, 10), [Validators.required]),
    datePaid: new FormControl(null),
    checkNumber: new FormControl(null),
    referenceNumber: new FormControl(null),
    vendorId: new FormControl(null),
    type: new FormControl(null, [Validators.required]),
    descriptionId: new FormControl(null, [Validators.required]),
    inventoryId: new FormControl(null, [Validators.required]),
  })
  public reconCosts: [];
  public vendors: [];
  public reconTypes: {} = INVENTORY.RECONDITIONING_TYPES
  public id: number = 0;
  public selectedId: 0;
  public reconDescs: [];
  public reconDescsSorted: [] = [];
  public costIncurred: number;
  public reconCostTotals: object = {}
  public reconCostTotal: number = 0;
  public corpId: number;
  /*
 * Functions
 */
  /*
  /*
  * life cycles
  */
  constructor(private route: ActivatedRoute, private master: MasterService, private ms: MessageService, private store: StoreService) {
    this.corpId = this.store.userAccount.hasOwnProperty("user") ? this.store.userAccount['user'].corporateId : null
  }

  ngOnInit() {
    // this.getVendors()
    this.getReconCostTypes()

    if (this.route.snapshot.params["id"] === 'add') {
      return
    }
    this.getReconCosts()

  }
  
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes && changes.inventoryId && changes.inventoryId.currentValue) {
      this.inventoryId = changes.inventoryId.currentValue
    }
  }

  public saveReconCost() {
    const identity = parseInt(this.information.get('id').value)
    if (isNaN(identity)) {
      this.createReconCost()
      return
    }

    this.modifyReconCost(identity)
  }

  public sortDesc() {
    const partition = (ary, callback) =>
      ary.reduce((acc, e) => {
        acc[callback(e) ? 0 : 1].push(e)
        return acc
      }, [[], []])

    let [withIn, notWithIn] = partition(this.reconDescs, (reconDesc => reconDesc.type == this.information.get("type").value))
    this.reconDescsSorted = withIn.concat("-").concat(notWithIn)
  }

  public deleteReconCost() {
    this.master.discard(`inventory/${this.inventoryId}/reconCost/${this.id}?sum=${this.costIncurred}`, (res) => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      (document.getElementById('btn-close-modal-delete-reconCost').click())
      this.reconCosts = res.data.reconditioningCosts
      this.reconCostTotals = res.data.reconditioningCostTotal
      this.updateReconCostOnGeneralTab(res.data.reconditioningCostTotal['total'] ? res.data.reconditioningCostTotal['total'] : 0.00)
    })
  }
  public modifyReconCost(identity) {
    this.information.get('inventoryId').setValue(this.inventoryId)
    if (this.information.invalid) {
      return
    }
    this.master.put(`inventory/${this.inventoryId}/reconCost/${identity}`, { information: this.information.value }, (res) => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.reconCosts = res.data.reconditioningCosts
      this.reconCostTotals = res.data.reconditioningCostTotal
      this.updateReconCostOnGeneralTab(res.data.reconditioningCostTotal['total'] ? res.data.reconditioningCostTotal['total'] : 0.00)
      this.resetInformation()
    })
  }

  public updateReconCostOnGeneralTab(reconCostTotal: number) {
    this.reconCostTotal = reconCostTotal
    this.emitterInformation$.emit({ message: 'setReconCostTotal', reconCostTotal: reconCostTotal })
  }

  public createReconCost() {
    this.information.get('inventoryId').setValue(this.inventoryId)
    if (this.information.invalid) {
      return
    }
    this.master.post(`inventory/${this.inventoryId}/reconCost`, { information: this.information.value }, (res) => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.reconCosts = res.data.reconditioningCosts
      this.reconCostTotals = res.data.reconditioningCostTotal
      this.updateReconCostOnGeneralTab(res.data.reconditioningCostTotal['total'] ? res.data.reconditioningCostTotal['total'] : 0.00)
      this.resetInformation()
    })
  }

  public getVendors() {
    //used for inventory source
    this.master.get(`contacts?type=vendor`, (res) => {
      this.vendors = [];
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.vendors = res.data.contacts
    })
  }
  public getReconCosts() {
    this.master.get(`inventory/${this.inventoryId}/reconCosts`, (res) => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.reconCosts = res.data.reconditioningCosts
      this.reconCostTotals = res.data.reconditioningCostTotal
      this.updateReconCostOnGeneralTab(res.data.reconditioningCostTotal['total'] ? res.data.reconditioningCostTotal['total'] : 0.00)
    })
  }


  public getReconCostTypes() {

    this.master.get(`corporations/${this.corpId}/reconDesc`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.reconDescs = res.data.reconDescriptions
      this.reconDescsSorted = this.reconDescs
    })
  }

  public resetInformation() {
    this.information.reset();
    this.information.get('dateIncurred').setValue(moment().format('YYYY-MM-DD'));
    document.getElementById('btn-save').innerHTML = 'Save'
    this.selectedId = null
  }

  public setId(id: number, cost: number) {
    this.id = id
    this.costIncurred = cost
  }

  public setInformation(reconditioningCosts: any) {
    (document.getElementById('btn-save').innerHTML = 'Update')
    this.selectedId = reconditioningCosts.id
    this.information.setValue({
      id: reconditioningCosts.id,
      phoneNumber: reconditioningCosts.phoneNumber,
      costIncurred: reconditioningCosts.costIncurred,
      dateIncurred: reconditioningCosts.dateIncurred,
      datePaid: reconditioningCosts.datePaid,
      checkNumber: reconditioningCosts.checkNumber,
      referenceNumber: reconditioningCosts.referenceNumber,
      vendorId: reconditioningCosts.vendorId,
      type: reconditioningCosts.type,
      descriptionId: reconditioningCosts.descriptionId,
      inventoryId: reconditioningCosts.inventoryId,
    })
  }
  public setContactIdEvent(e) {
    if (!e) {
      return
    }
    this.information.get("vendorId").setValue(e)
  }
}
