import { Component, EventEmitter, Injector, Input, OnInit, Output, InjectionToken } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// services
import * as languageLibrary from '../../../../services/language';
import { MasterService } from '../../../../services/master.service';
import { MessageService } from '../../../../services/message.service';
// external
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { PAYMENT_CYCLES } from '../../../../constants/sale'
// export const token = new InjectionToken<string>('');
@Component({
    selector: 'inventory-floor-plans',
    templateUrl: './inventory-floor-plans.component.html',
    styleUrls: ['./inventory-floor-plans.component.scss']
})
export class InventoryFloorPlansComponent implements OnInit {
    /*
      * Variables
      */
    @Output() emitterInformation$: EventEmitter<Object> = new EventEmitter()
    // save the language
    @Input() language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
    // define the inventoryId
    @Input() inventoryId: number = 0
    // define the balance
    public balance: string = ""
    @Input() purchaseCost: number = 0;
    @Input() dateOnLot: Date
    // permissions
    @Input() permissions: string[] = []
    public fpCostTotal: number = 0;
    // define the lenders
    public lenders: Object[] = []
    //  define if some changes not saved
    public savePending: boolean = false
    // set all words
    public words = languageLibrary.language
    public paymentFrequency: {} = PAYMENT_CYCLES
    // define expanded
    public expanded: boolean = true
    // showCreateForm
    public showCreateForm: boolean = true
    public floorPlan: [];
    public floorPlanReductionLines: any;
    public inventoryInfo: any;
    public floorPlanDisplayed: [];
    public activeFloorPlanExists: any
    public floorPlanTotals: [];
    public floorPlanCostTotal: number;
    public selectedId: number = null;
    public deleteId: number = null;
    public information = new FormGroup({
        id: new FormControl(null),
        initialAmount: new FormControl(0, [Validators.required, Validators.min(1)]),
        reductionAmount: new FormControl(0, [Validators.min(0)]),
        initialFeeAmount: new FormControl(0, [Validators.min(0)]),
        feeAmount: new FormControl(0, [Validators.min(0)]),
        dateOnFloorPlan: new FormControl(null, [Validators.required]),
        accountNumber: new FormControl(null),
        reductionFrequency: new FormControl(null),
        apr: new FormControl(0, [Validators.required, Validators.min(0)]),
        daysAllowed: new FormControl(0, [Validators.required, Validators.min(0)]),
        vendorId: new FormControl(null, [Validators.required]),
        inventoryId: new FormControl(null, [Validators.required]),
        contactFloorPlanId: new FormControl(null, [Validators.required]),
        daysPerYear: new FormControl(365, [Validators.required]),
    })
    public informationReduce = new FormGroup({
        reductionAmount: new FormControl(0, [Validators.required, Validators.pattern('^[0-9]*(\.[0-9]+)?$'), Validators.min(0.01)]),
        datePaid: new FormControl(new Date().toISOString().substring(0, 10), [Validators.required]),
        feeAmount: new FormControl(0),
        financeCharges: new FormControl(0, [Validators.min(0)]),
        floorPlanId: new FormControl(null),
        id: new FormControl(null),
        status: new FormControl("Reduction"),
        prevBalance: new FormControl(null),
        apr: new FormControl(0, [Validators.required, Validators.min(0)]),
    },
        {
            validators: Validators.compose([this.dateLessThan('datePaid'), this.amountLessThan('reductionAmount', 'prevBalance', 'id')])
        }
    )
    public floorPlanTotalSum: number = 0;
    public lenderAPR: number = 0;
    public modify: boolean = false;
    public myInjector: Injector;
    public activeComponent: any;
    get companyName() { return this.information.get('companyName') }
    get accountNumber() { return this.information.get('accountNumber') }
    get dateOnFloorPlan() { return this.information.get('dateOnFloorPlan') }
    get initialAmount() { return this.information.get('initialAmount') }
    get reductionAmount() { return this.information.get('reductionAmount') }
    get daysAllowed() { return this.information.get('daysAllowed') }
    get recurrentFeeFrecuency() { return this.information.get('recurrentFeeFrecuency') }
    get feeAmount() { return this.information.get('feeAmount') }
    get initialFeeAmount() { return this.information.get('initialFeeAmount') }
    get apr() { return this.information.get('apr') }
    /*
   * life cycles
   */

    constructor(private route: ActivatedRoute, private master: MasterService, private ms: MessageService, private injector: Injector) {

    }

    ngOnInit() {
        if (this.route.snapshot.params["id"] === 'add') {
            return
        }

        this.getFloorPlan()
    }

    ngOnChanges(): void {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        //Add '${implements OnChanges}' to the class.
        this.inventoryInfo = { purchaseCost: this.purchaseCost, dateOnLot: this.dateOnLot }
        this.setInventoryInformation(this.inventoryInfo)
    }

    /*
   * Functions
   */

    public dateLessThan(datePaid: string) {
        return (group: FormGroup): { [key: string]: any } => {
            let paid = group.controls[datePaid];
            if (!this.floorPlanDisplayed) {
                return
            }
            if (paid.value == "") {
                this.informationReduce.controls['datePaid'].setErrors({ 'datePaid': `Error! The reduction date cannot be empty.` });
                return {
                    dates: "The reduction date cannot be empty."
                };
            }
            let dateOnFP = this.floorPlanDisplayed['dateOnFloorPlan']
            if (paid.value < dateOnFP) {
                this.informationReduce.controls['datePaid'].setErrors({ 'datePaid': `Error! The reduction date cannot be before the Date on Floor Plan ${moment(dateOnFP).format('MM-DD-YYYY')}.` });
                return {
                    dates: "The reduction date cannot be before the Date on Floor Plan."
                };
            }
            if (paid.value > moment().format()) {
                this.informationReduce.controls['datePaid'].setErrors({ 'datePaid': `Error! The reduction date cannot be in the future.` });
                return {
                    dates: "The reduction date cannot be in the future."
                };
            }
            return {};
        }
    }

    public amountLessThan(reductionAmount: string, prevBalance: string, id: string) {
        return (group: FormGroup): { [key: string]: any } => {
            let amt = group.controls[reductionAmount];
            let previousBalance = group.controls[prevBalance];
            let redId = group.controls[id];
            if (!this.floorPlanDisplayed || !amt.value) {
                return
            }
            if (redId.value !== null) {
                if (amt.value > previousBalance.value) {
                    this.informationReduce.controls['reductionAmount'].setErrors({ 'reductionAmount': `Error! The reduction amount cannot be more than the current payoff balance.` });
                    return {
                        amt: `The reduction amount cannot be more than the current payoff balance.`
                    };
                }
                return
            }
            if (parseFloat(amt.value) > parseFloat(this.balance)) {
                this.informationReduce.controls['reductionAmount'].setErrors({ 'reductionAmount': `Error! The reduction amount cannot be more than the current payoff balance.` });
                return {
                    amt: `The reduction amount cannot be more than the current payoff balance.`
                };
            }
            return {};
        }
    }

    public getContactInfo() {
        let vendorId = this.information.get("vendorId").value
        if (isNaN(vendorId)) {
            return
        }
        this.master.get(`contacts/${vendorId}/floorplan`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            if (!res.data.contactFloorPlan) {
                this.information.patchValue({
                    reductionAmount: null,
                    initialFeeAmount: 0,
                    feeAmount: 0,
                    accountNumber: null,
                    reductionFrequency: null,
                    apr: 0,
                    daysAllowed: 0
                })
                return
            }

            this.setLenderInformation(res.data.contactFloorPlan)
        })
    }
    public getFloorPlanLendors() {
        this.master.get(`contacts?type=floorPlanVendor`, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.lenders = res.data.contacts
        })
    }
    public getFloorPlan() {
        this.master.get(`inventory/${this.inventoryId}/floorPlan`, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            if (!res.data.floorPlan) {
                this.updateFPCostOnGeneralTab(0)
                return
            }

            this.floorPlan = res.data.floorPlan
            this.floorPlanTotalSum = res.data.floorPlanTotalSum
            this.updateFPCostOnGeneralTab(this.floorPlanTotalSum ? this.floorPlanTotalSum : 0)
            this.showCreateForm = false
            if (!this.floorPlan) {
                this.showCreateForm = true
                return
            }
            this.activeFloorPlanExists = this.floorPlan.filter((fp) => fp['status'] === 'Active')
            if (this.activeFloorPlanExists.length == 0) {
                this.showCreateForm = true
            }
            this.selectFloorPlan(0)
        })
    }

    public resetFloorPlan() {
        this.floorPlanReductionLines = null
        this.floorPlanDisplayed = null
        this.floorPlanTotals = null
        this.floorPlanTotalSum = 0
        this.balance = null
        this.selectedId = null
        this.updateFPCostOnGeneralTab(0)
        this.resetInformationReduce()
        this.resetInformation(true)
    }
    public selectFloorPlan(id) {
        this.floorPlanReductionLines = this.floorPlan[id]['reductionLines']['finalReductionLines']
        this.floorPlanDisplayed = this.floorPlan[id]
        this.floorPlanTotals = this.floorPlan[id]['reductionLines']['floorPlanTotals']
        this.balance = parseFloat(this.floorPlanTotals['currentBalance']).toFixed(2);
        this.setInformationReduce(this.floorPlanDisplayed, false)
        this.setDates(this.floorPlanDisplayed['dateOnFloorPlan'], this.floorPlanDisplayed['daysAllowed'])
    }

    public setDates(dateOnFloorPlan, daysAllowed) {
        let today = moment(moment().format('YYYY-MM-DD'))
        let daysOnFloorPlan = 0
        let daysRemaining = 0
        let daysPastLimit = 0
        if (!dateOnFloorPlan) {
            return
        }
        daysOnFloorPlan = today.diff(dateOnFloorPlan, 'days') + 1
        daysRemaining = (daysAllowed - daysOnFloorPlan) + 1
        if (daysRemaining < 0) {
            daysRemaining = 0
        }
        const lastDateOnFloorPlan = new Date(dateOnFloorPlan);
        lastDateOnFloorPlan.setDate(lastDateOnFloorPlan.getDate() + (daysAllowed + 1));
        if (lastDateOnFloorPlan < new Date()) {
            daysPastLimit = (today.diff(lastDateOnFloorPlan, 'days') + 1) * -1
        }
        this.floorPlanDisplayed['daysOnFloorPlan'] = daysOnFloorPlan
        this.floorPlanDisplayed['daysRemaining'] = daysRemaining
        this.floorPlanDisplayed['daysPastLimit'] = daysPastLimit
        this.floorPlanDisplayed['lastDateOnFloorPlan'] = moment(lastDateOnFloorPlan).format('YYYY-MM-DD')
    }

    public setInformationReduce(floorPlanData: any, changeButtonLabel: boolean) {
        this.selectedId = null
        this.informationReduce.reset()
        this.informationReduce.get('reductionAmount').setValidators([Validators.required])
        this.informationReduce.get('datePaid').setValidators([Validators.required])
        this.informationReduce.get('status').setValue("Reduction")
        this.informationReduce.get('feeAmount').setValue(floorPlanData['feeAmount'])
        this.informationReduce.get('financeCharges').setValue(null)
        this.informationReduce.get('prevBalance').setValue(floorPlanData['prevBalance'])
        if (changeButtonLabel) {
            document.getElementById('btn-add-reduction').innerHTML = "Modify Reduction"
            this.selectedId = floorPlanData['id']
            this.informationReduce.get('id').setValue(floorPlanData['id'])
            this.informationReduce.get('floorPlanId').setValue(floorPlanData['floorPlanId'])
            this.informationReduce.get('datePaid').setValue(floorPlanData['datePaid'])
            this.informationReduce.get('apr').setValue(floorPlanData['reductionAPR'])
            this.informationReduce.get('reductionAmount').setValue(floorPlanData['reductionAmount'])
            if (floorPlanData['status'] == 'Initial') {
                this.informationReduce.get('reductionAmount').setValidators(null)
                this.informationReduce.get('datePaid').setValidators(null)
                this.informationReduce.get('status').setValue(floorPlanData['status'])
            }
        } else {
            this.informationReduce.get('floorPlanId').setValue(floorPlanData['id'])
            this.informationReduce.get('datePaid').setValue(new Date().toISOString().substring(0, 10))
            this.informationReduce.get('apr').setValue(floorPlanData['apr'])
            parseFloat(floorPlanData['reductionAmount']) > parseFloat(this.balance) ? this.informationReduce.get('reductionAmount').setValue(this.balance) : this.informationReduce.get('reductionAmount').setValue(floorPlanData['reductionAmount'])
        }
        this.informationReduce.get('reductionAmount').updateValueAndValidity()
        this.informationReduce.get('datePaid').updateValueAndValidity()
    }
    public modifyReduction() {
        if (this.informationReduce.invalid) {
            return
        }
        if (this.informationReduce.get("financeCharges").value == 'undefined') {
            this.informationReduce.get("financeCharges").setValue(null)
        }
        this.master.put(`inventory/${this.inventoryId}/floorPlanRed/${this.informationReduce.get("id").value}`, { information: this.informationReduce.value }, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            if (res.data.floorPlan.length < 1) {
                return
            }


            this.floorPlan = res.data.floorPlan
            this.floorPlanTotalSum = res.data.floorPlanTotalSum
            this.updateFPCostOnGeneralTab(this.floorPlanTotalSum ? this.floorPlanTotalSum : 0)
            this.activeFloorPlanExists = this.floorPlan.filter((fp) => fp['status'] === 'Active')
            this.showCreateForm = false
            if (this.activeFloorPlanExists.length == 0) {
                this.showCreateForm = true
            }
            this.selectFloorPlan(0);
            (document.getElementById('btn-add-reduction').innerHTML = "Add Reduction");
        })
    }

    public deleteReduction() {
        this.master.discard(`inventory/${this.inventoryId}/floorPlanRed/${this.deleteId}`, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            if (res.data.floorPlan.length < 1) {
                return
            }

            (document.getElementById('close-delete-modal-reduction').click())
            this.floorPlan = res.data.floorPlan
            this.floorPlanTotalSum = res.data.floorPlanTotalSum
            this.updateFPCostOnGeneralTab(this.floorPlanTotalSum ? this.floorPlanTotalSum : 0)
            this.activeFloorPlanExists = this.floorPlan.filter((fp) => fp['status'] === 'Active')
            this.showCreateForm = false
            if (this.activeFloorPlanExists.length == 0) {
                this.showCreateForm = true
                return
            }
            this.selectFloorPlan(0);
        })
    }

    public resetInformation(showForm: boolean) {
        this.information.reset()
        if (this.modify == true) {
            this.showCreateForm = false
            this.modify = false
            return
        }
        this.showCreateForm = showForm
        this.setInventoryInformation(this.inventoryInfo)
    }

    public resetInformationReduce() {
        this.informationReduce.reset();
        this.selectedId = null;
        this.deleteId = null;
        (document.getElementById('btn-add-reduction').innerHTML = "Add Reduction")
        if (!this.floorPlanDisplayed) {
            return
        }
        this.setInformationReduce(this.floorPlanDisplayed, false)
    }

    public setDeleteId(id) {
        this.deleteId = id
    }

    public saveReduction() {
        const identity = parseInt(this.informationReduce.get('id').value)
        const reductionAmount = this.informationReduce.get('reductionAmount').value

        let remainingAmount = parseFloat(this.balance) - reductionAmount
        if (remainingAmount <= 0) {
            this.payOff(identity, reductionAmount)
            // return
        }

        if (isNaN(identity)) {
            this.createReduction()
            return
        }
        this.modifyReduction()
    }
    public createReduction() {
        if (this.informationReduce.invalid) {
            return
        }

        this.master.post(`inventory/${this.inventoryId}/floorPlanRed`, { information: this.informationReduce.value }, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.floorPlan = res.data.floorPlan
            this.floorPlanTotalSum = res.data.floorPlanTotalSum
            this.updateFPCostOnGeneralTab(this.floorPlanTotalSum ? this.floorPlanTotalSum : 0)
            this.activeFloorPlanExists = this.floorPlan.filter((fp) => fp['status'] === 'Active')
            this.showCreateForm = false
            if (this.activeFloorPlanExists.length == 0) {
                this.showCreateForm = true
            }
            this.selectFloorPlan(0)
        })
    }

    public setLenderInformation(lenderData) {
        this.information.patchValue({
            vendorId: lenderData.contactId ? lenderData.contactId : lenderData.vendorId,
            contactFloorPlanId: lenderData.id,
            reductionAmount: lenderData.reductionAmount,
            initialFeeAmount: lenderData.initialFeeAmount,
            feeAmount: lenderData.feeAmount,
            accountNumber: lenderData.accountNumber,
            reductionFrequency: lenderData.reductionFrequency,
            apr: lenderData.apr,
            daysAllowed: lenderData.daysAllowed,
            daysPerYear: lenderData.daysPerYear
        });
        this.lenderAPR = lenderData.apr
    }

    public setInventoryInformation(inventoryData) {
        this.information.get("inventoryId").setValue(this.inventoryId)
        this.information.get("initialAmount").setValue(inventoryData.purchaseCost)
        this.information.get("dateOnFloorPlan").setValue(inventoryData.dateOnLot)
    }

    public setInformation(floorPlanData) {
        this.information.setValue({
            id: floorPlanData.id,
            daysPerYear: floorPlanData.daysPerYear,
            inventoryId: this.inventoryId,
            initialAmount: floorPlanData.initialAmount,
            dateOnFloorPlan: floorPlanData.dateOnFloorPlan,
            vendorId: floorPlanData.vendorId,
            reductionAmount: floorPlanData.reductionAmount,
            initialFeeAmount: floorPlanData.initialFeeAmount,
            feeAmount: floorPlanData.feeAmount,
            accountNumber: floorPlanData.accountNumber,
            reductionFrequency: floorPlanData.reductionFrequency,
            apr: floorPlanData.apr,
            daysAllowed: floorPlanData.daysAllowed,
            contactFloorPlanId: floorPlanData.contactFloorPlanId,
        })

    }

    public updateFPCostOnGeneralTab(floorPlanCostTotal: number) {
        this.floorPlanCostTotal = floorPlanCostTotal
        this.emitterInformation$.emit({ message: 'setFPCostTotal', floorPlanCostTotal: floorPlanCostTotal })
    }

    public save() {
        const identity = parseInt(this.information.get('id').value)
        this.showCreateForm = false
        if (isNaN(identity)) {
            this.createFloorPlan()
            return
        }

        this.modifyFloorPlan()
    }

    public modifyFloorPlan() {
        if (this.information.invalid) {
            return
        }

        this.master.put(`inventory/${this.inventoryId}/floorPlan/${this.floorPlanDisplayed['id']}`, { information: this.information.value }, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.floorPlan = res.data.floorPlan
            this.floorPlanTotalSum = res.data.floorPlanTotalSum
            this.updateFPCostOnGeneralTab(this.floorPlanTotalSum ? this.floorPlanTotalSum : 0)
            this.activeFloorPlanExists = this.floorPlan.filter((fp) => fp['status'] === 'Active')
            this.showCreateForm = false
            if (this.activeFloorPlanExists.length == 0) {
                this.showCreateForm = true
                return
            }
            this.selectFloorPlan(0)
            this.resetInformation(false)
        })
    }

    public createFloorPlan() {
        if (this.information.invalid) {
            return
        }

        this.master.post(`inventory/${this.inventoryId}/floorPlan`, { information: this.information.value }, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.floorPlan = res.data.floorPlan
            this.floorPlanTotalSum = res.data.floorPlanTotalSum
            this.updateFPCostOnGeneralTab(this.floorPlanTotalSum ? this.floorPlanTotalSum : 0)
            this.activeFloorPlanExists = this.floorPlan.filter((fp) => fp['status'] === 'Active')
            this.showCreateForm = false
            if (this.activeFloorPlanExists.length == 0) {
                this.showCreateForm = true
                return
            }
            this.selectFloorPlan(0)
            this.resetInformation(false)
        })
    }

    public payOff(identity, reductionAmount) {
        this.informationReduce.get('reductionAmount').setValue(reductionAmount ? reductionAmount : this.balance)
        this.informationReduce.get('feeAmount').setValue(0)
        this.informationReduce.get('status').setValue("Payoff")
        this.informationReduce.get('id').setValue(identity ? identity : null)
        this.informationReduce.get('financeCharges').setValue(null)
        this.showCreateForm = true
        this.activeFloorPlanExists = false
        this.createReduction()
    }

    public deleteFloorPlan() {
        if (!this.activeFloorPlanExists || this.floorPlanReductionLines.length > 1) {
            return
        }
        this.master.discard(`inventory/${this.inventoryId}/floorPlan/${this.floorPlanDisplayed['id']}`, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            (document.getElementById('close-delete-modal-floor-plan').click())
            this.floorPlan = res.data.floorPlan
            if (!this.floorPlan) {
                this.showCreateForm = true
                this.resetFloorPlan()
                return
            }
            this.floorPlanTotalSum = res.data.floorPlanTotalSum
            this.updateFPCostOnGeneralTab(this.floorPlanTotalSum ? this.floorPlanTotalSum : 0)
            this.showCreateForm = false

            this.activeFloorPlanExists = this.floorPlan.filter((fp) => fp['status'] === 'Active')
            if (this.activeFloorPlanExists.length == 0) {
                this.showCreateForm = true
            }
            this.selectFloorPlan(0)
        })
    }

    public modifyDefaults() {
        if (!this.activeFloorPlanExists) {
            return
        }
        this.showCreateForm = true;
        this.modify = true;
        (document.getElementById('close-modify-modal-floor-plan').click())
        this.setInformation(this.floorPlanDisplayed);
    }

    public setContactIdEvent(e) {
        if (!e) {
          return
        }
        this.information.get("vendorId").setValue(e)
        this.getContactInfo()
    }
}
