import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterService } from '../../../../../services/master.service'
import { MessageService } from '../../../../../services/message.service'
import * as languageLibrary from '../../../../../services/language'

@Component({
    selector: 'general-information',
    templateUrl: './general-information.component.html',
    styleUrls: ['./general-information.component.scss']
})
export class GeneralInformationComponent implements OnInit, OnChanges {

    /*
    * variables
    */
    @Input() isIndividual: boolean = true
    @Input() isFlat: boolean = false
    @Input() contactId: number = 0
    @Output() generalInformation$: EventEmitter<Object> = new EventEmitter()

    public words = languageLibrary.language
    public language: string = 'EN'
    public contact: Object = null
    public expanded: boolean = true
    public showControl: boolean = true
    public buyerTypes: [];
    public contactGeneralInfo = new FormGroup({
        contactId: new FormControl(0, []),
        isIndividual: new FormControl(true),
        firstName: new FormControl(null),
        lastName: new FormControl(null),
        suffix: new FormControl(null),
        middleName: new FormControl(null),
        title: new FormControl(null),
        birthdate: new FormControl(null),
        taxPayerNumber: new FormControl(null),
        companyName: new FormControl(null),
        buyerType: new FormControl("Individual", [Validators.required])
    })

    // Indicates whether or not the contact role is fixed for this list.
    public fixedRole = false

    // The key of the fixed contact role or null if none.
    public fixedRoleKey = null

    // The UI route path of the fixed contact role (default: 'contacts').
    public path = 'contacts'

    get company() { return this.contactGeneralInfo.get('companyName') }
    get first() { return this.contactGeneralInfo.get('firstName') }
    get last() { return this.contactGeneralInfo.get('lastName') }

    /*
    * Life Cycles
    */
    constructor(private router: Router, private route: ActivatedRoute, private master: MasterService, private ms: MessageService, private location: Location) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes) {
            return
        }

        const first = Object.values(changes).some(c => c.isFirstChange())
        if (first) {
            return
        }

        if (changes.contactId) {
            this.contactGeneralInfo.reset()
            this.getContact()
        }
    }

    ngOnInit() {
        const path = this.route.snapshot.url[0].path
        const pattern = new RegExp('employee', 'i')
        this.fixedRole = (path && pattern.test(path))
        if (this.fixedRole) {
            this.fixedRoleKey = 'employee'
            this.path = 'employees'
        }

        this.getBuyerTypes()
        this.getContact()
    }

    public getContact() {
        if (!this.contactId || (this.contactId <= 0)) {
            return
        }

        this.master.get(`contacts/${this.contactId}`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }
            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            if (!res.data.contact) {
                return
            }

            this.contact = res.data.contact
            this.setContactGeneralInfoForm()
        })
    }

    public setContactGeneralInfoForm = (): void => {
        if (!this.contact) {
            return
        }

        this.isIndividual = this.contact['isIndividual']
        this.contactId = parseInt(this.contact['id'])

        this.contactGeneralInfo.setValue({
            contactId: this.contact['id'],
            isIndividual: this.isIndividual,
            firstName: this.contact['firstName'],
            lastName: this.contact['lastName'],
            suffix: this.contact['suffix'],
            middleName: this.contact['middleName'],
            title: this.contact['title'],
            birthdate: this.contact['birthdate'],
            companyName: this.contact['companyName'],
            taxPayerNumber: this.contact['taxPayerNumber'],
            buyerType: this.contact['buyerType']
        })
        this.generalInformation$.emit({ message: 'individualValue', value: this.isIndividual })

        this.setFieldValidators()
    }

    public save() {
        const identity = this.contactId
        if (isNaN(identity)) {
            return
        }

        if (!this.isIndividual) {
            this.contactGeneralInfo.get('birthdate').setValue(null)
            this.contactGeneralInfo.get('suffix').setValue(null)
            this.contactGeneralInfo.get('title').setValue(null)
            this.contactGeneralInfo.get('middleName').setValue(null)
        } else {
            this.contactGeneralInfo.get('companyName').setValue(null)
        }

        if (identity <= 0) {
            this.createContact()
            return
        }

        this.modifyContact()
    }

    public createContact() {
        if (this.contactGeneralInfo.invalid) {
            this.ms.sendMessage("alert", { type: "danger", text: 'Please fill out the required fields.' });
            return
        }

        this.master.post(`contacts`, { information: this.contactGeneralInfo.value }, res => {
            if (!res) {
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.contactId = parseInt(res.data.contact.id)
            this.contactGeneralInfo.get('contactId').setValue(this.contactId)
            this.appendRole()

            if (!this.isFlat) {
                this.location.replaceState(`/${this.path}/${this.contactId}`);
            }

            this.generalInformation$.emit({ message: 'individualValue', value: this.isIndividual })
            this.generalInformation$.emit({ message: 'setContactId', value: this.contactId })
        })
    }

    public modifyContact = (): void => {
        this.contactGeneralInfo.get('isIndividual').setValue(this.isIndividual)
        if (this.contactGeneralInfo.invalid) {
            this.ms.sendMessage("alert", { type: "danger", text: 'Please fill out the required fields.' });
            return
        }

        const identity = parseInt(this.contactGeneralInfo.get('contactId').value)
        if (isNaN(identity) || (identity <= 0)) {
            return
        }

        const payload = Object.assign({}, this.contactGeneralInfo.value)

        this.master.put(`contacts/${identity}`, { information: this.contactGeneralInfo.value }, res => {
            if (!res) {
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.generalInformation$.emit({ message: 'individualValue', value: this.isIndividual })

        })
    }

    /**
     * In situations where the role is fixed and the 
     * contact was rreated we need to set the role.
     */
    private appendRole() {
        if (!this.fixedRole || !this.fixedRoleKey) {
            return
        }

        if (!this.contactId || (this.contactId <= 0)) {
            return
        }

        this.master.post(`contacts/${this.contactId}/roles`, { information: this.fixedRoleKey }, res => {
            if (!res) {
              return
            }
      
            if (res.status !== 200) {
              this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
              return
            }
        })
    }

    public modifyValidators = (event) => {
        if (event !== true) {
            var selectedBuyerType = document.getElementById("buyerType") as HTMLSelectElement;
            if (selectedBuyerType.options.length == 0) return
            var individual = this.buyerTypes[selectedBuyerType.options.selectedIndex]['isIndividual']
        }
        this.isIndividual = individual ? individual : event
        this.contactGeneralInfo.clearValidators()
        // this.contactGeneralInfo.get('isIndividual').setValidators([Validators.required])
        this.contactGeneralInfo.get('isIndividual').setValue(this.isIndividual)
        if (this.isIndividual) {
            this.contactGeneralInfo.get('firstName').setValidators([Validators.required])
            this.contactGeneralInfo.get('lastName').setValidators([Validators.required])
            this.contactGeneralInfo.get('companyName').setValidators(null)
            this.showControl = true
        }
        else {
            this.contactGeneralInfo.get('firstName').setValidators(null)
            this.contactGeneralInfo.get('lastName').setValidators(null)
            this.contactGeneralInfo.get('companyName').setValidators([Validators.required])
            this.showControl = false
        }

        this.contactGeneralInfo.get('firstName').updateValueAndValidity()
        this.contactGeneralInfo.get('lastName').updateValueAndValidity()
        // this.contactGeneralInfo.get('isIndividual').updateValueAndValidity()
        this.contactGeneralInfo.get('taxPayerNumber').updateValueAndValidity()
        this.contactGeneralInfo.get('companyName').updateValueAndValidity()
    }

    public setFieldValidators = () => {
        this.modifyValidators(this.contactGeneralInfo.get('isIndividual').value)
    }

    public getBuyerTypes() {
        if (this.buyerTypes) return
        this.master.get(`collections/sales/buyertypes`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
            }

            if (!res.data.BuyerTypes) {
                return;
            }

            this.buyerTypes = res.data.BuyerTypes.slice();
        })
    }
}