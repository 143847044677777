import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import * as languageLibrary from '../../../services/language'
import * as moment from "moment";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
// services
import { MessageService } from "../../../services/message.service";
import { MasterService } from "../../../services/master.service";
import { StoreService } from '../../../services/store.service'
import { formatPhoneNumber } from '../../../utils/utilities';
import { SEARCH_DEBOUNCE_MILLIS, MIN_SEARCH_CHARS, SCROLL_BOTTOM_OUT } from "src/app/constants/list";

@Component({
    selector: "sales",
    templateUrl: "./sales.component.html",
    styleUrls: ["./sales.component.scss"],
})

export class SalesComponent implements OnInit {
    // save the language
    public language = localStorage.getItem('language') || 'EN'
    
    // set all words
    public words = languageLibrary.language
    
    public sales: Object[] = []

    public statuses: Object[] = []

    // table headers
    public headers: Object[] = [
        { name: 'Vehicle', param: 'image', view: true, disabled: false },
        { name: 'Buyer', param: 'buyer', view: true, disabled: false },
        { name: 'Co-Buyer', param: 'cobuyerName', view: false, disabled: false },
        { name: 'Sale Date', param: 'saleDate', view: true, disabled: false },
        { name: 'Stock #', param: 'stockNumber', view: true, disabled: false },
        { name: 'Stage', param: 'saleStage', view: true, disabled: false },
        { name: 'Status', param: 'saleStatus', view: true, disabled: false },
        { name: 'VIN #', param: 'vinNumber', view: false, disabled: false },
        { name: 'Lienholder', param: 'primaryLienholder', view: true, disabled: false },
        { name: 'Sale Financing', param: 'saleFinancingType', view: true, disabled: false },
        { name: 'Exterior Color', param: 'exteriorColor', view: false, disabled: false },
        { name: 'Year', param: 'modelYear', view: false, disabled: false },
        { name: 'Make', param: 'make', view: false, disabled: false },
        { name: 'Model', param: 'model', view: false, disabled: false },
        { name: 'Mileage', param: 'miles', view: false, disabled: false },
        { name: 'Title Condition', param: 'titleCondition', view: false, disabled: false },
        { name: 'Sale Price', param: 'sellingPrice', view: true, disabled: false },
        { name: 'Inspection Expires', param: 'inspectionExpires', view: false, disabled: false },
        { name: 'Has Active Floor Plan', param: 'active', view: true, disabled: false },
        { name: 'Inventory Source', param: 'inventorySource', view: false, disabled: false },
        { name: 'Out of State', param: 'outOfState', view: false, disabled: false },
        { name: 'Phone Number', param: 'phoneNumber', view: true, disabled: false },
        { name: 'Email', param: 'email', view: false, disabled: false },
        { name: 'Address', param: 'address', view: false, disabled: false },
        { name: 'Salesperson', param: 'salesperson', view: true, disabled: false },
        { name: 'Account Owner', param: 'account', view: true, disabled: false },
        { name: 'Lot', param: 'lotId', view: false, disabled: false },

    ]
    
    // define if is loading
    public loading: boolean = false
    
    // permissions
    public permissions: Object[] = [];
        
    // Current page the user is viewing (last loaded).
    public page = 1

    // The number contacts currently being displayed.
    public displayed = 0

    // Indicates whether or not there are no more records to load.
    public exhausted = false

    // Total number of records available
    public records: number

    // The search form group
    private form: FormGroup = null

    // The current search text to filter the list by.
    private inquiry: string = null

    // A debounce timer for search events. Used to wait for 
    // the user to stop typing before we fetch.
    private watcher: ReturnType<typeof setTimeout> = null

    // Keep track of how the user wants to sort list.
    private sortables: Object = {'saleDate': 'DESC'}
    
    // define lot selected
    public lotSelected = 0
    
    // define the object preselected
    public preselectedObject: Object = {}
    
    //
    public groupParams = []
    
    //
    public preselected = {}
    
    //
    public enableBHPH: boolean = false

    // all cobuyers
    public cobuyers: any[] = []

    readonly CASH = 1;
    readonly WHOLESALE = 2;
    readonly OUTSIDE = 3;
    readonly BHPH = 4;
    readonly BUYER_ROLE = 1
    readonly COBUYER_ROLE = 2
    readonly COSIGNER_ROLE = 3
    readonly RETAIL_TRANS_TYPE = 'Retail'

    public userId: Number = null

    public isQuote: boolean = false

    // Determine the path to use based on whether we are viewing a sale or quote list.
    get path (): string { return this.isQuote ? 'quotes' : 'sales' }

    constructor(private route: ActivatedRoute, private ms: MessageService, private master: MasterService, private store: StoreService, private router: Router, private fb: FormBuilder) {}

    ngOnInit() {
        this.lotSelected = parseInt(localStorage.getItem('lot')) || parseInt(this.store.lotSelected) || 0

        const path = this.route.snapshot.url[0].path
        const pattern = new RegExp('quote', 'i')
        this.isQuote = (path && pattern.test(path))

        this.form = this.fb.group({
            inquiry: new FormControl('', []),
            type: new FormControl('', []),
            status: new FormControl('', [])
        })
    
        this.form.get('inquiry').valueChanges.subscribe(inquiry => {
            if (this.watcher) {
                clearTimeout(this.watcher)
                this.watcher = null
            }
    
            if (this.inquiry === inquiry) {
                return
            }
    
            this.watcher = setTimeout(() => {
                this.inquiry = inquiry
                this.reset()            
                this.fetch()
            }, SEARCH_DEBOUNCE_MILLIS)
        })

        this.form.get('type').valueChanges.subscribe(type => {
            this.reset()            
            this.fetch()
        })

        this.form.get('status').valueChanges.subscribe(status => {
            this.reset()            
            this.fetch()
        })

        this.populate()

        if (!this.store.userAccount['permissions']) {
            return
        }
    
        this.permissions = this.store.userAccount['permissions'][7].childrens[0].permissions
    }

    /**
     * Reset the pagination parameters and scroll to the top
    */
    private reset() {
        this.page = 1
        this.exhausted = false
        this.displayed = 0

        const list = document.querySelector('#listing')
        if (!list) {
            return
        }

        list.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto'
        })
    }

    private populate = () => {
        this.setEnableBHPH()
        this.fetchStatuses()
        this.fetch()
    }

    /**
     * 
     */
    private fetchStatuses() {
        this.master.get(`collections/sales/statuses`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.statuses = res.data['Statuses']
        })
    }

    /**
     * 
     */
    private setEnableBHPH() {
        if (!this.lotSelected || (this.lotSelected <= 0)) {
            return
        }

        this.master.get(`lots/${this.lotSelected}/salesSetup`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            if (!res.data.salesSetup) {
                this.enableBHPH = false
                return
            }

            this.enableBHPH = res.data.salesSetup.enableBHPH
        })
    }

    /**
     * Handle a lot change and query for a new list of sales.
     * @param lot Unique identification of the selected lot
     */
    public changeLot = (lot: number) => {
        if (this.lotSelected === lot) {
            return
        }

        this.lotSelected = lot || parseInt(localStorage.getItem('lot')) || parseInt(this.store.lotSelected) || 0
        this.reset()
        this.fetch()
        this.setEnableBHPH()
    }

    /**
     * Handle scroll events of the list container and load 
     * the next page when avilable and the user scrolled 
     * to the bottom.
     * @param e The scroll event that trigggered this handler.
     */
    public scrolled = (e): void => {
        if (!e || !e.target) {
            return
        }

        if (this.exhausted) {
            return
        }

        if (this.loading) {
            return
        }

        const location = Math.abs(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop)

        if (location >= SCROLL_BOTTOM_OUT) {
            return
        }

        this.page = this.page + 1
        this.fetch()
    }

    /**
     * 
     * @param field The field that user wants to sort by. 
     */
    public sort = (field: string): void => {
        if (!field || (field.length <= 0)) {
            return
        }

        if (this.sortables[field]) { // need to toggle the direction?
            switch (this.sortables[field]) {
                case 'ASC':
                    this.sortables[field] = 'DESC'
                    break
                default:
                    this.sortables[field] = 'ASC'
                    break
            }

            this.reset()
            this.fetch()
            return
        }

        this.sortables = {}
        this.sortables[field] = 'ASC'
        this.reset()
        this.fetch()
    }

    public sortedAsc = (field: string): Boolean => {
        if (!field || (field.length <= 0) || !this.sortables) {
            return false
        }

        if (!this.sortables[field]) {
            return false
        }

        return (this.sortables[field].toUpperCase() === 'ASC')
    }

    public sortedDesc = (field: string): Boolean => {
        if (!field || (field.length <= 0) || !this.sortables) {
            return false
        }

        if (!this.sortables[field]) {
            return false
        }

        return (this.sortables[field].toUpperCase() === 'DESC')
    }

    /**
     * Query for a list of sales.
     * @param lot Unique identification of the selected lot
     */
    public fetch = async () => {
        if (this.loading) { // already getting next page
            return
        }
  
        if (this.exhausted) { // currently showing all available records?
            return
        }
  
        this.loading = true
  
        let lotParam = ''
        if (this.lotSelected && (this.lotSelected > 0)) {
            lotParam = `&lotId=${this.lotSelected}`
        }
  
        let inquiryParam = ''
        if (this.inquiry && (this.inquiry.length > 0)) {
            inquiryParam = `&q=${encodeURIComponent(this.inquiry)}`
        }

        let statusParam = ''
        const status = parseInt(this.form.get('status').value)
        if (status && (!isNaN(status)) && (status > 0)) {
            statusParam = `&status=${status}`
        }

        let typeParam = ''
        const types = this.form.get('type').value
        if (types && (types.length > 0)) {
            typeParam = `&types=${encodeURIComponent(types)}`
        }

        let stageParam = `&stage=2` // 2 = sale
        if (this.isQuote) {
            stageParam = `&stage=1` // 1 = quote
        }
  
        let sortParam = ''
        if (this.sortables && (Object.keys(this.sortables).length > 0)) {
            const sorts = []
            for (const key of Object.keys(this.sortables)) {
                let direction = ''
                if (this.sortables[key] && this.sortables[key].length > 0) {
                    direction = `|${this.sortables[key]}`
                }
  
                sorts.push(`${key}${direction}`)
            }
  
            sortParam = `&sort=${sorts.join(',')}`
        }

        this.loading = true
        await this.master.getAsync(`sales?page=${this.page}${inquiryParam}${sortParam}${lotParam}${stageParam}${statusParam}${typeParam}`).then(res => {
            this.loading = false

            if (!res || !res.data) {
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error ? res.data.error : this.words[this.language]['apiNoResponse'] });
                return
            }

            this.records = res.data.records
            this.displayed = Math.min(this.records, (res.data.page * res.data.ipp))
            this.exhausted = (res.data.next === null)

            if (this.page > 1) {
                this.sales = this.sales.concat(res.data.sales)
                return
            }

            this.sales = res.data.sales
        })
    }

    //
    public preselect = (object) => {
        this.preselected = { ...object }
    }

    /**
     * @param index 
     */
    public toggleCell = (index?: number): void => {
        if ((index !== undefined) && (index >= 0) && (index <= this.headers.length - 1)) {
            this.headers[index]['view'] = !this.headers[index]['view']
        }
    }

     // save View
  public saveView = (): void => {
    let config = JSON.stringify(this.headers)
    this.master.put(`user/${this.userId}`, { users: { salesTable: config, id: this.userId } }, res => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }
      document.getElementById('btn-customizeColumns-close').click()
    })
  }

    // move the position of columns in the table
    public drop(event: CdkDragDrop<string[]>) {

        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
        this.toggleCell()
        // this.changePosition(event.currentIndex, event.previousIndex)
    }

    // ? pagitation
    // return true if two words are equal, else return false
    public compareLower = (word1: string, word2: string): boolean => {
        return word1 != null && word2 != null ? word1.toLowerCase().indexOf(word2.toLowerCase()) >= 0 : false
    }

    // ? create data
    //  create a new vehicle
    public createSale = (type: number): void => {
        this.loading = true
        this.router.navigate(
            [`/${this.path}/add`],
            {
                queryParams: {
                    type: type,
                }
            }
        );
    }

    // View the modify sale detail page
    public modifySale = (id: string): void => {
        this.router.navigate([`/${this.path}/${id}`]);
    }

    //  select a vehicle
    public selectSale = (sale: Object): void => {
        this.preselectedObject = { ...sale }
    }

    // delete the contact selected
    public delete = (): void => {
        let tradeIn = this.preselectedObject['data'] ? this.preselectedObject['data'].saleVehicles.filter(el => el['saleVehicleTransactionTypeId'] == 2) : 0

        if (tradeIn.length > 0) {
            this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['The selected sale cannot be deleted because it has an associated trade-in'] });
            // close delete modal
            (document.getElementById('btn-close-modal-delete') as HTMLButtonElement).click();
        } else {

            if (this.preselectedObject['data'] && this.preselectedObject['data'].saleVehicles.length > 0) {
                for (let index = 0; index < this.sales.length; index++) {

                    if (this.sales[index]['data'].saleVehicles.some(el => el['inventory'] && el.inventory['originId'] && el.inventory['originId'] == this.preselectedObject['data']['sale'].saleVehicles[0].id)) {
                        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['The selected sale cannot be deleted because it has an associated trade-in'] });
                        // close delete modal
                        (document.getElementById('btn-close-modal-delete') as HTMLButtonElement).click();
                        return
                    }
                }
            }

            this.loading = true;
            this.master.discard(`deleteSale?saleId=${this.preselectedObject['id']}`, res => {
                this.loading = false

                if (res) {
                    if (res.status == 200) {
                        // * success
                        // reload data
                        this.changeLot(this.lotSelected)
                        this.ms.sendMessage("alert", { type: "success", text: res.data.message });
                        // close delete modal
                        (document.getElementById('btn-close-modal-delete') as HTMLButtonElement).click();
                        this.reset()
                        this.fetch()
                    } else {
                        // ! error
                        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                    }
                } else {
                    // in case API no response
                    this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                }
            })
        }
    }

    // todo: listen to output of navbar
    public listenerNav = (e): void => {
        switch (e.message) {
            case 'changeLot':
                this.changeLot(e.lot)
                break;
            case 'changeLanguage':
                this.language = e.value
                break;
            case 'setPermissions':
                this.permissions = e.permissions[7].childrens[0].permissions
                break;

            default:
                break;
        }
    }

}
