import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormArray, FormGroup, Validators, FormBuilder } from '@angular/forms';

import { MasterService } from '../../../../services/master.service';
import { MessageService } from '../../../../services/message.service';
import * as languageLibrary from '../../../../services/language'
@Component({
  selector: 'add-users',
  templateUrl: './add-users.component.html',
  styleUrls: ['./add-users.component.scss']
})
export class AddUsersComponent implements OnInit {
  /*
* variables
*/
  // save the language
  @Input() language: string = 'EN'
  // set all words
  public words = languageLibrary.language
  // save the invities success and failed
  public invitations = { success: [], failed: [] }
  // form
  public form = null
  // define if is loading
  public loading: boolean = false
  // save the inputs status
  public disabled: boolean = false
  // define if the modal is open
  public isOpen: boolean = false

  // A handle to navbar event messages so we can unsubscribe later.
  private navbarWatcher: any = null
  
  //
  @Output() tableEmitter$: EventEmitter<Object> = new EventEmitter()

  /*
  * functions
  */

  /**
   * Reset the input form to its initial state.
   */
  public reset = (): void => {
    this.form.reset()
    this.invites.clear()
    this.disabled = false
    this.loading = false
    this.invitations = { success: [], failed: [] }
  }

  /**
   * Add a new user invite row to the form array
   * @param e Event that triggerd this method, if any
   */
  public add = (e): void => {
    const invite = this.fb.group({
      firstName: [null, [Validators.required, Validators.maxLength(50), Validators.minLength(2)]],
      lastName: [null, [Validators.required, Validators.maxLength(50), Validators.minLength(2)]],
      email: [null, [Validators.required, Validators.email]]
    })

    this.invites.push(invite)
  }

  /**
   * Remove a user invite from the form array
   * @param index The index of the invite to remove
   */
  public discard = (index: number): void => {
    if ((index < 0) || (index > this.invites.length - 1)) {
      return
    }

    this.invites.removeAt(index)
  }

  /**
   * Attempt to send the valid user invitations.
   */
  public async invite(){
    if (!this.form.valid) {
      return
    }

    this.loading = true
    const payload = []

    for (let group of this.invites.controls) {
      const controls = (group as FormGroup).controls
      payload.push({
        firstName: controls.firstName.value,
        lastName: controls.lastName.value,
        email: controls.email.value
      })
    }

    if (payload.length == 0) {
      this.loading = false
      return
    }

    // send invitations
    this.master.post('invite', { users: payload }, res => {
      this.loading = false
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      // set empty invitations
      this.invitations = { success: [], failed: [] }

      // todo: set invitations success
      res.data.users.success.forEach(el => {
        this.invitations.success.push(el.email)
      })

      // todo: set invitations failure
      res.data.users.failed.forEach(el => {
        this.invitations.failed.push(el.email)
      })

      this.disabled = true
      this.tableEmitter$.emit({ message: 'updateUserList' })
    })
    
  }



  /**
   * Instantiate a new user add modal
   * @param master Backend API service class helper
   * @param ms Alerts and warnings display helper
   * @param fb Angular form builder helper
   */
  constructor(private master: MasterService, private ms: MessageService, private fb: FormBuilder) {
    this.form = this.fb.group({
      invites: this.fb.array([])
    })
  }

  /**
   * Helper method to consistently get the list of form invites as an Array.
   */
  get invites() {
    return this.form.controls['invites'] as FormArray;
  }

  /**
   * Handle language change events.
   */
  ngOnInit() {
    this.navbarWatcher = this.ms.channelComponents$.subscribe(res => {
      if (res.message !== "changeLanguage") {
        return
      }

      this.language = res.value
    })
  }

  ngOnDestroy() {
    if (!this.navbarWatcher) {
      return
    }

    this.navbarWatcher.unsubscribe()
  }

}
