import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FormService } from '../../../../../services/form-service.service';
import { MasterService } from "../../../../../services/master.service";
import { MessageService } from "../../../../../services/message.service";
import { StoreService } from "../../../../../services/store.service";
import * as languageLibrary from '../../../../../services/language'

@Component({
  selector: 'dynamic-setup-form',
  templateUrl: './dynamic-setup-form.component.html',
  styleUrls: ['./dynamic-setup-form.component.scss']
})
export class DynamicSetupFormComponent implements OnInit {
  dynamicForm: FormGroup;
  private lotId: number = null
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language

  @Input() setupValues: string = null
  @Input() selectedForm: string = null

  constructor(private formBuilder: FormBuilder, private formService: FormService, private ms: MessageService, private master: MasterService, private store: StoreService) { }

  ngOnInit() {
    this.lotId = parseInt(localStorage.getItem('lot') || this.store.lotSelected)
  
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return
    }

    if(!changes.setupValues.currentValue && !changes.selectedForm.currentValue){
      return
    }

    this.setupValues = changes.setupValues.currentValue
    this.selectedForm = changes.selectedForm.currentValue
    this.setupValues = JSON.parse(this.setupValues)
    const formStructure = this.formService.getFormStructure(this.setupValues);

    let formGroup = {};
    formStructure.forEach(control => {
      formGroup[control.name] = [control.value || ''];
    });

    this.dynamicForm = this.formBuilder.group(formGroup);
  }

  public updateValue = (e) => {
    let element = e.target
    this.setupValues[element.name]['value'] = element.value
  }

  /**
   * saveDefaults
   */
  public saveDefaults = async() => {
    const res = await this.master.postAsync(`forms/${this.selectedForm['id']}?lotId=${this.lotId}`, {setupValues: JSON.stringify(this.setupValues)})
    
    if (!res || (res['status'] !== 200)) {
      this.ms.sendMessage("alert", { type: "danger", text: res ? res['data'].error : this.words[this.language]['apiNoResponse'] })
      return
    }

    document.getElementById('btn-close-dynamicSetupForm').click()
  }

}
