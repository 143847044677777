import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as words from '../../../../services/language'
import { MessageService } from '../../../../services/message.service';
import { MasterService } from '../../../../services/master.service';
import { StoreService } from '../../../../services/store.service';
import { Subject } from "rxjs";
import { ago } from 'src/app/utils/date';

@Component({
    selector: 'pay-promises',
    templateUrl: './pay-promises.component.html',
    styleUrls: ['./pay-promises.component.scss']
})
export class PayPromisesComponent implements OnInit {

    // **************************
    // ****** variables *********
    // **************************

    // Unique internal identity of the lot whose payment promises will be queried for.
    @Input() lotId: number = 0

    // Unique internal identity of the contact whose payment promises will be queried for, overrides the lotId.
    @Input() contactId: number = 0

    // Unique internal identity of the sale finance type to filter the queried payment promises with.
    @Input() financeTypeId: number = 0

    // Available payments for the given contact.
    @Input() pays: Object = null

    // A payment was taken so lets update our available payments and any suppporting displays.
    @Input() paymentTaken: Subject<Object>

    // Currently selected language
    @Input() language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'

    // Word replacements for each language.
    @Input() words: Object = words.language

    //
    public promises: Array<any> = []

    // The epxected payment identity whose payment promises will be edited.
    public expectedId: number = 0

    //
    public records: number = 0

    //
    public page: number = 1

    // Indicates whether the data is currently loading.
    public loading: boolean = false

    // Indicates whether any more data is available
    public exhausted: boolean = false

    // A handle to a payment taken subscription so we can unsubscribe later.
    private paymentTakenWatcher: any = null

    // Check to see if there is enough information to create a new promise
    get creatable(): boolean {
        if (!this.pays) {
            return false
        }

        let count = 0
        let total = 0

        const today = this.pays['today']
        if (today) {
            count += today.items.length
            total += today.total
        }

        const next = this.pays['next']
        if (next) {
            count += next.items.length
            total += next.total
        }

        return ((count > 0) && (total > 0))
    }

    // Get a list of payments that can have a promise created for them.
    get promisable(): Array<any> {
        if (!this.pays) {
            return []
        }

        const today = this.pays['today']
        if (today && today.items && (today.items.length > 0)) {
            return today.items
        }

        const next = this.pays['next']
        if (next && next.items && (next.items.length > 0)) {
            return next.items
        }

        return []
    }

    constructor (private route: ActivatedRoute, private router: Router, private ms: MessageService, private master: MasterService, private store: StoreService) {}

    ngOnInit() {
        this.fetch()

        if (this.paymentTaken) {
            this.paymentTakenWatcher = this.paymentTaken.subscribe((payment) => {
                this.reset()
                this.fetch()
            })
        }
    }

    ngOnDestroy() {
        if (this.paymentTakenWatcher) {
            this.paymentTakenWatcher.unsubscribe()
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes) {
            return
        }

        const first = Object.values(changes).some(c => c.isFirstChange())
        if (first) {
            return
        }

        if (changes.lotId || changes.contactId || changes.financeTypeId) {
            this.reset()
            this.fetch()
        }
    }

    private reset = (): void => {
        this.page = 1
        this.exhausted = false
        this.records = 0
    }

    private fetch = (): void => {
        if (this.loading) {
            return
        }

        if (this.exhausted) { // currently showing all available records?
            return
        }

        let lotParam = ''
        if (this.lotId && (this.lotId > 0)) {
            lotParam = `&lot=${this.lotId}`
        }

        let contactParam = ''
        if (this.contactId && (this.contactId > 0)) {
            contactParam = `&contact=${this.contactId}`
            lotParam = ''
        }

        let financeTypeParam = ''
        if (this.financeTypeId && (this.financeTypeId > 0)) {
            financeTypeParam = `&financeType=${this.financeTypeId}`
        }

        this.loading = true
        if (this.page === 1) {
            this.promises = []
        }

        this.master.get(`payments/promises?page=${this.page}&sort=due|ASC${lotParam}${contactParam}${financeTypeParam}`, (res) => {
            this.loading = false
  
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }
  
            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }
  
            this.records = res.data.records
            this.exhausted = (res.data.next === null)
  
            if (this.page > 1) {
                this.promises = this.promises.concat(res.data.payments)
                return
            }
  
            this.promises = res.data.promises
        })
    }

    /**
     * A promise in the list was clicked. 
     * @param e Event meta data that triggered the selection.
     * @param promise The payment promise object that was chosen by the user.
     */
    public selected = (e: Event, promise: Object): void => {
        e.preventDefault()
        e.stopPropagation()
        
        if (!promise || !promise['contactId']) {
            return
        }

        // this checks to see if we are on a customer collect screen or the main collections screen.
        if (!this.lotId && (this.lotId <= 0) && this.contactId && (this.contactId > 0)) {
            this.edit(e, promise)
            return
        }

        this.router.navigateByUrl(`/collections/${promise['contactId']}?pay=${promise['paymentExpectedId']}`)
    }

    /**
     * Open a modal to accept information related to a new promise to pay.
     * @param e Event meta data that triggered the creation.
     * @param payment The payment that will have a promise created.
     */
    public create = (e: Event, payment: Object): void => {
        if (!payment || !payment['id']) {
            return
        }

        const id = parseInt(payment['id'])
        if (!id || isNaN(id) || (id <= 0)) {
            return
        }

        this.expectedId = id

        const btn = document.getElementById('togglePayPromiseEdit') as HTMLButtonElement
        if (!btn) {
            return
        }

        btn.click()
    }

    /**
     * Open a modal to accept information related an existing promise to pay.
     * @param e Event meta data that triggered the edit.
     * @param promise The promise that will be edited.
     */
    public edit = (e: Event, promise: Object): void => {
        if (!promise || !promise['paymentExpectedId']) {
            return
        }

        const id = parseInt(promise['paymentExpectedId'])
        if (!id || isNaN(id) || (id <= 0)) {
            return
        }

        this.expectedId = id

        const btn = document.getElementById('togglePayPromiseEdit') as HTMLButtonElement
        if (!btn) {
            return
        }

        btn.click()
    }

     /**
     * Determine an tab icon to show based on the payment category.
     * @param category The payment category.
     */
     public icon = (category: string) => {
        if (!category || (category.length <= 0)) {
            return 'monetization_on'
        }

        switch(category.toLowerCase()) {
            case 'mv loan':
                return 'directions_car'
            case 'service':
                return 'car_repair'
            default:
                return 'monetization_on'
        }
    }

    public dueToClass = (due: string): string => {
        return ago(due)
    }

    /**
     * Listen for payment processing events from the take payment component then update 
     * the display and pass the changes to other components.
     * @param changed Indicates one or more payment promises were added or modified.
     */
    public promisesEdited = (changed: boolean) => {
        const btn = document.getElementById('togglePayPromiseEdit') as HTMLButtonElement
        if (btn) {
            btn.click()
        }

        this.expectedId = 0

        if (!changed) {
            return
        }

        this.reset()
        this.fetch()
    }
}
