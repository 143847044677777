import { Component, Injector, OnInit } from '@angular/core';
import { DynamicService } from 'src/app/services/dynamic.service';
import { token } from '../roles.component';

@Component({
  selector: '[role-vendor]',
  templateUrl: './role-vendor.component.html',
  styleUrls: ['./role-vendor.component.scss']
})
export class RoleVendorComponent implements OnInit {
  public contactId: number
  public contactRoleId: number = 0
  public dataIn: string;
  public calledFrom: string;

  constructor(private inject: Injector, private serv: DynamicService) { }

  ngOnInit() {
    this.dataIn = this.inject.get(token);
    this.contactId = this.dataIn['contactId']
    this.contactRoleId = this.dataIn['contactRoleId']
    this.calledFrom = this.dataIn['calledFrom']

    if (!this.contactId) {
      return
    }

    if (this.calledFrom == "contactAdvancedSearch") {
      this.serv.outputFromDynamicComponent({ action: "setupNeeded", setupNeeded: false, type:"vendor" });
      return
    }
  }

  deactivateRole() {
    this.serv.outputFromDynamicComponent({ action: "deactivate", contactRoleId: this.contactRoleId });
  }

}
