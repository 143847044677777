import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicService } from 'src/app/services/dynamic.service';
import { MasterService } from 'src/app/services/master.service';
import { MessageService } from 'src/app/services/message.service';
import { token } from '../roles.component';
import * as languageLibrary from '../../../../../../services/language'

@Component({
  selector: '[role-dca]',
  templateUrl: './role-dca.component.html',
  styleUrls: ['./role-dca.component.scss']
})
export class RoleDCAComponent implements OnInit {
  public contactId: number
  public contactRoleId: number = 0
  public words = languageLibrary.language
  public language: string = 'EN'
  public dcaInformation = new FormGroup({
    id: new FormControl(null),
    contactId: new FormControl(null, [Validators.required]),
    costPercentOfFee: new FormControl(null),
    feeCapPercentage: new FormControl(null),
    termRange: new FormControl(null)
  })
  public dataIn: string;
  public id: number = 0;
  public termRange = [];
  public termRangeTemplate: string = `[{"termMonthsFrom":0,"termMonthsTo":12,"dollarCap": 100},{"termMonthsFrom":12,"termMonthsTo":24,"dollarCap": 200}]`
  public calledFrom: string;

  constructor(private master: MasterService, private ms: MessageService, private inject: Injector, private serv: DynamicService) {
  }

  deactivateRole() {
    this.serv.outputFromDynamicComponent({ action: "deactivate", contactRoleId: this.contactRoleId });
  }
  ngOnInit(): void {

    this.dataIn = this.inject.get(token);
    this.contactId = this.dataIn['contactId']
    this.contactRoleId = this.dataIn['contactRoleId']
    this.calledFrom = this.dataIn['calledFrom']

    if (!this.contactId) {
      return
    }

    this.dcaInformation.get("contactId").setValue(this.contactId)

    this.getDCA()
  }
  public getDCA() {
    this.termRange = JSON.parse(this.termRangeTemplate)
    this.master.get(`contacts/${this.contactId}/dca`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      if (res.data.contactDCA.length == 0) {
        if (this.calledFrom == "contactAdvancedSearch")
          this.serv.outputFromDynamicComponent({ action: "setupNeeded", setupNeeded: true, type:"debtCancelation" });
        return
      }

      this.setInformation(res.data.contactDCA[0])

      if (this.calledFrom == "contactAdvancedSearch") {
        this.serv.outputFromDynamicComponent({ action: "setupNeeded", setupNeeded: false, type:"debtCancelation" });
        return
      }
    })
  }

  public saveDCA() {
    this.dcaInformation.get("termRange").setValue(JSON.stringify(this.termRange))
    if (!this.dcaInformation.valid) {
      return
    }

    if (this.id <= 0) {
      this.createDCA()
      return
    }

    if (this.id >= 1) this.modifyDCA()
  }

  createDCA() {
    this.master.post(`contacts/${this.contactId}/dca`, { information: this.dcaInformation.value, }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
      this.setInformation(res.data.contactDCA)

      if (this.calledFrom == "contactAdvancedSearch")
        this.serv.outputFromDynamicComponent({ action: "setupSaved", setupSaved: true, type:"debtCancelation" });
    })
  }

  modifyDCA() {
    this.master.put(`contacts/${this.contactId}/dca/${this.id}`, { information: this.dcaInformation.value, }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
      if (this.calledFrom == "contactAdvancedSearch")
        this.serv.outputFromDynamicComponent({ action: "setupSaved", setupSaved: true, type:"debtCancelation" });
    })
  }

  deleteDCA() {
    this.master.discard(`contacts/${this.contactId}/dca/${this.id}`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.resetInformation()
    })
  }

  resetInformation() {
    this.dcaInformation.reset({
      contactId: this.contactId
    })
    this.id = null
  }

  setInformation(info) {
    this.id = info["id"]
    this.termRange = info['termRange'] ? JSON.parse(info['termRange']) : JSON.parse(this.termRangeTemplate)
    this.dcaInformation.setValue({
      id: info['id'],
      contactId: info['contactId'],
      costPercentOfFee: info['costPercentOfFee'],
      feeCapPercentage: info['feeCapPercentage'],
      termRange: this.termRange
    })
  }

}
