import { Component, OnInit } from '@angular/core';
import { formatDate, Location } from '@angular/common';
import * as languageLibrary from '../../../../services/language'
import { MasterService } from 'src/app/services/master.service';
import { MessageService } from 'src/app/services/message.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SEARCH_DEBOUNCE_MILLIS, MIN_SEARCH_CHARS } from 'src/app/constants/list';
import { StoreService } from 'src/app/services/store.service';
import { formatPhoneNumber } from 'src/app/utils/utilities';
import { Observable } from 'rxjs';
import { INVENTORY } from 'src/app/constants/inventory';
import { PAYMENT_CYCLES } from 'src/app/constants/payments';

export interface Contact {
  name: string;
  id: number;
}
@Component({
  selector: 'app-service-detail',
  templateUrl: './service-detail.component.html',
  styleUrls: ['./service-detail.component.scss']
})
export class ServiceDetailComponent implements OnInit {
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
    // list of colors
    public colors: {} = INVENTORY.COLORS
  public paymentCycles = PAYMENT_CYCLES
  public words = languageLibrary.language
  public ticketId: number = 0;
  public ticket: Object = []
  public lines: Object = []
  public lotId: number;
  public lineTotal: number = 0
  public contacts: Contact[] = [];
  private form: FormGroup = null
  public filteredContactOptions: Observable<Contact[]>;
  public totals: [];
  public lineInfoEdit: boolean;
  public lineInfoCreate: boolean;
  public lineId: any;
  public contact: any;
  public vinNumber: string = null;
  // zip code list
  public zipCodeList: Object[] = []
  // latest search inquiry
  public zipInquiry = null
  // set time out
  private timeOutID: any;
  public headerInformation = new FormGroup({
    id: new FormControl(0),
    serviceDate: new FormControl(null),
    status: new FormControl("Open"),
    description: new FormControl(null),
    vehicleExteriorColor: new FormControl(null),
    licensePlateNumber: new FormControl(null),
    mileage: new FormControl(null),
    workPhone: new FormControl(null),
    homePhone: new FormControl(null),
    cellPhone: new FormControl(null),
    emailAddress: new FormControl(null),
    address: new FormControl(null),
    address2: new FormControl(null),
    city: new FormControl(null),
    state: new FormControl(null),
    zipCode: new FormControl(null),
    county: new FormControl(null),
    country: new FormControl(null),
    preferredContactMethod: new FormControl("Call"),
    promisedDate: new FormControl(null),
    saleId: new FormControl(null),
    vehicleId: new FormControl(null, [Validators.required]),
    contactId: new FormControl(null),
    lotId: new FormControl(null, [Validators.required]),
    contactFirstName: new FormControl(null),
    contactLastName: new FormControl(null),
    contactCompanyName: new FormControl(null),
  })
  // public contactName: string = null;
  public lineInformation = new FormGroup({
    id: new FormControl(0),
    serviceType: new FormControl(null),
    partNumber: new FormControl(null),
    description: new FormControl(null),
    quantity: new FormControl(0),
    price: new FormControl(0),
    rate: new FormControl(0),
    hours: new FormControl(0),
    lineTotal: new FormControl({ value: 0, disabled: true }),
    serviceTicketHeaderId: new FormControl(null),
  })
  public terms = new FormGroup({
    id: new FormControl(0),
    numberOfPmts: new FormControl(0),
    paymentAmount: new FormControl(0),
    paymentCycle: new FormControl(null),
    firstScheduledPmtDateDue: new FormControl(0),
  })
  public saleInfo: any;
  public vehicleInfo: any;
  public vehicleListOptions: []
  public saleContactsListOptions: []
  public found: any;
  public show = false
  public message: string;
  public ignoreDefaultContact: boolean = false;

  constructor(private router: Router, private master: MasterService, private ms: MessageService, private route: ActivatedRoute, private store: StoreService, private location: Location, private fb: FormBuilder) {
    this.form = this.fb.group({
      inquiry: new FormControl(null, [Validators.required]),
      vinNumber: new FormControl(null, [Validators.required]),
      contactFullName: new FormControl(null),
    })
  }

  ngOnInit() {
    this.lotId = parseInt(localStorage.getItem('lot') || this.store.lotSelected)
    if (!isNaN(this.lotId) || (this.lotId > 0)) {
      this.headerInformation.get("lotId").setValue(this.lotId)
    }
    this.ticketId = parseInt(this.route.snapshot.params['id'])
    if (isNaN(this.ticketId) || (this.ticketId <= 0)) {
      this.ticketId = 0
      // this.fetchContacts()
      this.show = true
      return
    }

    this.getTicket()
  }

  /*
  * functions
  */
  // todo: listen to output of navbar
  public listenerNav = (e): void => {
    switch (e['message']) {
      case 'changeLanguage':
        this.language = e['value']
        break
    }
  }

  // get the ticket
  public getTicket() {
    this.master.get(`service/${this.ticketId}`, res => {

      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }
      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      if (!res.data.ticket) {
        return
      }

      this.ticket = res.data.ticket
      this.lines = res.data.ticket.serviceTicketLines
      this.totals = res.data.totalSummary
      this.setHeaderInformation()
    })

  }
  // get the ticket
  public getTicketLines() {
    this.master.get(`service/${this.ticketId}/lines`, res => {

      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }
      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      if (!res.data.lines) {
        this.lines = []
        this.totals = []
        return
      }

      this.lines = res.data.lines
      this.totals = res.data.totalSummary
    })

  }

  // if any changes made to the form, save
  public changed() {
    if (this.headerInformation.pristine) {
      return
    }

    if (this.headerInformation.invalid || this.headerInformation.disabled) {
      return
    }

    this.headerInformation.markAsPristine()

    if (this.ticketId <= 0) {
      this.createTicket()
    }

    this.modifyTicket()
  }

  // create ticket
  public createTicket() {
    this.master.post(`service`, { information: this.headerInformation.value }, res => {

      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.ticketId = res.data.ticket.id
      this.location.replaceState(`/service/${this.ticketId}`);
      this.getTicket()

    })
  }

  // modify ticket
  public modifyTicket() {
    if (this.ticketId == 0){
      return
    }
    
    this.master.put(`service/${this.ticketId}`, { information: this.headerInformation.value }, res => {

      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }
      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

    })

  }

  // save the ticket line
  public async save(i) {
    if (this.lineInformation.pristine) {
      return
    }
    if (this.ticketId == 0) {
      return
    }

    let lineId = this.lineInformation.get("id").value
    this.lineInformation.get("serviceTicketHeaderId").setValue(this.ticketId)

    if (lineId <= 0) {
      this.createTicketLine()
      return
    }

    this.modifyTicketLine(lineId, i)
  }

  // modify ticket line
  public modifyTicketLine(lineId: number, i: number) {
    this.master.put(`service/${this.ticketId}/lines/${lineId}`, { information: this.lineInformation.value }, res => {

      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }
      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.resetLineInformation(i)
      this.getTicketLines()

    })
  }

  // create ticket line
  public createTicketLine() {
    this.master.post(`service/${this.ticketId}/line?lotId=${this.lotId}`, { information: this.lineInformation.value }, res => {

      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }
      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      if (res.data.ticketId) {
        this.ticketId = res.data.ticketId
        this.location.replaceState(`/service/${this.ticketId}`);
      }
      this.getTicketLines()
      this.resetNewLineInformation()

    })
  }

  // use to store the desired line number to delete
  setLineNumber(lineId) {
    this.lineId = lineId
  }

  // delete ticket line
  public deleteTicketLine() {
    this.master.discard(`service/${this.ticketId}/line/${this.lineId}`, res => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }
      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.ticket = []
      this.totals = []
      document.getElementById('close-delete-line-modal').click()
      this.getTicketLines()

    })
  }

  // set line information when editing
  public setLineInformation(line, i) {
    this.lineInfoEdit = true
    document.getElementById(`lineInfoEdit-${i}`).classList.remove("d-none")
    var elements = document.getElementsByClassName(`lineInfoText-${i}`)
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index];
      element.classList.add('d-none')
    }
    this.lineInformation.patchValue({
      id: line['id'],
      serviceType: line['serviceType'],
      partNumber: line['partNumber'],
      description: line['description'],
      quantity: line['quantity'],
      price: line['price'],
      rate: line['rate'],
      hours: line['hours'],
      lineTotal: line['lineTotal'],
      serviceTicketHeaderId: this.ticket['id']
    })
  }

  // add a new line
  public add(serviceType) {
    this.lineInfoCreate = true
    var elements = document.getElementsByClassName(`lineInfoText`)
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index];
      element.classList.remove('d-none')
    }
    this.lineInformation.reset({
      serviceTicketHeaderId: this.ticket['id'],
      serviceType: serviceType
    })
  }

  // resetting the new line
  public resetNewLineInformation() {
    this.lineInfoCreate = false
    this.lineInformation.reset({
      serviceTicketHeaderId: this.ticket['id']
    })
  }

  // resetting the existing line form, if they click cancel
  public resetLineInformation(i) {
    this.lineInfoEdit = false
    document.getElementById(`lineInfoEdit-${i}`).classList.add("d-none")
    var elements = document.getElementsByClassName(`lineInfoText-${i}`)
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index];
      element.classList.remove('d-none')
    }
    this.lineInformation.reset({
      serviceTicketHeaderId: this.ticket['id']
    })
  }

  public resetHeaderInformation(tabName) {
    if (tabName == 'contact') this.resetHeaderContactInformation()
    if (tabName == 'vehicle') this.resetHeaderVehicleInformation()
    this.prepTicketForSave()
  }

  public resetHeaderContactInformation() {
    this.form.reset()
    this.headerInformation.patchValue({
      contactId: null,
      workPhone: null,
      homePhone: null,
      cellPhone: null,
      emailAddress: null,
      address: null,
      address2: null,
      city: null,
      state: null,
      zipCode: null,
      county: null,
      country: null,
      contactFirstName: null,
      contactLastName: null,
      contactCompanyName: null,
      preferredContactMethod: null
    })
    this.ignoreDefaultContact = true
    if (!this.message) this.headerInformation.enable()
    if (this.headerInformation.get("vehicleId").value !== null) this.submitVIN()
    this.show = true
  }

  public resetHeaderVehicleInformation() {
    this.vehicleInfo = null
    this.saleInfo = null
    this.vinNumber = null
    this.form.get("vinNumber").patchValue(null)
    this.headerInformation.patchValue({
      vehicleExteriorColor: null,
      licensePlateNumber: null,
      mileage: null,
      vehicleId: null,
      saleId: null
    })
    if (this.headerInformation.get("contactId").value !== null) this.submitContact(this.headerInformation.get("contactId").value)
    if (!this.message) this.headerInformation.enable()
  }

  public setHeaderInformation() {
    this.vehicleInfo = null
    this.saleInfo = null
    this.headerInformation.setValue({
      id: this.ticket['id'],
      serviceDate: this.ticket['serviceDate'] ? [formatDate(this.ticket['serviceDate'], 'yyyy-MM-ddTHH:mm', 'en')] : null,
      status: this.ticket['status'],
      description: this.ticket['description'],
      vehicleExteriorColor: this.ticket['vehicleExteriorColor'],
      licensePlateNumber: this.ticket['licensePlateNumber'],
      mileage: this.ticket['mileage'],
      workPhone: formatPhoneNumber(this.ticket['workPhone']),
      homePhone: formatPhoneNumber(this.ticket['homePhone']),
      cellPhone: formatPhoneNumber(this.ticket['cellPhone']),
      emailAddress: this.ticket['emailAddress'],
      address: this.ticket['address'],
      address2: this.ticket['address2'],
      city: this.ticket['city'],
      state: this.ticket['state'],
      zipCode: this.ticket['zipCode'],
      county: this.ticket['county'],
      country: this.ticket['country'],
      preferredContactMethod: this.ticket['preferredContactMethod'],
      promisedDate: this.ticket['promisedDate'],
      saleId: this.ticket['saleId'],
      vehicleId: this.ticket['vehicleId'],
      contactId: this.ticket['contactId'],
      lotId: this.ticket['lotId'],
      contactFirstName: this.ticket['contactFirstName'],
      contactLastName: this.ticket['contactLastName'],
      contactCompanyName: this.ticket['contactCompanyName'],
    })
    if (this.headerInformation.get("vehicleId").value) this.submitVIN()
    if (this.headerInformation.get('saleId').value) this.getSaleBuyer(this.headerInformation.get('saleId').value)
    this.vinNumber = this.ticket['vehicle'] ? this.ticket['vehicle']['vinNumber'] : null
    this.form.get("vinNumber").patchValue(this.vinNumber)
    if (this.headerInformation.get("contactId").value == null) {
      this.show = true
    }
    if (this.vinNumber) {
      this.vehicleInfo = {
        make: this.ticket['vehicle']['make'],
        model: this.ticket['vehicle']['model'],
        modelYear: this.ticket['vehicle']['modelYear'],
        engine: this.ticket['vehicle']['engine'],
        bodyStyle: this.ticket['vehicle']['bodyStyle'],
        stockNumber: this.ticket['vehicle'].inventory ? this.ticket['vehicle'].inventory.stockNumber : null,
        inventoryId: this.ticket['vehicle'].inventory ? this.ticket['vehicle'].inventory.id : null
      }
    }
  }

  public getSaleBuyer(saleId) {
    if (!saleId) {
      return
    }
    if (this.headerInformation.get("contactId").value !== null) return
    this.master.get(`sales/${saleId}/buyers?roles=Buyer`, res => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      if (this.headerInformation.get('contactFirstName').value == null && this.headerInformation.get('contactLastName').value == null && this.headerInformation.get('contactCompanyName').value == null) {
        this.contact = res.data[0].contact
        this.headerInformation.patchValue({
          contactId: this.contact.id,
          contactFirstName: this.contact.firstName,
          contactLastName: this.contact.lastName,
          contactCompanyName: !this.contact['isIndividual'] ? this.contact.companyName : null,
          address: this.contact.contactAddresses.length > 0 ? this.contact.contactAddresses[0].address + " " + this.contact.contactAddresses[0].address2 + " " + this.contact.contactAddresses[0].city + " " + this.contact.contactAddresses[0].state + " " + this.contact.contactAddresses[0].zipCode : null,
          emailAddress: this.filterForCommunication(this.contact.contactCommunications, 'email', "email"),
          cellPhone: this.filterForCommunication(this.contact.contactCommunications, 'cell', "phone"),
          workPhone: this.filterForCommunication(this.contact.contactCommunications, 'work', "phone"),
          homePhone: this.filterForCommunication(this.contact.contactCommunications, 'home', "phone"),
        })
      }
      this.saleInfo = {
        saleId: res.data[0].saleId,
        buyerName: res.data[0].contactId !== this.headerInformation.get("contactId").value ? res.data[0].contact.name : null
      }
    })
  }

  filterForCommunication(contactCommunications, searchFor, type): any[] {
    let relationshipSearchList = contactCommunications ? contactCommunications : null
    if (relationshipSearchList.length == 0) { return null }
    let returnedValue = null
    if (type == "phone") { returnedValue = relationshipSearchList.find(comms => comms.subtype === searchFor); }
    if (type == "email") { returnedValue = relationshipSearchList.find(comms => comms.type === searchFor); }
    if (typeof returnedValue !== 'undefined') {
      return returnedValue.value
    }
    return null
  }

  public submitContact(contactId) {
    if (!contactId) {
      return
    }
    this.message = null
    this.show = false

    this.master.get(`contacts/${contactId}/purchases`, res => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      if (!res.data.contact) {
        return
      }

      if (this.headerInformation.get("vehicleId").value == null) this.vehicleListOptions = res.data.vehicleListOptions && res.data.vehicleListOptions.length > 0 ? res.data.vehicleListOptions : null

      if (contactId == this.headerInformation.get("contactId").value) {
        return
      }

      this.contact = res.data.contact
      this.headerInformation.patchValue({
        contactId: this.contact.id,
        contactFirstName: this.contact.firstName,
        contactLastName: this.contact.lastName,
        contactCompanyName: !this.contact['isIndividual'] ? this.contact.companyName : null,
        address: this.contact.contactAddresses.length > 0 ? this.contact.contactAddresses[0].address : null,
        address2: this.contact.contactAddresses.length > 0 ? this.contact.contactAddresses[0].address2 : null,
        city: this.contact.contactAddresses.length > 0 ? this.contact.contactAddresses[0].city : null,
        state: this.contact.contactAddresses.length > 0 ? this.contact.contactAddresses[0].state : null,
        zipCode: this.contact.contactAddresses.length > 0 ? this.contact.contactAddresses[0].zipCode : null,
        county: this.contact.contactAddresses.length > 0 ? this.contact.contactAddresses[0].county : null,
        country: this.contact.contactAddresses.length > 0 ? this.contact.contactAddresses[0].country : null,
        emailAddress: this.filterForCommunication(this.contact.contactCommunications, 'email', "email"),
        cellPhone: this.filterForCommunication(this.contact.contactCommunications, 'cell', "phone"),
        workPhone: this.filterForCommunication(this.contact.contactCommunications, 'work', "phone"),
        homePhone: this.filterForCommunication(this.contact.contactCommunications, 'home', "phone"),
      })
      this.prepTicketForSave()
    })
  }

  public submitVIN() {
    this.message = null
    this.show = false

    if (this.headerInformation.get("vehicleId").value !== null) return
    if (!this.vinNumber) return
    if (this.vinNumber.length !== 17) return

    this.master.get(`vehicles/${this.vinNumber}/purchasers`, res => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.saleContactsListOptions = res.data.saleContactsListOptions && res.data.saleContactsListOptions.length > 0 ? res.data.saleContactsListOptions : null
      if (this.saleContactsListOptions && this.saleContactsListOptions.length > 0) {
        const lastSale = this.saleContactsListOptions.slice(-1).pop()
        this.saleInfo = {
          saleId: lastSale['saleId'],
          buyerName: lastSale['contactName'],
        }
        if (this.headerInformation.get("contactFirstName").value == null && this.headerInformation.get("contactLastName").value == null && this.headerInformation.get("contactCompanyName").value == null && !this.ignoreDefaultContact) {
          this.setContact(lastSale)
        }
        this.headerInformation.patchValue({ saleId: lastSale['saleId'] })
        this.saleContactsListOptions = null
      }

      const vinStatus = res.data.vinStatus
      if (vinStatus) {
        if (vinStatus.vehicleInfo) {
          this.vehicleInfo = {
            make: vinStatus.vehicleInfo['make'] ? vinStatus.vehicleInfo['make'] : null,
            model: vinStatus.vehicleInfo['model'] ? vinStatus.vehicleInfo['model'] : null,
            modelYear: vinStatus.vehicleInfo['modelYear'] ? vinStatus.vehicleInfo['modelYear'] : null,
            engine: vinStatus.vehicleInfo['engine'] ? vinStatus.vehicleInfo['engine'] : null,
            bodyStyle: vinStatus.vehicleInfo['bodyStyle'] ? vinStatus.vehicleInfo['bodyStyle'] : null,
            stockNumber: vinStatus.vehicleInfo.inventory ? vinStatus.vehicleInfo.inventory.stockNumber : null,
            inventoryId: vinStatus.vehicleInfo.inventory ? vinStatus.vehicleInfo.inventory.id : null
          }
        }
        if (vinStatus.foundInInventory && !vinStatus.foundOnActiveSale) {
          this.headerInformation.disable()
          this.form.disable()
          this.lineInformation.disable()
          this.message = "This VIN is found in inventory but has not yet been sold. To add a reconditioning cost, "
          return
        }
      }

      this.headerInformation.enable()
      this.form.enable()
      this.lineInformation.enable()
      this.headerInformation.get("vehicleId").setValue(vinStatus.vehicleInfo.id)
      this.prepTicketForSave()

    })
  }

  public calculateLineTotal() {
    const serviceType = this.lineInformation.get('serviceType').value
    if (serviceType == 'Parts') {
      this.lineInformation.patchValue({
        rate: null,
        hours: null
      })
      this.lineInformation.get('lineTotal').setValue(this.lineInformation.get('quantity').value * this.lineInformation.get('price').value)
      return this.lineInformation.get('quantity').value * this.lineInformation.get('price').value
    }
    if (serviceType == 'Labor') {
      this.lineInformation.patchValue({
        quantity: null,
        price: null,
        partNumber: null
      })
      this.lineInformation.get('lineTotal').setValue(this.lineInformation.get('rate').value * this.lineInformation.get('hours').value)
      return this.lineInformation.get('rate').value * this.lineInformation.get('hours').value
    }
  }

  // selecting a contact from the auto complete dropdown from the contact fetch()
  public selectContact(e) {
    if (!e) {
      return
    }
    if (this.headerInformation.get("contactId").value == e) {
      return
    }
    this.show = false
    this.form.get("contactFullName").reset(null)
    this.saleContactsListOptions = null
    this.submitContact(e)
  }

  // selecting a contact from the sale contact list options
  public setContact(e) {
    if (!e) {
      return
    }
    if (this.headerInformation.get("contactId").value == e.contactId) {
      return
    }
    this.show = false
    this.saleContactsListOptions = null
    this.headerInformation.patchValue({ saleId: e.saleId })
    this.getSaleBuyer(e.saleId)
  }

  // entering a VIN
  public setVehicle() {
    this.found = false
    this.message = null
    if (this.vehicleListOptions && this.vehicleListOptions.length > 0) {
      this.found = this.vehicleListOptions.find(vin => this.vinNumber == vin['vehicleInfo']['vinNumber'])
      if (this.found) {     //if the vehicle is found in the list that shows the sales, then set the sale for them
        this.vehicleListOptions = null
        this.headerInformation.patchValue({ saleId: this.found['saleId'], vehicleId: this.found['vehicleInfo']['id'] })
        this.getSaleBuyer(this.found['saleId'])
        // this.vehicleInfo = this.found['vehicleInfo']
        this.vehicleInfo = { inventoryId: this.found.inventoryId, stockNumber: this.found.stockNumber }
        this.prepTicketForSave()
        return
      }
    }
    this.vehicleListOptions = null
    this.submitVIN()
  }


  public selectVehicle(e) {      //selecting vin from list
    if (!e) {
      return
    }
    this.vehicleListOptions = null
    this.found = false
    this.vinNumber = e.vehicleInfo.vinNumber
    this.form.get("vinNumber").patchValue(this.vinNumber)
    this.headerInformation.patchValue({ saleId: e.saleId, vehicleId: e.vehicleInfo.id })
    this.vehicleInfo = e.vehicleInfo
    this.prepTicketForSave()
    this.submitVIN()
  }

  public prepTicketForSave() {
    if (this.headerInformation.get('vehicleId').value == null) {
      return
    }
    this.headerInformation.markAsDirty()
    this.changed()
  }

  // public nameChanged() {
  //   if (this.headerInformation.get("vehicleId").value == null) {
  //     return
  //   }
  //   this.headerInformation.get('contactId').patchValue(null)
  // }

  public deleteTicketHeader() {
    if (!this.ticketId) {
      return
    }

    this.master.discard(`service/${this.ticketId}`, res => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      document.getElementById("close-delete-modal").click()
      this.router.navigate([`service`]);
    })
  }

  public clearContactFullName() {
    if (this.headerInformation.get('contactId').value) {
      return
    }
    this.form.get('contactFullName').setValue(null)
  }

  // ? address lookup
  // verify if the corporate is already exist
  public searchZipCode(): void {
    const code = this.headerInformation.get('zipCode').value
    if (!code || code.length < MIN_SEARCH_CHARS) {
      return
    }

    if (this.zipInquiry && (code === this.zipInquiry)) { // hasn't changed?
      return
    }

    clearTimeout(this.timeOutID)

    this.timeOutID = setTimeout(() => {
      this.zipCodeList = []
      this.zipInquiry = code

      this.master.get(`readLocationByZipcode?zipcode=${code}`, res => {
        if (!res || !res.data) {
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
          return
        }

        if (res.status !== 200) {
          return
        }

        this.zipCodeList = res.data.locations

        // autocomplete
        if ((code >= 5) && (this.zipCodeList.length === 1)) {
          this.selectZipCode(this.zipCodeList[0])
        }
      })
    }, SEARCH_DEBOUNCE_MILLIS)
  }

  // set the address exist
  public selectZipCode = (address: Object): void => {
    if (!address || !this.headerInformation) {
      return
    }

    this.headerInformation.get('city').setValue(address['city'])
    this.headerInformation.get('state').setValue(address['state'])
    this.headerInformation.get('county').setValue(address['county'])
    this.headerInformation.get('zipCode').setValue(address['zipcode'])
    this.headerInformation.get('country').setValue('United States')

    this.zipCodeList = []
  }

  public calculate() {
    if (this.terms.get('paymentAmount').value == 0 || isNaN(this.terms.get('paymentAmount').value)) {
      const paymentAmount = Math.fround(this.totals['total'] / this.terms.get('numberOfPmts').value).toFixed(2)
      this.terms.patchValue({ paymentAmount }, { emitEvent: true })
    }
    if (this.terms.get('numberOfPmts').value == 0) {
      const numberOfPmts = Math.fround(this.totals['total'] / this.terms.get('paymentAmount').value).toFixed(0) 
      this.terms.patchValue({ numberOfPmts })
    }
  }

  public printServiceAgreement() {
    const payload = this.terms.getRawValue()
    this.master.post(`printServiceAgreement/${this.ticketId}`, { ...payload }, res => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        return
      }
    })
  }

}
