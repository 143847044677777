import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// services
import { MasterService } from '../../services/master.service'
import { MessageService } from '../../services/message.service';
import { StoreService } from '../../services/store.service';
import * as languageLibrary from '../../services/language'

@Component({
  selector: 'customer-information-form',
  templateUrl: './customer-information-form.component.html',
  styleUrls: ['./customer-information-form.component.scss']
})
export class CustomerInformationFormComponent implements OnInit {

  /*
  * Variables
  */

  // user informaion
  @Input() user = { email: '', firstName: '', lastName: '', phone: '', corporateURL: '' }

  // save the language  
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language'):'EN'

  // set all words  
  public words = languageLibrary.language
  
  // define the status of end-point
  public loading: boolean = false
  
  // list of languages
  public languagesList: object[] = [{ name: 'english', value: 'EN' }, { name: 'spanish', value: 'ES' }]
  
  // this variable is independence of form group
  public corporateURL: string = ''

  // A handle to navbar event messages so we can unsubscribe later.
  private navbarWatcher: any = null
  
  // Group of inputs
  public customerInformation = new FormGroup({
    email: new FormControl({value: null, disabled: true}, [Validators.required, Validators.email, Validators.maxLength(150), Validators.minLength(5)]),
    firstName: new FormControl(null, [Validators.required, Validators.maxLength(50), Validators.minLength(3), Validators.pattern('[A-Za-zÑñÁáÉéÍíÓóÚú ]{3,50}')]),
    lastName: new FormControl(null, [Validators.required, Validators.maxLength(50), Validators.minLength(3), Validators.pattern('[A-Za-zÑñÁáÉéÍíÓóÚú _-]{3,50}')]),
    phone: new FormControl(null, [Validators.required, Validators.pattern('[0-9]{10,11}')]),
    languagePreference: new FormControl('EN', [Validators.required])
  })

  // get information of inputs
  get email() { return this.customerInformation.get('email') }
  get firstName() { return this.customerInformation.get('firstName') }
  get lastName() { return this.customerInformation.get('lastName') }
  get phone() { return this.customerInformation.get('phone') }
  get languagePreference() { return this.customerInformation.get('languagePreference') }

  /*
  * Functions
  */
  
  //  send the new user information 
  public save = (): void => {
    if (this.customerInformation.invalid) {
      return
    }

    this.loading = true
    const payload = this.customerInformation.value
    if (!payload) {
      return
    }

    payload.id = parseInt(this.store.userAccount['user'].id)
    if (isNaN(payload.id) || (payload.id <= 0)) {
      return
    }

    this.master.put('updateUser', payload, res => {
      this.loading = false
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language].apiNoResponse })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
      
      this.ms.sendMessage("alert", { type: "success", text: this.words[this.language].profileUpdated })
      this.ms.channelComponents$.emit({ message: 'updateInformation', data: this.customerInformation.value })
    })
  }

  public cancel = (): void => {
    this.customerInformation.setValue({
      email: this.store.userAccount['user'].email,
      firstName: this.store.userAccount['user'].firstName,
      lastName: this.store.userAccount['user'].lastName,
      phone: this.store.userAccount['user'].phone,
      languagePreference: this.store.userAccount['user'].languagePreference,
    })

    this.corporateURL = this.store.userAccount['user'].corpShortURL
  }

   /*
  todo: change the language preferences
  */
  public changeLanguage = (e): void => {
    if (!e || !e.target || !e.target.value) {
      return
    }

    this.customerInformation.get('languagePreference').setValue(e.target.value, {onlySelf: true})
  }
  /*
  * Life cycles
  */
  constructor(private master: MasterService, private ms: MessageService, private store: StoreService) {
    // set data from store service
    if (!this.store || !this.store.userAccount) {
      return
    }
    
    this.customerInformation.setValue({
      email: this.store.userAccount['user'].email,
      firstName: this.store.userAccount['user'].firstName,
      lastName: this.store.userAccount['user'].lastName,
      phone: this.store.userAccount['user'].phone,
      languagePreference: this.store.userAccount['user'].languagePreference
    })

    this.corporateURL = this.store.userAccount['user'].corpShortURL
  }

  ngOnInit() {
    // detect change language
    this.navbarWatcher = this.ms.channelComponents$.subscribe(res => {
      if (res.message == "changeLanguage") {
        this.language = res.value
      }
    })
  }

  ngOnDestroy() {
    if (!this.navbarWatcher) {
      return
    }

    this.navbarWatcher.unsubscribe()
  }
}