import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import * as languageLibrary from '../../../../../../services/language'
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DynamicService } from 'src/app/services/dynamic.service';
import { token } from '../roles.component';
import { MasterService } from 'src/app/services/master.service';
import { MessageService } from 'src/app/services/message.service';
import { StoreService } from 'src/app/services/store.service';

export interface Product {
  name: string;
  id: number;
}
@Component({
  selector: '[role-warranty]',
  templateUrl: './role-warranty.component.html',
  styleUrls: ['./role-warranty.component.scss']
})
export class RoleWarrantyComponent implements OnInit {
  public contactId: number
  public contactRoleId: number = 0
  public words = languageLibrary.language
  public language: string = 'EN'
  public warrantyInformation = new FormGroup({
    id: new FormControl(null),
    contactId: new FormControl(null, [Validators.required]),
    dealerCost: new FormControl(null),
    sellingPrice: new FormControl(null),
    deductible: new FormControl(null),
    partsPercentage: new FormControl(null),
    laborPercentage: new FormControl(null),
    warrantedTime: new FormControl(null),
    warrantedMiles: new FormControl(null),
    warrantedComponents: new FormControl(null),
    buyersObligation: new FormControl(null),
    contractUsed: new FormControl(null),
    productId: new FormControl(null, [Validators.required]),
    productName: new FormControl(null)
  })
  public dataIn: string;
  public id: number = 0;
  public calledFrom: string;
  public contactWarrantyProducts: [];

  constructor(private master: MasterService, private ms: MessageService, private inject: Injector, private serv: DynamicService, private store: StoreService, private fb: FormBuilder) {

  }

  deactivateRole() {
    this.serv.outputFromDynamicComponent({ action: "deactivate", contactRoleId: this.contactRoleId });
  }


  ngOnInit(): void {

    this.dataIn = this.inject.get(token);
    this.contactId = this.dataIn['contactId']
    this.contactRoleId = this.dataIn['contactRoleId']
    this.calledFrom = this.dataIn['calledFrom']

    if (!this.contactId) {
      return
    }

    this.warrantyInformation.get("contactId").setValue(this.contactId)

    this.getWarranty()
  }

  public getWarranty() {
    this.master.get(`contacts/${this.contactId}/warranty`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      if (res.data.contactWarranty.length === 0) {
        // if (this.calledFrom == "contactAdvancedSearch")
        //   this.serv.outputFromDynamicComponent({ action: "setupNeeded", setupNeeded: true, type: "warranty" });
        return
      }

      this.contactWarrantyProducts = res.data.contactWarranty

      // if (this.calledFrom == "contactAdvancedSearch") {
      //   this.serv.outputFromDynamicComponent({ action: "setupNeeded", setupNeeded: false, type: "warranty" });
      //   return
      // }
    })
  }

  public saveWarranty() {
    if (!this.warrantyInformation.valid) {
      return
    }

    if (this.id <= 0) {
      this.createWarranty()
      return
    }

    if (this.id >= 1) this.modifyWarranty()
  }

  createWarranty() {
    this.master.post(`contacts/${this.contactId}/warranty`, { information: this.warrantyInformation.value, }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
      this.resetInformation()
      // this.setInformation(res.data.contactWarranty)

      this.contactWarrantyProducts = res.data.contactWarranty

      if (this.calledFrom == "contactAdvancedSearch")
        this.serv.outputFromDynamicComponent({ action: "setupSaved", setupSaved: true, type: "warranty" });
    })
  }

  modifyWarranty() {
    this.master.put(`contacts/${this.contactId}/warranty/${this.id}`, { information: this.warrantyInformation.value, }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
      this.resetInformation()

      this.contactWarrantyProducts = res.data.contactWarranty
      if (this.calledFrom == "contactAdvancedSearch")
        this.serv.outputFromDynamicComponent({ action: "setupSaved", setupSaved: true, type: "warranty" });

    })
  }

  deleteWarranty() {
    this.master.discard(`contacts/${this.contactId}/warranty/${this.id}`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.contactWarrantyProducts = res.data.contactWarranty
      const btn = (document.getElementById('btn-close-modal-delete-product') as HTMLButtonElement)
      if (btn) { btn.click() }

      this.resetInformation()
    })
  }

  resetInformation() {
    this.warrantyInformation.reset({
      contactId: this.contactId
    })
    this.id = null
  }


  setInformation(info) {
    this.setId(info['id'])
    this.warrantyInformation.setValue({
      id: info['id'],
      contactId: info['contactId'],
      dealerCost: info['dealerCost'],
      sellingPrice: info['sellingPrice'],
      deductible: info['deductible'],
      partsPercentage: info['partsPercentage'],
      laborPercentage: info['laborPercentage'],
      warrantedTime: info['warrantedTime'],
      warrantedMiles: info['warrantedMiles'],
      warrantedComponents: info['warrantedComponents'],
      buyersObligation: info['buyersObligation'],
      contractUsed: info['contractUsed'],
      productId: info['productId'],
      productName: info['product']['name']
    })
  }

  setId(id) {
    this.id = id
  }

  public setProductIdEvent(e) {
      if (!e) {
        return
      }
      this.warrantyInformation.get("productId").setValue(e)
  } 

}
