import { Component, Input, OnInit } from '@angular/core';
import { MasterService } from '../../../../services/master.service'
import { MessageService } from '../../../../services/message.service'
import { StoreService } from '../../../../services/store.service'
import * as languageLibrary from '../../../../services/language'

@Component({
  selector: 'roles-detail',
  templateUrl: './roles-detail.component.html',
  styleUrls: ['./roles-detail.component.scss']
})
export class RolesDetailComponent implements OnInit {
  /*
  * variables 
  */
  
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  
  // set all words
  public words = languageLibrary.language
  
  //  get the role selected
  @Input() role: Object = { name: '', id: '' }
  
  // permissions
  //  path structure
  // @ name: row name
  // @ permissions: define the permissions on views, sections and fields
  // @ level: define the level of deep
  // @ CRUD: define the actions avaible on views, sections and fields
  @Input() permissionsDetail: Object[] = []

  // define the loading state
  public loading: boolean = false

  // define the permissions
  public permissions: Object[] = []

  // A handle to navbar event messages so we can unsubscribe later.
  private navbarWatcher: any = null

  /*
  * functions 
  */
  // get permissions
  public getPermissions = (id: string): void => {
    this.loading = true
    this.master.get(`getPagesPermissions?roleId=${id}`, res => {
      this.loading = false

      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.permissions = res.data.pages
      
    })
  }
  // toggle permissions
  public togglePermisions = (roleId: string, table: string, children: Object, permission: string, isCheked: boolean): void => {

    if (this.permissionsDetail.includes('edit') || true) {
      
      // construct the toggle settings
      let params = [{
        name: table,
        childrens: this.manageArray(children, permission)
      }]

      this.master.post('pagesPermissions', { roleId: roleId, pages: params }, res => {
        if (!res || !res.data) {
          this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
          return
        }
        
        if (res.status !== 200) {
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
          return   
        }
        
        const message = isCheked ? "disabled" : "enabled"
        this.setUserAccount()
      })

    }

  }

  // add or remove permissions
  public manageArray = (children: Object, permission: string): Object[] => {
    if (!children['permissions'].includes(permission)) {
      children['permissions'].push(permission)
      return [children]
    }

    if (permission !== 'view') {
      let index = children['permissions'].indexOf(permission)
      children['permissions'].splice(index, 1)
      return [children]
    }
    
    children['permissions'] = []
    return [children]
  }
  
  // reload permissions
  public setUserAccount = (): void => {
    this.master.get('getUserAccount', res => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.store.userAccount = res
      this.permissionsDetail = res.data.permissions[3].childrens[2].permissions     
    })
  }
  
  /*
  * life cycles 
  */
  constructor(private master: MasterService, private ms: MessageService, private store: StoreService) {}

  ngOnInit() {
    this.getPermissions(this.role['id'])
    
    this.navbarWatcher = this.ms.channelComponents$.subscribe(res => {
      if (res.message == 'changeRole') {
        this.role = res.role
        this.getPermissions(res.role.id)
        return
      } 
      
      if (res.message == "changeLanguage") {
        this.language = res.value
      }
    })
  }

  ngOnDestroy() {
    if (!this.navbarWatcher) {
      return
    }

    this.navbarWatcher.unsubscribe()
  }

}
