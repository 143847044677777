import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { formatPercent } from "../../utils/format"
import { clean } from "../../utils/numeric"

@Component({
    selector: 'percent-input',
    templateUrl: './percent-input.component.html',
    styleUrls: ['./percent-input.component.scss']
})
export class PercentInputComponent {

    /** Unique identity for this input so label htmlFor will work. */
    @Input() id: String = null

    /** The parent form control want to use the currency input. */
    @Input() control: FormControl = null

    /** A value, typically a calculated dollar amount, to prepend to the input. */
    @Input() prepend: String = null

    /** Listen for input focus events if further processing is necessary. */
    @Output() focused = new EventEmitter<Event>()

    /** Listen for input blur events if further processing is necessary. */
    @Output() blurred = new EventEmitter<Event>()

    /** Listen for input commit (enter key) events if further processing is necessary. */
    @Output() committed = new EventEmitter<Event>()

    /** The last known amount after a blur or commit so we can check for changes and decide to fire events. */
    private lastAmount: number = 0
    
    /**
     * Clean any extraneous formatting from the number and select all for edit.
     */
    public focus = (e: Event) => {
        if (!e || !e.target) {
            return
        }

        const input = (e.target as HTMLInputElement)
        if (!input) {
            return
        }

        this.lastAmount = clean(input.value)
        input.value = this.lastAmount.toString()
        input.setSelectionRange(0, input.value ? input.value.length : 0)

        this.focused.next(e)
    }

    /**
     * Format currency input fields on blur
     */
    public blur = (e: Event) => {
        if (!e || !e.target) {
            return
        }

        const input = (e.target as HTMLInputElement)
        if (!input) {
            return
        }

        let amount = clean(input.value)
        if (!amount || isNaN(amount)) {
            amount = 0
        }

        input.value = formatPercent(amount)
        this.control.setValue(input.value)

        if (amount === this.lastAmount) { // already committed or no change since recent focus event?
            return
        }

        this.lastAmount = amount
        this.blurred.next(e)
    }

    /**
     * Notify listners of value when enter key detected
     */
    public keydown = (e: KeyboardEvent) => {
        if (!e || !e.target) {
            return
        }

        const key = e.key
        if (!key || (key !== 'Enter')) {
            return
        }

        e.preventDefault()
        e.stopPropagation()

        const input = (e.target as HTMLInputElement)
        if (!input) {
            return
        }

        let amount = clean(input.value)
        if (!amount || isNaN(amount)) {
            amount = 0
        }

        if (amount === this.lastAmount) { // no change?
            return
        }

        this.lastAmount = amount
        this.committed.next(e)
    }
    
}

