import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { MatAutocompleteModule, MatInputModule, MatFormFieldModule } from '@angular/material';
// services
import { MasterService } from "./services/master.service";
import { StoreService } from "./services/store.service";
import { RoleService } from "./services/role.service"
import { SecurityService } from "./services/security.service"
// components
import { AppComponent } from './app.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterFormComponent } from './views/login/components/register-form/register-form.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { AlertComponent } from './components/alert/alert.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginFormComponent } from './views/login/components/login-form/login-form.component';
import { ProfileDashboardComponent } from './views/profile-dashboard/profile-dashboard.component';
import { CustomerInformationFormComponent } from './components/customer-information-form/customer-information-form.component';
import { AccountSettingsFormComponent } from './components/account-settings-form/account-settings-form.component';
import { EmailComponent } from './views/activation-views/email/email.component';
import { ActivateAccountComponent } from './views/activation-views/activate-account/activate-account.component';
import { LandingComponent } from './information-views/landing/landing.component';
import { ContactComponent } from './information-views/contact/contact.component';
import { PrivacyComponent } from './information-views/privacy/privacy.component';
import { PricingComponent } from './information-views/pricing/pricing.component';
import { PartnersComponent } from './information-views/partners/partners.component';
import { FeaturesComponent } from './information-views/features/features.component';
import { FaqComponent } from './information-views/faq/faq.component';
import { HelpComponent } from './information-views/help/help.component';
import { UsersComponent } from './views/users/users.component';
import { ResetPasswordComponent } from './views/activation-views/reset-password/reset-password.component';
import { ResetChangePasswordComponent } from './views/activation-views/reset-change-password/reset-change-password.component';
// import { InvitationsActivationComponent } from './views/activation-views/invitations-activation/invitations-activation.component';
import { ContactsComponent } from './views/contacts-views/contacts/contacts.component';
import { TableUserOptionsComponent } from './views/users/components/table-user-options/table-user-options.component';
import { AddUsersComponent } from './views/users/components/add-users/add-users.component';
import { ContactsDetailComponent } from './views/contacts-views/contacts/contacts-detail/contacts-detail.component';
import { GeneralInformationComponent } from './views/contacts-views/contacts/components/general-information/general-information.component';
import { AddressesComponent } from './views/contacts-views/contacts/components/addresses/addresses.component';
import { AddressChooserComponent } from './views/sales/components/buyer/components/address-chooser/address-chooser.component';
import { CommunicationsComponent } from './views/contacts-views/contacts/components/communications/communications.component';
import { IdentificationsComponent } from './views/contacts-views/contacts/components/roles/role-lead/identifications/identifications.component';
import { IncomeComponent } from './views/contacts-views/contacts/components/roles/role-lead/income/income.component'
import { ReferencesComponent } from './views/contacts-views/contacts/components/roles/role-lead/references/references.component';
import { ContactModalComponent } from './views/contacts-views/contacts/contact-modal/contact-modal.component';
import { CommissionComponent } from "./views/contacts-views/contacts/components/commission/commission.component";
import { NotesComponent } from './views/contacts-views/contacts/components/notes/notes.component';
import { HistoryComponent } from './views/contacts-views/contacts/history/history.component';
import { ContactRolesComponent } from './views/contacts-views/contacts/components/roles/roles.component'
import { ContactCPIComponent } from './views/contacts-views/contacts/components/roles/role-cpi/role-cpi.component';
import { CorporationsComponent } from './views/corporateSetup-views/views/corporations/corporations.component';
import { CorporationsDetailComponent } from './views/corporateSetup-views/views/corporations-detail/corporations-detail.component';
import { GeneralInformationCorporateComponent } from './views/corporateSetup-views/components/general-information/general-information.component'
import { AddressesCorporateComponent } from './views/corporateSetup-views/components/addresses/addresses.component';
import { CommunicationsCorporateComponent } from './views/corporateSetup-views/components/communications/communications.component';
import { CommissionCorpComponent } from "./views/corporateSetup-views/components/commission-corp/commission-corp.component";
import { SecurityComponent } from './views/security/security.component';
import { RolesDetailComponent } from './views/security/components/roles-detail/roles-detail.component'
import { UsersRolesComponent } from './views/security/components/users/users.component';
import { InventoryComponent } from './views/inventory/inventory/inventory.component';
import { InventoryDetailComponent } from './views/inventory/inventory-detail/inventory-detail.component';
import { InventoryGeneralInformationComponent } from './views/inventory/components/inventory-general-information/inventory-general-information.component';
import { InventoryReconditioningCostComponent } from './views/inventory/components/inventory-reconditioning-cost/inventory-reconditioning-cost.component';
import { InventoryFloorPlansComponent } from './views/inventory/components/inventory-floor-plans/inventory-floor-plans.component';
import { InventorySetupComponent } from './views/corporateSetup-views/components/inventory-setup/inventory-setup.component';
import { InventoryHistoryComponent } from './views/inventory/components/inventory-history/inventory-history.component';
import { InventoryQuickAddComponent } from './views/inventory/components/inventory-quick-add/inventory-quick-add.component';
import { ReportSetupComponent } from './views/corporateSetup-views/components/report-setup/report-setup.component';
import { ContactFloorPlanComponent } from './views/contacts-views/contacts/components/roles/role-floorplan/role-floorplan.component';
import { BusinessContactComponent } from './views/contacts-views/contacts/components/roles/role-businessContact/role-businessContact.component';
import { InventoryScanComponent } from './views/inventory/components/inventory-scan/inventory-scan.component'
import { BuyersReportComponent } from './views/reports/buyers-report/buyers-report.component';
import { PaymentReceiptComponent } from './views/reports/payment-receipt/payment-receipt.component';
import { NotesInventoryComponent } from './views/inventory/components/notes-inventory/notes-inventory.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PipePhoneModule } from './utils/pipe-module';
import { InventoryImagesListComponent } from './views/inventory/components/inventory-images-list/inventory-images-list.component';
import { InventoryFilesComponent } from './views/inventory/components/inventory-files/inventory-files.component';
import { SalesComponent } from "./views/sales/sales/sales.component";
import { SalesDetailComponent } from "./views/sales/sales-detail/sales-detail.component";
import { DealBuyerComponent } from "./views/sales/components/buyer/buyer.component";
import { ContactListComponent } from "./views/sales/components/buyer/components/contact-list/contact-list.component";
import { ContactDetailComponent } from "./views/sales/components/buyer/components/contact-detail/contact-detail.component";
import { DealStockComponent } from "./views/sales/components/stock/stock.component";
import { DealContractComponent } from "./views/sales/components/contract/contract.component";
import { SaleSetupComponent } from "./views/corporateSetup-views/components/sales-setup/sales-setup.component";
import { CommunicationCenterComponent } from "./views/communication-center/communication-center.component";
import { ServiceComponent } from "./views/service/service.component";
import { IntegrationComponent } from "./views/integration-views/integration.component";
import { CanDeactivateGuard } from './services/can-deactivate-guard.service';
import { TradeInComponent } from './views/sales/components/stock/components/trade-in/trade-in.component';
import { AccesoriesComponent } from './views/sales/components/contract/components/accesories/accessories.component';
import { TtlComponent } from './views/sales/components/contract/components/ttl/ttl.component';
import { TermsComponent } from './views/sales/components/contract/components/terms/terms.component';
import { CollectionsComponent } from './views/payments/collections.component';
import { CollectComponent } from './views/payments/collect/collect.component';
import { DownsDepositsComponent } from './views/payments/components/dash/downs-deposits.component';
import { PaysComponent } from './views/payments/components/collect/pays.component';
import { PayPromisesComponent } from './views/payments/components/dash/pay-promises.component';
import { PaysDueSoonComponent } from './views/payments/components/dash/pays-due-soon.component';
import { PaysPastDueComponent } from './views/payments/components/dash/pays-past-due.component';
import { RecentPaysComponent } from './views/payments/components/dash/recent-pays.component';
import { CollectionsSetupComponent } from './views/corporateSetup-views/components/collections-setup/collections-setup.component';
import { TakePaymentComponent } from './views/payments/components/collect/take-payment.component';
import { PayPromiseEditComponent } from './views/payments/components/collect/promise-edit.component';
// import { CPIComponent } from './views/cpi-views/cpi.component';
import { AutoInsuranceComponent } from './views/sales/components/auto-insurance/auto-insurance.component';
import { CPIComponent } from './views/sales/components/auto-insurance/components/cpi/cpi.component';
import { OutsideInsuranceComponent } from './views/sales/components/auto-insurance/components/outside-insurance/outside-insurance.component';
import { CardCalculateComponent } from './views/sales/components/contract/components/terms/components/card-calculate/card-calculate.component'
import { CardCompareComponent } from './views/sales/components/contract/components/terms/components/card-compare/card-compare.component'
import { PrintCenterComponent } from './views/sales/components/print-center/print-center.component'
import { PaymentsTermsComponent } from './views/sales/components/contract/components/terms/components/payments-terms/payments-terms.component';
import { InventoryListComponent } from "./views/sales/components/stock/components/inventory-list/inventory-list.component";
import { SafeUrlPipe } from './utils/pipe-module';
import { DynamicService } from "./services/dynamic.service";
import { ContactLeadComponent } from "./views/contacts-views/contacts/components/roles/role-lead/role-lead.component";
import { RoleLienholderComponent } from './views/contacts-views/contacts/components/roles/role-lienholder/role-lienholder.component';
import { RoleRelatedFinanceComponent } from './views/contacts-views/contacts/components/roles/role-related-finance/role-related-finance.component';
import { RoleWarrantyComponent } from './views/contacts-views/contacts/components/roles/role-warranty/role-warranty.component';
import { RoleLifeInsuranceComponent } from './views/contacts-views/contacts/components/roles/role-lifeinsurance/role-lifeinsurance.component';
import { RoleGapComponent } from './views/contacts-views/contacts/components/roles/role-gap/role-gap.component';
import { RoleInventorySourceComponent } from './views/contacts-views/contacts/components/roles/role-inventory-source/role-inventory-source.component';
import { RoleVendorComponent } from './views/contacts-views/contacts/components/roles/role-vendor/role-vendor.component';
import { RoleDCAComponent } from './views/contacts-views/contacts/components/roles/role-dca/role-dca.component'
import { PersonalAutoInsuranceComponent } from "./views/contacts-views/contacts/components/roles/role-lead/personal-auto-insurance/personal-auto-insurance.component";
import { CurrencyInputComponent } from "./components/inputs/currency-input.component";
import { CountInputComponent } from "./components/inputs/count-input.component";
import { PercentInputComponent } from "./components/inputs/percent-input.component";
import { ContactAdvancedSearchComponent } from './views/contacts-views/contacts/contact-advanced-search/contact-advanced-search.component';
import { SalesProductsComponent } from './views/sales/components/contract/components/products/products.component';
import { CorporateProductsComponent } from "./components/inputs/corporate-products/corporate-products.component";
import { ServiceDetailComponent } from './views/service/components/service-detail/service-detail.component';
import { ServiceNotesComponent } from './views/service/components/service-notes/service-notes.component';
import { DealsComponent } from './views/deals/deals.component';
import { DynamicSetupFormComponent } from './views/sales/components/print-center/dynamic-setup-form/dynamic-setup-form.component';
import { FormService } from "./services/form-service.service";
import { GpsComponent } from './views/gps/gps.component';
import { GpsInventoryAssignmentComponent } from './views/gps/gps-inventory-assignment/gps-inventory-assignment.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterFormComponent,
    DashboardComponent,
    SideMenuComponent,
    NavBarComponent,
    AlertComponent,
    FooterComponent,
    LoginFormComponent,
    ProfileDashboardComponent,
    CustomerInformationFormComponent,
    AccountSettingsFormComponent,
    EmailComponent,
    ActivateAccountComponent,
    LandingComponent,
    ContactComponent,
    PrivacyComponent,
    PricingComponent,
    PartnersComponent,
    FeaturesComponent,
    FaqComponent,
    HelpComponent,
    UsersComponent,
    ResetPasswordComponent,
    ResetChangePasswordComponent,
    // InvitationsActivationComponent,
    ContactsComponent,
    TableUserOptionsComponent,
    AddUsersComponent,
    ContactsDetailComponent,
    GeneralInformationComponent,
    AddressesComponent,
    AddressChooserComponent,
    CommunicationsComponent,
    IdentificationsComponent,
    CommissionComponent,
    CommissionCorpComponent,
    IncomeComponent,
    ReferencesComponent,
    ContactModalComponent,
    NotesComponent,
    HistoryComponent,
    ContactRolesComponent,
    CorporationsComponent,
    CorporationsDetailComponent,
    GeneralInformationCorporateComponent,
    AddressesCorporateComponent,
    CommunicationsCorporateComponent,
    SecurityComponent,
    UsersRolesComponent,
    RolesDetailComponent,
    InventoryComponent,
    InventoryDetailComponent,
    InventoryGeneralInformationComponent,
    // InventoryFinancialsComponent,
    // InventoryAdministrativeComponent,
    InventoryReconditioningCostComponent,
    InventoryFloorPlansComponent,
    InventoryHistoryComponent,
    InventorySetupComponent,
    ReportSetupComponent,
    ContactFloorPlanComponent,
    BuyersReportComponent,
    PaymentReceiptComponent,
    // InventoryRecallsComponent,
    InventoryScanComponent,
    InventoryQuickAddComponent,
    NotesInventoryComponent,
    SalesComponent,
    SalesDetailComponent,
    DealBuyerComponent,
    ContactListComponent,
    ContactDetailComponent,
    DealStockComponent,
    DealContractComponent,
    InventoryImagesListComponent,
    InventoryFilesComponent,
    SaleSetupComponent,
    TradeInComponent,
    AccesoriesComponent,
    TtlComponent,
    TermsComponent,
    ServiceComponent,
    // IntegrationComponent,
    DownsDepositsComponent,
    PaysComponent,
    PayPromisesComponent,
    PaysDueSoonComponent,
    PaysPastDueComponent,
    RecentPaysComponent,
    TakePaymentComponent,
    PayPromiseEditComponent,
    CollectionsSetupComponent,
    CollectionsComponent,
    CollectComponent,
    CommunicationCenterComponent,
    CPIComponent,
    AutoInsuranceComponent,
    OutsideInsuranceComponent,
    CardCalculateComponent,
    CardCompareComponent,
    PrintCenterComponent,
    PaymentsTermsComponent,
    PipePhoneModule,
    InventoryListComponent,
    IntegrationComponent,
    SafeUrlPipe,
    ContactCPIComponent,
    ContactLeadComponent,
    BusinessContactComponent,
    RoleLienholderComponent,
    RoleRelatedFinanceComponent,
    RoleLifeInsuranceComponent,
    RoleWarrantyComponent,
    RoleGapComponent,
    RoleInventorySourceComponent,
    RoleVendorComponent,
    RoleDCAComponent,
    PersonalAutoInsuranceComponent,
    CurrencyInputComponent,
    CountInputComponent,
    PercentInputComponent,
    ContactAdvancedSearchComponent,
    SalesProductsComponent,
    CorporateProductsComponent,
    ServiceDetailComponent,
    ServiceNotesComponent,
    DealsComponent,
    DynamicSetupFormComponent,
    GpsComponent,
    GpsInventoryAssignmentComponent
  ],
  entryComponents: [
    ContactCPIComponent,
    ContactFloorPlanComponent,
    ContactLeadComponent,
    BusinessContactComponent,
    RoleLienholderComponent,
    RoleRelatedFinanceComponent,
    RoleLifeInsuranceComponent,
    RoleWarrantyComponent,
    RoleGapComponent,
    RoleInventorySourceComponent,
    RoleVendorComponent,
    RoleDCAComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DragDropModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule
    // ButtonModule
  ],
  providers: [MasterService, StoreService, CanDeactivateGuard, RoleService, DynamicService, SecurityService, FormService],
  bootstrap: [AppComponent],
})
export class AppModule { }
