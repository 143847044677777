import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MessageService } from "../../../../../../services/message.service";
import { MasterService } from "../../../../../../services/master.service";
import { StoreService } from "../../../../../../services/store.service";
import * as words from '../../../../../../services/language'
import { Subject } from 'rxjs';

import { SEARCH_DEBOUNCE_MILLIS, MIN_SEARCH_CHARS, SCROLL_BOTTOM_OUT } from "src/app/constants/list";

@Component({
    selector: "inventory-list",
    templateUrl: "./inventory-list.component.html",
    styleUrls: ["./inventory-list.component.scss"],
})
export class InventoryListComponent implements OnInit, OnChanges {

    @Input() show: boolean = false
    @Input() allowAddVehicle: boolean = false
    @Input() statuses: string = 'available'

    @Output() vehicleSelectedEvent = new EventEmitter<Object>()

    // Let the inventory add component know the user wants to save the information.
    public vehicleSavedEvent: Subject<Object> = new Subject();

    // save the language
    public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
    
    // set all words
    public words = words.language
    
    // save all data by type
    public vehicles: Object[] = [];
    
    // define if is loading
    public loading: boolean = false;
    
    // permissions
    public permissions: Object[] = [];
    
    // Current page the user is viewing (last loaded).
    public page = 1

    // The number contacts currently being displayed.
    public displayed = 0

    // Indicates whether or not there are no more records to load.
    public exhausted = false

    // Total number of records available
    public records: number

    // The search form group
    private form: FormGroup = null

    // The current search text to filter the list by.
    private inquiry: string = null

    // A debounce timer for search events. Used to wait for 
    // the user to stop typing before we fetch.
    private watcher: ReturnType<typeof setTimeout> = null

    // Keep track of how the user wants to sort list.
    private sortables: Object = {'stockNumber': 'ASC'}

    // The id of the current lot
    private lotId = 0

    public canSave: boolean = false

    // ************
    // life cycles
    // *************
    constructor(private route: ActivatedRoute, private router: Router, private ms: MessageService, private master: MasterService, private store: StoreService, private fb: FormBuilder) {}

    ngOnInit() {
        this.lotId = parseInt(localStorage.getItem('lot'))
        this.reset()

        this.form = this.fb.group({
            inquiry: new FormControl('', [])
        })

        this.form.get('inquiry').valueChanges.subscribe(inquiry => {
            if (this.watcher) {
                clearTimeout(this.watcher)
                this.watcher = null
            }

            if (this.inquiry === inquiry) {
                return
            }
    
            this.watcher = setTimeout(() => {
                this.watcher = null
                this.inquiry = inquiry
                this.reset()            
                this.fetch()
            }, SEARCH_DEBOUNCE_MILLIS)
        })

        // normal
        if (!this.store.userAccount['permissions']) {
            return
        }
  
        if (this.store.userAccount["permissions"]) {
            this.permissions = this.store.userAccount["permissions"][1].childrens[0].permissions
        }

        // if not have permissions, return to account settings
        if (!this.permissions.includes("view")) {
            return
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.show && (changes.show.currentValue === true)) {
            this.defaultTab()
            this.reset()
            this.fetch()
        }
        if (changes && changes.allowAddVehicle) {
            this.defaultTab()
            this.reset()
            this.fetch()
        }
    }

    /**
     * Reset the pagination parameters and scroll to the top
     */
    private reset() {
        this.page = 1
        this.exhausted = false
        this.displayed = 0
        this.vehicles = []
        this.lotId = this.lotId == 0 ? parseInt(localStorage.getItem('lot')) : this.lotId

        const list = document.querySelector('#listing')
        if (!list) {
            return
        }

        list.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto'
        })
    }

    /*
    * functions
    */

    /**
     * Handle scroll events of the list container and load 
     * the next page when avilable and the user scrolled 
     * to the bottom.
     * @param e The scroll event that trigggered this handler.
     */
    public scrolled = (e): void => {
        if (!e || !e.target) {
            return
        }

        if (this.exhausted) {
            return
        }

        if (this.loading) {
            return
        }

        const location = Math.abs(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop)

        if (location >= SCROLL_BOTTOM_OUT) {
            return
        }

        this.page = this.page + 1
        this.fetch()
    }
    
    /**
     * 
     * @param field The field that user wants to sort by. 
     */
    public sort = (field: string): void => {
        if (!field || (field.length <= 0)) {
            return
        }

        field = field.toLowerCase()
        if (this.sortables[field]) { // need to toggle the direction?
            switch (this.sortables[field]) {
                case 'ASC':
                    this.sortables[field] = 'DESC'
                    break
                default:
                    this.sortables[field] = 'ASC'
                    break
            }

            this.reset()
            this.fetch()
            return
        }

        this.sortables[field] = 'ASC'
        this.reset()
        this.fetch()
    }

    public sortedAsc = (field: string): Boolean => {
        if (!field || (field.length <= 0) || !this.sortables) {
            return false
        }

        field = field.toLowerCase()
        if (!this.sortables[field]) {
            return false
        }

        return (this.sortables[field].toUpperCase() === 'ASC')
    }

    public sortedDesc = (field: string): Boolean => {
        if (!field || (field.length <= 0) || !this.sortables) {
            return false
        }

        field = field.toLowerCase()
        if (!this.sortables[field]) {
            return false
        }

        return (this.sortables[field].toUpperCase() === 'DESC')
    }

    /**
     * Acquire inventory list
     */
    public fetch = (): void => {
        if (this.loading) { // already getting next page?
            return
        }

        if (this.exhausted) { // currently showing all available records?
            return
        }

        if (this.lotId == 0){
            return
        }

        this.loading = true

        let inquiryParam = ''
        if (this.inquiry && (this.inquiry.length > 0)) {
            inquiryParam = `&q=${encodeURIComponent(this.inquiry)}`
        }

        let sortParam = ''
        if (this.sortables && (Object.keys(this.sortables).length > 0)) {
            const sorts = []
            for (const key of Object.keys(this.sortables)) {
                let direction = ''
                if (this.sortables[key] && this.sortables[key].length > 0) {
                    direction = `|${this.sortables[key]}`
                }

                sorts.push(`${key}${direction}`)
            }

            sortParam = `&sort=${sorts.join(',')}`
        }

        this.master.get(`inventory?lotId=${this.lotId}&page=${this.page}&statuses=${this.statuses}${inquiryParam}${sortParam}`, (res) => {
            this.loading = false

            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.records = res.data.records
            this.displayed = Math.min(this.records, (res.data.page * res.data.ipp))
            this.exhausted = (res.data.next === null)

            if (this.page > 1) {
                this.vehicles = this.vehicles.concat(res.data.inventory)
                return
            }

            this.vehicles = res.data.inventory
        })
    }

    // todo: listen to output of navbar
    public listenerNav = (e): void => {

        switch (e['message']) {
            case 'changeLanguage':
                this.language = e['value']
                break;
            case 'setPermissions':
                // permissions on user list

                this.permissions = e['permissions'][1].childrens[0].permissions;
                if (this.permissions.includes('view')) {
                    this.reset()
                    this.fetch()
                }
                break;
            case 'changeType':
                this.form.get('inquiry').setValue('')
                this.reset()
                this.fetch()
                break
            default:
                break;
        }
    }

    public vehicleValidity = (valid: boolean): void => {
        this.canSave = valid
    }

    public vehicleSelected = (id, item): void => {
        this.vehicleSelectedEvent.emit({ message: 'choseVehicle', inventoryId: id, inventoryDetails: item, vehicleId: item.vehicleId })
    }

    public saveVehicle = (e) => {
        this.vehicleSavedEvent.next(e)
    }

    /**
     * Ensure the default tab is selected.
     */
    public defaultTab() {
        const vehicleFindTab = document.getElementById('vehicle-find')
        const vehicleAddTab = document.getElementById('vehicle-add')
        const addTab = document.getElementById('add-tab')

        if (!vehicleAddTab || !vehicleFindTab) {
            return
        }

        vehicleAddTab.classList.remove('show', 'active')
        addTab.classList.remove('d-none')
        vehicleAddTab.classList.remove('d-none')
        vehicleFindTab.classList.add('show', 'active')
        if (!this.allowAddVehicle) {
            addTab.classList.add('d-none')
            vehicleAddTab.classList.add('d-none')
        }
    }
}
