import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
// services
import { MasterService } from "../../services/master.service";
import { MessageService } from "../../services/message.service";
import { StoreService } from "../../services/store.service";
import * as languageLibrary from '../../services/language'
import * as moment from 'moment'
import * as XLSX from 'xlsx'
import { isThisTypeNode } from "typescript";

@Component({
  selector: "app-integration",
  templateUrl: "./integration.component.html",
  styleUrls: ["./integration.component.scss"],
})
export class IntegrationComponent implements OnInit {

  /*
* Variables
*/
  // save all data by type
  public integrationCompanies: Object[] = []
  public integrations: Object[] = []
  public permissions: Object[] = []

  // define if is loading
  public loading: boolean = false
  // save the language
  
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language

  /*
  * functions
  */
  //
  public getIntegrations = (): void => {
    this.master.get('integrationsList', res => {
      this.loading = false

      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.integrationCompanies = res.data.IntegrationCompanies || []
      document.getElementById('btn-viewAllIntegrations').click()
    })
  }
  public getCorporationIntegrations = (): void => {
    this.master.get('integrations', res => {
      this.loading = false

      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.integrations = res.data.integrations || []
    })
  }
  

  // todo: listen to output of navbar
  public listenerNav = (e): void => {
    switch (e['message']) {
      case 'changeLanguage':
        this.language=e['value']
        break
    }
  }

  /*
  * life cycles
  */
  constructor(
    private master: MasterService,
    private ms: MessageService,
    private store: StoreService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.getCorporationIntegrations()
  }
}
