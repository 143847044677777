import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService } from '../../services/message.service';

import { ALERT_DISPLAY_TIMEOUT } from 'src/app/constants/alert';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnDestroy 
{  
  private subscription: Subscription;
  public messages: object[] = [];

  constructor(private ms: MessageService) {
    // Creates a suscription to message service, waits for a message
    this.subscription = this.ms.onMessage().subscribe(msg => {
      if (msg.id !== "alert") {
        return
      }

      this.messages.push(msg.data)
      
      setTimeout(()=>{
        this.messages.shift()
      }, ALERT_DISPLAY_TIMEOUT);
    })
  }

  // Destroys suscription to message service
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
