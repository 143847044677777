import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MasterService } from 'src/app/services/master.service';
import { MessageService } from 'src/app/services/message.service';
import * as languageLibrary from '../../services/language'
import { TAX_METHODS } from 'src/app/constants/sale';

@Component({
  selector: 'app-deals',
  templateUrl: './deals.component.html',
  styleUrls: ['./deals.component.scss']
})

export class DealsComponent implements OnInit {
  public terms = new FormGroup({
    saleDate: new FormControl("2023-01-18", [Validators.required]),
    firstScheduledPaymentDueDate: new FormControl("2023-02-18", [Validators.required]),
    salePrice: new FormControl(14100, []),
    apr: new FormControl(11.000, [Validators.required]),
    subjectToFinance: new FormControl(16868.20, []),
    scheduledPaymentAmount: new FormControl(0, [Validators.required]),
    numberOfPayments: new FormControl(1, [Validators.required]),
    taxRate: new FormControl(0.0625, []),
    paymentCycle: new FormControl("Monthly", []),
    zipCode: new FormControl(77080, []),
    modelYear: new FormControl(2006, []),
    lotId: new FormControl(5, []),
    changedField: new FormControl(0, []),
    finalOddLastPayment: new FormControl(0, []),
    excludeAmort: new FormControl(0, []),
    tradeInAllowance: new FormControl(0, []),
    taxMethod: new FormControl('Deferred', []),
    financingTypeId: new FormControl("BHPH", []),
    totalDown: new FormControl(0, []),
  })
  public words = languageLibrary.language
  public schedule = []
  public scheduleTotals = null
  @Input() language: string = 'EN'
  public calculating: boolean = false;
  public initialize: boolean;
  //  An associative array of sale tax methods with the id as the key
  public taxMethods: Object = TAX_METHODS
  get taxMethodKeys() { return Object.keys(this.taxMethods) }
  constructor(private master: MasterService, private ms: MessageService) { }

  ngOnInit() {
  }

  public calculate(e): void {
    if (this.calculating) {
      return
    }

    let changedField = e.target.id

    if (changedField == 'saleDate' || changedField == 'paymentCycle') {
      this.setFirstScheduledPaymentDueDate()
    }

    if (this.terms.get('salePrice').value == 0) {
      return
    }

    if (this.terms.pristine) {
      return
    }

    if (!this.terms.get('numberOfPayments').value && !this.terms.get('scheduledPaymentAmount').value) {
      return
    }

    this.schedule = []
    this.scheduleTotals = null
    this.terms.patchValue({ changedField: changedField })

    if (changedField == 'apr' || changedField == 'firstScheduledPaymentDueDate' || changedField == 'numberOfPayments' || changedField == 'subjectToFinance' || changedField == 'paymentCycle' || changedField == 'saleDate' || changedField == 'taxRate' || changedField == 'modelYear' || changedField == 'excludeAmort' || changedField == 'tradeInAllowance' || changedField == 'taxMethod') {
      this.terms.patchValue({ scheduledPaymentAmount: 0 })
    }
    if (changedField == 'scheduledPaymentAmount' || changedField == 'salePrice') {
      this.terms.patchValue({ numberOfPayments: 0 })
    }
    if (changedField == 'salePrice') {
      this.terms.patchValue({ subjectToFinance: 0 })
    }
    if (changedField == 'saleDate') {
      this.terms.patchValue({ modelYear: 0 })
      this.setFirstScheduledPaymentDueDate()
    }

    if (!this.terms.get('numberOfPayments').value && !this.terms.get('scheduledPaymentAmount').value || !this.terms.get('subjectToFinance').value || !this.terms.get('modelYear').value) {
      return
    }

    let payload = this.terms.value

    this.terms.markAsPristine()
    this.calculating = true
    this.master.patch(`engine`, { term: payload }, res => {
      this.calculating = false
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.schedule = res.data.result.schedule
      this.scheduleTotals = res.data.result.scheduleTotals
      if (!this.scheduleTotals){
        return
      }
      this.terms.patchValue({
        apr: this.scheduleTotals.apr * 100,
        numberOfPayments: this.scheduleTotals.numberOfPayments,
        scheduledPaymentAmount: parseFloat(this.scheduleTotals.scheduledPaymentAmount),
        finalOddLastPayment: this.scheduleTotals.finalOddLastPayment
      })

    })
  }

  public setFirstScheduledPaymentDueDate() {
    let firstDueDate = null
    let paymentCycle = this.terms.get('paymentCycle').value
    let saleDate = this.terms.get('saleDate').value
    switch (paymentCycle.toLowerCase()) {
      case 'weekly': // once a week
        firstDueDate = moment(saleDate).add(1, 'week').format('yyyy-MM-DD')
        break
      case 'bi-weekly': // every two weeks
      case 'biweekly':
        firstDueDate = moment(saleDate).add(2, 'weeks').format('yyyy-MM-DD')
        break
      case 'semi-monthly': // twice a month
      case 'semimonthly':
        firstDueDate = moment(saleDate).add(15, 'days').format('yyyy-MM-DD')
        break
      case 'bi-monthly': // every two months
        firstDueDate = moment(saleDate).add(2, 'months').format('yyyy-MM-DD')
        break
      case 'quaterly': // every three months
        firstDueDate = moment(saleDate).add(3, 'months').format('yyyy-MM-DD')
        break
      case 'annually':
        firstDueDate = moment(saleDate).add(1, 'year').format('yyyy-MM-DD')
        break
      default: // once a month (i.e. monthly)
        firstDueDate = moment(saleDate).add(1, 'month').format('yyyy-MM-DD')
        break
    }

    if (!firstDueDate) { // no change?
      return
    }
    this.terms.patchValue({ firstScheduledPaymentDueDate: firstDueDate })
  }

}
