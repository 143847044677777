import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MasterService } from 'src/app/services/master.service';
import { MessageService } from 'src/app/services/message.service';
import * as words from '../../../services/language'

@Component({
  selector: 'gps-inventory-assignment',
  templateUrl: './gps-inventory-assignment.component.html',
  styleUrls: ['./gps-inventory-assignment.component.scss']
})
export class GpsInventoryAssignmentComponent implements OnInit {

  @Input() vehicleId = null
  /** Listen for input commit (enter key) events if further processing is necessary. */
  @Output() refreshEmitter$: EventEmitter<Object> = new EventEmitter()

  // set all words
  public words = words.language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  public gpsInventoryAssignments = [] = []

  constructor(private master: MasterService, private ms: MessageService) { }

  update(): void {
    this.listGPSInventoryAssignments()
  }

  ngOnInit() {
    this.listGPSInventoryAssignments()
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.vehicleId) {
      this.vehicleId = changes.vehicleId.currentValue
      this.listGPSInventoryAssignments()
    }
  }


  public listGPSInventoryAssignments = () => {
    let vehicleIdParam = ''
    if (this.vehicleId && (this.vehicleId > 0)) {
      vehicleIdParam = `?vehicleId=${this.vehicleId}`
    }

    this.master.get(`gpsInventoryAssignments${vehicleIdParam}`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.gpsInventoryAssignments = res.data.GPSInventoryAssignment
    })
  }

  public activateDevice = (data) => {
    let payload = { status: 'Activated', vehicleId: data.vehicleId }
    this.master.put(`gpsDevice/${data.GPSDeviceId}`, { information: payload }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.refreshEmitter$.emit({ message: 'refreshList' })
      this.listGPSInventoryAssignments()

    })
  }

  public deactivateDevice = (data) => {
    let payload = { status: 'Inactive', inventoryAssignmentId: data.id }
    this.master.put(`gpsDevice/${data.GPSDeviceId}`, { information: payload }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.refreshEmitter$.emit({ message: 'refreshList' })
      this.listGPSInventoryAssignments()

    })
  }

  public removeGPSInventoryAssignment = (data) => {
    console.log(data)
    this.master.discard(`gpsInventoryAssignment/${data.id}`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.unassignDevice(data)
    })
  }

  public unassignDevice = (data) => {
    let payload = { status: 'Unassigned', id: data.GPSDeviceId }
    this.master.put(`gpsDevice/${data.GPSDeviceId}`, { information: payload }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.refreshEmitter$.emit({ message: 'refreshList' })
      this.listGPSInventoryAssignments()

    })
  }
}
