import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
//services
import { MasterService } from '../../../services/master.service'
import { MessageService } from '../../../services/message.service';

@Component({
  selector: 'app-reset-change-password',
  templateUrl: './reset-change-password.component.html',
  styleUrls: ['./reset-change-password.component.scss']
})
export class ResetChangePasswordComponent implements OnInit {
  private token: string;
  private type: string;
  private typePassword: string = "password";
  public loading: boolean = false;
  public resetPassForm = new FormGroup({
    password: new FormControl(null, [Validators.required, Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&?¿¡*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&?¿¡*()_+]{7,39}$/)]),
    confirmPassword: new FormControl(null, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]),
  }, {
    validators: Validators.compose([this.compare('password', 'confirmPassword')])
  })
  public userId: number = null;
  get password() { return this.resetPassForm.get('password') }
  get confirmPassword() { return this.resetPassForm.get('confirmPassword') }

  constructor(private master: MasterService, private ms: MessageService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.token = params.get('token')
      this.type = params.get('type')

      //only occurs when a user who has registered an account doesn't have a password set already
      this.master.get(`verify?type=${this.type}&token=${this.token}`, res => {
        this.token = res.data.token
        this.master.token = res.data.token
        this.userId = res.data.userId
        localStorage.setItem('token', res.data.token)
      })
    })
  }

  public compare(password: string, confirmPassword: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let password1 = group.controls[password];
      let password2 = group.controls[confirmPassword];
      if (!password2.value && !password1.value) {
        return
      }
      if (password1.value !== password2.value) {
        return {
          comparisonResult: "Error: Passwords must match."
        };
      }
      return {};
    }
  }

  changeTypePassword = (): void => {
    if (this.typePassword == 'text') {
      this.typePassword = 'password'
      return
    }

    this.typePassword = 'text'
  }

  resetPassword(): void {
    if (!this.resetPassForm.valid) {
      return
    }
    this.master.patch('users/reset', { information: { password: this.resetPassForm.get('password').value, userId: this.userId, token: this.token } }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: 'An unknown error has occurred.' })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "warning", text: res.data.error })
        return
      }

      this.master.reset() // reset
      localStorage.setItem('token', res.data.token)
      this.master.token = res.data.token
      // this.store.userAccount = {}   
      this.router.navigate([`dashboard`])
    })
  }
}