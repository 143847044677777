import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
// services
import { MessageService } from '../../../../services/message.service'
import { MasterService } from '../../../../services/master.service'
// external
import * as languageLibrary from '../../../../services/language'
import { ActivatedRoute } from '@angular/router';
import { SUPPORTED_IMAGE_TYPES, SUPPORTED_IMAGE_EXTENSIONS } from 'src/app/constants/file';

@Component({
  selector: 'inventory-images-list',
  templateUrl: './inventory-images-list.component.html',
  styleUrls: ['./inventory-images-list.component.scss']
})
export class InventoryImagesListComponent implements OnInit {
  /*
    * Variables
    */
  // define the inventoryId
  @Input() inventoryId: number = 0
  // permissions
  @Input() permissions = []
  // save the language
  @Input() language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // Emit to the parent
  @Output() emitterInformation$: EventEmitter<Object> = new EventEmitter()
  // set all words
  public words = languageLibrary.language
  // images array
  public images = []
  public files = []
  public imagesToUpload = []
  //  define the image selected
  public imageSelected: Object = {}
  // define expanded
  public expanded: boolean = true
  // loading state
  public loading: boolean = false
  public selectedId: number = 0;
  // Group of inputs
  public information: FormGroup;
  public showUploadButton: boolean;
  public allFiles: Array<Object> = []

  get caption() { return this.information.get('caption') }
  get description() { return this.information.get('description') }
  get imageList() {
    return this.information.controls['imageList'] as FormArray;
  }
  get extensions() { return SUPPORTED_IMAGE_EXTENSIONS.join(',') }

  /*
  * functions
  */
  public async sort() {
    let ids = this.images.map(image => image['id'])
    this.master.patch(`inventory/${this.inventoryId}/upload`, { information: ids }, res => {
      if (!res) {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return;
      }
      if (res.status !== 200) {
        // ! in case error
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
      }

      this.images = res.data.uploads;
      this.resetInformation();
    })
  }

  public drop(event: CdkDragDrop<string[]>) {
    if (event.previousIndex == event.currentIndex) {
      return
    }
    moveItemInArray(this.images, event.previousIndex, event.currentIndex);
    this.sort()
  }

  //  cut base64 string
  public subStringB64 = (word: string): string => {
    let index = word.indexOf(',')
    return word.substring(index + 1)
  }
  // resize the base64 image
  public async resizeImage(image, maxWidth, maxHeight) {
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.onload = () => {
        let canvas = (document.createElement('canvas') as HTMLCanvasElement)
        // size
        const MAX_WIDTH = maxWidth
        const MAX_HEIGHT = maxHeight
        let width = img.width
        let height = img.height

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width
            width = MAX_WIDTH
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height
            height = MAX_HEIGHT
          }
        }
        canvas.width = width
        canvas.height = height
        let cx = canvas.getContext('2d')

        cx.drawImage(img, 0, 0, width, height)
        resolve(this.subStringB64(canvas.toDataURL()))
      }
      img.onerror = reject
      img.src = image
    })
  }
  /*
   * life cycles
   */
  constructor(private ms: MessageService, public master: MasterService, public route: ActivatedRoute, private fb: FormBuilder) {
    this.information = this.fb.group({
      imageList: this.fb.array([])
    })
  }

  ngOnInit() {
    if (this.route.snapshot.params["id"] === 'add') {
      return
    }

    this.getImages()
  }

  public setDefaultImage(fileURL: string) {
    this.emitterInformation$.emit({ message: 'setDefaultImage', fileURL })
  }

  public addImages(images) {
    this.imageList.clear()
    for (let i = 0; i < images.length; i++) {
      if (images[i].sortOrder == 1) {
        this.setDefaultImage(images[i].url)
      }
      this.imageList.push(this.fb.group({
        id: [images[i].id, [Validators.required]],
        caption: [images[i].caption],
        description: [images[i].description],
        url: [images[i].url],
        markForDeletion: false
      }))
    }
  }
  public getImages() {
    this.master.get(`inventory/${this.inventoryId}/upload`, (res) => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.images = res.data.uploads
      this.resetInformation()
    })
  }

  public createImages(imageData: any) {
    if (imageData.length == 0 || this.loading == true) {
      return
    }

    this.loading = true
    this.master.post(`inventory/${this.inventoryId}/upload`, { information: imageData }, res => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.images = res.data.uploads
      this.loading = false
      document.getElementById('btn-close-modal-upload-image').click()
      this.showUploadButton = false
      this.sort()
      this.resetInformation()
    })
  }

  public modifyImage() {
    this.setDefaultImage('')
    let modifiedImages = this.information.controls['imageList']['controls']
      .filter(image => image.pristine == false
      ).map(image => { return { id: image.value.id, caption: image.value.caption, description: image.value.description, markForDeletion: image.value.markForDeletion } })

    if (modifiedImages.length == 0) {
      document.getElementById('btn-close-modal-modify-image').click()
      return
    }

    this.master.put(`inventory/${this.inventoryId}/upload`, { information: modifiedImages }, res => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.images = res.data.uploads
      document.getElementById('btn-close-modal-modify-image').click()
      this.sort()
      this.resetInformation()
    })
  }

  public setId(id: number) {
    this.selectedId = id
  }

  public deleteImage() {
    this.setDefaultImage('')
    this.master.discard(`inventory/${this.inventoryId}/upload/${this.selectedId}`, res => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      (document.getElementById('btn-close-modal-delete-image').click())
      this.images = res.data.uploads
      this.sort()
      this.resetInformation()
    })
  }

  // get image and convert to base64
  public async uploadImages(e) {
    let reader = []
    let context = this
    let counter = this.images.length > 0 ? this.images.length + 1 : 1
    context.files = e.target.files
    for (let index = 0; index < context.files.length; index++) {
      this.allFiles.push(context.files[index])

      // check if file is invalid
      if (!SUPPORTED_IMAGE_TYPES.includes(context.files[index].type)) {
        this.ms.sendMessage("alert", { type: "danger", text: 'Invalid image format' });
        return
      }

      // * format valid
      reader[index] = new FileReader();
      reader[index].readAsDataURL(context.files[index]);
      reader[index].onload = async function (event) {
        context.imagesToUpload.push({
          'name': context.files[index].name,
          'caption': context.files[index].name,
          'fileSize': context.files[index].size,
          'fileType': context.files[index].type,
          'module': "Inventory",
          'sortOrder': counter++,
          'inventoryId': context.inventoryId,
          '1024': await context.resizeImage(reader[index].result, 1024, 768),
          '480': await context.resizeImage(reader[index].result, 480, 360),
          'sizeThumb': 480,
          'url': event.target.result,
        })
      }
    }

    if (this.allFiles.length == this.imagesToUpload.length) {
      this.showUploadButton = true
    }
  }

  public deleteImageToUpload(i) {
    this.imagesToUpload.splice(i, 1)
  }
  // select a image
  public resetInformation() {
    this.information.reset()
    this.allFiles = []
    this.addImages(this.images)
    this.imageSelected = null
    this.selectedId = null
    this.imagesToUpload = []
    this.files = []
  }
}
