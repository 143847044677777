import { Component, OnInit } from '@angular/core';
// services
import { ActivatedRoute } from '@angular/router';
import { MasterService } from '../../../../services/master.service';
import { MessageService } from '../../../../services/message.service'
import * as languageLibrary from '../../../../services/language'

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  /*
  * variables
  */
 // permissions
 public permissions: Object[] = []
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  //  define the data audit log
  public data: Object[] = []
  // save all data audit log
  public dataComplete: Object[] = []
  // define the rows ordered
  public order = { isAscend: true, type: '' }
  // save the pagination settings
  public pagination = { index: 0, rowsByPage: 10, pages: [{ numPage: 0, start: 0, end: 8 }] }
  // define if is loading
  public loading: boolean = false
  // define the contact type
  public type: string = ''
  // define the contact ID
  public id: string = ''
  // table headers
  public headers: Object[] = [
    { name: 'batchId', param: 'batchId' },
    { name: 'transactionSource', param: 'transactionSource' },
    { name: 'corporateId', param: 'corporateId' },
    { name: 'contactId', param: 'contactId' },
    { name: 'userId', param: 'userId' },
    { name: 'auditClass', param: 'auditClass' },
    { name: 'inventoryId', param: 'inventoryId' },
    { name: 'IPAddress', param: 'IPAddress' },
    { name: 'amount', param: 'amount' },
    { name: 'primaryKey', param: 'primaryKey' },
    { name: 'tableName', param: 'tableName' },
    { name: 'fieldName', param: 'fieldName' },
    { name: 'oldValue', param: 'oldValue' },
    { name: 'newValue', param: 'newValue' },
    { name: 'created At', param: 'createdAt' },
    { name: 'updated At', param: 'updatedAt' }
  ]
  /*
  * functions
  */

  /*
 todo: pagination and rows per view functions
 */
  // sort the users registers
  public sort = (type: string): void => {
    if (type == this.order.type) {
      this.order.isAscend = !this.order.isAscend
    } else {
      this.order.type = type
      this.order.isAscend = true
    }
    if (this.order.isAscend) {
      // ascend
      this.data = this.data.sort((a, b) => a[type] > b[type] ? 1 : -1)
    } else {
      // descend
      this.data = this.data.sort((a, b) => a[type] > b[type] ? -1 : 1)
    }
  }
  // change num page view
  public changePage = (page: number): void => {
    if (page == -1) {
      // back
      if (this.pagination.index >= 1) {
        this.pagination.index = this.pagination.index - 1
      }
    } else {
      // next
      if (this.pagination.index < this.pagination.pages.length - 1) {
        this.pagination.index = this.pagination.index + 1
      }
    }
  }
  // create a pagination
  public newPagination = (registers: Object[]): void => {
    this.pagination.pages = []
    this.pagination.index = 0
    // this.pagination.rowsByPage=registers.length
    // let registrosPerPage=2
    for (let index = 0; index < (registers.length / this.pagination.rowsByPage); index++) {
      this.pagination.pages.push({ numPage: index, start: this.pagination.rowsByPage * index, end: (this.pagination.rowsByPage * (index + 1)) })
    }

  }
  // change the rows per page
  public changeRows = (): void => {
    this.pagination.index = 0
    this.newPagination(this.data)
  }
  // filter by word
  public search = (e): void => {
    if (e.key == 'Enter') {
      let word = (document.getElementById('search') as HTMLInputElement).value;
      this.data = this.dataComplete.filter(el => ((el['newValue']).includes(word) ||
        (el['batchId']).toString().includes(word) || (el['id']).toString().includes(word) ||
        (el['tableName']).includes(word) || (el['fieldName']).includes(word) ||
        (el['primaryKey']).toString().includes(word) || (el['oldValue']).includes(word)

        ))

      if (this.data.length >= 1) {
        this.pagination.rowsByPage=this.data.length
        // exist rows
        this.newPagination(this.data)
      } else {
        this.pagination.rowsByPage=10
        this.ms.sendMessage("alert", { type: "info", text: this.words[this.language][ 'noFound' ] });
      }
    }
  }



  public researchFilter=(e):void=>{

    if(e.key=='Enter'){
      this.getAuditLogInfo()
    }
  }


  // get the audit log info
  public getAuditLogInfo = (): void => {
    this.loading = true
    this.master.post('getAuditLog', { contactId: this.id, type: this.type }, res => {
      if (res) {
        if (res.status == 200) {
          // * success
          this.data = res.data.auditlogs
          this.dataComplete=res.data.auditlogs
          if(this.data.length>0){
            this.newPagination(this.data)
          }else{
            // ! data not found
          this.ms.sendMessage("alert", { type: "info", text: this.words[this.language][ 'noFound' ] });
          }
          this.loading = false
        } else {
          // ! send message in case error
          this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        }
      } else {
        // in case API no response
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language][ 'apiNoResponse' ] });
      }
    })
  }

    // todo: listen to output of navbar
public listenerNav=(e):void=>{

  switch (e['message']) {
    case 'changeLanguage':
      this.language=e['value']
      break;
    case 'setPermissions':
      // permissions on user list
      this.permissions = e.permissions

    break;

    default:
      break;
  }
}

  /*
  * Life cycles
  */
  constructor(private route: ActivatedRoute, private master: MasterService, private ms: MessageService) {
    this.type = this.route.snapshot.params['type']
    this.id = this.route.snapshot.params['id']
    this.getAuditLogInfo()
  }

  ngOnInit() {


  }


}
