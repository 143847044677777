import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
// services
import { MessageService } from "../../../../services/message.service";
import { MasterService } from "../../../../services/master.service";
import * as languageLibrary from '../../../../services/language'
import { StoreService } from "src/app/services/store.service";
import { PAYMENT_CYCLES } from '../../../../constants/payments'


@Component({
    selector: "auto-insurance",
    templateUrl: "./auto-insurance.component.html",
    styleUrls: ["./auto-insurance.component.scss"],
})
export class AutoInsuranceComponent implements OnInit {
    public id: number = null
    public status: string = null;
    public section = 0
    public showTabs: boolean = false
    public policyToCopy: [] = [];
    public cpiPolicyInfo: [] = [];
    public outsideInsurancePolicyInfo: [] = [];
    public saleId: number;
    public saleInfo: Object = Array;
    public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
    public words = languageLibrary.language
    public inactivePolicies: Object[] = [];
    public idForAction: Number = 0;
    // todo: listen to output of navbar
    public listenerNav = (e): void => {
        switch (e['message']) {
            case 'changeLanguage':
                this.language = e['value']
                break;
            default:
                break;
        }
    }
    // todo: listen to output of navbar
    public listenerChildrens = (e): void => {
        switch (e['message']) {
            case 'getAutoInsurance':
                this.getAutoInsuranceDetails()
                break;
            default:
                break;
        }
    }

    constructor(
        private route: ActivatedRoute,
        private ms: MessageService,
        private master: MasterService,
        private store: StoreService,
        private location: Location
    ) { }

    ngOnInit() {
        this.saleId = parseInt(this.route.snapshot.params['saleId'])
        if (isNaN(this.saleId) || (this.saleId <= 0)) {
            this.saleId = 0
            return
        }

        this.getAutoInsuranceDetails()
    }

    public setDetails(insuranceDetail) {
        this.policyToCopy = insuranceDetail
    }

    public setId(id) {
        this.idForAction = id
    }

    public duplicateCPIPolicy() {
        this.cpiPolicyInfo = null
        this.outsideInsurancePolicyInfo = null
        document.getElementById("btn-close-modal-duplicate").click();
        this.policyToCopy['id'] = null
        this.policyToCopy['status'] = 'Duplicate - Not Saved'
        if (this.policyToCopy['cpi'] == 1) {
            this.cpiPolicyInfo = this.policyToCopy
            this.section = 0
            return
        }
        this.outsideInsurancePolicyInfo = this.policyToCopy
        this.section = 1
    }

    public getAutoInsuranceDetails() {
        this.resetInformation()
        this.master.get(`sales/${this.saleId}/insurance`, res => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            if (!res.data.saleInfo) {
                return
            }
            this.setSaleInformation(res.data.saleInfo)
            
            if (res.data.activePolicies.length >= 1) {
                this.showTabs = false
                if (res.data.activePolicies[0].cpi == 1) {
                    this.cpiPolicyInfo = res.data.activePolicies[0]
                    this.cpiPolicyInfo['expectedPayments'] = res.data.expectedPayments
                    this.section = 0
                } else {
                    this.outsideInsurancePolicyInfo = res.data.activePolicies[0]
                    this.outsideInsurancePolicyInfo['expectedPayments'] = res.data.expectedPayments
                    this.section = 1
                }
            }
            this.inactivePolicies = res.data.inactivePolicies
        })
    }

    public resetInformation() {
        this.showTabs = true
        this.saleInfo = null
        this.cpiPolicyInfo = null
        this.outsideInsurancePolicyInfo = null
        this.inactivePolicies = null
        this.idForAction
    }

    public setSaleInformation(saleInfo) {
        const salesTerm = saleInfo.saleTerms.find(saleTerm => saleTerm.active == true)
        this.saleInfo = {
            id: saleInfo.id,
            contactId: saleInfo.saleBuyers[0].contact.id,
            saleDate: saleInfo.saleDate,
            buyerName: saleInfo.saleBuyers[0] ? saleInfo.saleBuyers[0].contact.lastName + ", " + saleInfo.saleBuyers[0].contact.firstName : null,
            saleStatus: saleInfo.saleStatus.name,
            stockNumber: saleInfo.saleVehicles[0] ? saleInfo.saleVehicles[0].inventory.stockNumber : null,
            saleVehicle: saleInfo.saleVehicles[0] ?
                saleInfo.saleVehicles[0].inventory.vehicle.modelYear + ", " + saleInfo.saleVehicles[0].inventory.vehicle.make + ", " + saleInfo.saleVehicles[0].inventory.vehicle.model : null,
            loanPayoff: salesTerm !== null ? salesTerm.loanPayoff : null,
            scheduledPaymentAmount: salesTerm !== null ? salesTerm.schedulePmtAmt : null,
            salePaymentCycle: salesTerm.paymentCycle !== null ? salesTerm.paymentCycle.name : null,
            firstScheduledPmtDue: salesTerm.firstScheduledPmtDue
        }
    }
}
