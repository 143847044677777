/*
* libraries
*/
import { Component, OnInit,Output,EventEmitter, Input,SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from '../../services/message.service'
import { StoreService } from '../../services/store.service'
import { SecurityService } from '../../services/security.service'
import * as words from '../../services/language'

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  /*
  * Variables
  */

  //
  @Output() sideNavMessage$:EventEmitter<object>=new EventEmitter()

  // permissions
  @Input() permissions: Object[] = []
  
  // save the language
  @Input() language: string = 'EN'
  
  // users menu
  public displayMenu: boolean = true
  
  // corporation setup
  public displayMenu2: boolean = true
  
  //
  public displaySalesMenu: boolean = true
  
  // set all words
  public  words = words.language
  
  //
  public image = {}

  // A handle to navbar event messages so we can unsubscribe later.
  private navbarWatcher: any = null
  
  /*
  * functions
  */

  /*
    ------------------------
    todo: open payment view in another window
    / ------------------------
  */
  public openPayment=()=>{
    let index = window.location.href.indexOf('#/')
    let route = window.location.href.substring(0,index+2)
    window.open(`${route}collections`, '_blank')
  }

  // todo: listen to output of navbar
  public listenerNav = (e): void => {
    if (!e || !e['message']) {
      return
    }

    switch (e['message']) {
      case 'changeLanguage':
        this.language = e['value']
        break
      case 'setPermissions':
        // permissions on user list

        this.permissions = e['permissions'];
        // image
        // if(this.store.userAccount['corporateLogo'].corporateLogoURL){
        //   this.image = { corporateLogoURL: this.store.userAccount['corporateLogo'].corporateLogoURL, display: this.store.userAccount['corporateLogo'].displayLogoInternal == 0 ? false : true }
        // }else{
        //   this.image={}
        // }
        break
      case 'reloadLogo':
        this.image = e.value
        this.store.userAccount['corporateLogo']=e.value
        break
    }
  }

  /*
  * Life cycles
  */
  constructor(private ms: MessageService, private router: Router, private store: StoreService, private security: SecurityService) {}

  ngOnInit() {
    if (this.store && this.store.userAccount) {
      const user = this.store.userAccount

      // permissions on user list
      if (user['permissions']) {
        this.permissions = this.store.userAccount['permissions']
      }
      
      // image
      this.image={}
      if (user['corporateLogo'] && user['corporateLogo'].corporateLogoURL) {
        this.image = { corporateLogoURL: user['corporateLogo'].corporateLogoURL, display: (user['corporateLogo'].displayLogoInternal == 0) ? false : true }
      }
    }

    // listener
    this.navbarWatcher = this.ms.channelComponents$.subscribe(res => {
      if(res.message=='reloadLogo') {
        this.image = res.value
        this.store.userAccount['corporateLogo']=res.value
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes && changes.permissions && changes.permissions.currentValue) {
      this.permissions = changes.permissions.currentValue
    }
  }

  ngOnDestroy() {
    if (!this.navbarWatcher) {
      return
    }

    this.navbarWatcher.unsubscribe()
  }
}
