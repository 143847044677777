import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from '../../../../services/message.service'
import { MasterService } from '../../../../services/master.service'
import { StoreService } from '../../../../services/store.service'
import * as languageLibrary from '../../../../services/language'
import { Subject } from 'rxjs';

@Component({
  selector: 'app-corporations-detail',
  templateUrl: './corporations-detail.component.html',
  styleUrls: ['./corporations-detail.component.scss']
})
export class CorporationsDetailComponent implements OnInit {
  /*
* Variables
*/
  //  sections pending save
  private sections = [
    { name: 'generalInformation', savePending: false, btn: 'btn-save-generalInformation' },
    { name: 'Addresses', savePending: false, btn: 'btn-save-addresses_new' },
    { name: 'communications', savePending: false, btn: 'btn-save-communications' },
    { name: 'inventorySetup', savePending: false, btn: 'btn-save-inventorySetup' },
    { name: 'buyersGuide', savePending: false, btn: 'btn-save-buyersGuide' },
  ]

  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  
  // set all words
  public words = languageLibrary.language
  
  //  data analize
  public data: Object = {}
  
  // define the corporate type
  public type: string = ''
  
  // define the corporate id
  public id: number = 0
  
  // define contactId
  public contactId: number = 0
  
  // unique identity of the dealer to display, if any.
  public dealershipId: number = 0;
  
  // define if is loading
  public loading: boolean = false
  
  // permissions
  public permissions: Object[] = []
  
  // define if some field was updated
  public isGhost: boolean = false
  
  // error type
  public error: string = ''

  public inventoryByCorp
  
  public detailInfo: [];

  public addressChangedEvent: Subject<Object> = new Subject();
  /*
 * Functions
 */

  // todo: listen to output of navbar
  public listenerNav = (e): void => {
    switch (e['message']) {
      case 'changeLanguage':
        this.language = e['value']
        break;
      case 'setPermissions':
        // permissions on user list
        this.permissions = e.permissions
        // if not have permissions, return to account settings
        /*
        if (this.permissions[2]['childrens'][0].permissions.includes('view')) {
          // this.chargeData(this.type, this.id, true)
        }
        */
        break;

    }
  }

  public listenerChildrens = (e): void => {
    switch (e.message) {
      case 'setIds':
        this.contactId = e.contactId || 0
        this.id = e.id || 0
        break
        case 'setDetailInfo':
          this.detailInfo = e.detailInfo
          break
    }
  }

  /**
   * Handle address add/change events
   * @param address 
   */
  public addressChanged = (address): void => {
    this.addressChangedEvent.next(address)
  }
  /*
* Life cycles
*/

  constructor(private route: ActivatedRoute, private store: StoreService, private ms: MessageService, private master: MasterService) {}

  ngOnInit() {
    this.type = this.route.snapshot.params['type']

    let identity = parseInt(this.route.snapshot.params['id'])
    if (!identity || isNaN(identity) || (identity <= 0)) {
      identity = 0
    }

    this.id = identity

    let dealerId = parseInt(this.route.snapshot.queryParams['dealer'])
    if (!dealerId || isNaN(dealerId) || (dealerId <= 0)) {
      dealerId = 0
    }

    this.dealershipId = dealerId

    if (isNaN(this.id) || (this.id <= 0)) {
      this.id = 0
    }

    if (isNaN(this.contactId) || (this.contactId <= 0)) {
      this.contactId = 0
    }

    // normal
    if (!this.store.userAccount['permissions']) {
      return
    }

    // permissions on user list
    this.permissions = this.store.userAccount['permissions']

    // if not have permissions, return to account settings
    /*
    if (!this.permissions[2]['childrens'][0].permissions.includes('view')) {
      return
      // this.chargeData(this.type, this.id, true)
    }
    */
  }

}
