/*
* libraries
*/
import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
// services
import { MasterService } from '../../services/master.service'
import { MessageService } from '../../services/message.service';
import { StoreService } from '../../services/store.service'
import * as words from '../../services/language'
import { LANGUAGES } from "../../constants/general";

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

    /*
    * Variables
    */
    // save the language
    public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
    public image: Object;
    // define the languages
    public languages: Object[] = LANGUAGES
    @Input() sideBar = true
    @Output() navMessage$: EventEmitter<object> = new EventEmitter()
    public words = words
    public languageSelect: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
    public user = { firstName: '', lastName: '', corpID: '', corpName: '' }
    public lots = []
    public lotSelected = ''

    // A handle to navbar event messages so we can unsubscribe later.
  private navbarWatcher: any = null
    /*
    * functions
    */

    /*
    ------------------------
     todo: change Language
     @param language:string=language selected
    / ------------------------
    */
    public changeLanguage = (language: string): void => {
        this.languageSelect = language
        const returnedValue = this.languages.find(lang => lang['value'] == language)
        this.image = returnedValue['image']
        localStorage.setItem('language', this.languageSelect)
        this.navMessage$.emit({ message: 'changeLanguage', value: this.languageSelect })
        this.ms.channelComponents$.emit({ message: 'changeLanguage', value: this.languageSelect })
    }

    /*
    ------------------------
     todo: log out
    / ------------------------
    */
    public logout = (): void => {
        this.master.discard('session', res => {
            // No concern for API errors here. Still 
            // want to clear the user token and 
            // redirect to login.
            localStorage.clear()
            this.master.reset()
            this.store.userAccount = {}
            this.router.navigate(['/login']);
        })
    }

    /*
    ------------------------
     todo: get user data
    / ------------------------
    */
    public readUser = (): void => {
        this.master.get(`getUserAccount?lotId=${this.lotSelected}`, res => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: "API no response" })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            // set data to store service
            this.store.userAccount = res['data']

            let route = window.location.hash
            let page = route.substring(2)
            this.validPermissions(this.store.userAccount['permissions'], page)
            this.determineCapabilities(res['data']['permissions'])

            // send permissions for all components
            this.ms.channelComponents$.emit({
                message: 'setPermissions',
                permissions: this.store.userAccount['permissions'],
                integrations: this.store.userAccount['integrationsPermissions'],
                capabilities: this.store.userAccount['capabilities'],
                page: page
            })

            this.navMessage$.emit({
                message: 'setPermissions',
                value: this.languageSelect,
                permissions: this.store.userAccount['permissions'],
                integrations: this.store.userAccount['integrationsPermissions'],
                capabilities: this.store.userAccount['capabilities']
            })

            // set data to object userInformation
            this.user = { ...this.store.userAccount['user'] }

            // send user account
            this.ms.channelComponents$.emit({ message: 'getUserAccount', data: this.store.userAccount['user'] })
            this.navMessage$.emit({ message: 'getUserAccount', data: this.store.userAccount['user'] })

            this.changeLanguage(this.user['languagePreference'])

            this.lots = null
            if (this.user && this.user['lots']) {
                this.lots = this.user['lots']
            }
            this.determineLot()
        })
    }

    /**
     * 
     * @param permissions 
     * @returns 
     */
    private determineCapabilities = (permissions: any[]): void => {
        const capabilities = {}
        if (!permissions || (permissions.length <= 0)) {
            this.store.userAccount['capabilities'] = capabilities
            return
        }
   
        for (let index = 0; index < permissions.length; index++) {
            const group = permissions[index]

            if (!group || !group.childrens || (group.childrens.length <= 0)) {
                continue
            }

            for (let jndex = 0; jndex < group.childrens.length; jndex++) {
                const permission = group.childrens[jndex]

                if (!permission.permissions || !Array.isArray(permission.permissions)) {
                    continue
                }

                const module = group.name.trim().toUpperCase().replace(/\s+/g, '_')
                const name = permission.name.trim().toUpperCase().replace(' & ', ' ').replace(', AND', ', ').replace('(S)', 'S')
                const names = name.split(',')

                for (const item of names) {
                    const resource = item.trim().replace(/\s+/g, '_')
                    capabilities[`${module}:${resource}_VIEW`] = permission.permissions.includes('view')
                    capabilities[`${module}:${resource}_ADD`] = permission.permissions.includes('add')
                    capabilities[`${module}:${resource}_UPDATE`] = permission.permissions.includes('edit')
                    capabilities[`${module}:${resource}_DELETE`] = permission.permissions.includes('delete')
                }
            }
        }
        
        this.store.userAccount['capabilities'] = capabilities
    }

    /*
    ------------------------
     todo: check permissions in each page
     @param permissions: array of permissions
     @param page: current page
    / ------------------------
    */
    public validPermissions = (permissions: any[], page: string) => {
        if (!permissions || permissions.length <= 0) {
            window.history.back()
            return
        }

        // users
        if (page.indexOf('users') >= 0 && !permissions[0].childrens[0].permissions.includes('view')) {
            window.history.back()
        }

        let contact = page.split('/')
        // contacts
        if (page.indexOf('contacts') > 0 && contact.length == 2 && !permissions[1].childrens[0].permissions.includes('view')) {
            window.history.back()
        }

        // contact detail
        if (page.indexOf('contacts') > 0 && contact.length == 3 && !permissions[1].childrens[0].permissions.includes('edit')) {
            window.history.back()
        }

        // corporations
        if (page === 'corporations' && !permissions[2].childrens[0].permissions.includes('view')) {
            window.history.back()
        }

        // corporations detail
        if (page.indexOf('corporations/') >= 0 && !permissions[2].childrens[0].permissions.includes('edit')) {
            window.location.href = "#/profile"
        }

        // permissions
        if (page === 'permissions' && !permissions[3].childrens[0].permissions.includes('view')) {
            window.history.back()
        }

        // inventory
        if (page === 'inventory' && !permissions[4].childrens[0].permissions.includes('view')) {
            window.history.back()
        }

        // inventory detail
        if (page.indexOf('inventory/') >= 0 && !permissions[4].childrens[0].permissions.includes('edit')) {
            window.location.href = "#/profile"
        }

        // reports
        if (page.indexOf('reports/') >= 0 && !permissions[5].childrens[0].permissions.includes('view')) {
            window.location.href = "#/profile"
        }
    }
    /*
    ------------------------
     todo: log out
     @param lot:current lot
    / ------------------------
    */
    public changeLot = (lot: string): void => {
        // save lot in local storage
        localStorage.setItem('lot', lot)
        this.store.lotSelected = lot

        // page in
        let route = window.location.hash
        let page = route.substring(2)

        // emit message
        this.navMessage$.emit({ message: 'changeLot', lot: lot, page: page })
        this.ms.channelComponents$.emit({ message: 'changeLot', lot: lot, page: page })
    }

    /**
     * Determine the current lot based on what is available.
     */
    private determineLot = (): void => {
        if (!this.lots || (this.lots.length <= 0)) {
            localStorage.removeItem('lot')
            this.store.lotSelected = null
            return
        }

        if (localStorage.getItem('lot') === null) {
            // ! in case not exist in localstorage
            this.lotSelected = this.lots[0].id
            localStorage.setItem('lot', this.lotSelected)
            this.changeLot(this.lotSelected)
            return
        }

        // ! in case exist localstorage
        this.lotSelected = localStorage.getItem('lot')
        
        const exist = this.lots.some(el => el['id'] == this.lotSelected)
        if (!exist) {
            this.lotSelected = this.lots[0].id
        }

        this.changeLot(this.lotSelected)
    }

    /*
    * life cicles
    */
    constructor(private master: MasterService, private ms: MessageService, private store: StoreService, private router: Router) {
        // set data
        if (!this.store || !this.store.userAccount || !this.store.userAccount['user']) {
            this.readUser()
            return
        }

        this.user = this.store.userAccount['user']
        this.changeLanguage(localStorage.getItem('language') ? localStorage.getItem('language') : this.user['languagePreference'])

        this.lots = null
        if (this.user && this.user['lots']) {
            this.lots = this.user['lots']
        }

        let route = window.location.hash
        let page = route.substring(2)
        this.validPermissions(this.store.userAccount['permissions'], page)
        this.determineLot()
    }

    ngOnInit() {
        // listener
        this.navbarWatcher = this.ms.channelComponents$.subscribe(res => {
            if (res.message == 'updateInformation') {
                this.user.firstName = res.data.firstName
                this.user.lastName = res.data.lastName
                this.user['phone'] = res.data.phone
                this.store.userAccount['user'] = { ...this.user }
                return
            }

            if (res.message == 'updateLotList') {
                this.lots = res.lots
                this.store.userAccount['user'].lots = this.lots
                this.determineLot()
                return
            }
        })
    }

    ngOnDestroy() {
        if (!this.navbarWatcher) {
          return
        }
    
        this.navbarWatcher.unsubscribe()
      }
}
