import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, Injector } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MasterService } from '../../../../../../services/master.service'
import { MessageService } from '../../../../../../services/message.service'
import * as languageLibrary from '../../../../../../services/language'
import { token } from '../roles.component';
import { DynamicService } from 'src/app/services/dynamic.service';

@Component({
  selector: '[role-businessContact]',
  templateUrl: './role-businessContact.component.html',
  styleUrls: ['./role-businessContact.component.scss']
})

export class BusinessContactComponent implements OnInit {
  public contactId: number = 0
  public contactRoleId: number = 0
  public words = languageLibrary.language
  public language: string = 'EN'
  // table headers
  public headers: Object[] = [
    { name: 'title', param: 'title' },
    { name: 'firstName', param: 'firstName' },
    { name: 'lastName', param: 'lastName' },
    { name: 'phoneNumber', param: 'phoneNumber' },
    { name: 'emailAddress', param: 'emailAddress' },
    { name: 'actions' }]
  public businessContactList: object = {}
  public relationshipId: number = null
  public loading: boolean = false
  public viewDetail: boolean = false
  public auxContact = {}
  public dataIn: string;
  // Group of inputs
  public information = new FormGroup({
    fromContactId: new FormControl(0),
    firstName: new FormControl(null, [Validators.required]),
    lastName: new FormControl(null, [Validators.required]),
    relationship: new FormControl("BusinessContact"),
    title: new FormControl(null),
    phoneNumber: new FormControl(null),
    emailAddress: new FormControl(null),
  }
  )

  // get information of inputs
  get firstName() { return this.information.get('firstName') }
  get lastName() { return this.information.get('lastName') }
  get relationship() { return this.information.get('relationship') }
  get phoneNumber() { return this.information.get('phoneNumber') }
  get emailAddress() { return this.information.get('emailAddress') }

  /*
* functions
*/

  constructor(private master: MasterService, private ms: MessageService, private inject: Injector, private serv: DynamicService) {

  }

  deactivateRole() {
    this.serv.outputFromDynamicComponent({ action: "deactivate", contactRoleId: this.contactRoleId });
  }

  ngOnInit() {
    this.dataIn = this.inject.get(token);
    this.contactId = this.dataIn['contactId']
    this.contactRoleId = this.dataIn['contactRoleId']

    if (!this.contactId) {
      return
    }
    
    this.readRelationships()
  }

  // todo:get data for contact selected
  public getContactBusinessContactDetail = (relationship: Object, openDetail: boolean) => {
    this.loading = true
    this.master.get(`contacts/${relationship['fromContactId']}`, res => {
      this.loading = false
      this.viewDetail = true

      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
      this.auxContact = res.data.contact

      setTimeout(() => {
        const btn = (document.getElementById('contactReferenceDetailModal') as HTMLButtonElement)
        if (btn) {
          btn.click()
        }
      }, 100)
    })
  }

  // clean Fields
  public reset = (): void => {
    this.information.reset()
    this.loading = false
  }

  public createContact = (closeAfter) => {
    this.information.get("relationship").setValue("BusinessContact")
    this.master.post(`contacts/${this.contactId}/relationships`, this.information.value, res => {
      if (!res) {
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.readRelationships()
      const btn = (document.getElementById('btn-cancel-businessContacts-new') as HTMLButtonElement)
      if (btn && closeAfter) {
        btn.click()
      }
      this.reset()

    })
  }

  // delete businessContact
  public discard = (): void => {
    this.loading = true
    this.master.discard(`contacts/${this.contactId}/relationships/${this.relationshipId}`, res => {
      this.loading = false
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.readRelationships()

      let btn = (document.getElementById('close-delete-modal-businessContact') as HTMLButtonElement)
      if (btn) {
        btn.click()
      }

    })
  }

  private readRelationships = (): void => {
    this.master.get(`contacts/${this.contactId}/relationships?relationship=BusinessContact`, (res: { status: number; data: { error: any; relationships: object; }; }) => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
      this.businessContactList = res.data.relationships
      
    })
  }

  filterForCommunication(relationship, searchFor): any[] {
    let relationshipSearchList = relationship.contact.contactCommunications ? relationship.contact.contactCommunications : null
    if (relationshipSearchList.length == 0) { return null }
    return relationshipSearchList.filter(comms => comms.type === searchFor);
  }

  filterForCommunicationModify(relationship, searchFor): any[] {
    let relationshipSearchList = relationship.contact.contactCommunications ? relationship.contact.contactCommunications : null
    if (relationshipSearchList.length == 0) { return null }
    const returnedValue = relationshipSearchList.find(comms => comms.type === searchFor);
    if (typeof returnedValue !== 'undefined') {
      return returnedValue.value
    }
    return null
  }
}
