import { Component, OnInit } from '@angular/core';
import {language} from '../../services/language'
import {MessageService} from '../../services/message.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  /* 
  * Variables
  */
  // enable to sign up section
  signUpEnable:boolean=false
  // 
  word=language
  public image={}
  /* 
  * Functions
  */
  public listenerChildrens = (e): void => {
    switch (e.message) {
      case 'setImage':
        this.image = e.image
        break
        default:
          break
    }
  }
  /* 
  * Life cycles
  */
  constructor(private message:MessageService) { }

  ngOnInit() {
    
    // listen changes of another components
    this.message.channelComponents$.subscribe(res=>{
      if(res.message=='changeSection'){
        this.signUpEnable=res.value
      }
    })
  }



}
