import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
// services
import { MasterService } from "../../services/master.service";
import { MessageService } from "../../services/message.service";
import { StoreService } from "../../services/store.service";
import * as languageLibrary from '../../services/language'
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  /*
  * Variables
  */

  // save all data by type
  public data: Object[] = []

  public user = { firstName: '', lastName: '', corpID: '', corpName: '' }
  
  // define all lots
  public lots = []
  
  // define the lot selected
  public lotSelected = ''
  
  // permissions
  public permissions: Object[] = []
  
  // define if is loading
  public loading: boolean = false
  
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  
  // set all words
  public words = languageLibrary.language
  
  /*
  * functions
  */

  // get data of selected lot
  public changeLot = (lot: string): void => {
    // save lot in local storage
    localStorage.setItem('lot', lot)
    this.store.lotSelected = lot

    // page in
    // let route = window.location.hash    
    // let page = route.substring(2)    

    // emit message
    // this.ms.channelComponents$.emit({ message: 'changeLot', lot: lot, page: page })
  }

  //  create a new vehicle
  public addVehicle = (): void => {
    if (!this.lotSelected) {
      return
    }

    const lotId = parseInt(this.lotSelected)
    if (isNaN(lotId) || (lotId <= 0)) {
      return
    }
    
    this.router.navigate([`/inventory/add`], { queryParams: {'lot': lotId} })
  }

  //  create a new sale
  public addSale = (): void => {
    if (!this.lotSelected) {
      return
    }

    const lotId = parseInt(this.lotSelected)
    if (isNaN(lotId) || (lotId <= 0)) {
      return
    }

    let type = 'outside'
    const lot = this.store.userAccount['user'].lots.find(el => el['id'] == this.lotSelected)    
    if (lot) {
      type = 'bhph'
    }
    
    this.router.navigate([`/sales/add`])
  }

  // todo: listen to output of navbar
  public listenerNav=(e): void => {
    switch (e['message']) {
      case 'changeLanguage':
        this.language=e['value']
        break
      case 'setPermissions':
        // permissions on user list
        this.permissions = ['view','edit','delete','create']
        break
    }
  }

  constructor(private master: MasterService, private ms: MessageService, private store: StoreService, private router: Router) {}

  ngOnInit() {
    this.lotSelected = localStorage.getItem('lot')
  }
}
