import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as words from '../../services/language'
import { MessageService } from '../../services/message.service';
import { MasterService } from '../../services/master.service';
import { StoreService } from '../../services/store.service';
import { SEARCH_DEBOUNCE_MILLIS } from 'src/app/constants/list';

@Component({
    selector: 'app-collections',
    templateUrl: './collections.component.html',
    styleUrls: ['./collections.component.scss']
})
export class CollectionsComponent implements OnInit {

    // **************************
    // ****** variables *********
    // **************************

    /** Save the language */
    public language: string= 'EN'

    /** Set all words */
    public words = words.language

    /** Unique internal identity of the dealership whose collections will be shown. */
    private dealerId: number = 0

    /** Unique internal identity of the lot whose collections will be shown. */
    public lotId: number = 0

    /** Unique internal identity of the sale whose collections will be shown. */
    private saleId: number = 0

    /** Unique internal identity of the sale finance type to filter the shown collections. */
    private financeTypeId: number = 0

    /** Unique internal identity of the contact/customer whose collections will be shown. */
    public contactId: number = 0

    /** The search form group */
    private form: FormGroup = null

    /** Sales that match any search inquiry from the user. */
    public sales: Array<any> = []

    /** Contacts that match any search inquiry from the user. */
    public contacts: Array<any> = []

    /** Indicates the sales or contacts are being searched. */
    public loading: boolean = false

    /** */
    public enableBHPH: boolean = false

    /** A debounce timer for search events. Used to wait for the user to stop typing before we fetch. */
    private watcher: ReturnType<typeof setTimeout> = null

    // **************************
    // ****** life cycles *******
    // **************************

    constructor(private route: ActivatedRoute, private ms: MessageService, private master: MasterService, private store: StoreService, private router: Router, private fb: FormBuilder) { }

    ngOnInit() {
        if (localStorage.getItem('lot')) {
            this.lotId = parseInt(localStorage.getItem('lot'))
        }

        this.form = this.fb.group({
            sale: new FormControl('', []),
            financeType: new FormControl('', []),
            contact: new FormControl('', [])
        })

        this.form.get('sale').valueChanges.subscribe((inquiry) => {
            if (this.watcher) {
                clearTimeout(this.watcher)
                this.watcher = null
            }

            if (!inquiry || (inquiry.length <= 0)) {
                this.sales = []
                this.saleId = 0
                return
            }

            for (const sale of this.sales) {
                if (inquiry !== this.displaySale(sale)) {
                    continue
                }

                this.saleSelected(null, sale)
                return
            }

            this.watcher = setTimeout(() => {
                this.fetchSales()
            }, SEARCH_DEBOUNCE_MILLIS)
        })

        this.form.get('financeType').valueChanges.subscribe((type) => {
            this.financeTypeSelected(null, type)
        })

        this.form.get('contact').valueChanges.subscribe((inquiry) => {
            if (this.watcher) {
                clearTimeout(this.watcher)
                this.watcher = null
            }

            if (!inquiry || (inquiry.length <= 0)) {
                this.contacts = []
                this.contactId = 0
                return
            }

            for (const contact of this.contacts) {
                if (inquiry !== this.displayContact(contact)) {
                    continue
                }

                this.contactSelected(null, contact)
                return
            }

            this.watcher = setTimeout(() => {
                this.fetchContacts()
            }, SEARCH_DEBOUNCE_MILLIS)
        })

        this.setEnableBHPH()
    }

    // **************************
    // ****** functions *********
    // **************************

    // Listen to output of navbar
    public listenerNav = (e): void => {
        switch (e['message']) {
            case 'changeLanguage':
                this.language = e['value']
                break
            case 'setPermissions':
                break
            case 'changeLot':
                const id = parseInt(e.lot || 0)
                if (!id || isNaN(id) || (id <= 0)) {
                    return
                }

                if (this.lotId === id) {
                    return
                }

                this.lotId = id
                this.setEnableBHPH()
                break
        }
    }

    /**
     * Display sale succintly for use in a data list.
     * @param sale The sale to display in the data list.
     * @returns A succint version of the sale to make it identifiable in the a data list.
     */
    public displaySale = (sale: Object): string => {
        if (!sale || !sale['id']) {
            return ''
        }

        return `${sale['sold']} ${sale['buyer']} ${sale['year']} ${sale['model']}`
    }

    /**
     * Display contact succintly for use in a data list.
     * @param contact The contact to display in the data list.
     * @returns A succint version of the contact to make it identifiable in the a data list.
     */
    public displayContact = (contact: Object): string => {
        if (!contact || !contact['id']) {
            return ''
        }

        return `${contact['name']} ${contact['city']}, ${contact['state']}`
    }

    /**
     * Handle sale selections from the search box.
     * @param e The event that triggered the selection.
     * @param sale The selected sale from the data list HTML element.
     */
    public saleSelected = (e, sale: Object): void => {
        if (!sale || !sale['id']) {
            return
        }

        this.saleId = parseInt(sale['id'])
        this.form.get('contact').setValue('')
    }

    /**
     * Handle sale finance type selections from the drop down.
     * @param e The event that triggered the selection.
     * @param type The selected sale finance type from the drop down.
     */
    public financeTypeSelected = (e, type: string): void => {
        if (!type || (type.length <= 0)) {
            this.financeTypeId = 0
            return
        }

        this.financeTypeId = parseInt(type)
    }

    /**
     * Handle contact selections from the search box.
     * @param e The event that triggered the selection.
     * @param contact The selected contact from the data list HTML element.
     */
    public contactSelected = (e, contact: Object): void => {
        if (!contact || !contact['id']) {
            return
        }

        this.contactId = parseInt(contact['id'])
        this.form.get('sale').setValue('')
    }
    
    /**
     * Query for a list of sales matching the search criteria.
     */
    private fetchSales = async () => {
        if (this.loading) { // already searching
            return
        }

        if (this.saleId && (this.saleId > 0)) { // sale selected
            return
        }

        this.sales = []
        this.saleId = 0
  
        const inquiry = this.form.get('sale').value
        if (!inquiry || (inquiry.length <= 0)) {
            return
        }
  
        const inquiryParam = `&q=${encodeURIComponent(inquiry)}`

        let lotParam = ''
        if (this.lotId && (this.lotId > 0)) {
            lotParam = `&lotId=${this.lotId}`
        }

        let financeTypeParam = ''
        if (this.financeTypeId && (this.financeTypeId > 0)) {
            financeTypeParam = `&type=${this.financeTypeId}`
        }

        this.loading = true
        await this.master.getAsync(`sales?page=1&stage=2${inquiryParam}${lotParam}${financeTypeParam}`).then(res => { // stage 2 = sale, 
            this.loading = false

            if (!res || !res.data) {
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error ? res.data.error : this.words[this.language]['apiNoResponse'] });
                return
            }

            if (!res.data.sales || (res.data.sales.length <= 0)) {
                return
            }

            for (const sale of res.data.sales) {
                this.sales.push({
                    id: parseInt(sale['id']),
                    sold: sale['saleDate'],
                    buyer: sale['buyer'],
                    year: sale['year'],
                    model: sale['model']
                })
            }
        })
    }

    /**
     * Query for a list of contacts matching the search criteria.
     */
    private fetchContacts = async () => {
        if (this.loading) { // already searching
            return
        }

        if (this.contactId && (this.contactId > 0)) { // contact selected
            return
        }
  
        this.contacts = []
        this.contactId = 0

        const inquiry = this.form.get('contact').value
        if (!inquiry || (inquiry.length <= 0)) {
            return
        }
  
        const inquiryParam = `&q=${encodeURIComponent(inquiry)}`

        let lotParam = ''
        if (this.lotId && (this.lotId > 0)) {
            lotParam = `&lotId=${this.lotId}`
        }

        this.loading = true
        await this.master.getAsync(`contacts?page=1${inquiryParam}${lotParam}`).then(res => {
            this.loading = false

            if (!res || !res.data) {
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error ? res.data.error : this.words[this.language]['apiNoResponse'] });
                return
            }

            if (!res.data.contacts || (res.data.contacts.length <= 0)) {
                return
            }

            for (const contact of res.data.contacts) {
                this.contacts.push({
                    id: parseInt(contact['id']),
                    name: contact['name'],
                    city: contact['city'],
                    state: contact['state']
                })
            }
        })
    }

    /**
     * 
     */
    private setEnableBHPH() {
        if (!this.lotId || (this.lotId <= 0)) {
            return
        }

        this.master.get(`lots/${this.lotId}/salesSetup`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            if (!res.data.salesSetup) {
                this.enableBHPH = false
                return
            }

            this.enableBHPH = res.data.salesSetup.enableBHPH
        })
    }
}
