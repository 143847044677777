import { Component, Input, OnInit, EventEmitter, Output } from "@angular/core";
import { FormControl, FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
// services
import { MasterService } from '../../../../services/master.service'
import { MessageService } from '../../../../services/message.service'
import { Router, ActivatedRoute } from '@angular/router';
import * as languageLibrary from '../../../../services/language'
import { Subject } from "rxjs";

import { TAX_METHODS, PAYMENT_CYCLE_OPTIONS, SALE_TYPES } from "src/app/constants/sale";
import { formatCurrency, formatPercent } from "src/app/utils/format";
import { clean } from "src/app/utils/numeric";

@Component({
    selector: "sales-setup",
    templateUrl: "./sales-setup.component.html",
    styleUrls: ["./sales-setup.component.scss"],
})
export class SaleSetupComponent implements OnInit {
    // **************
    // variables
    // *************

    // save the language
    @Input() language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'

    @Input() entityType: string
    
    @Input() entityId: number

    @Input('addressChangedEvent') addressChangedEvent: Subject<Object>

    public words = languageLibrary.language
    
    public expanded: boolean = true;
    
    public loading: boolean = false    

    public saving: boolean = false
    
    public form: FormGroup = null

    public feeForm: FormGroup = null
    
    public salesSetup: Object;
    
    public saleTypes: Object = SALE_TYPES

    public taxMethods: Object = TAX_METHODS

    public paymentCycles = PAYMENT_CYCLE_OPTIONS
    /**
     * Helper method to consistently get the list of form fees as an Array.
     */
    get fees() {
        return this.feeForm.controls['fees'] as FormArray;
    }

    get taxMethodKeys() { return Object.keys(this.taxMethods) }
    get saleTypeKeys() { return Object.keys(this.saleTypes) }

    // **************
    // life cycles
    // *************
    constructor(private master: MasterService, private route: ActivatedRoute, private ms: MessageService, private fb: FormBuilder) {
        this.feeForm = this.fb.group({
            fees: this.fb.array([])
        })

        this.form = this.fb.group({
            id: new FormControl(0),
            dealershipId: this.entityType === 'dealership' ? new FormControl(this.entityId) : new FormControl(null),
            lotId: this.entityType === 'lot' ? new FormControl(this.entityId) : new FormControl(null),
            corporateId: this.entityType === 'corporate' ? new FormControl(this.entityId) : new FormControl(null),
            enableBHPH: new FormControl('false'),
            sellerFinanceNumber: new FormControl(null),
            defaultBHPHTaxMethod: new FormControl(null),
            defaultSaleTypeId:  new FormControl(null),
            defaultSaleStageId: new FormControl(null),
            defaultBHPHPaymentCycle: new FormControl(null),
            defaultLienholderContactId: new FormControl(null),
            defaultWarrantyContactId: new FormControl(null),
            defaultGAPContactId: new FormControl(null),
            defaultLifeInsuranceContactId: new FormControl(null),
            defaultAHDContactId: new FormControl(null),
            useStateMaximums: new FormControl('false'),
            aprBelow2: new FormControl(0),
            apr3To4: new FormControl(0),
            apr5Over: new FormControl(0),
            aprMatureSIE: new FormControl(0),
            acrueAfterMaturity: new FormControl('false')
        })
    }

    ngOnInit() {
        this.entityType = this.route.snapshot.params['type']
        this.entityId = parseInt(this.route.snapshot.params['id'])

        if (isNaN(this.entityId) || this.entityId == 0) {
            this.entityId = null
        }

        this.monitorBhph()
        this.monitorInterestAcrue()
        this.monitorStateMaximums()
        this.getSalesSetup()
        this.fetchFees()        

        this.addressChangedEvent.subscribe((address) => {
            this.fetchFees()
        })
    }

    /**
     * Handle changes to the enableBHPH sales setup option.
     */
    private monitorBhph = () => {
        this.form.get('enableBHPH').valueChanges.subscribe((enabled) => {
            if ((enabled === true) || (enabled === 'true')) {
                this.saleTypes = SALE_TYPES
                this.taxMethods = TAX_METHODS
                this.form.get('sellerFinanceNumber').enable()
                return
            }

            this.form.get('sellerFinanceNumber').disable()
            this.saleTypes = {}
            const keys = Object.keys(SALE_TYPES)
            for (const key in keys) {
                if (!key || !SALE_TYPES[key]) {
                    continue
                }

                if (SALE_TYPES[key] === 'BHPH') {
                    continue
                }

                this.saleTypes[key] = SALE_TYPES[key]
            }

            this.taxMethods = {}
            for (const taxMethod in TAX_METHODS) {
                if (!taxMethod || !TAX_METHODS[taxMethod]) {
                    continue
                }

                if (taxMethod === 'PAID') {
                    continue
                }

                this.taxMethods[taxMethod] = TAX_METHODS[taxMethod]
            }
        })
    }

    /**
     * Handle changes to the acrueAfterMaturity sales setup option.
     */
    private monitorInterestAcrue = () => {
        this.form.get('acrueAfterMaturity').valueChanges.subscribe((enabled) => {
            if ((enabled === true) || (enabled === 'true')) {
                this.form.get('aprMatureSIE').enable()
                return
            }

            this.form.get('aprMatureSIE').setValue(formatPercent(0))
            this.form.get('aprMatureSIE').disable()
        })
    }

    /**
     * Handle changes to the useStateMaximums sales setup option.
     */
    private monitorStateMaximums = () => {
        this.form.get('useStateMaximums').valueChanges.subscribe((enabled) => {
            if ((enabled === true) || (enabled === 'true')) {
                this.form.patchValue({
                    aprBelow2: formatPercent(0),
                    apr3To4: formatPercent(0),
                    apr5Over: formatPercent(0)
                })

                this.form.get('aprBelow2').enable()
                this.form.get('apr3To4').enable()
                this.form.get('apr5Over').enable()

                return
            }

            this.form.get('aprBelow2').disable()
            this.form.get('apr3To4').disable()
            this.form.get('apr5Over').disable()
        })
    }

    //
    // **************
    // functions
    // *************

    /**
     * Remove a lot fee from the form array
     * @param index The index of the fee to remove
     */
    public discardFee = (index: number): void => {
        if ((index === undefined) || (index === null) || (index < 0) || (index > this.fees.length - 1)) {
            return
        }

        if (this.saving) {
            return
        }
        
        const id = parseInt(this.fees.controls[index].get('id').value)
        if (!id || (isNaN(id)) || (id <= 0)) {
            this.fees.removeAt(index)
            return
        }

        this.saving = true
        this.master.discard(`lots/${this.entityId}/fees/${id}`, res => {
            this.saving = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.fees.removeAt(index)            
        })
    }

    /**
     * Save all fees that are pending a save.
     */
    public saveAllFees = (): Promise<void> => {
        if (this.saving) {
            return
        }

        for (const fee of this.fees.controls) {
            if (fee.invalid || fee.pristine) {
                continue
            }

            this.upsertFee(fee as FormGroup)
        }
    }

    /**
     * Save (upsert) a lot fee from the form array
     * @param index The index of the fee to save
     */
    public saveFee = (index: number): Promise<void> => {
        if ((index === undefined) || (index === null) || (index < 0) || (index > this.fees.length - 1)) {
            return
        }

        if (this.saving) {
            return
        }

        const fee = this.fees.controls[index] as FormGroup
        this.upsertFee(fee)
    }

    /**
     * Determine if the API call should be an insert or 
     * modify and call the proper method.
     * @param fee 
     * @returns 
     */
    private upsertFee = (fee: FormGroup) => {
        const id = parseInt(fee.get('id').value)
        if ((isNaN(id)) || (id < 0)) {
            return
        }

        if (id === 0) {
            this.insertFee(fee)
            return      
        }

        this.modifyFee(id, fee)
    }

    /**
     * Add a new fee into the system for the given lot.
     * @param fee 
     */
    private insertFee = (fee: FormGroup): Promise<void> => {
        if (!fee) {
            return
        }

        this.saving = true
        this.master.post(`lots/${this.entityId}/fees`, [fee.value], res => {
            this.saving = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            fee.markAsPristine()

            if (!res.data || (res.data.length <= 0)) {
                return
            }

            fee.get('id').setValue(res.data[0].id)
        })
    }

    /**
     * Modify an existing lot fee in the system.
     * @param fee 
     */
    private modifyFee = (id: number, fee: FormGroup): Promise<void> => {
        if (!id || !fee || (id <= 0)) {
            return
        }

        this.saving = true
        this.master.put(`lots/${this.entityId}/fees/${id}`, fee.value, res => {
            this.saving = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            fee.markAsPristine()
        })
    }

    /**
     * Add a new lot fee row to the form array
     * @param {Object} [meta = null] An optional object to set the initial values of the fee being added.
     */
    public addFee = (meta = null): void => {
        const decimal = /^[0-9,]*(\.[0-9]{1,6})?$/

        if (!meta) {
            meta = {}
        }

        const rate = ((meta.fee) && (meta.fee.rate))
        const amount = meta.amount ? meta.amount : 0
        const original = (meta.fee && meta.fee.amount) ? meta.fee.amount : 0

        const fee = this.fb.group({
            id: [meta.id || 0, []],
            lotId: [this.entityId, []],
            feeId: [meta.feeId || null, []],
            name: [meta.name || null, [Validators.required]],
            amount: [rate ? formatPercent(amount) : formatCurrency(amount), [Validators.pattern(decimal)]],
            description: [meta.description || null, []],
            rate: [rate, []],
            editable: [(!meta.fee) || (meta.fee.editable) ? true : false, []],
            default: [rate ? formatPercent(original) : formatCurrency(original), [Validators.pattern(decimal)]]
        })

        this.fees.push(fee)
    }

    getSalesSetup() {
        if (isNaN(this.entityId) || this.entityId == 0 || this.entityId == null) {
            return
        }

        this.master.get(`lots/${this.entityId}/salesSetup`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.salesSetup = res.data.salesSetup
            if (!this.salesSetup) {
                this.form.patchValue({
                    lotId: this.entityType === 'lot' ? this.entityId : null,
                    dealershipId: this.entityType === 'dealership' ? this.entityId : null,
                    enableBHPH: false
                }, { emitEvent: true })
                return
            }

            this.setInformationValues(this.salesSetup)
        })
    }

    fetchFees() {
        this.fees.clear()        
        if (isNaN(this.entityId) || this.entityId == 0 || this.entityId == null) {
            return
        }

        this.master.get(`lots/${this.entityId}/fees`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            for (const fee of res.data) {
                this.addFee(fee)
            }
        })
    }

    private setInformationValues(salesSetupData: Object) {
        this.form.setValue({
            id: salesSetupData['id'],
            lotId: salesSetupData['lotId'],
            dealershipId: salesSetupData['dealershipId'],
            corporateId: salesSetupData['corporateId'],
            enableBHPH: (salesSetupData['enableBHPH'] === true).toString(),
            sellerFinanceNumber: salesSetupData['sellerFinanceNumber'],
            defaultBHPHTaxMethod: salesSetupData['defaultBHPHTaxMethod'],
            defaultSaleTypeId: salesSetupData['defaultSaleTypeId'],
            defaultSaleStageId: salesSetupData['defaultSaleStageId'],
            defaultBHPHPaymentCycle: salesSetupData['defaultBHPHPaymentCycle'],
            defaultLienholderContactId: salesSetupData['defaultLienholderContactId'],
            defaultWarrantyContactId: salesSetupData['defaultWarrantyContactId'],
            defaultGAPContactId: salesSetupData['defaultGAPContactId'],
            defaultLifeInsuranceContactId: salesSetupData['defaultLifeInsuranceContactId'],
            defaultAHDContactId: salesSetupData['defaultAHDContactId'],
            useStateMaximums: (salesSetupData['useStateMaximums'] === true).toString(),
            aprBelow2: formatPercent(salesSetupData['aprBelow2']),
            apr3To4: formatPercent(salesSetupData['apr3To4']),
            apr5Over: formatPercent(salesSetupData['apr5Over']),
            aprMatureSIE: formatPercent(salesSetupData['aprMatureSIE']),
            acrueAfterMaturity: (salesSetupData['acrueAfterMaturity'] === true).toString()
        })
    }

    public save() {
        if (this.form.invalid) {
            return
        }

        if (!this.form.controls['id'].value){
            this.createSalesSetup()
            return
        }

        this.modifySalesSetup()
    }

    modifySalesSetup() {
        const payload = JSON.parse(JSON.stringify(this.form.value))
        delete(payload.id)
        delete(payload.corporateId)
        delete(payload.lotId)
        delete(payload.dealershipId)
        payload.defaultSaleStageId = parseInt(payload.defaultSaleStageId)
        payload.defaultSaleTypeId = parseInt(payload.defaultSaleTypeId)
        payload.defaultLienholderContactId = parseInt(payload.defaultLienholderContactId),
        payload.defaultWarrantyContactId = parseInt(payload.defaultWarrantyContactId),
        payload.defaultGAPContactId = parseInt(payload.defaultGAPContactId),
        payload.defaultLifeInsuranceContactId = parseInt(payload.defaultLifeInsuranceContactId),
        payload.defaultAHDContactId = parseInt(payload.defaultAHDContactId),
        payload.enableBHPH = (payload.enableBHPH === 'true')
        payload.useStateMaximums = (payload.useStateMaximums === 'true')
        payload.aprBelow2 = clean(payload.aprBelow2)
        payload.apr3To4 = clean(payload.apr3To4)
        payload.apr5Over = clean(payload.apr5Over)
        payload.aprMatureSIE = clean(payload.aprMatureSIE)
        payload.acrueAfterMaturity = (payload.acrueAfterMaturity === 'true')

        this.master.put(`lots/${this.entityId}/salesSetup/${this.form.get('id').value}`, { salesSetupData: payload }, res => {
            this.loading = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

        })
    }

    createSalesSetup() {
        this.loading = true

        this.form.get('lotId').setValue(this.entityType === 'lot' ? this.entityId : null)
        this.form.get('dealershipId').setValue(this.entityType === 'dealership' ? this.entityId : null)

        const payload = JSON.parse(JSON.stringify(this.form.value))
        delete(payload.id)
        delete(payload.corporateId)
        delete(payload.lotId)
        payload.defaultSaleStageId = parseInt(payload.defaultSaleStageId)
        payload.defaultSaleTypeId = parseInt(payload.defaultSaleTypeId)
        payload.defaultLienholderContactId = parseInt(payload.defaultLienholderContactId),
        payload.defaultWarrantyContactId = parseInt(payload.defaultWarrantyContactId),
        payload.defaultGAPContactId = parseInt(payload.defaultGAPContactId),
        payload.defaultLifeInsuranceContactId = parseInt(payload.defaultLifeInsuranceContactId),
        payload.defaultAHDContactId = parseInt(payload.defaultAHDContactId),
        payload.enableBHPH = (payload.enableBHPH === 'true')
        payload.useStateMaximums = (payload.useStateMaximums === 'true')
        payload.aprBelow2 = clean(payload.aprBelow2)
        payload.apr3To4 = clean(payload.apr3To4)
        payload.apr5Over = clean(payload.apr5Over)
        payload.aprMatureSIE = clean(payload.aprMatureSIE)
        payload.acrueAfterMaturity = (payload.acrueAfterMaturity === 'true')

        this.master.post(`lots/${this.entityId}/salesSetup`, { salesSetupData: payload }, res => {
            this.loading = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.form.get("id").patchValue(res.data.salesSetup.id)

        })
    }
}
