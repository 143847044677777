import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MessageService } from "../../../services/message.service";
import { MasterService } from "../../../services/master.service";
import { StoreService } from "../../../services/store.service";
import * as words from '../../../services/language'

import { SEARCH_DEBOUNCE_MILLIS, MIN_SEARCH_CHARS, SCROLL_BOTTOM_OUT } from "src/app/constants/list";

@Component({
    selector: "app-contacts",
    templateUrl: "./contacts.component.html",
    styleUrls: ["./contacts.component.scss"],
})
export class ContactsComponent implements OnInit {
    /*
     * Variables
     */
    
    // save the language
    public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
    
    // set all words
    public words = words.language

    // Current type filter value.
    private type: string = null
    
    // define the contact type (title)
    public typeTitle: Object = {
        leads: "Leads",
        employees: "Employees",
        buyers: "Buyers",
        vendors: "vendors",
        finance: "Companies",
        lenders: "Floor Plans",
        insurance: "Insurance",
        lienholders: "lienholders",
    };
    
    // save all data by type
    public contacts: Object[] = [];
    
    // save the users has been selecteds
    public usersSelecteds: Object[] = [];
    
    public contactIdToDelete: number = null
    
    // define the contact Selected
    public contactSelected: Object = {}
    
    // define if is loading
    public loading: boolean = false;
    
    // permissions
    public permissions: Object[] = [];
    
    // Current page the user is viewing (last loaded).
    public page = 1

    // The number contacts currently being displayed.
    public displayed = 0

    // Indicates whether or not there are no more records to load.
    public exhausted = false

    // Total number of records available
    public records: number

    // The search form group
    private form: FormGroup = null

    // The current search text to filter the list by.
    private inquiry: string = null

    // A debounce timer for search events. Used to wait for 
    // the user to stop typing before we fetch.
    private watcher: ReturnType<typeof setTimeout> = null

    // Keep track of how the user wants to sort list.
    private sortables: Object = {'name': 'ASC'}

    public roles: [] = [];
    
    public roleSelected: string = "";

    // Indicates whether or not the contact role is fixed for this list.
    public fixedRole = false

    // The name of the fixed contact role or null if none.
    public fixedRoleName = null

    // The UI route path of the fixed contact role (default: 'contacts').
    public path = 'contacts'

    // ************
    // life cycles
    // *************
    constructor(private route: ActivatedRoute, private router: Router, private ms: MessageService, private master: MasterService, private store: StoreService, private fb: FormBuilder) {
        this.form = this.fb.group({
            inquiry: new FormControl('', []),
            role: new FormControl('', [])
        })
    }

    ngOnInit() {
        const path = this.route.snapshot.url[0].path
        const pattern = new RegExp('employee', 'i')
        this.fixedRole = (path && pattern.test(path))
        if (this.fixedRole) {
            this.form.get('role').setValue('employee')
            this.fixedRoleName = 'Employees'
            this.path = 'employees'
        }

        this.fetchRoles()

        this.form.get('inquiry').valueChanges.subscribe(inquiry => {
            if (this.watcher) {
                clearTimeout(this.watcher)
                this.watcher = null
            }

            if (this.inquiry === inquiry) {
                return
            }
    
            this.watcher = setTimeout(() => {
                this.inquiry = inquiry
                this.reset()            
                this.fetch()
            }, SEARCH_DEBOUNCE_MILLIS)
        })

        this.form.get('role').valueChanges.subscribe(role => {
            if (this.fixedRole) {
                return
            }

            this.reset()            
            this.fetch()
        })

        if (this.store.userAccount["permissions"]) {
            this.permissions = this.store.userAccount["permissions"][1].childrens[0].permissions
        }

        this.reset()
        this.fetch()
    }


    public fetchRoles() {
        if (this.fixedRole) {
            this.roles = []
            return
        }

        this.master.get(`collections/contacts/roles?display=true`, res => {
          if (!res) {
            this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
            return
          }
    
          if (res.status !== 200) {
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
            return
          }
    
          this.roles = res.data.contactRoleTypes
        })
    }

    /**
     * Reset the pagination parameters and scroll to the top
     */
    private reset() {
        this.page = 1
        this.exhausted = false
        this.displayed = 0

        const list = document.querySelector('#listing')
        if (!list) {
            return
        }

        list.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto'
        })
    }

    /*
    * functions
    */
    public createContact() {
        this.router.navigate([`/${this.path}/add`])
        return
    }

    public setContactIdForDelete = (e, contactId): void => {
        if (!e) {
            return
        }
        
        this.contactIdToDelete = contactId
    }

    // delete the contact selected
    public deleteContact = (): void => {
        this.loading = true;
        this.master.discard(`contacts/${this.contactIdToDelete}`, (res) => {
            this.loading = false;

            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }
            // close delete modal
            (document.getElementById("btn-close-modal-delete") as HTMLButtonElement).click();
            
            this.reset()
            this.fetch()
        })
    }

    /**
     * Handle scroll events of the list container and load 
     * the next page when avilable and the user scrolled 
     * to the bottom.
     * @param e The scroll event that trigggered this handler.
     */
    public scrolled = (e): void => {
        if (!e || !e.target) {
            return
        }

        if (this.exhausted) {
            return
        }

        if (this.loading) {
            return
        }

        const location = Math.abs(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop)

        if (location >= SCROLL_BOTTOM_OUT) {
            return
        }

        this.page = this.page + 1
        this.fetch()
    }
    
    /**
     * 
     * @param field The field that user wants to sort by. 
     */
    public sort = (field: string): void => {
        if (!field || (field.length <= 0)) {
            return
        }

        if (this.sortables[field]) { // need to toggle the direction?
            switch (this.sortables[field]) {
                case 'ASC':
                    this.sortables[field] = 'DESC'
                    break
                default:
                    this.sortables[field] = 'ASC'
                    break
            }

            this.reset()
            this.fetch()
            return
        }

        this.sortables = {}
        this.sortables[field] = 'ASC'
        this.reset()
        this.fetch()
    }

    public sortedAsc = (field: string): Boolean => {
        if (!field || (field.length <= 0) || !this.sortables) {
            return false
        }

        if (!this.sortables[field]) {
            return false
        }

        return (this.sortables[field].toUpperCase() === 'ASC')
    }

    public sortedDesc = (field: string): Boolean => {
        if (!field || (field.length <= 0) || !this.sortables) {
            return false
        }

        if (!this.sortables[field]) {
            return false
        }

        return (this.sortables[field].toUpperCase() === 'DESC')
    }

    public typeChanged(e) {
        this.type = e
        this.reset()
        this.fetch()
    }
    /**
     * Acquire contact list by given type
     */
    public fetch = (): void => {
        // if not have permissions, return to account settings
        if (!this.permissions.includes("view")) {
            return
        }
        
        if (this.loading) { // already getting next page
            return
        }

        if (this.exhausted) { // currently showing all available records?
            return
        }

        this.loading = true

        let typeParam = ''
        if (this.type && (this.type.length > 0)) {
            typeParam = `&type=${encodeURIComponent(this.type)}`
        }

        let roleParam = ''
        const roleKey = this.form.get('role').value
        if (roleKey && (roleKey.length > 0)) {
            roleParam = `&type=${roleKey}`
        }

        let inquiryParam = ''
        if (this.inquiry && (this.inquiry.length > 0)) {
            inquiryParam = `&q=${encodeURIComponent(this.inquiry)}`
        }

        let sortParam = ''
        if (this.sortables && (Object.keys(this.sortables).length > 0)) {
            const sorts = []
            for (const key of Object.keys(this.sortables)) {
                let direction = ''
                if (this.sortables[key] && this.sortables[key].length > 0) {
                    direction = `|${this.sortables[key]}`
                }

                sorts.push(`${key}${direction}`)
            }

            sortParam = `&sort=${sorts.join(',')}`
        }

        this.master.get(`contacts?page=${this.page}${typeParam}${inquiryParam}${sortParam}${roleParam}`, (res) => {
            this.loading = false

            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.records = res.data.records
            this.displayed = Math.min(this.records, (res.data.page * res.data.ipp))
            this.exhausted = (res.data.next === null)

            if (this.page > 1) {
                this.contacts = this.contacts.concat(res.data.contacts)
                return
            }

            this.contacts = res.data.contacts
        })
    }


    // todo: listen to output of navbar
    public listenerNav = (e): void => {

        switch (e['message']) {
            case 'changeLanguage':
                this.language = e['value']
                break;
            case 'setPermissions':
                // permissions on user list

                this.permissions = e['permissions'][1].childrens[0].permissions;
                if (this.permissions.includes('view')) {
                    this.reset()
                    this.fetch()
                }
                break;
            case 'changeType':
                this.form.get('inquiry').setValue('')
                this.reset()
                this.fetch()
                break
            default:
                break;
        }
    }
}
