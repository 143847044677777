import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
// services
import { MessageService } from '../../../../services/message.service'
import { MasterService } from '../../../../services/master.service'
import { StoreService } from '../../../../services/store.service'
import * as languageLibrary from '../../../../services/language'
import * as moment from 'moment/moment';


@Component({
    selector: 'inventory-history',
    templateUrl: './inventory-history.component.html',
    styleUrls: ['./inventory-history.component.scss']
})
export class InventoryHistoryComponent implements OnInit {
    /*
    * Variables
    */
    //
    @Output() emitterInformation$: EventEmitter<Object> = new EventEmitter()
    // save the language
    @Input() language: string = 'EN'
    @Input() lotId: number;
    @Input() statusId: number;
    @Input() dateOnLot: Date;
    @Input() inventoryId: number = 0
    // set all words
    public words = languageLibrary.language
    public inventoryStatuses: any[];   //selectable
    public statusHistoryList: any[];
    // define the inventory id
    public loading: boolean = false
    public statusInformation = new FormGroup({
        statusId: new FormControl(null, Validators.required),
        startDate: new FormControl(new Date().toISOString().substring(0, 10), Validators.required,),
        endDate: new FormControl(null),
        inventoryId: new FormControl(null, Validators.required),
        id: new FormControl(null),
    },
        {
            validators: Validators.compose([this.dateInPast('startDate')])
        })
    /*
    * life cycles
    */
    constructor(private router: Router, private route: ActivatedRoute, private ms: MessageService, private master: MasterService, private store: StoreService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        //Add '${implements OnChanges}' to the class.
        const identity = this.inventoryId
        if (isNaN(identity) || identity == 0) {
            return
        }
        this.getInventoryHistory()
    }

    ngOnInit() {
        this.getInventoryStatuses()
        const identity = this.inventoryId
        if (isNaN(identity) || identity == 0) {
            return
        }

        this.statusInformation.get('inventoryId').setValue(this.inventoryId)
        this.getInventoryHistory()

    }

    public saveStatus() {
        const identity = this.inventoryId
        if (isNaN(identity)) {
            return
        }

        const id = parseInt(this.statusInformation.get('id').value)
        if (isNaN(id)) {
            this.createStatus()
            return
        }

        this.modifyStatus()
    }

    public createStatus() {
        this.statusInformation.get('inventoryId').setValue(this.inventoryId)
        if (this.statusInformation.invalid) {
            return
        }

        this.master.post(`inventory/${this.inventoryId}/status`, { information: this.statusInformation.value }, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.statusHistoryList = res.data.statusHistory
            this.emitterInformation$.emit({ message: 'showNewStatusId', statusId: res.data.statusId })
        })
    }
    public selectStatus(statusHistoryData) {
        (document.getElementById('save-btn').innerHTML = 'Update')
        this.statusInformation.setValue({
            statusId: statusHistoryData.statusId,
            startDate: statusHistoryData.startDate,
            endDate: statusHistoryData.endDate,
            inventoryId: statusHistoryData.inventoryId,
            id: statusHistoryData.id
        })
    }

    public modifyStatus() {
        if (this.statusInformation.invalid) {
            return
        }

        let id = parseInt(this.statusInformation.get('id').value)

        this.master.put(`inventory/${this.inventoryId}/status/${id}`, { information: this.statusInformation.value }, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.statusHistoryList = res.data.statusHistory
            this.emitterInformation$.emit({ message: 'showNewStatusId', statusId: res.data.statusId })
            this.resetStatusInformationForm()
        })
    }

    public resetStatusInformationForm() {
        this.statusInformation.reset();
        this.statusInformation.get("inventoryId").setValue(this.inventoryId);
        (document.getElementById('save-btn').innerHTML = 'Save')
    }

    public deleteStatus(id) {
        if (this.statusInformation.get('statusId').value === 5 || this.statusHistoryList.length == 1) {
            return
        }

        this.master.discard(`inventory/${this.inventoryId}/status/${id}`, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.statusHistoryList = res.data.statusHistory
            this.emitterInformation$.emit({ message: 'showNewStatusId', statusId: res.data.statusId })
        })
    }
    public calculateDaysDiff = (startDateVar: Date, endDateVar: Date) => {
        if (!startDateVar) {
            return null
        }
        let startDate = moment(startDateVar);
        let endDate = endDateVar ? moment(endDateVar) : null
        if (!endDate) {
            let now = moment(moment().format('YYYY-MM-DD'))
            let daysDiff = now.diff(startDate, 'days')
            if (daysDiff === 0) {
                return 'today'
            }
            return daysDiff + ' day(s)'
        }
        return `${endDate.diff(startDate, 'days')} day(s)`
    }

    public getInventoryStatuses() {
        this.master.get(`collections/inventory/statuses?selectable=true`, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.inventoryStatuses = res.data.inventoryStatuses
        })
    }


    public getInventoryHistory() {
        this.master.get(`inventory/${this.inventoryId}/statusHistory`, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.statusHistoryList = res.data.inventoryStatusHistory
        })
    }

    public dateInPast(startDate: string) {
        return (group: FormGroup): { [key: string]: any } => {
            let start = group.controls[startDate]
            if (start.value >= moment().format()) {
                return {
                    dates: "Start date from should be in the past"
                };
            } 
            if (start.value < this.dateOnLot) {
                return {
                    dates: "Start date cannot be before the date on lot."
                };
            }
            return
        }
    }
}


