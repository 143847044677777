import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
// services
import { MasterService } from "../../services/master.service";
import { MessageService } from "../../services/message.service";
import { StoreService } from "../../services/store.service";
import * as languageLibrary from '../../services/language'
import { formatLastVINDigits, formatPhoneNumber } from "src/app/utils/utilities";
@Component({
  selector: "service",
  templateUrl: "./service.component.html",
  styleUrls: ["./service.component.scss"],
})
export class ServiceComponent {
  /*
  * Variables
  */
  public tickets: any;
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  public lotId: any;
  public formatLastVINDigits = formatLastVINDigits
  public formatPhoneNumber = formatPhoneNumber

  /*
    * life cycles
    */
  constructor (private master: MasterService, private ms: MessageService, private store: StoreService, private router: Router) {
  }

  ngOnInit(){
    this.lotId = parseInt(localStorage.getItem('lot') || this.store.lotSelected)
    this.getTickets()
  }


  public getTickets(){
    this.master.get(`service?lotId=${this.lotId}`, res =>{
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.tickets = res.data.tickets

    })
  }

  // todo: listen to output of navbar
  public listenerNav = (e): void => {
    switch (e['message']) {
      case 'changeLanguage':
        this.language = e['value']
        break
    }
  }

}
