import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
// services
import { MessageService } from '../../../../services/message.service'
import { MasterService } from '../../../../services/master.service'
import { StoreService } from '../../../../services/store.service'
import * as languageLibrary from '../../../../services/language'

import { INVENTORY } from '../../../../constants/inventory'
import * as moment from 'moment/moment';

import { formatCurrency, formatCount } from 'src/app/utils/format';
import { clean } from 'src/app/utils/numeric';

@Component({
    selector: 'inventory-general-information',
    templateUrl: './inventory-general-information.component.html',
    styleUrls: ['./inventory-general-information.component.scss']
})
export class InventoryGeneralInformationComponent implements OnInit {

    /*
    * Variables
    */

    // get the recon cost from the reconditioning component
    @Input() reconCostTotal: number = 0
    
    // get the floor plan cost from the floor plan component
    @Input() floorPlanCostTotal: number = 0
    
    // save the language
    @Input() language: string = 'EN'
    
    @Input() isFlat = false

    @Input() cleanStockNumber

    // contact object
    @Input() data: Object = {}

    // contact object
    @Input() setup: Object = {}

    // permissions
    @Input() permissions: string[] = []

    // permissions
    @Input() vehicleId: string = null

    // define the file URL
    @Input() fileURL: string = ''

    // define the inventory id
    @Input() inventoryId: number = 0

    // car types catalog
    @Input() types: string[] = []

    @Output() vehicleSelectedEvent = new EventEmitter<Number>()

    @Output() emitterInformation$: EventEmitter<Object> = new EventEmitter()

    @Output() allData$: EventEmitter<Object> = new EventEmitter();

    // set all words
    public words = languageLibrary.language

    // get the total cost
    public costTotal: number = 0

    public inventoryStatusesDisplayable: any[];

    // define expanded as true
    public expanded: boolean = true

    // image to display
    public image = null

    public duplicateVINFound: boolean;

    public lots: [] = [];
    
    public userId: number;
    
    public vendors: any[];
    
    public paymentMethods: any[];
    
    public markAsDeleted: boolean = false
    
    public statusId: number;
    
    public statusHistoryList: any[];
    
    public featuresList: object[] = [];
    
    // lot Id
    public lotId: number;
    
    // list of odometers
    public odometers: {} = INVENTORY.ODOMETER_TYPES
    
    // list of colors
    public colors: {} = INVENTORY.COLORS
    
    // list of Vehicle Types
    public vehicleTypes: {} = INVENTORY.VEHICLE_TYPES
    
    // loading
    public loading: boolean = false

    // Indicates the data is currently being saved (inserted or modified)
    public saving: boolean = false

    public information = new FormGroup({
        vinNumber: new FormControl(null, [Validators.required]),
        stockNumber: new FormControl(null, [Validators.required]),
        miles: new FormControl(null, [Validators.pattern('[0-9,]{1,20}')]),
        odometer: new FormControl('ACTUAL', [Validators.required]),
        exteriorColor: new FormControl(null),
        exteriorColorMinor: new FormControl(null),
        interiorColor: new FormControl(null),
        type: new FormControl(null),
        modelYear: new FormControl(null),
        make: new FormControl(null),
        model: new FormControl(null),
        cylinders: new FormControl(null),
        transmission: new FormControl(null),
        driveline: new FormControl(null),
        bodyStyle: new FormControl(null),
        trimLevel: new FormControl(null),
        engine: new FormControl(null),
        curbWeight: new FormControl(null),
        carryingWeight: new FormControl(null),
        gvwr: new FormControl(null),
        tonnage: new FormControl(null),
        lotId: new FormControl(0),
        id: new FormControl(0),
        vehicleId: new FormControl(0),
        purchaseCost: new FormControl(0, Validators.min(0)),
        totalFloorPlanCost: new FormControl({ value: 0, disabled: true }),
        totalCost: new FormControl({ value: 0, disabled: true }),
        sellingPrice: new FormControl(0, Validators.min(0)),
        writeDownCost: new FormControl(0, Validators.min(0)),
        downPayment: new FormControl(0, Validators.min(0)),
        bookValue: new FormControl(0, Validators.min(0)),
        licensePlateNumber: new FormControl(null),
        announcements: new FormControl(null),
        dateTitleDeliveredCustomer: new FormControl(null),
        dateOnLot: new FormControl(new Date().toISOString().substring(0, 10)),
        inventorySourceId: new FormControl(null),
        source: new FormControl(null),
        keyNumberAssigned: new FormControl(null),
        airbagStatus: new FormControl(null),
        inspectionDate: new FormControl(null),
        datePurchased: new FormControl(null),
        titleCondition: new FormControl(null),
        inspectionDueDate: new FormControl(null),
        datePaid: new FormControl(null),
        titleLocation: new FormControl(null),
        inspectionExpires: new FormControl(null),
        paymentMethod: new FormControl(null),
        titleNumber: new FormControl(null),
        dateTitleReceived: new FormControl(null),
        cleanStockNumber: new FormControl(null),
        statusId: new FormControl(1),
    })

    public deletable: boolean = false;

    // Group of inputs

    // get information of inputs
    get vinNumber() { return this.information.get('vinNumber') }
    get stockNumber() { return this.information.get('stockNumber') }
    get modelYear() { return this.information.get('modelYear') }
    get make() { return this.information.get('make') }
    get model() { return this.information.get('model') }
    get trimLevel() { return this.information.get('trimLevel') }
    get bodyStyle() { return this.information.get('bodyStyle') }
    get engine() { return this.information.get('engine') }
    get transmission() { return this.information.get('transmission') }
    get driveline() { return this.information.get('driveline') }
    get type() { return this.information.get('type') }
    get cylinders() { return this.information.get('cylinders') }
    get miles() { return this.information.get('miles') }
    get exteriorColor() { return this.information.get('exteriorColor') }
    get interiorColor() { return this.information.get('interiorColor') }
    get exteriorColorMinor() { return this.information.get('exteriorColorMinor') }
    get odometer() { return this.information.get('odometer') }
    get curbWeight() { return this.information.get('curbWeight') }
    get carryingWeight() { return this.information.get('carryingWeight') }
    get gvwr() { return this.information.get('gvwr') }
    get tonnage() { return this.information.get('tonnage') }
    get sellingPrice() { return this.information.get('sellingPrice') }
    get purchaseCost() { return this.information.get('purchaseCost') }
    get totalFloorPlanCost() { return this.information.get('totalFloorPlanCost') }
    get totalCost() { return this.information.get('totalCost') }
    get writeDownCost() { return this.information.get('writeDownCost') }
    get downPayment() { return this.information.get('downPayment') }
    get bookValue() { return this.information.get('bookValue') }

    /*
    * life cycles
    */
    constructor(private router: Router, private route: ActivatedRoute, private ms: MessageService, private master: MasterService, private store: StoreService) {}

    ngOnInit() {
        this.lotId = parseInt(localStorage.getItem('lot') || this.store.lotSelected)
        this.information.get("lotId").setValue(this.lotId)
    }

    ngAfterViewInit(): void {
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.
        // this.getVendors()
        this.getLots()
        this.getPaymentMethods()
        this.getInventoryStatuses()
        this.getGeneralDetails()
    }

    ngOnChanges(changes: SimpleChanges): void {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        //Add '${implements OnChanges}' to the class.
        if (changes.reconCostTotal || changes.floorPlanCostTotal) {
            this.calculateTotal()
        }
        
        if (changes.inventoryId) {
            this.inventoryId = changes.inventoryId.currentValue
            this.getGeneralDetails()
        }
    }

    /*
    * functions
    */
    public getGeneralDetails() {
        if (this.inventoryId <= 0) {
            return
        }

        this.master.get(`inventory/${this.inventoryId}`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.setInformation(res.data.inventory)
            this.emitterInformation$.emit({ message: 'setInventoryId', inventoryId: this.inventoryId, stockNumber: res.data.inventory.stockNumber, vehicleId: res.data.inventory.vehicleId })
            this.emitterInformation$.emit({ message: 'setDetailInfo', detailInfo: { createdBy: res.data.inventory.createdByUser ? res.data.inventory.createdByUser.firstName + " " + res.data.inventory.createdByUser.lastName : null, modifiedBy: res.data.inventory.modifiedByUser ? res.data.inventory.modifiedByUser.firstName + " " + res.data.inventory.modifiedByUser.lastName : null, createdAt: res.data.inventory.createdAt, updatedAt: res.data.inventory.updatedAt } })
            this.emitterInformation$.emit({ message: 'setFPDefaultData', purchaseCost: res.data.inventory.purchaseCost, dateOnLot: res.data.inventory.dateOnLot })
            this.calculateTotal()
        })
    }

    public deleteInventory() {
        if (this.statusId === 6) {
            return
        }

        this.master.discard(`inventory/${this.inventoryId}`, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            (document.getElementById('btn-delete-generalInformation') as HTMLButtonElement).click();
            this.router.navigate([['/inventory']])
        })
    }

    public saveInventory() {
        if (this.inventoryId <= 0) {
            this.createInventory()
        }

        this.modifyInventory()
    }

    public modifyInventory() {
        if (this.inventoryId <= 0) {
            return
        }

        if (this.saving) {
            return
        }

        const payload = JSON.parse(JSON.stringify(this.information.value))
        payload['purchaseCost'] = clean(payload['sellingPrice'])
        payload['totalFloorPlanCost'] = clean(payload['totalFloorPlanCost'])
        payload['totalCost'] = clean(payload['totalCost'])
        payload['writeDownCost'] = clean(payload['writeDownCost'])
        payload['downPayment'] = clean(payload['downPayment'])
        payload['bookValue'] = clean(payload['bookValue'])
        payload['sellingPrice'] = clean(payload['sellingPrice'])
        payload['tonnage'] = (payload['tonnage'] && (payload['tonnage'].length > 0)) ? payload['tonnage'] : null
        payload['curbWeight'] = clean(payload['curbWeight'])
        payload['carryingWeight'] = clean(payload['carryingWeight'])
        payload['gvwr'] = clean(payload['gvwr'])
        payload['miles'] = clean(payload['miles'])

        this.saving = true
        this.master.put(`inventory/${this.inventoryId}`, { inventoryData: payload, featuresList: JSON.stringify(this.featuresList) }, res => {
            this.saving = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.data.error == 'Error: Stock Number is taken.') {
                this.information.controls['stockNumber'].setErrors({ 'duplicateFound': "Error! Stock Number is taken." });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.setInformation(res.data.inventory)
            this.emitterInformation$.emit({ message: 'setInventoryId', inventoryId: this.inventoryId, stockNumber: res.data.inventory.stockNumber })
            this.emitterInformation$.emit({ message: 'setDetailInfo', detailInfo: { modifiedBy: res.data.inventory.modifiedByUser ? res.data.inventory.modifiedByUser.firstName + " " + res.data.inventory.modifiedByUser.lastName : null, createdAt: res.data.inventory.createdAt, createdBy: res.data.inventory.createdByUser.firstName + " " + res.data.inventory.createdByUser.lastName, updatedAt: res.data.inventory.updatedAt } })
            this.emitterInformation$.emit({ message: 'setFPDefaultData', purchaseCost: res.data.inventory.purchaseCost, dateOnLot: res.data.inventory.dateOnLot })
            //this.router.navigate([`/inventory/${this.inventoryId}`]);
            this.calculateTotal()
        })
    }

    public createInventory() {
        if (this.saving) {
            return
        }

        const payload = JSON.parse(JSON.stringify(this.information.value))
        payload['purchaseCost'] = clean(payload['sellingPrice'])
        payload['totalFloorPlanCost'] = clean(payload['totalFloorPlanCost'])
        payload['totalCost'] = clean(payload['totalCost'])
        payload['writeDownCost'] = clean(payload['writeDownCost'])
        payload['downPayment'] = clean(payload['downPayment'])
        payload['bookValue'] = clean(payload['bookValue'])
        payload['sellingPrice'] = clean(payload['sellingPrice'])
        payload['tonnage'] = (payload['tonnage'] && (payload['tonnage'].length > 0)) ? payload['tonnage'] : null
        payload['curbWeight'] = clean(payload['curbWeight'])
        payload['carryingWeight'] = clean(payload['carryingWeight'])
        payload['gvwr'] = clean(payload['gvwr'])
        payload['miles'] = clean(payload['miles'])
        

        this.saving = true
        this.master.post(`inventory/`, { inventoryData: payload, featuresList: JSON.stringify(this.featuresList) }, res => {
            this.saving = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.data.error == 'Error: Stock Number is taken.') {
                this.information.controls['stockNumber'].setErrors({ 'duplicateFound': "Error! Stock Number is taken." });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.setInformation(res.data.inventory)
            this.emitterInformation$.emit({ message: 'setInventoryId', inventoryId: this.inventoryId, stockNumber: res.data.inventory.stockNumber })
            this.emitterInformation$.emit({ message: 'setDetailInfo', detailInfo: { createdBy: res.data.inventory.createdByUser.firstName + " " + res.data.inventory.createdByUser.lastName, modifiedBy: null, createdAt: res.data.inventory.createdAt, updatedAt: null } })
            this.emitterInformation$.emit({ message: 'setFPDefaultData', purchaseCost: res.data.inventory.purchaseCost, dateOnLot: res.data.inventory.dateOnLot })
            this.emitterInformation$.emit({ message: 'addedVehicle', inventoryId: this.inventoryId, stockNumber: res.data.inventory.stockNumber })
            this.vehicleSelectedEvent.emit(this.inventoryId)
            //this.router.navigate([`/inventory/${this.inventoryId}`]);
            this.calculateTotal()
        })
    }

    public async setInformation(inventory: []) {
        if (!inventory || inventory.length <= 0) return

        this.inventoryId = inventory['id']
        this.statusId = inventory['statusId']
        this.information.setValue({
            vinNumber: inventory['vehicle'].vinNumber || null,
            stockNumber: inventory['stockNumber'] || null,
            miles: formatCount(inventory['miles'] || null),
            odometer: inventory['odometer'] || null,
            exteriorColor: inventory['exteriorColor'] || null,
            exteriorColorMinor: inventory['exteriorColorMinor'] || null,
            interiorColor: inventory['interiorColor'] || null,
            type: inventory['vehicle'].type || null,
            modelYear: inventory['vehicle'].modelYear || null,
            make: inventory['vehicle'].make || null,
            model: inventory['vehicle'].model || null,
            cylinders: inventory['vehicle'].cylinders || null,
            transmission: inventory['vehicle'].transmission || null,
            driveline: inventory['vehicle'].driveline || null,
            bodyStyle: inventory['vehicle'].bodyStyle || null,
            trimLevel: inventory['vehicle'].trimLevel || null,
            engine: inventory['vehicle'].engine || null,
            curbWeight: formatCount(inventory['vehicle'].curbWeight || null),
            carryingWeight: formatCount(inventory['vehicle'].carryingWeight || null),
            gvwr: formatCount(inventory['gvwr'] || null),
            tonnage: inventory['vehicle'].tonnage || '',
            lotId: inventory['lotId'] || null,
            id: inventory['id'] || null,
            vehicleId: inventory['vehicleId'] || null,
            purchaseCost: formatCurrency(inventory['purchaseCost'] || null),
            totalFloorPlanCost: formatCurrency(inventory['totalFloorPlanCost'] || null),
            totalCost: formatCurrency(inventory['totalCost'] || null),
            sellingPrice: formatCurrency(inventory['sellingPrice'] || null),
            writeDownCost:  formatCurrency(inventory['writeDownCost'] || null),
            downPayment: formatCurrency(inventory['downPayment'] || null),
            bookValue: formatCurrency(inventory['bookValue'] || null),
            licensePlateNumber: inventory['licensePlateNumber'] || null,
            announcements: inventory['announcements'] || null,
            dateTitleDeliveredCustomer: inventory['dateTitleDeliveredCustomer'] || null,
            dateOnLot: inventory['dateOnLot'] || null,
            inventorySourceId: inventory['inventorySourceId'] || null,
            source: inventory['source'] || null,
            keyNumberAssigned: inventory['keyNumberAssigned'] || null,
            airbagStatus: inventory['airbagStatus'] || null,
            inspectionDate: inventory['inspectionDate'] || null,
            datePurchased: inventory['datePurchased'] || null,
            titleCondition: inventory['titleCondition'] || null,
            inspectionDueDate: inventory['inspectionDueDate'] || null,
            datePaid: inventory['datePaid'] || null,
            titleLocation: inventory['titleLocation'] || null,
            inspectionExpires: inventory['inspectionExpires'] || null,
            paymentMethod: inventory['paymentMethod'] || null,
            titleNumber: inventory['titleNumber'] || null,
            dateTitleReceived: inventory['dateTitleReceived'] || null,
            cleanStockNumber: inventory['cleanStockNumber'] || null,
            statusId: inventory['statusId'],
        })

        this.featuresList = (JSON.parse(inventory['vehicle'].features))
        this.calculateDaysOld()

        this.information.enable()
        if (this.statusId === 6) { // 6 = Deleted
            this.information.disable()
        } 

        this.showCurrentStatus(this.statusId, inventory['inventoryStatus'].selectable)
    }

    public checkDuplicateVIN(inventoryId: number, stockNumber: string, vinNumber: string) {
        if (!vinNumber || (vinNumber.length <= 0)) {
            return
        }

        this.duplicateVINFound = false
        this.master.get(`inventory/vin/exists?vinNumber=${vinNumber}&inventoryId=${inventoryId}&stockNumber=${stockNumber}`, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status === 200) {
                return
            }

            this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
            this.duplicateVINFound = (res.status === 409)
        })
    }

    public decodeVIN(stockNumber: string, vinNumber: string) {
        this.information.get('vinNumber').setValue(vinNumber.toUpperCase())
        if (this.markAsDeleted || !vinNumber) {
            return
        }

        // set stock Number and check duplicate VIN
        this.setStockNumber(stockNumber, vinNumber)

        //decode VIN here
        this.decode(vinNumber)
    }

    public decode(vinNumber: string) {
        this.master.get(`vendors/vin/decode?vinNumber=${vinNumber.toUpperCase()}&lotId=${this.lotId}`, res => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (!res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            const decoded = res.data
            this.information.get("modelYear").setValue(decoded.modelYear)
            this.information.get("make").setValue(decoded.make)
            this.information.get("model").setValue(decoded.model)
            this.information.get("cylinders").setValue(decoded.cylinders || null)
            this.information.get("transmission").setValue(decoded.transmission || null)
            this.information.get("driveline").setValue(decoded.driveline || null)
            this.information.get("trimLevel").setValue(decoded.trimLevel || null)
            this.information.get("bodyStyle").setValue(decoded.bodyStyle || null)
            this.information.get("engine").setValue(decoded.engine || null)
            this.information.get("curbWeight").setValue(decoded.curbWeight || null)
            this.featuresList = decoded.features

        })
    }

    public showCurrentStatus(statusId: number, deletable: boolean) {
        if (this.information.get('statusId').value !== statusId) {
            let control = (document.getElementById("inventoryStatus_" + this.information.get('statusId').value) as HTMLInputElement);
            if (!control || control.value == 'undefined') {
                return
            }
            control.classList.remove("current");
        }
        this.deletable = deletable
        this.information.get('statusId').setValue(statusId)
        switch (statusId) {
            case 1:
                document.getElementById("process-line").style.setProperty("width", "0%");
                break;
            case 2:
                    document.getElementById("process-line").style.setProperty("width", "25%");
                break;
            case 3:
                document.getElementById("process-line").style.setProperty("width", "50%");
                break;
            case 4:
                document.getElementById("process-line").style.setProperty("width", "75%");
                break;
            case 5:
                document.getElementById("process-line").style.setProperty("width", "100%");
                break;
            case 7:
                // change purchase to trade-in
                document.getElementById("process-line").style.setProperty("width", "0%");
                break;
            default:
                break;
        }

        const statusElement = document.getElementById("inventoryStatus_" + statusId)
        if (!statusElement) return
        statusElement.classList.add('current');
    }

    public listenerChildrens = (e): void => {
        switch (e.message) {
            case 'showNewStatusId':
                this.statusId = e.statusId
                this.showCurrentStatus(this.statusId,true)
                break
            case 'saveScannedVIN':
                this.information.get("vinNumber").setValue(e.vinNumber)
                this.decodeVIN("", e.vinNumber)
                break;
            case 'failedScannedVIN':
                this.information.controls['vinNumber'].setErrors(e.error);
                break;
            default:
                break;
        }
    }

    public setStockNumber(stockNumber: string, vinNumber: string): any {
        if (stockNumber !== "" && !this.information.controls['stockNumber'].hasError) {
            return
        }
        
        this.master.get(`inventory/stock/next?lotId=${this.lotId}&vinNumber=${vinNumber}`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            const info = res.data.inventoryStockData
            if (!info) {
                return
            }

            const stockNumber = info.preStockNumber ? isNaN(info.preStockNumber) ? info.preStockNumber.toUpperCase() : info.preStockNumber : null
            this.information.get("stockNumber").setValue(stockNumber)
            this.information.get("cleanStockNumber").setValue(stockNumber)

            this.checkDuplicateVIN(this.inventoryId, stockNumber, vinNumber)
        })
    }

    public calculateDaysOld = () => {
        let dateOnLot = moment(this.information.get('dateOnLot').value);
        if (!dateOnLot) {
            return 0
        }
        let now = moment(moment().format('YYYY-MM-DD'))
        return now.diff(dateOnLot, 'days')
    }

    public getVendors() {
        //used for inventory source
        this.master.get(`contacts?type=vendor`, (res) => {
            this.vendors = [];
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.vendors = res.data.contacts
        })
    }

    public getLots() {
        //used for inventory source
        this.master.get(`lots`, (res) => {
            this.lots = [];
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }
            this.lots = JSON.parse(JSON.stringify(res.data.lots))
        })
    }

    public calculateTotal() {
        this.costTotal = (this.reconCostTotal ? this.reconCostTotal : 0) + (this.floorPlanCostTotal ? this.floorPlanCostTotal : 0) + parseFloat(this.information.get('purchaseCost').value)
    }

    public restoreInventory() {
        this.master.patch(`inventory/${this.inventoryId}/restore`, null, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.getGeneralDetails();
            (document.getElementById('btn-restore-generalInformation') as HTMLButtonElement).click();
            this.information.enable();
        })
    }

    public getInventoryStatuses() {

        this.master.get(`collections/inventory/statuses?displayable=true`, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.inventoryStatusesDisplayable = res.data.inventoryStatuses
        })
    }

    public getPaymentMethods() {
        this.master.get(`collections/payment/methods`, (res) => {
            this.paymentMethods = [];
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.paymentMethods = res.data.paymentMethods
        })
    }

    public dateInPast(startDate: string) {
        return (group: FormGroup): { [key: string]: any } => {
            let start = group.controls[startDate]
            if (start.value >= moment().format()) {
                return {
                    dates: "Start date from should be in the past"
                };
            }
            return
        }
    }

    public changeFeature(feature: any): void {
        this.featuresList[`${feature.key}`] = (document.getElementById(`${feature.key}`) as HTMLInputElement).value
    }

    public notifyUser() {
        navigator.clipboard.writeText(this.information.get('vinNumber').value).then(() => {
            this.ms.sendMessage("alert", { type: "success", text: `The VIN number is already on your clipboard. Just paste the VIN.` });
            /* Resolved - text copied to clipboard successfully */
        }, () => {
            console.error('Failed to copy');
            /* Rejected - text failed to copy to the clipboard */
        });

    }

    public setContactIdEvent(e) {
        if (!e) {
          return
        }
        this.information.get("inventorySourceId").setValue(e)
    }
}


