import { Component, Injector, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MasterService } from '../../../../../../services/master.service'
import { MessageService } from '../../../../../../services/message.service'
import * as languageLibrary from '../../../../../../services/language'
import { PAYMENT_CYCLES } from '../../../../../../constants/sale'
import { DynamicService } from 'src/app/services/dynamic.service';
import { token } from '../../roles/roles.component';
@Component({
  selector: '[role-floorplan]',
  templateUrl: './role-floorplan.component.html',
  styleUrls: ['./role-floorplan.component.scss']
})
export class ContactFloorPlanComponent implements OnInit {
  public contactId: number = 0
  public contactRoleId: number = 0
  private id: number = 0
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  public words = languageLibrary.language
  public isOpen: boolean = true
  public paymentFrequency: {} = PAYMENT_CYCLES
  public dataIn: string;

  // A handle to navbar event messages so we can unsubscribe later.
  private navbarWatcher: any = null
  
  public floorPlanInformation = new FormGroup({
    id: new FormControl(null),
    contactId: new FormControl(null, [Validators.required]),
    accountNumber: new FormControl(null),
    apr: new FormControl(null, [Validators.pattern('[0-9]{1,2}(\\.[0-9]{1,4})?'), Validators.min(0), Validators.max(99.9999)]),
    daysPerYear: new FormControl(null, [Validators.required, Validators.pattern('[0-9]{1,3}'), Validators.max(366)]),
    daysAllowed: new FormControl(null, [Validators.required, Validators.pattern('[0-9]{1,3}')]),
    reductionAmount: new FormControl(null),
    reductionFrequency: new FormControl('Monthly'),
    feeAmount: new FormControl(null),
    initialFeeAmount: new FormControl(null),
  })
  public calledFrom: string;

  get accountNumber() { return this.floorPlanInformation.get('accountNumber') }
  get apr() { return this.floorPlanInformation.get('apr') }
  get daysPerYear() { return this.floorPlanInformation.get('daysPerYear') }
  get daysAllowed() { return this.floorPlanInformation.get('daysAllowed') }
  get reductionAmount() { return this.floorPlanInformation.get('reductionAmount') }
  get reductionFrequency() { return this.floorPlanInformation.get('reductionFrequency') }
  get feeAmount() { return this.floorPlanInformation.get('feeAmount') }
  get initialFeeAmount() { return this.floorPlanInformation.get('initialFeeAmount') }


  /*
  * Life Cycles
  */
  constructor(private master: MasterService, private ms: MessageService, private inject: Injector, private serv: DynamicService) {
    this.navbarWatcher = this.ms.channelComponents$.subscribe(res => {
      if (res.message == "changeLanguage") {
        this.language = res.value
      }
    })
  }

  deactivateRole() {
    this.serv.outputFromDynamicComponent({ action: "deactivate", contactRoleId: this.contactRoleId });
  }

  ngOnInit() {
    this.dataIn = this.inject.get(token);
    this.contactId = this.dataIn['contactId']
    this.contactRoleId = this.dataIn['contactRoleId']
    this.calledFrom = this.dataIn['calledFrom']

    if (!this.contactId) {
      return
    }

    this.floorPlanInformation.get("contactId").setValue(this.contactId)

    this.getFloorPlan()
  }

  ngOnDestroy() {
    if (!this.navbarWatcher) {
      return
    }

    this.navbarWatcher.unsubscribe()
  }

  /*
  * functions
  */
  public setInformation(contactFloorPlan) {
    this.floorPlanInformation.setValue({
      id: contactFloorPlan['id'],
      contactId: contactFloorPlan['contactId'],
      accountNumber: contactFloorPlan['accountNumber'],
      apr: contactFloorPlan['apr'],
      daysPerYear: contactFloorPlan['daysPerYear'],
      daysAllowed: contactFloorPlan['daysAllowed'],
      reductionAmount: contactFloorPlan['reductionAmount'],
      reductionFrequency: contactFloorPlan['reductionFrequency'],
      feeAmount: contactFloorPlan['feeAmount'],
      initialFeeAmount: contactFloorPlan['initialFeeAmount'],
    })
    this.id = contactFloorPlan['id']
    this.contactId = contactFloorPlan['contactId']
  }

  public save() {
    const identity = parseInt(this.floorPlanInformation.get('contactId').value)
    const id = parseInt(this.floorPlanInformation.get('id').value)
    if (isNaN(identity)) {
      return
    }
    if (isNaN(id)) {
      this.createFloorPlanSetup()
      return
    }

    this.modifyFloorPlanSetup(id)
  }

  public createFloorPlanSetup = (): void => {
    if (this.floorPlanInformation.invalid) {
      return
    }

    this.floorPlanInformation.get('contactId').setValue(this.contactId)
    this.master.post(`contacts/${this.contactId}/floorplan`, { information: this.floorPlanInformation.value, }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      if (!res.data.contactFloorPlan){
        return
      }
      
      this.floorPlanInformation.get('id').patchValue(res.data.contactFloorPlan['id'])

      if (this.calledFrom == "contactAdvancedSearch")
        this.serv.outputFromDynamicComponent({ action: "setupSaved", setupSaved: true, type:"floorPlanVendor" });
    })
  }

  public modifyFloorPlanSetup = (id): void => {
    if (this.floorPlanInformation.invalid) {
      return
    }

    this.floorPlanInformation.get('contactId').setValue(this.contactId)
    this.master.put(`contacts/${this.contactId}/floorplan/${id}`, { information: this.floorPlanInformation.value }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }
      if (this.calledFrom == "contactAdvancedSearch")
        this.serv.outputFromDynamicComponent({ action: "setupSaved", setupSaved: true, type:"floorPlanVendor" });

    })
  }

  public clear = (): void => {
    this.id = parseInt(this.floorPlanInformation.get('id').value)
    this.floorPlanInformation.reset()
    if ((this.contactId <= 0) || (this.id <= 0) || (isNaN(this.id))) {
      return
    }

    this.master.discard(`contacts/${this.contactId}/floorplan/${this.id}`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

    })

  }

  public getFloorPlan() {
    this.master.get(`contacts/${this.contactId}/floorplan`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      if (!res.data.contactFloorPlan) {
        if (this.calledFrom == "contactAdvancedSearch")
        this.serv.outputFromDynamicComponent({ action: "setupNeeded", setupNeeded: true, type:"floorPlanVendor" });
        return
      }

      this.setInformation(res.data.contactFloorPlan)

      if (this.calledFrom == "contactAdvancedSearch") {
        this.serv.outputFromDynamicComponent({ action: "setupNeeded", setupNeeded: false, type:"floorPlanVendor" });
        return
      }
    })
  }
}
