import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "../../../services/message.service";
import { MasterService } from "../../../services/master.service";
import { StoreService } from "../../../services/store.service";
import * as languageLibrary from '../../../services/language'
@Component({
  selector: "app-inventory-detail",
  templateUrl: "./inventory-detail.component.html",
  styleUrls: ["./inventory-detail.component.scss"],
})
export class InventoryDetailComponent implements OnInit {
  /*
   * Variables
   */
  // define if exist
  public savePending: boolean = false
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  // permissions
  public permissions: string[] = [];
  // define the inventory id
  public inventoryId: number = 0;
  // define the vehicle id
  public vehicleId: number = 0;
  // set all data (general information, history, financials and administrative)
  public data: Object = {}
  // set all data (reaconditioning cost, notes and floor plans)
  public data2: Object = {};
  // define if some field was updated
  public isGhost: boolean = false;
  // VIN to display
  public stockNumber: string = "";
  public purchaseCost: number = 0;
  public dateOnLot: Date
  public vinNumber: string = "";
  // loading
  public loading: boolean = false;
  // error type
  public error: string = ''
  // lotId
  public lotId: number = 0;
  public detailInfo: [];
  public reconCostTotal: 0;
  public floorPlanCostTotal: 0;
  public fileURL: string;

  // todo: listen to output of navbar
  public listenerNav = (e): void => {
    switch (e['message']) {
      case 'changeLanguage':
        this.language = e['value']
        break;
      case 'setPermissions':
        this.permissions = e.permissions;
        break
      default:
        break;
    }
  }

  // listener childrens
  public listenerChildrens = (e): void => {
    switch (e.message) {
      case 'setDetailInfo':
        this.detailInfo = e.detailInfo
        break;
      case 'setInventoryId':
        this.inventoryId = e.inventoryId
        this.stockNumber = e.stockNumber
        this.vehicleId = e.vehicleId
        break
      case 'setReconCostTotal':
        this.reconCostTotal = e.reconCostTotal
        break
      case 'setFPCostTotal':
        this.floorPlanCostTotal = e.floorPlanCostTotal
        break
      case 'setFPDefaultData':
        this.purchaseCost = e.purchaseCost
        this.dateOnLot = e.dateOnLot
        break
      case 'setDefaultImage':
        this.fileURL = e.fileURL
        break;
      default:
        break;
    }
  }
  /*
   * Life cycles
   */
  constructor(private route: ActivatedRoute, private router: Router, private ms: MessageService, private master: MasterService, private store: StoreService) { }

  ngOnInit() {
    this.loading = true
    if (this.route.snapshot.params["id"] === 'add') {
      let lotIdParam = parseInt(this.route.snapshot.queryParams['lotId'])
      if (!lotIdParam || isNaN(lotIdParam) || (lotIdParam <= 0)) {
        lotIdParam = 0
      }

      this.lotId = lotIdParam

      if (isNaN(this.lotId) || (this.lotId <= 0)) {
        this.lotId = 0
      }

      this.loading = false
      return
    }

    this.stockNumber = this.route.snapshot.queryParams['stockNumber']

    let identity = parseInt(this.route.snapshot.params['id'])

    if (!identity || isNaN(identity) || (identity <= 0)) {
      identity = 0
    }

    this.inventoryId = identity

    if (isNaN(this.inventoryId) || (this.inventoryId <= 0)) {
      this.inventoryId = 0
    }

    this.loading = false
  }
}
