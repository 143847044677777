import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DynamicService } from 'src/app/services/dynamic.service';
import { MasterService } from 'src/app/services/master.service';
import { MessageService } from 'src/app/services/message.service';
import { token } from '../../roles.component';
import * as languageLibrary from '../../../../../../../services/language'

@Component({
  selector: 'personal-auto-insurance',
  templateUrl: './personal-auto-insurance.component.html',
  styleUrls: ['./personal-auto-insurance.component.scss']
})
export class PersonalAutoInsuranceComponent implements OnInit {
  @Input() contactId: number
  public contactRoleId: number = 0
  public words = languageLibrary.language
  public language: string = 'EN'
  public personalAutoInsuranceInformation = new FormGroup({
    id: new FormControl(null),
    contactId: new FormControl(null, [Validators.required]),
    insuranceContactId: new FormControl(null, [Validators.required]),
    policyNumber: new FormControl(null),
    effectiveFrom: new FormControl(null),
    expiryDate: new FormControl(null)
  })
  public autoInsurances = []
  public personalAutoInsurances = []
  public dataIn: string;
  public id: number = 0;
  constructor(private master: MasterService, private ms: MessageService, private inject: Injector, private serv: DynamicService) {
  }

  deactivateRole() {
    this.serv.outputFromDynamicComponent({ action: "deactivate", contactRoleId: this.contactRoleId });
  }
  ngOnInit(): void {

    this.dataIn = this.inject.get(token);
    this.contactId = this.dataIn['contactId']
    this.contactRoleId = this.dataIn['contactRoleId']

    if (!this.contactId) {
      return
    }

    this.personalAutoInsuranceInformation.get("contactId").setValue(this.contactId)

    this.getPersonalAutoInsurance()
    // this.getAutoInsurance()
  }

  public getPersonalAutoInsurance() {
    this.resetInformation()
    this.master.get(`contacts/${this.contactId}/personalAutoInsurance`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      if (res.data.contactPersonalAutoInsurance.length == 0) {
        return
      }

      this.personalAutoInsurances = res.data.contactPersonalAutoInsurance
    })
  }
  
  public getAutoInsurance() {
    this.master.get(`contacts?type=autoInsurance`, (res) => {
        if (!res || !res.data) {
            this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
            return
        }

        if (res.status !== 200) {
            this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
            return
        }

        this.autoInsurances = res.data.contacts
    })
}

  public savePersonalAutoInsurance() {
    if (this.personalAutoInsuranceInformation.get("effectiveFrom").value == "") { this.personalAutoInsuranceInformation.get("effectiveFrom").setValue(null) }
    if (this.personalAutoInsuranceInformation.get("expiryDate").value == "") { this.personalAutoInsuranceInformation.get("expiryDate").setValue(null) }
    if (!this.personalAutoInsuranceInformation.valid) {
      return
    }

    if (this.id <= 0) {
      this.createPersonalAutoInsurance()
      return
    }

    if (this.id >= 1) this.modifyPersonalAutoInsurance()
  }

  createPersonalAutoInsurance() {
    this.master.post(`contacts/${this.contactId}/personalAutoInsurance`, { information: this.personalAutoInsuranceInformation.value, }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      document.getElementById("btn-cancel-personal-auto-insurance").click()
      this.getPersonalAutoInsurance()

    })
  }

  modifyPersonalAutoInsurance() {
    this.master.put(`contacts/${this.contactId}/personalAutoInsurance/${this.id}`, { information: this.personalAutoInsuranceInformation.value, }, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.getPersonalAutoInsurance()
      document.getElementById("btn-cancel-personal-auto-insurance-modify").click()
    })
  }

  deletePersonalAutoInsurance() {
    this.master.discard(`contacts/${this.contactId}/personalAutoInsurance/${this.id}`, res => {
      if (!res) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
        return
      }

      this.getPersonalAutoInsurance()
      document.getElementById("btn-cancel-personal-auto-insurance-delete").click()
    })
  }

  resetInformation() {
    this.personalAutoInsuranceInformation.reset({
      contactId: this.contactId
    })
    this.id = null
  }

  setInformation(info) {
    this.id = info["id"]
    this.personalAutoInsuranceInformation.setValue({
      id: info['id'],
      contactId: info['contactId'],
      insuranceContactId: info['insuranceContactId'],
      policyNumber: info['policyNumber'],
      effectiveFrom: info['effectiveFrom'],
      expiryDate: info['expiryDate'],
    })
  }

  public setContactIdEvent(e) {
      if (!e) {
        return
      }
      this.personalAutoInsuranceInformation.get("insuranceContactId").setValue(e)
  }
}