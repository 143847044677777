import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, } from '@angular/router';
import { MasterService } from '../../../../services/master.service';
import { MessageService } from '../../../../services/message.service'
import { StoreService } from '../../../../services/store.service'
import * as languageLibrary from '../../../../services/language'

// external
import * as moment from 'moment/moment'
import { Observable } from 'rxjs';

@Component({
    selector: 'app-contacts-detail',
    templateUrl: './contacts-detail.component.html',
    styleUrls: ['./contacts-detail.component.scss']
})
export class ContactsDetailComponent implements OnInit {

    /*
    * Variables
    */
    public language: string = 'EN'

    public words = languageLibrary.language
    
    public contactId: number = 0

    public viewAlert: boolean = false
    
    public individualValue: boolean = true
    
    public contact: any;

    // Indicates whether or not the contact role is fixed for this list.
    public fixedRole = false

    // The name of the fixed contact role or null if none.
    public fixedRoleName = null

    // The UI route path of the fixed contact role (default: 'contacts').
    public path = 'contacts'

    /*
    * life cycles
    */
    constructor(private router: Router, private route: ActivatedRoute, private master: MasterService, private ms: MessageService, public store: StoreService) {}

    ngOnInit() {
        const path = this.route.snapshot.url[0].path
        const pattern = new RegExp('employee', 'i')
        this.fixedRole = (path && pattern.test(path))
        if (this.fixedRole) {
            this.fixedRoleName = 'Employees'
            this.path = 'employees'
        }

        this.contactId = parseInt(this.route.snapshot.params['id'])
        if (isNaN(this.contactId) || (this.contactId <= 0)) {
            this.contactId = 0
        }

        this.getContact()
    }

    public getContact() {
        if (!this.contactId || (this.contactId <= 0)) {
            return
        }

        this.master.get(`contacts/${this.contactId}`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }
            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            if (!res.data.contact) {
                return
            }

            this.contact = res.data.contact
        })
    }

    // todo: listen to output of navbar
    public listenerNav = (e): void => {
        switch (e['message']) {
            case 'changeLanguage':
                this.language = e['value']
                break
        }
    }

    // todo: listener to childrens
    public listenerChildrens = (e): void => {
        if (!e || !e.message) {
            return
        }
        switch (e.message) {
            case 'changeLanguage':
                this.language = e['value']
                break;
            case 'individualValue':
                this.individualValue = e.value
                break
            case 'setContactId':
                this.contactId = parseInt(e.value)
                break
        }
    }
}
