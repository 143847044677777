import { Component, OnInit, OnChanges, Input, SimpleChanges } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';
import * as languageLibrary from '../../../../services/language'
// services
import { MessageService } from "../../../../services/message.service";
import { MasterService } from "../../../../services/master.service";
import { StoreService } from "../../../../services/store.service";
import { SecurityService } from '../../../../services/security.service'
//
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { SALE_TYPES } from "src/app/constants/sale"
//
import * as printJs from 'print-js'

@Component({
  selector: 'print-center',
  templateUrl: './print-center.component.html',
  styleUrls: ['./print-center.component.scss']
})
export class PrintCenterComponent implements OnInit, OnChanges {
  // ---------------
  // *  variables
  // --------------
  
  // Unique internal identity of the sale
  @Input() saleId: number = 0
  
  // Unique internal identity for the sale type of the associated sale.
  @Input() saleType: number = 0

  // permissions
  @Input() permissions: Object[] = []

  private lotId: number = null

  private lotAddress: Object = null

  // A list of available forms for the sale.
  public forms: Object[] = []

  // A grouping of the existing forms suitable for display in the UI.
  public groups: Object = {}

  public showMergeDocumentSection: boolean = false

  //
  public hideNoCopies: boolean = false

  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'

  // set all words
  public words = languageLibrary.language

  // oder of table
  public order: Object = { isAscend: false, type: '' }

  // files
  public files = []
  
  //
  public emails: string[] = []

  //
  public documents: Object[] = []

  //
  public documentSelected: Object[] = []

  // loading status
  public loading: boolean = false

  public pdf: any = null
  
  //
  public emailForm = new FormGroup({
    subject: new FormControl('', [Validators.required]),
    bodyText: new FormControl('', [Validators.required])
  })

  public setupValuesString: string = null
  public selectedForm: any;
  //
  public imagesValid: string[] = ['jpeg', 'jpg', 'png', 'bmp', 'gif', 'eps', 'svg', 'heic', 'heif']

  get groupKeys() { return this.groups ? Object.keys(this.groups) : [] }
  public formKeys(group) { return (this.groups && this.groups[group]) ? Object.keys(this.groups[group]) : [] }
  public copies(group, form) { return (this.groups && this.groups[group] && this.groups[group][form]) ? this.groups[group][form][0].copies : 0 }
  public revision(group, form) { return (this.groups && this.groups[group] && this.groups[group][form]) ? this.groups[group][form][0].revision : 0 }
  public modalRequired(group, form) { return (this.groups && this.groups[group] && this.groups[group][form]) ? this.groups[group][form][0].modalRequired : 0 }
  public modal(group, form) { return (this.groups && this.groups[group] && this.groups[group][form]) ? this.groups[group][form][0].modal : 0 }
  public setupValues(group, form) { return (this.groups && this.groups[group] && this.groups[group][form]) ? this.groups[group][form][0].setupValues : 0 }
  public form(group, form) { return (this.groups && this.groups[group] && this.groups[group][form]) ? this.groups[group][form][0] : 0 }

  // ---------------
  // *  life cycles
  // --------------
  constructor(private ms: MessageService, private master: MasterService, private store: StoreService, private security: SecurityService, private sanitizer: DomSanitizer) { }
  
  ngOnInit() {
    this.lotId = parseInt(localStorage.getItem('lot') || this.store.lotSelected)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return
    }

    if (changes.saleType) {
      this.discoverForms()
    }
  }

  // todo: get reports
  public discoverForms = async () => {
    if (!this.saleType || (this.saleType <= 0)) {
      return
    }

    const lotState = await this.determineLotState()

    const res = await this.master.getAsync(`forms?lotId=${this.lotId}&type=point-of-sale${lotState ? '&state=' + lotState : ''}`)

    if (!res || (res['status'] !== 200)) {
      this.ms.sendMessage("alert", { type: "danger", text: res ? res['data'].error : this.words[this.language]['apiNoResponse']})
      return
    }

    let saleTypeKey = SALE_TYPES[this.saleType].toLowerCase()
    if (saleTypeKey.indexOf(' ')) {
      saleTypeKey = saleTypeKey.split(' ')[0]
    }

    this.forms = res.data.filter(el => el[saleTypeKey] == true)
    this.groups = this.grouped()
  }

  /**
   * Group the current forms list by category and name 
   * so they are ready for display in the UI.
   */
  public grouped = (): object => {
    const groups = {}

    if (!this.forms || (this.forms.length <= 0)) {
      return
    }

    for (const form of this.forms) {
      if (!groups[form['category']]) {
        groups[form['category']] = {}
      }

      if (!groups[form['category']][form['name']]) {
        groups[form['category']][form['name']] = []
      }

      groups[form['category']][form['name']].push({
        id: parseInt(form['id']),
        producer: form['producer'],
        copies: parseInt(form['copies']),
        selected: true,
        revision: form['revision'],
        modal: form['modal'],
        modalRequired: form['modalRequired'],
        setupValues: form['setupValues'],
        name: form['name'],
      })
    }

    return groups
  }

  /**
   * 
   * @param group 
   * @param form 
   */
  public view = (group, form) => {
    if (!this.groups || !group || !form) {
      return
    }

    if (!this.groups[group] || !this.groups[group][form] || (this.groups[group][form].length <= 0)) {
      return
    }

    const item = this.groups[group][form][0]

    this.download(item.id, form)
  }

  /**
   * Download the given form from the API.
   * @param id Unique internal identity of the form to construct and download.
   * @param name The name of the form to download.
   */
  public download = (id: number, name: string) => {
    if (!this.saleId || this.saleId <= 0) {
      return
    }

    if (!id || id <= 0) {
      return
    }

    const fileName = name ? `${name.replace(/[^A-Za-z]+/g, '').toLowerCase()}.pdf` : 'form.pdf'
    this.pdf = null

    this.master.getDataFromURL(`sales/${this.saleId}/forms/${id}?lotId=${this.lotId}`, fileName, (meta) => {
      if (!meta || !meta.status || !meta.data) {
        this.ms.sendMessage("alert", { type: "danger", text: (meta && meta.error) ? meta.error : this.words[this.language]['apiNoResponse']})
        return
      }

      this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(meta.data)
      if (!this.pdf) {
        this.ms.sendMessage("alert", { type: "danger", text: "Pdf meta data is empty or null."})
        return
      }

      const btn = document.getElementById('btn-view-pdf-modal') as HTMLButtonElement
      if (!btn) {
        return
      }

      btn.click()
    })
  }

  /**
   * Query for or used cached sale lot state.
   * @returns A two-character state abbreviation for the associated sale lot
   */
  private determineLotState = async () => {
    if (!this.lotId || (this.lotId <= 0)) {
      return null      
    }

    if (this.lotAddress) {
      return this.lotAddress['state']
    }

    const res = await this.master.getAsync(`lots/${this.lotId}`)
    if (!res || !res.data || !res.data['lotDetails'] || !res.data['lotDetails']['contact'] || 
      !res.data['lotDetails']['contact']['contactAddresses'] || (res.data['lotDetails']['contact']['contactAddresses'].length <= 0)) {
      return null
    }

    const addy = res.data['lotDetails']['contact']['contactAddresses'].find(addy => addy.primaryAddress == 1)
    if (!addy || !addy.state) {
      return null
    }

    this.lotAddress = addy
    return this.lotAddress['state']
  }

  // todo: get reports
  public generateReport = async (document: Object) => {

  }

  public openModal(group, form){
    this.setupValuesString = this.setupValues(group, form)
    this.selectedForm = this.form(group,form)
    document.getElementById("btn-view-dynamicSetupForm-modal").click()
  }
}
