import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { formatDate } from '@angular/common'
// services
import { MessageService } from "../../../../services/message.service";
import { MasterService } from "../../../../services/master.service";
import { StoreService } from "../../../../services/store.service";
import { SecurityService } from '../../../../services/security.service'
//
// import { NgxXml2jsonService } from "ngx-xml2json";
import * as languageLibrary from '../../../../services/language'
import { formatCurrency } from "src/app/utils/format";
import { clean } from "src/app/utils/numeric";
import { Subject } from "rxjs";

import { SALE_TYPES } from "src/app/constants/sale";
import * as moment from 'moment'

@Component({
    selector: "contract",
    templateUrl: "./contract.component.html",
    styleUrls: ["./contract.component.scss"],
})
export class DealContractComponent implements OnInit, OnChanges {
    
    //
    @Input() isUnLock = true
    
    // sale id
    @Input() saleId: number = null
    
    //
    @Input() buyerType = null

    //
    @Input() presets = null

    // permissions
    @Input() permissions: Object[] = []

    //
    @Input() isQuote: boolean = true

    // 
    @Input('createSaleEvent') createSaleEvent: Subject<number>

    //
    @Input() buyerChanged: Subject<Object>

    //
    @Input() vehicleChanged: Subject<Object>

    //
    @Input() tradeChanged: Subject<any>

    //
    @Output() priceChanged: EventEmitter<Object> = new EventEmitter()

    //
    @Output() saleTypeChangeEvent: EventEmitter<Object> = new EventEmitter()

    //
    @Output() saleStatusChangeEvent: EventEmitter<Object> = new EventEmitter()

    //
    public saleSetupChangeEvent: Subject<any> = new Subject();

    //
    public ttlResetEvent: Subject<any> = new Subject();

    //
    private buyerId: number = 0
    
    //
    private saleVehicleId: number = 0

    //
    public kpi: Object = {
        subjectToFinance: 0,
        totalPaymentAmount: 0,
        financeCharges: 0
    }

    //  An associative array of sale types with the id as the key
    public saleTypes: Object = SALE_TYPES

    public saleType: number = 0
    
    //
    public loading: boolean = false

    // save the language
    public language: any = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'

    // set all words
    public words = languageLibrary.language

    //
    public ttl: number = 0
    
    public accessories: number = 0
    
    public down: number = 0

    public totalSalePrice: number = 0

    public driveOutPrice: number = 0

    public allowance: number = 0

    public payoff: number = 0

    public financed: number = 0

    public salesTax: number = 0

    public salesTaxRate: number = 0

    //
    public fees: Object[] = []
    //
    public products: number = 0

    public saleStatuses: [] = []

    //
    public errorsCatalog = {
        'username': 'User Name',
        'password': 'Password',
        'corporateName': 'Corporate Name',
        'firstName': 'First Name',
        'middleName': 'Middle Name',
        'lastName': 'Last Name',
        'suffix': 'Suffix',
        'dealNumber': 'Deal Number',
        'entityType': 'Entity Type',
        'city': 'city',
        'state': 'State',
        'street1': 'Street',
        'zipcode': 'Zip code',
        'salesPrice': 'Sales Price',
        'bodyType': 'Body Type',
        'majorColorCode': 'Color',
        'make': 'Make',
        'model': 'model',
        'modelYear': 'Year',
        'vin': 'VIN Number'
    }

    /** The lot or buyer zip code associated with the current sale. */
    public saleZip: string = null

    /** The sale retail vehicle year of manufacture. */
    public vehicleYear: number = null
    
    //
    public txdmv: any = {}

    public form: FormGroup = this.fb.group({
        saleDate: [moment().format('yyyy-MM-DD'), []],
        type: [0, []],
        status:  [1, []], // 1 = Pending
        baseSalePrice: [0, []],
        expires: [moment().format('yyyy-MM-DD'), []],
    })

    /** 
     * The last down or deferred payment date or the 
     * sale date if no down or deferred payments. Used 
     * by terms to compute first payment date. 
    */
    public soldOrLastDeferDate = null
    private lastDeferredPayDate = null

    public product: number = 0
    public productCount: any;
    public stage: string = null;

    // A handle to navbar event messages so we can unsubscribe later.
    private navbarWatcher: any = null

    get baseSalePrice (): FormControl { return this.form.get('baseSalePrice') as FormControl }
    get price (): number { return clean(this.form.get('baseSalePrice').value) }
    get feeable (): boolean { return ((this.saleType) && (['Cash deal', 'Outside', 'BHPH'].includes(this.saleTypes[this.saleType]))) }
    get termable (): boolean { return ((this.saleType) && (['Outside', 'BHPH'].includes(this.saleTypes[this.saleType]))) }
    get outside(): boolean { return ((this.saleType) && (this.saleTypes[this.saleType] === 'Outside')) }
    get bhph(): boolean { return ((this.saleType) && (this.saleTypes[this.saleType] === 'BHPH')) }
    get sold(): string { return moment(this.form.get('saleDate').value).format('yyyy-MM-DD') }
    get wholesale(): boolean { return ((this.saleType) && (this.saleTypes[this.saleType] === 'Wholesale')) }
    get saleTypeKeys() { return Object.keys(this.saleTypes) }

    get statuses() { 
        if (!this.saleStatuses || !this.saleType || !this.saleTypes) {
            return []
        }

        const type = this.saleTypes[this.saleType].toLowerCase().split(' ')[0]
        return this.saleStatuses.filter((status) => status[type] === true)
    }

    constructor(private route: ActivatedRoute, private ms: MessageService,
        private master: MasterService, private store: StoreService, 
        private security: SecurityService, private fb: FormBuilder) {
        
        this.navbarWatcher = this.ms.channelComponents$.subscribe(res => {
            if (res.message == 'setPermissions') {
                // permissions on user list
                this.setAtributes(res['integrations'])
            }
        })
    }

    ngOnInit() {
        this.saleId = parseInt(this.route.snapshot.params['id'])
        if (!this.saleId || isNaN(this.saleId) || (this.saleId <= 0)) {
            this.saleId = null
        }

        this.determineTermComputeDate()

        const suggestedSaleType = parseInt(this.route.snapshot.queryParams['type']) 
        if (suggestedSaleType && !isNaN(suggestedSaleType) && (suggestedSaleType > 0)) {
            this.saleType = suggestedSaleType
            this.form.get('type').setValue(suggestedSaleType)
            this.saleTypeChangeEvent.emit(suggestedSaleType)
        }

        this.readSaleSummary()
        this.fetchSaleStatuses()

        this.createSaleEvent.subscribe((saleId) => {
            if (saleId <= 0) {
                this.saleId = null
                return
            }

            this.saleId = saleId
        })

        this.buyerChanged.subscribe((buyer) => {
            if (!buyer && (this.buyerId > 0)) { // existing buyer discarded?
                this.ttlResetEvent.next()
                return
            }

            const buyerId = parseInt(buyer['id'])
            if (isNaN(buyerId) || (buyerId < 0) || (this.buyerId == buyerId)) {
                return
            }

            this.buyerId = buyerId
            this.saleZip = buyer['zip']

            if (buyer['init'] && buyer['init'] === true) { // initial load?
                return
            }

            this.ttlResetEvent.next()
        })
        
        this.vehicleChanged.subscribe((vehicle) => { // retail vehicle changed
            const initial = (!this.saleVehicleId || this.saleVehicleId <= 0)

            const vehicleId = parseInt(vehicle['id'])
            if (isNaN(vehicleId) || (vehicleId < 0) || (this.saleVehicleId == vehicleId)) {
                return
            }

            this.saleVehicleId = vehicleId
            this.vehicleYear = vehicle['year']

            if (initial) { // for the initial sale vehicle read we just need the id and year
                return
            }

            const currentPrice = clean(this.baseSalePrice.value)
            const newPrice = parseFloat(vehicle['price'])

            if (isNaN(newPrice)) {
                return
            }

            if (currentPrice == newPrice) {
                return
            }

            if ((this.saleVehicleId) && (newPrice > 0)) { // user has customized the base price?
                return
            }

            this.baseSalePrice.setValue(formatCurrency(newPrice))
            this.changed(null)
        })

        this.tradeChanged.subscribe((trades) => { // trade added/removed or details changed
            if (!trades) {
                this.allowance = 0
                this.payoff = 0
                this.financed = 0
                this.calculateTotal(false)
                return
            }

            this.allowance = parseFloat(trades.allowance)
            this.payoff = parseFloat(trades.payoff)
            this.calculateTotal(false)
        })
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes) {
            return
        }

        const first = Object.values(changes).some(c => c.isFirstChange())
        if (first) {
            return
        }

        if (changes.isUnLock) {
            this.form.disable({ emitEvent: false })
            if (this.isUnLock) {
                this.form.enable({ emitEvent: false })
            }
        }

        if (changes.saleId) {
            this.readSaleSummary()
        }

        if (changes.saleType) {
            this.ttl = this.feeable ? this.ttl : 0
            this.calculateTotal(true)
        }

        if (changes.presets) {
            if (!this.presets) {
                return
            }
            
            this.saleSetupChangeEvent.next(this.presets)

            const enableBHPH = this.presets['enableBHPH']

            this.saleTypes = SALE_TYPES
            if (!enableBHPH) { // remove BHPH from available finance types?
                this.saleTypes = {}
                const keys = Object.keys(SALE_TYPES)
                for (const key in keys) {
                    if (!key || !SALE_TYPES[key]) {
                        continue
                    }

                    if (SALE_TYPES[key] === 'BHPH') {
                        continue
                    }

                    this.saleTypes[key] = SALE_TYPES[key]
                } 
            }

            const defaultSaleType = parseInt(this.presets['defaultSaleTypeId'] || 0)      
            if (defaultSaleType && !this.saleType) {
                this.form.get('type').setValue(defaultSaleType)
            }
        }
    }

    ngOnDestroy() {
        this.buyerChanged.unsubscribe()
        this.vehicleChanged.unsubscribe()
        this.tradeChanged.unsubscribe()
        
        if (!this.navbarWatcher) {
            return
        }

        this.navbarWatcher.unsubscribe()
    }

    /**
     * Handle blur and commit events on the base sale price currency input.
     * @param e 
     */
    public changed = (e): void => {
        const price = clean(this.baseSalePrice.value)
        if (!price || isNaN(price) || (price < 0)) {
            return
        }

        this.calculateTotal(true)
        this.priceChanged.emit({ value: price })
    }

    //todo: term listener
    public termListener = (e): void => {
        this.kpi = { ...e }

        if (this.kpi && this.kpi['totalDown']) {
            this.down = parseFloat(this.kpi['totalDown'])
            this.calculateTotal(false)
        }
    }

    public editable = () => {
        if (!this.isUnLock) {
            return false
        }

        if (!this.saleId || (this.saleId <= 0)) {
            return false
        }

        if (!this.buyerId || (this.buyerId <= 0)) {
            return false
        }

        if (!this.saleVehicleId || (this.saleVehicleId <= 0)) {
            return false
        }

        return true
    }

    private fetchSaleStatuses = ():void => {
        this.master.get(`collections/sales/statuses`, res => {
            this.saleStatuses = res.data.Statuses ? res.data.Statuses.slice() : []
        })
    }

    // =============================
    // ? read taxes
    // =============================
    public readSaleSummary = (): void => {
        if (!this.saleId || (this.saleId <= 0)) {
            return
        }

        this.master.get(`sales/${this.saleId}`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            let suggestedSaleType = parseInt(this.route.snapshot.queryParams['type']) 
            if (!suggestedSaleType || isNaN(suggestedSaleType) || (suggestedSaleType <= 0)) {
                suggestedSaleType = 0
            }

            let defaultSaleType = this.presets ? parseInt(this.presets['defaultSaleTypeId'] || 0) : 0
            if (!defaultSaleType || isNaN(defaultSaleType) || (defaultSaleType <= 0)) {
                defaultSaleType = 0
            }

            this.form.patchValue({
                saleDate: res.data.sale.saleDate,
                type: parseInt(res.data.sale.saleFinancingTypeId || suggestedSaleType || defaultSaleType),
                status: parseInt(res.data.sale.saleStatusId),
                baseSalePrice: formatCurrency(res.data.sale.salePrice),
                expires: res.data.sale.expirationDate,
            })

            this.determineTermComputeDate()
            this.saleType = parseInt(this.form.get('type').value)
            this.saleTypeChangeEvent.emit(this.saleType)
            this.stage = res.data.sale.stage

            this.totalSalePrice = parseFloat(res.data.sale.totalSalePrice || 0)
            this.driveOutPrice = parseFloat(res.data.sale.driveOutPrice || 0)
            this.calculateTotal(false)
            this.listen()
        })
    }

    /**
     * Listen for changes to sale date and type after the initial sale load.
     */
    private listen = () => {
        this.form.get('saleDate').valueChanges.subscribe(newValue => {
            const parsed = new Date(newValue)
            if (!parsed || (parsed.toString().toLowerCase() == 'invalid date')) {
                return
            }

            this.save()
            this.determineTermComputeDate()
        })

        this.form.get('expires').valueChanges.subscribe(newValue => {
            const parsed = new Date(newValue)
            if (!parsed || (parsed.toString().toLowerCase() == 'invalid date')) {
                return
            }

            this.save()
        })

        this.form.get('type').valueChanges.subscribe(newValue => {
            this.saleType = parseInt(newValue)
            this.save()
        })

        this.form.get('status').valueChanges.subscribe(newValue => {
            this.save()
        })
    }

    // =============================
    // ? read taxes
    // =============================
    public calculateTotal = (save: boolean): void => {
        this.totalSalePrice = clean(this.baseSalePrice.value) + this.accessories - this.allowance
        this.driveOutPrice = this.totalSalePrice + this.ttl + this.products
        this.financed = this.driveOutPrice - this.down + this.payoff

        if (!save) {
            return
        }

        this.save()
    }

    //
    public transformNumber = (value: any): number => {
        if (!value) {
            return 0
        }

        const result = parseFloat(value)
        if (!result || isNaN(result)) {
            return 0
        }

        return result
    }

    public save = async () => {
        if (!this.saleId || (this.saleId <= 0)) {
            return
        }

        const payload = {
            saleDate: this.form.get('saleDate').value,
            saleFinancingTypeId: parseInt(this.form.get('type').value),
            saleStatusId: parseInt(this.form.get('status').value),
            salePrice: clean(this.baseSalePrice.value),
            totalSalePrice: this.totalSalePrice,
            driveOutPrice: this.driveOutPrice,
            expirationDate: this.form.get('expires').value
        }
        
        await this.master.patch(`sales/${this.saleId}`, payload, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            if (this.form.get('type').dirty) {
                this.saleTypeChangeEvent.emit(this.saleType)
            }

            if (this.form.get('status').dirty) {
                this.saleStatusChangeEvent.emit(parseInt(this.form.get('status').value))
            }

            this.form.markAsPristine()
        })
    }

    // todo ttl listener
    public ttlListener = (meta: any): void => {
        this.fees = meta.fees
        this.salesTax = meta.salesTax || 0
        this.salesTaxRate = meta.salesTaxRate || 0
        this.ttl = meta.value
        this.calculateTotal(meta.save || false)
    }

    // todo products listener
    public productsListener = (meta: any): void => {
        this.products = meta.value
        this.productCount = meta.productCount
        this.calculateTotal(meta.save || false)
    }

    //
    public accessoriesChanged = (meta): void => {
        this.accessories = meta.value
        this.calculateTotal(meta.save || false)

    }

    public paymentsChanged = (meta): void => {
        this.down = meta.value
        this.calculateTotal(meta.save || false)
        this.lastDeferredPayDate = meta.last || null
        this.determineTermComputeDate()
    }

    /**
     * When the sale date or a down/deferred payment 
     * changes lets compute the last deferred 
     * payment date so the terms can set the first 
     * payment date.
     */
    private determineTermComputeDate = (): void => {
        if (this.lastDeferredPayDate) {
            this.soldOrLastDeferDate = moment(this.lastDeferredPayDate).format('yyyy-MM-DD')
            return
        }

        const sold = this.form.get('saleDate').value
        this.soldOrLastDeferDate = moment(sold).format('yyyy-MM-DD')
    }

    public setAtributes = (permissions: any[]): void => {
        
    }

    /*
    public openTXDMV = (): void => {
        this.loading = true
        this.master.post('webDealerDispatcher', {
            activity: 'read',
            saleId: this.saleId,
            lotId: this.store.lotSelected,
            primaryKeyId: this.inventory.inventoryId ? this.inventory.inventoryId : null
        }, res => {
            this.loading = false
            if (res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            let id = null
            this.txdmv = res.data
            if (!this.txdmv.body || (this.txdmv.body.length <= 0)) {
                return
            }

            for (let index = 0; index < this.txdmv.body.length; index++) {
                this.txdmv.body[index].message = JSON.parse(this.txdmv.body[index].message)
                if (this.txdmv.body[index].message['ax21:success'] == 'true') {
                    id = this.txdmv.body[index].message['ax21:titleId']
                }
            }

            this.txdmv.body.forEach(el => {
                el.message['ax21:titleId'] = id
                el.message['ax21:fees'] = el.message['ax21:fees'] && typeof el.message['ax21:fees'] === 'object' ? [] : el.message['ax21:fees']
                // el.message['ax21:messages']=el.message['ax21:messages'] && typeof el.message['ax21:messages']==='object'?[]:el.message['ax21:messages']
            })
        })
    }

    public checkTitleTXDMV = (): void => {
        this.loading = true
        this.master.post('webDealerDispatcher', {
            activity: 'checkTitleStatus',
            checkStatusData: {
                dealersConfigId: this.txdmv.integrationUseParameters.dealer.id,
                primaryKeyId: this.txdmv.header.inventory,
                userId: this.txdmv.integrationUseParameters.user.userId,
                username: this.txdmv.integrationUseParameters.user.username,
                dealerId: this.txdmv.integrationUseParameters.dealer.dealerId,
                password: this.txdmv.integrationUseParameters.user.password,
                titleId: this.txdmv.body[0].message['ax21:titleId'],
            }
        }, res => {
            this.loading = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.openTXDMV()
            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] })
        })
    }

    // upload title aplication
    public uploadTitle = (): void => {
        if (!this.buyerType || !this.inventory) {
            return
        }

        let color = this.txdmv.colorsCatalog.find(el => el['name'] == this.inventory.data['exteriorColor'])
        const make = this.txdmv.makesCatalog.find(el => el['name'] == this.inventory.data['make'].toUpperCase())

        // error make
        if (make == undefined) {
            this.ms.sendMessage("alert", { type: "warning", text: 'The make or model of the vehicle is not compatible with webDealer' });
            return
        }

        let uploadTitleData = {
            dealersConfigId: this.txdmv.integrationUseParameters.dealer.id,
            primaryKeyId: this.txdmv.header.inventory,
            userId: this.txdmv.integrationUseParameters.user.userId,
            username: this.txdmv.integrationUseParameters.user.username,
            dealerId: this.txdmv.integrationUseParameters.dealer.dealerId,
            password: this.txdmv.integrationUseParameters.user.password,
            corporateName: this.store.userAccount['user'].corporateName,
            createTimestamp: moment().format('yyyy-MM-DD'),
            contractDate: moment(this.buyerType['createdAt']).format('yyyy-MM-DD'),
            dealNumber: this.inventory['stockNumber'],
            firstName: this.buyerType['firstName'],
            lastName: this.buyerType['lastName'],
            entityType: this.buyerType['code'] ? this.buyerType['code'] : '',
            city: this.buyer['city'],
            state: this.buyer['state'],
            street1: this.buyer['address'],
            zipcode: this.buyer['zipCode'],
            idJurisdiction: this.buyer['issuer'], //2
            idNumber: this.buyer['number'],
            idType: this.buyerType.contactIdentifications.length > 0 ? this.buyerType.contactIdentifications[0].identificationType['code'] : '',
            salesPrice: this.baseSalePrice.value,
            bodyType: this.inventory.data['type'], // 2
            majorColorCode: color.code,
            make: make.code,
            model: this.inventory.data['model'],
            modelYear: this.inventory.data['modelYear'],
            vin: this.inventory.data['vinNumber'],
        }

        for (const property in uploadTitleData) {
            if (uploadTitleData[property] == '') {
                this.ms.sendMessage("alert", { type: "info", text: `${this.errorsCatalog[property]} cannot be empty` });
                return
            }
        }
        uploadTitleData['middleName'] = this.buyerType['middleName']
        uploadTitleData['suffix'] = this.buyerType['suffix']

        this.loading = true
        this.master.post('webDealerDispatcher', {
            activity: 'uploadTitle',
            uploadTitleData: uploadTitleData
        }, res => {
            this.loading = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.ms.sendMessage("alert", { type: "success", text: this.words[this.language]['success'] });
            this.openTXDMV()
        })
    }
    */
    

    //
    public openWebSite = () => {
        window.open('https://webdealer.txdmv.gov/title/login.do#')
    }

}
