import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MasterService } from '../../../../../services/master.service'
import { MessageService } from '../../../../../services/message.service'
import { ActivatedRoute, Router } from '@angular/router';
import * as languageLibrary from '../../../../../services/language'
import * as moment from 'moment';
import { SEARCH_DEBOUNCE_MILLIS, MIN_SEARCH_CHARS } from '../../../../../constants/list'
import { ADDRESS_OWNERSHIP, ADDRESS_TYPES } from 'src/app/constants/contact'
import { ThrowStmt } from '@angular/compiler';


@Component({
    selector: 'addresses',
    templateUrl: './addresses.component.html',
    styleUrls: ['./addresses.component.scss']
})
export class AddressesComponent implements OnInit, OnChanges {
    //
    @Input() isFlat: boolean = false
    @Input() isIndividual: boolean = false
    @Input() contactId: number = 0
    public words = languageLibrary.language
    public language: string = 'EN'
    public expanded: boolean = true
    public addressList: Object[] = []
    public addressSelected: Object = { object: {}, index: 0 }
    public addressIdToDelete: number = null
    public addressIndexToDelete: number = null
    public showControl: boolean = false
    // define the address selected
    public selected: Object = { address: 'none' }
    // save the types
    public types: Object[] = ADDRESS_TYPES
    // save the rent or own
    public rentOwn: string[] = ADDRESS_OWNERSHIP
    // define if loading state
    public loading: boolean = false
    // zip code list
    public zipCodeList: Object[] = []
    // latest search inquiry
    public zipInquiry = null
    // set time out
    private timeOutID: any;
    //  define if some changes not saved
    public savePending: boolean = false
    public addressUpdate: boolean;
    public addressPayload: Object[] = [];
    // Group of inputs
    public information = new FormGroup({
        contactId: new FormControl(this.contactId, [Validators.required]),
        addressTypeId: new FormControl(null, [Validators.required]),
        status: new FormControl(null),
        address: new FormControl(null, [Validators.required]),
        address2: new FormControl(null),
        zipCode: new FormControl(null, [Validators.required, Validators.pattern('[0-9]{3,10}')]),
        city: new FormControl(null, [Validators.required]),
        state: new FormControl(null, [Validators.required]),
        county: new FormControl(null, [Validators.required]),
        country: new FormControl(null, [Validators.required]),
        startDate: new FormControl(null),
        endDate: new FormControl(null)
    },
        {
            validators: Validators.compose([this.dateLessThan('startDate', 'endDate')])
        }
    )

    // get information of inputs
    get address() { return this.information.get('address') }
    get address2() { return this.information.get('address2') }
    get zipCode() { return this.information.get('zipCode') }
    get startDate() { return this.information.get('startDate') }
    get endDate() { return this.information.get('endDate') }

    /*
    * Life cicles
    */
    constructor(private master: MasterService, private ms: MessageService, private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.checkValidators()
        this.acquireAddresses()
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes) {
            return
        }

        const first = Object.values(changes).some(c => c.isFirstChange())
        if (first) {
            return
        }

        if (changes.contactId) {
            this.reset()
            this.acquireAddresses()
        }

        if (changes.isIndividual) {
            this.checkValidators()
        }
    }

    /*
    todo: reload and clean the inputs
    */
    public reset = (): void => {
        this.addressList = []
    }

    public checkValidators() {
        if (this.isIndividual) {
            this.information.get('status').setValidators([Validators.required]);
            this.showControl = true
        } else {
            this.information.get('status').setValue("Other")
            this.information.get('status').clearValidators();
            this.showControl = false
        }
        this.information.get('status').updateValueAndValidity();
        return;
    }

    public dateLessThan(startDate: string, endDate: string) {
        return (group: FormGroup): { [key: string]: any } => {
            let start = group.controls[startDate];
            let end = group.controls[endDate];
            if (end.value !== '') {
                if (start.value > end.value) {
                    return {
                        dates: "Date from should be less than Date to"
                    };
                }
                return
            }
            return {};
        }
    }

    public acquireAddresses = () => {
        if (!this.contactId || this.contactId <= 0) {
            return
        }

        this.master.getAsync(`contacts/${this.contactId}/addresses`).then(res => {
            this.loading = false

            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.addressList = res.data.addresses
        })
    }

    // clean Fields
    public cleanFields = (clearPayload: boolean): void => {
        if (clearPayload) { this.addressPayload = [] }
        this.addressSelected = { object: {}, index: 0 }
        this.zipCodeList = []
        this.addressUpdate = false
        this.information.reset()
        this.loading = false
        this.information.get("contactId").setValue(this.contactId)
        this.information.get("status").setValue("Other")
    }

    calculateDiff(startDateVar: string, endDateVar: string) {
        if (!startDateVar || !endDateVar) {
            return
        }
        let startDate = Date.parse(startDateVar); //time in milliseconds
        let endDate = Date.parse(endDateVar); //time in milliseconds
        var diffDays = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
        return diffDays;
    }

    public add = (addressToSave: Object): void => {
        this.addressPayload.push(addressToSave)
        this.cleanFields(false)
    }

    // save
    public save = (closeAfter): void => {
        if (this.addressPayload.length == 0 && this.information.invalid) {
            return
        }

        this.loading = true
        if (!this.isIndividual) {
            this.information.get('status').setValue("Other")
        }

        if (!this.information.invalid) {
            this.addressPayload.push(this.information.value)
        }

        this.master.post(`contacts/${this.contactId}/addresses`, { addresses: this.addressPayload }, res => {
            this.loading = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.savePending = false
            if (closeAfter && !this.isFlat) {
                const btn = (document.getElementById('btn-cancel-addresses') as HTMLButtonElement)
                if (btn) { btn.click() }
            }
            this.cleanFields(true)
            this.acquireAddresses()
        })
    }

    // modifyAddress
    public modifyAddress = (): void => {
        if (this.information.invalid) {
            return
        }

        this.loading = true
        if (!this.isIndividual) {
            this.information.get('status').setValue("Other")
        }

        this.master.put(`contacts/${this.contactId}/addresses/${this.addressSelected['object'].id}`, this.information.value, res => {
            this.loading = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            if (!this.isFlat) {
                const btn = (document.getElementById('btn-close-addresses-update') as HTMLButtonElement)
                if (btn) { btn.click() }
            }

            this.cleanFields(true)
            this.acquireAddresses()
        })
    }

    // select address update
    public selectAddressUpdate = (address: Object): void => {
        this.addressSelected['object'] = address
        this.addressUpdate = true;
        this.information.setValue({
            contactId: this.contactId ? this.contactId : address['contactId'],
            addressTypeId: address['addressTypeId'],
            status: address['status'],
            address: address['address'],
            address2: address['address2'],
            zipCode: address['zipCode'],
            city: address['city'],
            state: address['state'],
            county: address['county'],
            country: address['country'],
            startDate: address['startDate'],
            endDate: address['endDate'],
        })
    }

    /*
   ! delete
   */

    // select address
    public selectAddress = (address: Object): void => {
        this.addressSelected['object'] = address
    }

    /*
    ! delete
    */
    public delete = (): void => {
        this.loading = true
        this.master.discard(`contacts/${this.contactId}/addresses/${this.addressSelected['object']['id']}`, res => {
            this.loading = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            if (!this.isFlat) {
                const btn = (document.getElementById('close-delete-modal') as HTMLButtonElement)
                if (btn) { btn.click() }
            }

            this.cleanFields(true)
            this.acquireAddresses()
        })
    }

    // todo:alert quest delete
    public questDelete = (address: Object) => {
        if (!confirm(this.words[this.language]['deleteAddress'])) {
            return
        }

        this.selectAddress(address)
        this.delete()
    }

    // define the address default
    public selectDefault = (address: Object): void => {
        if (!address || !address['id']) {
            return
        }

        const identity = parseInt(address['id'])
        if (isNaN(identity) || (identity <= 0)) {
            this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
            return
        }

        this.master.patch(`contacts/${this.contactId}/addresses/${address['id']}/primary`, null, res => {
            this.loading = false
            if (!res) {
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.acquireAddresses()
        })
    }

    // ? address lookup
    // verify if the corporate is already exist
    public searchZipCode(): void {
        const code = this.information.get('zipCode').value
        if (!code || code.length < MIN_SEARCH_CHARS) {
            return
        }

        if (this.zipInquiry && (code === this.zipInquiry)) { // hasn't changed?
            return
        }

        clearTimeout(this.timeOutID)

        this.timeOutID = setTimeout(() => {
            this.zipCodeList = []
            this.zipInquiry = code

            this.master.get(`readLocationByZipcode?zipcode=${code}`, res => {
                if (!res) {
                    this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                    return
                }

                if (res.status !== 200) {
                    return
                }

                this.zipCodeList = res.data.locations

                // autocomplete
                if ((code >= 5) && (this.zipCodeList.length === 1)) {
                    this.selectZipCode(this.zipCodeList[0])
                }
            })
        }, SEARCH_DEBOUNCE_MILLIS)
    }

    // set the address exist
    public selectZipCode = (address: Object): void => {
        if (!address || !this.information) {
            return
        }

        this.information.get('city').setValue(address['city'])
        this.information.get('state').setValue(address['state'])
        this.information.get('county').setValue(address['county'])
        this.information.get('zipCode').setValue(address['zipcode'])
        this.information.get('country').setValue('United States')

        this.zipCodeList = []
    }

    // open the location
    public openLocation = (address: string, county: string, state: string): void => {
        address = address.replace(' ', '+')
        county = county.replace(' ', '+')
        state = state.replace(' ', '+')
        console.info(`https://www.google.com/maps/search/?api=1&query=${address}+${county}+${state}`);

        window.open(`https://www.google.com/maps/search/?api=1&query=${address}+${county}+${state}`, '_blank')
    }

}
