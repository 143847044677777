import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
// services
import { MessageService } from "../../../../../../services/message.service";
import { MasterService } from "../../../../../../services/master.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as languageLibrary from '../../../../../../services/language'
import { StoreService } from "src/app/services/store.service";
import { PAYMENT_CYCLES } from '../../../../../../constants/payments'
import { formatDecimal } from '../../../../../../utils/format'
import * as moment from "moment";


@Component({
    selector: "cpi",
    templateUrl: "./cpi.component.html",
    styleUrls: ["./cpi.component.scss"],
})
export class CPIComponent implements OnInit {
    @Input() saleId: number;
    @Input() saleInfo: Object = Array;
    @Input() cpiPolicyInfo: Object = Array;
    @Output() emitterInformation$: EventEmitter<Object> = new EventEmitter()
    public id: number = null
    public status: string = "";
    public paymentCycles = PAYMENT_CYCLES
    public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
    public information = new FormGroup({
        id: new FormControl(null),
        cpi: new FormControl(1, [Validators.required]),
        endDate: new FormControl(null, [Validators.required]),
        effectiveDate: new FormControl(new Date().toISOString().substring(0, 10), [Validators.required]),
        premiumPerPayment: new FormControl(null, [Validators.required, Validators.min(1)]),
        monthlyPremium: new FormControl(null, [Validators.required]),
        defaultDeductibleAmount: new FormControl(null, [Validators.required, Validators.min(1)]),
        saleLoanBalance: new FormControl(null, [Validators.required, Validators.min(1)]),
        months: new FormControl(null, [Validators.required, Validators.min(1)]),
        firstScheduledPmtDue: new FormControl(new Date().toISOString().substring(0, 10), [Validators.required]),
        paymentCycle: new FormControl("Monthly", [Validators.required]),
        contactId: new FormControl(null, [Validators.required]),
        saleId: new FormControl(null, [Validators.required]),
        status: new FormControl("Active", [Validators.required])
    },
        {
            validators: Validators.compose([this.dateCheck('firstScheduledPmtDue', 'effectiveDate'), this.calculate('months', 'firstScheduledPmtDue', 'monthlyPremium', 'paymentCycle')])
        })

    public expectedPayments: Object[] = [];
    public sales: Object[] = [];
    public companiesCPI: Object[] = [];
    public words = languageLibrary.language
    public inactivePolicies: Object[] = [];
    public idForAction: Number = 0;
    //
    public payments: Array<any> = []
    //
    public records: number = 0
    //
    public page: number = 1
    // Indicates whether the data is currently loading.
    public loading: boolean = false
    // Indicates whether any more data is available
    public exhausted: boolean = false
    // todo: listen to output of navbar
    public listenerNav = (e): void => {
        switch (e['message']) {
            case 'changeLanguage':
                this.language = e['value']
                break;
            default:
                break;
        }
    }
    public cpiDetailToCopy: [] = [];

    constructor(
        private route: ActivatedRoute,
        private ms: MessageService,
        private master: MasterService,
        private store: StoreService,
        private location: Location
    ) { }

    ngOnInit() {
        // this.getVendors()
    }

    ngOnChanges(changes: SimpleChanges): void {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        //Add '${implements OnChanges}' to the class.
        if (changes.saleInfo) {
            if (!this.saleInfo) {
                return
            }
            this.information.get('firstScheduledPmtDue').setValue(this.saleInfo['firstScheduledPmtDue'])
            this.information.get('saleId').setValue(this.saleId)
            this.information.get('paymentCycle').setValue(this.saleInfo['salePaymentCycle'])
            this.information.get('saleLoanBalance').setValue(this.saleInfo['loanPayoff'])
            this.reset()
            this.fetch()
        }

        if (changes.cpiPolicyInfo) {
            if (changes.cpiPolicyInfo.currentValue !== null) {
                this.setInformation(changes.cpiPolicyInfo.currentValue)
            }
        }
    }

    public dateCheck(firstScheduledPmtDue: string, effectiveDate: string) {
        return (group: FormGroup): { [key: string]: any } => {
            let firstScheduledPmtDueDate = group.controls[firstScheduledPmtDue];
            let effDate = group.controls[effectiveDate];
            if (!this.saleInfo) {
                return
            }
            if (effDate.value !== '') {
                if (effDate.value < this.saleInfo['saleDate']) {
                    this.information.controls['effectiveDate'].setErrors({ 'error': "Date from should be on or after the sale date" });
                    // return {
                    //     dates: "Date from should be on or after the sale date"
                    // };
                }
            }
            if (firstScheduledPmtDueDate.value !== '') {
                if (firstScheduledPmtDueDate.value < this.saleInfo['saleDate']) {
                    this.information.controls['firstScheduledPmtDue'].setErrors({ 'error': "Date from should be on or after the sale date" });
                    // return {
                    //     dates: "Date from should be on or after the sale date"
                    // };
                }
            }
            return {};
        }
    }


    public saveCPI() {
        if (!this.information.valid) {
            return
        }

        this.information.updateValueAndValidity({ onlySelf: true })
        if (this.id <= 0) this.createCPI()
        if (this.id >= 1) this.modifyCPI()
    }

    public modifyCPI() {
        this.master.put(`sales/${this.saleId}/insurance/${this.id}`, { information: this.information.value }, res => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.emitterInformation$.emit({ message: 'getAutoInsurance' })
        })
    }

    public createCPI() {
        this.master.post(`sales/${this.saleId}/insurance/`, { information: this.information.value }, res => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.emitterInformation$.emit({ message: 'getAutoInsurance' })
            this.location.replaceState(`sales/${this.saleId}/insurance`);
        })
    }

    public setDetails(cpiDetail) {
        this.cpiDetailToCopy = cpiDetail
    }

    public deactivateCPIPolicy() {
        this.master.patch(`sales/${this.saleId}/insurance/${this.id}/deactivate`, { saleId: this.saleId }, res => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.resetInformation()

            document.getElementById("btn-close-modal-cancel").click();
            this.emitterInformation$.emit({ message: 'getAutoInsurance' })
        })
    }

    public getSaleCPIDetails() {
        this.saleId = this.information.get('saleId').value
        this.getCPIDetails()
    }

    public setId(id) {
        this.idForAction = id
    }

    public getCPIDetails() {
        this.master.get(`insurance/${this.saleId}`, res => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            if (!res.data.saleInfo) {
                return
            }

            if (res.data.activePolicies.length >= 1) {
                this.setInformation(res.data.activePolicies[0])
            }
            this.expectedPayments = res.data.expectedPayments
            this.inactivePolicies = res.data.inactivePolicies
        })
    }

    public getVendors() {
        this.master.get(`contacts?type=cpiVendor`, (res) => {
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.companiesCPI = res.data.contacts
        })
    }

    public resetInformation() {
        this.id = null
        this.status = ""
        this.idForAction = null
        this.expectedPayments = null
        this.information.reset()
        this.information.get('saleId').setValue(this.saleId)
        this.information.get('status').setValue("Active")
        this.information.get('cpi').setValue(true)
        this.information.get('firstScheduledPmtDue').setValue(new Date().toISOString().substring(0, 10))
        this.information.get('effectiveDate').setValue(new Date().toISOString().substring(0, 10))
    }
    public resetSaleInformation() {
        this.saleId = null
        this.saleInfo = null
        this.information.reset()
    }

    public calculate(months: string, firstScheduledPmtDueDate: string, monthlyPremium: string, paymentCycle: string) {
        return (group: FormGroup): { [key: string]: any } => {
            let termInMonths = group.controls[months];
            let firstScheduledPmtDue = group.controls[firstScheduledPmtDueDate];
            let monthlyAmount = group.controls[monthlyPremium];
            let pmtCycle = group.controls[paymentCycle];
            if (!termInMonths.value || !monthlyAmount.value || !pmtCycle.value) {
                return
            }

            let scheduledAmt = 0
            const freq = {
                weekly: 52.0 / 12.0,
                'bi-weekly': 52.0 / 24.0,
                'semi-monthly': 24.0 / 12.0, // twice a month
            }
            switch (pmtCycle.value) {
                case 'Weekly':
                    scheduledAmt = monthlyAmount.value / freq.weekly
                    break;
                case 'Bi-Weekly':
                    scheduledAmt = monthlyAmount.value / freq['bi-weekly']
                    break;
                case 'Semi-Monthly':
                    scheduledAmt = monthlyAmount.value / freq['semi-monthly']
                    break;
                case 'Monthly':
                    scheduledAmt = monthlyAmount.value
                    break;
            }
            
            this.information.get('premiumPerPayment').setValue(scheduledAmt, { onlySelf: true })

            if (!firstScheduledPmtDue.value) {
                this.information.get('endDate').setValue(null, { onlySelf: true })
                return
            }

            let endDate = moment(firstScheduledPmtDue.value).add(termInMonths.value, 'months').format('MM/DD/YYYY');;
            this.information.get('endDate').setValue(endDate, { onlySelf: true })
            return
        }
    }
    private reset = (): void => {
        this.page = 1
        this.exhausted = false
        this.records = 0
    }

    private fetch = (): void => {
        if (this.loading) {
            return
        }

        if (this.exhausted) { // currently showing all available records?
            return
        }

        let contactParam = ''
        if (this.saleInfo['contactId'] && (this.saleInfo['contactId'] > 0)) {
            contactParam = `&contact=${this.saleInfo['contactId']}`
        }

        let saleParam = ''
        if (this.saleId && (this.saleId > 0)) {
            saleParam = `&saleId=${this.saleId}`
        }

        this.loading = true
        this.payments = []
        this.master.get(`payments/expected?page=${this.page}&sort=due|ASC${contactParam}${saleParam}&categories=cpi&ipp=7`, (res) => {
            this.loading = false
  
            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }
  
            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }
  
            this.records = res.data.records
            this.exhausted = (res.data.next === null)
  
            if (this.page > 1) {
                this.payments = this.payments.concat(res.data.payments)
                return
            }
  
            this.payments = res.data.payments
        })
    }

    public setInformation(cpiPolicies) {
        this.id = cpiPolicies['id']
        this.status = cpiPolicies['status']
        this.information.setValue({
            id: cpiPolicies['id'],
            cpi: cpiPolicies['cpi'],
            saleId: cpiPolicies['saleId'],
            effectiveDate: cpiPolicies['effectiveDate'],
            premiumPerPayment: cpiPolicies['premiumPerPayment'],
            monthlyPremium: cpiPolicies['monthlyPremium'],
            defaultDeductibleAmount: cpiPolicies['defaultDeductibleAmount'],
            saleLoanBalance: cpiPolicies['saleLoanBalance'],
            months: cpiPolicies['months'],
            firstScheduledPmtDue: cpiPolicies['firstScheduledPmtDue'],
            paymentCycle: cpiPolicies['paymentCycle'],
            status: cpiPolicies['status'],
            contactId: cpiPolicies['contactId'],
            endDate: cpiPolicies['endDate'],
        })
        this.expectedPayments = cpiPolicies.expectedPayments
    }

    public setContactIdEvent(e) {
        if (!e) {
          return
        }
        this.information.get("contactId").setValue(e)
    }
}
