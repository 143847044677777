import { Injectable, } from '@angular/core';
import { CanActivate } from "@angular/router";
import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs/Observable';
import Axios from 'axios'
import { MessageService } from '../services/message.service'
import { environment } from '../../environments/environment';
@Injectable({
    providedIn: 'root'
})

export class MasterService implements CanActivate {
    private headers: Record<string, string> = {}
    private host: string = "https://rockitdms.com/api/"
    public hostApi: string = environment.urlGeneral
    public token = ''
    private accessCode: string = '8437d5a3-91c9-4544-9680-fdafb9ef39dc'

    constructor(private ms: MessageService) {
        this.headers['Accept'] = 'application/json';
        this.token = localStorage.getItem('token') ? localStorage.getItem('token') : ''
        this.host = environment.url
    }

    /**
     * Attach the autorization request header param when available.
     */
    private withAuth = () => {
        if (!this.token || (this.token.length <= 0) || this.headers['Authorization']) {
            return
        }

        this.headers['Authorization'] = `bearer ${this.token}`
    }

    // Funtions

    /**
     * Reset the token and auth header upon a logout/leave event.
     */
    public reset = () => {
        delete(this.headers['Authorization'])
        this.token = ''
    }

    /**
     * Add a new resource using the backend API.
     * @param action The URL invluding any path or query params.
     * @param body The resource meta data to include in the API request content body.
     * @param callback An optional listener to be notified when the request completes. Includes the response or null if failure.
     */
    public post = (action: string, body: object, callback): void => {
        this.withAuth()

        Axios.post(`${this.host}${action}`, body, { headers: this.headers }).then(res => {
            callback(res)
        })
            .catch(error => {
                this.tokenExpired(error)

                if (error.response) {
                    callback(error.response)
                    return
                }

                console.error(`post ${action} errored: ${error}`)
                callback(null)
            })
    }

    /**
     * Add a new resource using the backend API asynchoronously using async/await methodology.
     * @param action The URL invluding any path or query params.
     * @param body The resource meta data to include in the API request content body.
     * @returns A promise to be handled by the callee.
     */
    public postAsync = async (action: string, body: object) => {
        this.withAuth()
        return await Axios.post(`${this.host}${action}`, body, { headers: this.headers })
    }

    /**
     * Fully modify an existing resource using the backend API. Content body (params) 
     * contains all fields reuquired to completely modify the resource.
     * @param action The URL invluding any path or query params.
     * @param body The resource meta data to include in the API request content body.
     * @param callback An optional listener to be notified when the request completes. Includes the response or null if failure.
     */
    public put = (action: string, body: object, callback): void => {
        this.withAuth()

        Axios.put(`${this.host}${action}`, body, { headers: this.headers }).then(res => {
            callback(res)
        })
            .catch(error => {
                this.tokenExpired(error)

                if (error.response) {
                    callback(error.response)
                    return
                }

                console.error(`put ${action} errored: ${error}`)
                callback(null)
            })
    }

    /**
     * Partially modify an existing resource using the backend API.
     * @param action The URL invluding any path or query params.
     * @param body The resource meta data to include in the API request content body.
     * @param callback An optional listener to be notified when the request completes. Includes the response or null if failure.
     */
    public patch = (action: string, body: object, callback): void => {
        this.withAuth()

        Axios.patch(`${this.host}${action}`, body, { headers: this.headers }).then(res => {
            callback(res)
        })
            .catch(error => {
                this.tokenExpired(error)

                if (error.response) {
                    callback(error.response)
                    return
                }

                console.error(`patch ${action} errored: ${error}`)
                callback(null)
            })
    }

    /**
     * Read the details an existing resource using the backend API.
     * @param action The URL invluding any path or query params.
     * @param callback An optional listener to be notified when the request completes. Includes the response or null if failure.
     */
    public get = (action: string, callback): void => {
        this.withAuth()
        Axios.get(`${this.host}${action}`, { headers: this.headers }).then(res => {
            callback(res)
        })
            .catch(error => {
                this.tokenExpired(error)
                this.userDeactivated(error)

                if (error.response) {
                    callback(error.response)
                    return
                }

                console.error(`get ${action} errored: ${error}`);
                callback(null)
            })
    }

    /**
     * Read the details an existing resource using the backend API.
     * @param action The URL invluding any path or query params.
     * @param callback An optional listener to be notified when the request completes. Includes the response or null if failure.
     */
    public getAsync = async (action: string) => {
        this.withAuth()
        return await Axios.get(`${this.host}${action}`, { headers: this.headers })
    }

    /**
     * Delete an existing resource using the backend API.
     * @param action The URL invluding any path or query params.
     * @param callback An optional listener to be notified when the request completes. Includes the response or null if failure.
     */
    public discard = (action: string, callback): void => {
        this.withAuth()

        Axios.delete(`${this.host}${action}`, { headers: this.headers }).then(res => {
            callback(res)
        })
            .catch(error => {
                this.tokenExpired(error)

                if (error.response) {
                    callback(error.response)
                    return
                }

                console.error(`delete ${action} errored: ${error}`);
                callback(null)
            })
    }

    private tokenExpired = (error): void => {
        if (!error.response || !error.response.data || !error.response.data.error) {
            return
        }

        const message = error.response.data.error
        if (!message) {
            return
        }

        if ((message.indexOf('token') === -1) && (message.indexOf('invalidToken') === -1)) {
            return
        }

        localStorage.removeItem('token')
        localStorage.removeItem('lot')
        this.token = ''
        window.location.href = "#/login"
        window.location.reload()
    }

    private userDeactivated = (error): void => {
        if (!error.response || !error.response.data || !error.response.data.error) {
            return
        }

        const message = error.response.data.error
        if (!message) {
            return
        }

        if ((message.indexOf('The user has been deactivated.') === -1)) {
            return
        }

        this.ms.sendMessage("alert", { type: "danger", text: 'The user has been deactivated.' });
        localStorage.removeItem('token')
        localStorage.removeItem('lot')
        this.token = ''
        window.location.href = "#/login"
        window.location.reload()
    }

    /*
    *  send a get to back-end
    @action: define the type of action to be carried out
    @params: defines the vin Number
    */
    public getVinQuery = (format: string, reportType: string, vinNumber: string, callback) => {
        Axios.get(`https://www.recognition.ws/vindecode/v2?accesscode=${this.accessCode}&format=${format}&reportType=${reportType}&vin=${vinNumber}`).then(res => {
            callback(res)
        }).catch(error => {
            callback(error.response)
        })
    }

    /*
    *  send a get to back-end
    @action: define the type of action to be carried out
    @params: defines the vin Number
    */
    // public getVINdecodeNHTSA = (format: string, vinNumber: string, callback) => {
    //     Axios.get(`https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinValuesExtended/${vinNumber}?format=${format}`).then(res => {
    //         callback(res)
    //     }).catch(error => {
    //         callback(error.response)
    //     })
    // }
    //


    public getDataFromURL = (url: string, fileName: string, callback) => {
        if (!url.startsWith('http')) {
            url = `${this.host}${url}`
            this.withAuth()
        }

        Axios({
            url: url,
            headers: this.headers,
            method: 'GET',
            responseType: 'blob'
        })
        .then(res => {
            const reader = new FileReader()
            reader.onload = function(e) {
                const result = e.target['result']
                callback({ status: true, data: result })
            }
            reader.readAsDataURL(res.data)                
        })
        .catch(async (error) => {
            let message = null

            if (error.response) {
                const text = await error.response.data.text()
                const json = JSON.parse(text)
                message = json.error || error.message
            }
            else {
                // Something happened in setting up the request that triggered an Error
                message = error.message
            }

            callback({ status: false, error: message, data: null })
        })

    }

    /*
    *  Check if the session is valid to access dashboard.
    */
    canActivate(): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            this.get('mainDashboard', message => {
                // if (message != undefined && message.status == 400) {
                //     window.location.href = '#/login'
                //     window.location.reload()
                //     return
                // }

                observer.next(true)
                observer.complete()
            })
        })
    }

}
