import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, Injector } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
// services
import { MasterService } from '../../../../../../../services/master.service'
import { MessageService } from '../../../../../../../services/message.service'
import * as languageLibrary from '../../../../../../../services/language'
import { INCOME_SOURCES } from 'src/app/constants/contact'
import { token } from '../../roles.component';
import { DynamicService } from 'src/app/services/dynamic.service';
@Component({
    selector: 'income',
    templateUrl: './income.component.html',
    styleUrls: ['./income.component.scss']
})
export class IncomeComponent implements OnInit {
    /*
    * variables
    */
    @Input() contactId: number = 0
    public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
    public words = languageLibrary.language
    public incomeList: Object[] = []
    public loading: boolean = false
    public incomeId: number = null
    public totalIncome: number = 0
    public types: string[] = INCOME_SOURCES
    public viewAmount: boolean = false
    public form = new FormGroup({
        sources: this.fb.array([])
    })
    public modForm = new FormGroup({
        contactId: new FormControl(null, [Validators.required]),
        type: new FormControl(null, [Validators.required]),
        value: new FormControl(null, [Validators.required, Validators.min(1), Validators.pattern('^[0-9]{1,16}(\.[0-9]{0,2})?$')])
    })
    public dataIn: string;

    get value() { return this.modForm.get('value') }
    get sources() {
        return this.form.controls['sources'] as FormArray;
    }

    constructor(private master: MasterService, private ms: MessageService, private fb: FormBuilder, private inject: Injector, private serv: DynamicService) { }

    ngOnInit() {
        this.reset()
        this.resetMod()
        this.dataIn = this.inject.get(token);
        this.contactId = this.dataIn['contactId']

        if (!this.contactId) {
            return
        }

        this.getIncome()
    }

    ngOnChanges(changes: SimpleChanges): void {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        //Add '${implements OnChanges}' to the class.
        this.computeTotal()
    }
    sendData() {
        this.serv.outputFromDynamicComponent('Sent data from Tab1');
    }
    /*
    * functions
    */
    // save changes
    public create = (): void => {
        if (this.form.invalid) {
            return
        }

        const payload = []

        for (let group of this.sources.controls) {
            const controls = (group as FormGroup).controls
            payload.push({
                contactId: this.contactId,
                type: controls.type.value,
                value: controls.value.value
            })
        }

        if (payload.length <= 0) {
            this.loading = false
            return
        }

        this.loading = true
        this.master.post(`contacts/${this.contactId}/incomes`, { incomes: payload }, res => {
            this.loading = false

            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.incomeList = res.data.incomeList
            this.computeTotal()
            // close modal
            const btn = (document.getElementById('btn-cancel-income') as HTMLButtonElement)
            if (btn) {
                btn.click()
            }

        })
    }

    // update
    public modify = (): void => {
        if (this.modForm.invalid) {
            return
        }

        if (!this.incomeId || (this.incomeId <= 0)) {
            return
        }

        this.loading = true
        let payload = this.modForm.value
        this.master.put(`contacts/${this.contactId}/income/${this.incomeId}`, payload, res => {
            this.loading = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }            ;

            this.incomeList = res.data.incomeList
            this.computeTotal()
            // close modal
            const btn = (document.getElementById('btn-close-income') as HTMLButtonElement)
            if (btn) {
                btn.click()
            }
        })
    }
    /*
    ! delete
    */
    public delete = (): void => {
        this.loading = true
        this.master.discard(`contacts/${this.contactId}/income/${this.incomeId}`, res => {
            this.loading = false
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.incomeList = res.data.incomeList
            this.computeTotal()

            const btn = (document.getElementById('close-delete-modal-income') as HTMLButtonElement)
            if (btn) {
                btn.click()
            }

        })
    }
    // select income update
    public selectIncome = (income: Object, action: string): void => {
        this.incomeId = income['id']
        if (action = 'modify') {
            this.modForm.setValue({
                contactId: this.contactId,
                type: income['type'],
                value: income['value'],
            })
        }
    }

    // Total of all income sources
    public computeTotal = (): void => {
        const incomes = this.incomeList.map(el => parseFloat((el['value'])))
        this.totalIncome = incomes.reduce((total, value) => parseFloat((value + total).toFixed(2)), 0)
    }

    /**
     * Add a new contact income source row to the form array
     * @param e Event that triggerd this method, if any
     */
    public addIncomeSourceRow = (e): void => {
        const source = this.fb.group({
            type: new FormControl(null, [Validators.required]),
            value: new FormControl(null, [Validators.required, Validators.min(1), Validators.pattern('^[0-9]{1,16}(\.[0-9]{0,2})?$')])
        })

        this.sources.push(source)
    }

    /**
     * Remove a contact income source from the form array
     * @param index The index of the invite to remove
     */
    public discard = (index: number): void => {
        if ((index < 0) || (index > this.sources.length - 1)) {
            return
        }

        this.sources.removeAt(index)
    }

    /*
    * Reset the sources input array
    */
    public reset = (): void => {
        this.loading = false
        this.form.reset()
        this.sources.clear()
        this.addIncomeSourceRow(null)
    }

    /*
    * Reset/empty the contact income modification fields
    */
    public resetMod = (): void => {
        this.incomeId = null
        this.modForm.reset()
    }

    public getIncome() {
        this.master.get(`contacts/${this.contactId}/income`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error })
                return
            }

            this.incomeList = res.data.incomeList
            this.computeTotal()
        })
    }
}
