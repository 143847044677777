import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor() { }

  getFormStructure(setupValues) {
    // Return the JSON structure defined earlier
    return setupValues
    
  }
}
