import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ISSUERS } from 'src/app/constants/contact';
import * as words from '../../../../../../services/language';
import { MasterService } from "../../../../../../services/master.service";
import { MessageService } from "../../../../../../services/message.service";
import { StoreService } from "../../../../../../services/store.service";

import { MIN_SEARCH_CHARS, SCROLL_BOTTOM_OUT, SEARCH_DEBOUNCE_MILLIS } from "src/app/constants/list";

@Component({
    selector: "contact-list",
    templateUrl: "./contact-list.component.html",
    styleUrls: ["./contact-list.component.scss"],
})
export class ContactListComponent implements OnInit, OnChanges {
    /** Filter the list by the given saleId so to help prevent adding the same contact twice. */
    @Input() saleId: number = 0

    /** Indicates if the parent has asked this component to how/hide itself. */
    @Input() show: boolean = true

    /** Notify listeners that a contact was selected from the list or a new one was created. */
    @Output() contactSelectedEvent = new EventEmitter<Number>()

    // zip code list
    public zipCodeList: Object[] = []
    
    // latest search inquiry
    public zipInquiry = null
    
    // set time out
    private timeOutID: any;
    
    public issuers = ISSUERS
    
    public buyerTypes: string[] = []
    
    public buyerTypeId: number = null

    public types: string[] = []

    // save the language
    public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'

    // set all words
    public words = words.language

    // Indicates whether the current contact type being displayed is an individual or a company (false)
    public isIndividual: boolean = true;

    // define the contact type (title)

    // save all data by type
    public contacts: Object[] = [];

    // define if is loading
    public loading: boolean = false;

    // permissions
    public permissions: Object[] = [];

    // Current page the user is viewing (last loaded).
    public page = 1

    // The number contacts currently being displayed.
    public displayed = 0

    // Indicates whether or not there are no more records to load.
    public exhausted = false

    // Total number of records available
    public records: number

    // The search form group
    private form: FormGroup = null

    // The current search text to filter the list by.
    private inquiry: string = null

    // A debounce timer for search events. Used to wait for 
    // the user to stop typing before we fetch.
    private watcher: ReturnType<typeof setTimeout> = null

    // Keep track of how the user wants to sort list.
    private sortables: Object = { 'name': 'ASC' }
    
    public saleBuyerInformation = new FormGroup({
        isIndividual: new FormControl(true, [Validators.required]),
        taxPayerNumber: new FormControl(null),
        identificationTypeId: new FormControl(null, []),
        number: new FormControl(null, []),
        issuer: new FormControl(null, []),
        expirationDate: new FormControl(null, []),
        firstName: new FormControl(null),
        lastName: new FormControl(null),
        middleName: new FormControl(null),
        companyName: new FormControl(null),
        address: new FormControl(null, [Validators.required]),
        address2: new FormControl(null),
        city: new FormControl({ value: null, disabled: true }, [Validators.required]),
        state: new FormControl({ value: null, disabled: true }, [Validators.required]),
        zipCode: new FormControl(null, [Validators.required]),
        county: new FormControl({ value: null, disabled: true }, [Validators.required]),
        country: new FormControl({ value: null, disabled: true }, [Validators.required]),
        phoneNumber: new FormControl(null),
        emailAddress: new FormControl(null),
        roles: new FormControl("salesLead", [Validators.required])
    })
    
    // ************
    // life cycles
    // *************
    constructor(private ms: MessageService, private master: MasterService, private store: StoreService, private fb: FormBuilder) {
        this.form = this.fb.group({
            inquiry: new FormControl('')
        })

        this.form.get('inquiry').valueChanges.subscribe(inquiry => {
            if (this.watcher) {
                clearTimeout(this.watcher)
                this.watcher = null
            }

            if (this.inquiry === inquiry) {
                return
            }

            this.watcher = setTimeout(() => {
                this.inquiry = inquiry
                this.reset()
                this.fetch()
            }, SEARCH_DEBOUNCE_MILLIS)
        })
    }

    ngOnInit() {
        this.getTypes()
        if (this.store.userAccount["permissions"]) {
            this.permissions = this.store.userAccount["permissions"][1].childrens[0].permissions
        }

        // if not have permissions, return to account settings
        if (!this.permissions.includes("view")) {
            return
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes) {
            return
        }

        if (changes.show && (changes.show.currentValue === true)) {
            this.defaultTab()
            this.reset()
            this.fetch()
        }
    }

    /**
     * Reset the pagination parameters and scroll to the top
     */
    private reset() {
        this.page = 1
        this.exhausted = false
        this.displayed = 0

        const list = document.querySelector('#listing')
        if (!list) {
            return
        }

        list.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto'
        })
    }
    /**
     * Handle scroll events of the list container and load 
     * the next page when avilable and the user scrolled 
     * to the bottom.
     * @param e The scroll event that trigggered this handler.
     */
    public scrolled = (e): void => {
        if (!e || !e.target) {
            return
        }

        if (this.exhausted) {
            return
        }

        if (this.loading) {
            return
        }

        const location = Math.abs(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop)

        if (location >= SCROLL_BOTTOM_OUT) {
            return
        }

        this.page = this.page + 1
        this.fetch()
    }

    /**
     * 
     * @param field The field that user wants to sort by. 
     */
    public sort = (field: string): void => {
        if (!field || (field.length <= 0)) {
            return
        }

        field = field.toLowerCase()
        if (this.sortables[field]) { // need to toggle the direction?
            switch (this.sortables[field]) {
                case 'ASC':
                    this.sortables[field] = 'DESC'
                    break
                default:
                    this.sortables[field] = 'ASC'
                    break
            }

            this.reset()
            this.fetch()
            return
        }

        this.sortables[field] = 'ASC'
        this.reset()
        this.fetch()
    }

    public sortedAsc = (field: string): Boolean => {
        if (!field || (field.length <= 0) || !this.sortables) {
            return false
        }

        field = field.toLowerCase()
        if (!this.sortables[field]) {
            return false
        }

        return (this.sortables[field].toUpperCase() === 'ASC')
    }

    public sortedDesc = (field: string): Boolean => {
        if (!field || (field.length <= 0) || !this.sortables) {
            return false
        }

        field = field.toLowerCase()
        if (!this.sortables[field]) {
            return false
        }

        return (this.sortables[field].toUpperCase() === 'DESC')
    }

    /**
     * Acquire contact list by given type
     */
    public fetch = (): void => {
        if (this.loading) { // already getting next page
            return
        }

        if (this.exhausted) { // currently showing all available records?
            return
        }

        this.loading = true

        let inquiryParam = ''
        if (this.inquiry && (this.inquiry.length > 0)) {
            inquiryParam = `&q=${encodeURIComponent(this.inquiry)}`
        }

        let sortParam = ''
        if (this.sortables && (Object.keys(this.sortables).length > 0)) {
            const sorts = []
            for (const key of Object.keys(this.sortables)) {
                let direction = ''
                if (this.sortables[key] && this.sortables[key].length > 0) {
                    direction = `|${this.sortables[key]}`
                }

                sorts.push(`${key}${direction}`)
            }

            sortParam = `&sort=${sorts.join(',')}`
        }

        let notSaleParam = ''
        if (this.saleId && (this.saleId > 0)) {
            notSaleParam = `&notSaleId=${this.saleId}`
        }

        this.master.get(`contacts?page=${this.page}${inquiryParam}${notSaleParam}${sortParam}`, (res) => {
            this.loading = false

            if (!res || !res.data) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
                return
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
                return
            }

            this.records = res.data.records
            this.displayed = Math.min(this.records, (res.data.page * res.data.ipp))
            this.exhausted = (res.data.next === null)

            if (this.page > 1) {
                this.contacts = this.contacts.concat(res.data.contacts)
                this.displayed = this.contacts.length
                return
            }

            this.contacts = res.data.contacts;
            this.displayed = this.contacts.length
        })
    }

    /*
    todo: get types of identifications
    */
    public getTypes = (): void => {
        this.master.get('collections/contacts/identificationTypes', res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
            }

            this.types = res.data.IdentificationTypes
        })

        this.master.get(`collections/sales/buyertypes`, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
            }

            this.buyerTypes = res.data.BuyerTypes ? res.data.BuyerTypes.slice() : []
            
            const defaultType = this.buyerTypes.find(item => item['isIndividual'] == true)
            if (!defaultType) {
                return
            }

            this.buyerTypeId = parseInt(defaultType['id'])
        })
    }

    // todo: listen to output of navbar
    public listenerNav = (e): void => {

        switch (e['message']) {
            case 'changeLanguage':
                this.language = e['value']
                break;
            case 'setPermissions':
                // permissions on user list

                this.permissions = e['permissions'][1].childrens[0].permissions;
                if (this.permissions.includes('view')) {
                    this.reset()
                    this.fetch()
                }
                break;
            default:
                break;
        }
    }

    // ? address lookup
    // verify if the corporate is already exist
    public searchZipCode(): void {
        const code = this.saleBuyerInformation.get('zipCode').value
        if (!code || code.length < MIN_SEARCH_CHARS) {
            return
        }

        if (this.zipInquiry && (code === this.zipInquiry)) { // hasn't changed?
            return
        }

        clearTimeout(this.timeOutID)

        this.timeOutID = setTimeout(() => {
            this.zipCodeList = []
            this.zipInquiry = code

            this.master.get(`readLocationByZipcode?zipcode=${code}`, res => {
                if (!res) {
                    this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] })
                    return
                }

                if (res.status !== 200) {
                    return
                }

                this.zipCodeList = res.data.locations

                // autocomplete
                if ((code >= 5) && (this.zipCodeList.length === 1)) {
                    this.selectZipCode(this.zipCodeList[0])
                }
            })
        }, SEARCH_DEBOUNCE_MILLIS)
    }

    // set the address exist
    public selectZipCode = (address: Object): void => {
        if (!address || !this.saleBuyerInformation) {
            return
        }

        this.saleBuyerInformation.get('city').setValue(address['city'])
        this.saleBuyerInformation.get('state').setValue(address['state'])
        this.saleBuyerInformation.get('county').setValue(address['county'])
        this.saleBuyerInformation.get('zipCode').setValue(address['zipcode'])
        this.saleBuyerInformation.get('country').setValue('United States')

        this.zipCodeList = []
    }

    public contactSelected = (id): void => {
        this.form.get('inquiry').setValue('', {emitEvent: false})
        this.contactSelectedEvent.emit(id)
    }

    public updateIsIndividual(e) {
        if (e.target.value !== "1") {
            this.saleBuyerInformation.get("isIndividual").setValue(false)
            this.isIndividual = false
            this.modifyValidators()
            return
        }

        this.saleBuyerInformation.get("isIndividual").setValue(true)
        this.isIndividual = true
        this.modifyValidators()
    }

    public saveContact(){
        if (this.saleBuyerInformation.invalid) {
            return
        }

        this.createContact()
    }

    public createContact() {
        this.saleBuyerInformation.get('roles').setValue(JSON.stringify(["salesLead"]))
        if (this.saleBuyerInformation.invalid){
            return
        }
        
        const information = this.saleBuyerInformation.getRawValue()
        this.master.post(`contacts/quick`, { information: information }, res => {
            if (!res) {
                this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
            }

            if (res.status !== 200) {
                this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
            }

            if (!res.data.contactCreated) {
                return
            }

            const contactId = parseInt(res.data.contactCreated['id'])
            this.contactSelected(contactId)
            
            this.isIndividual = true
            this.saleBuyerInformation.reset()
            this.modifyValidators()
            this.form.reset()
        })
    }

    /**
     * Ensure the default tab is selected.
     */
    public defaultTab() {
        const findTab = document.getElementById('contact-find')
        const addTab = document.getElementById('contact-add')

        if (!addTab || !findTab) {
            return
        }

        addTab.classList.remove('show', 'active')
        findTab.classList.add('show', 'active')
    }

    public modifyValidators() {
        this.saleBuyerInformation.clearValidators()
        if (this.isIndividual) {
            this.saleBuyerInformation.get('firstName').setValidators([Validators.required])
            this.saleBuyerInformation.get('lastName').setValidators([Validators.required])
            this.saleBuyerInformation.get('companyName').setValidators(null)
        }
        else {
            this.saleBuyerInformation.get('companyName').setValidators([Validators.required])
        }

        this.saleBuyerInformation.get('firstName').updateValueAndValidity()
        this.saleBuyerInformation.get('lastName').updateValueAndValidity()
        this.saleBuyerInformation.get('taxPayerNumber').updateValueAndValidity()
        this.saleBuyerInformation.get('companyName').updateValueAndValidity()
    }
}
