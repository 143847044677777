import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// services
import { MasterService } from '../../../../services/master.service'
import { MessageService } from '../../../../services/message.service'
import { SecurityService } from '../../../../services/security.service'
import * as languageLibrary from '../../../../services/language'

@Component({
  selector: 'notes-inventory',
  templateUrl: './notes-inventory.component.html',
  styleUrls: ['./notes-inventory.component.scss']
})
export class NotesInventoryComponent implements OnInit {
  /*
 * variables
 */
  @Input() isEdit: boolean = true
  @Input() isFlat: boolean = false
  // save the language
  public language: string = localStorage.getItem('language') ? localStorage.getItem('language') : 'EN'
  // set all words
  public words = languageLibrary.language
  // define note selected
  public noteSelected: number = 0
  // define note selected
  public noteIndex: number = 0
  // define if form window is open
  public windowOpen: boolean = true
  // loading state
  public loading: boolean = false
  // permissions
  @Input() permissions: string[] = []
  // permissions
  @Input() inventoryId: number = 0
  // save the note
  public notes = []

  // A handle to navbar event messages so we can unsubscribe later.
  private navbarWatcher: any = null
  
  // Group of inputs
  public information = new FormGroup({
    text: new FormControl(null, [Validators.required]),
    id: new FormControl(null),
    alertFlag: new FormControl(null),
    inventoryId: new FormControl(null, [Validators.required])
  })
  /*
  * functions
  */


  // get notes
  public getNotes = (): void => {
    this.loading = true
    this.master.get(`inventory/${this.inventoryId}/notes`, (res) => {
      this.loading = false
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.notes = res.data.notes
      if (res.data.alertExists) {
        (document.getElementById('btn-note-alert') as HTMLButtonElement).click();
      }
    })
  }


  // add new note
  public save = (): void => {
    const identity = parseInt(this.information.get('id').value)
    this.information.get('inventoryId').setValue(this.inventoryId)
    if (isNaN(identity)) {
      this.createNote()
      return
    }

    this.modifyNote(identity)
  }

  public createNote() {
    if (this.information.invalid) {
      return
    }

    this.master.post(`inventory/${this.inventoryId}/notes`, { information: this.information.value }, (res) => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.resetInformation()
      this.notes = res.data.notes
    })
  }

  public modifyNote(identity: number) {
    if (this.information.invalid) {
      return
    }

    this.master.put(`inventory/${this.inventoryId}/notes/${identity}`, { information: this.information.value }, (res) => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.resetInformation()
      this.notes = res.data.notes
    })
  }


  public deleteNote(identity: number) {
    this.master.discard(`inventory/${this.inventoryId}/notes/${identity}`, (res) => {
      if (!res || !res.data) {
        this.ms.sendMessage("alert", { type: "danger", text: this.words[this.language]['apiNoResponse'] });
        return
      }

      if (res.status !== 200) {
        this.ms.sendMessage("alert", { type: "danger", text: res.data.error });
        return
      }

      this.resetInformation()
      this.notes = res.data.notes
    })
  }

  public resetInformation = (): void => {
    this.noteSelected = 0
    this.information.reset();
    this.information.get('inventoryId').setValue(this.inventoryId);
    (document.getElementById('save-btn-note').innerHTML = "Create")
  }
  /*
  todo: update functions
  */
  // set note form for update
  public setInformation = (note: Object): void => {
    (document.getElementById('save-btn-note').innerHTML = "Update")
    this.noteSelected = note['id']
    this.information.setValue({
      text: note['text'],
      id: note['id'],
      alertFlag: note['alertFlag'],
      inventoryId: this.inventoryId
    })
  }

  /*
    * life cycles
    */
  constructor(private master: MasterService, private ms: MessageService, private security: SecurityService) {
    this.navbarWatcher = this.ms.channelComponents$.subscribe(res => {
      if (res.message == 'changeLanguage') {
        this.language = res.value
      }
    })
  }

  ngOnInit() {
    this.resetInformation()

    if (!this.inventoryId) {
      return
    }

    this.getNotes()
  }

  ngOnDestroy() {
    if (!this.navbarWatcher) {
      return
    }

    this.navbarWatcher.unsubscribe()
  }


}
